import { sendRequest } from "../../hooks/requests/useRequest";

export async function getListUseOfCfdi(tax_regime_id, is_physical_person = false)
{
    let requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `CfdiCatalog/ListUseCfdi/${tax_regime_id}/${is_physical_person}`);

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }    
}

export async function getListUseOfCfdiGeneral(tax_regime_id, is_physical_person = false)
{
    let requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `CfdiCatalog/ListUseCfdiGeneral`);

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }    
}