import React, { Fragment, useState, useEffect } from 'react';
import { LogIn, User, Bell, Minimize, Search, X } from 'react-feather';
import '../../assets/css/pantalla1.css'
import { Label } from 'reactstrap';



import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';

import { Notification, Admin, Account, LogOut } from '../../constant'

import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { useHistory } from 'react-router-dom';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();


const Rightbar = (props) => {
  const [searchresponsive, setSearchresponsive] = useState(true)
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [selected, setSelected] = useState("en")
  const history = useHistory();

  const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key)
  };

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true)
    }
  }, []);


  let cat = localStorage.getItem("flebotomistaUser");
  let cat2 = localStorage.getItem("flebotomisaNombre");




  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
      else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }






  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }

  //Mode Moonligth
  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  const cerrarSesion = () => {
    let toma = localStorage.getItem("tomaMuestra");
    let toma2 = localStorage.getItem("flebotomisaNombre");


    localStorage.removeItem("token");
    localStorage.removeItem("tomaMuestra");
    localStorage.removeItem("id");
    localStorage.removeItem("nameUser");
    localStorage.removeItem("sectionProcessArray");
    localStorage.removeItem("usersArray");
    localStorage.removeItem("consentimientoInformado");
    localStorage.removeItem("branchId");

    if (toma == "2") {
      setTimeout(() => {
        history.replace(`${process.env.PUBLIC_URL}/toma`);
      }, 500);
    }
    else if (toma == "4") {
      setTimeout(() => {
        history.replace(`${process.env.PUBLIC_URL}/envios`);
      }, 500);
    }
    else {
      setTimeout(() => {
        history.replace(`${process.env.PUBLIC_URL}/seguimiento`);
      }, 500);
    }
  }

  const [nameUser, setNameUser] = useState("User");
  //141202

  useEffect(() => {
    localStorage.getItem("nameUser") !== null && setNameUser(localStorage.getItem("nameUser").toUpperCase());
  }, []);

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0 text-white">

        <ul className="nav-menus">

          <li className="d-flex align-items-center">
          </li>

          <li className="mx-auto">
            <Label style={{ color: "#143a52", fontSize: "18px", fontWeight: "bold", textAlign: "center", display: "block" }}>
              Toma de Muestra
            </Label>
          </li>
          <li></li>
          <li></li>
          <li></li>
          <li>
            <div className="mode text-white" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>
          <li>
          </li>
          <li className="maximize-Icono-Maximizar text-white"><a className="text-white" href="#Datos" onClick={goFull}><Minimize /></a></li>
          <li className="profile-nav onhover-dropdown p-0 prueba1w">
            <div className="media profile-media" >  <br /> <br />
              <img style={{ width: "80%" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
              <div className="media-body p-2 text-white"><span>{"Flebotomista"} <i className="middle fa fa-angle-down"></i> <br />  </span>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li ><User /><span>{cat2}  </span> </li>
              <li onClick={cerrarSesion}><LogIn /><span>Cerrar sesión</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}
export default translate(Rightbar);