import React from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { ExportInvoiceDefaultValues } from '../../data/const';
import { PaginationComponent } from '../Pagination/PaginationComponent';
import { GetInvoiceId } from '../../services/invoice/invoice';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';

export const ModalInvoiceRelateds = (props) => {
    const {
        modalTitle, modalListEgress,
        listNims,
        toggleModalListEgress,
        handleSelectInvoice,
        setPage, page, limit, setLimit, totalPages
    } = props;    

    const [createSweet] = useSweetAlert();

    const selectInvoice = async (invoice) => 
    {
        if (invoice?.id_invoice)
        {
            const dataInvoice = await GetInvoiceId(invoice?.id_invoice, true);

            console.log(dataInvoice);

            if (dataInvoice.code === 200)
            {
                handleSelectInvoice([dataInvoice?.data]);
                toggleModalListEgress();
            }
            else
                createSweet("warning", "warning", "Facturas", "No fue posible encontrar la información de la factura");
        }
    }

    return (
        <Modal isOpen={modalListEgress} toggle={toggleModalListEgress} centered={true} size='xl'>
            <ModalHeader toggle={toggleModalListEgress} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" >
                        <div className='table-responsive'>
                            <Table size='xs' className='table-bordered'>
                                <thead className="theadInvoice">
                                    <tr>
                                        <th className='text-center labeltheadInvoice'>Acciones</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thFolio}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thDate}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thPayMethod}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thTotal}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thUuid}</th>
                                        <th className='text-center labeltheadInvoice'>{ExportInvoiceDefaultValues.tableHeaderEgress.thPaid}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listNims.length > 0 && listNims.map(_invoice => {
                                            return (
                                                <tr className='text-center' key={'trInvoice-'+_invoice.value}>
                                                    <td>
                                                        <Button color='primary' outline size='sm' onClick={() => selectInvoice(_invoice)}>Seleccionar</Button>
                                                    </td>
                                                    <td>{_invoice.folio}</td>
                                                    <td>{_invoice.date}</td>
                                                    <td>{_invoice.code_pay_method}</td>
                                                    <td>{_invoice.total}</td>
                                                    <td>{_invoice.uuid}</td>
                                                    <td>{_invoice.is_paid ? 'PAGADA' : 'PENDIENTE DE PAGO'}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col sm="12" md="12">
                        <PaginationComponent
                            setPage={setPage}
                            page={page}
                            limit={limit}
                            setLimit={setLimit}
                            totalPages={totalPages}
                        />
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}
