import React, { useState } from 'react';
import { Button, Label, ModalBody, ModalFooter, Row, Col, Modal, ModalHeader, Input } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ModalCrudGlobalComment } from './ModalCrudGlobalComment';

export const ModalTableGlobalComment = (props) => {
    const {
        toggleTableGlobalComment, modalTableGlobalComment, method, handleCreateGlobalComment, modalCrudComment, toggleCrudComment,
        saveCreate, handleInputChange, text, type, comments,handleInsert, handleDeleteCommentText, loading, search, setSearch
    } = props;

    const actions = (rowData) => {
        return (
            <div className="d-flex justify-content-center align-items-center gap-2">
             
            <Button outline color="primary" size="sm" onClick={() => handleInsert(rowData)} className='mr-2' disabled={loading}>
                <i className="fa fa-plus"></i>
            </Button>

            <Button outline color="danger" size="sm" onClick={() => handleDeleteCommentText(rowData.id_global_comment_text)} disabled={loading}>
                <i className="fa fa-trash"></i>
            </Button>
        </div>
        );
    };

    return (
        <>
            <Modal isOpen={modalTableGlobalComment} toggle={toggleTableGlobalComment} backdrop="static" keyboard={false} centered={true} className="modal-lg" style={{ maxWidth: "50vw", width: "50%", height: "50vh" }} >
                <ModalHeader toggle={toggleTableGlobalComment} className="bg-primary">
                    Comentarios Globales
                </ModalHeader>
                <ModalBody>
                    <Row className="d-flex justify-content-between">
                        <div className="px-3 mb-2 d-flex me-auto">
                            <Button outline color="primary" size="xs" onClick={toggleCrudComment}>
                                <Label className="labelcreatePatient p-1">Crear Texto</Label>
                            </Button>
                        </div>

                        {/* Search */}
                        <div className="px-3 mb-2 d-flex ms-auto">
                            <Input type="search" placeholder="Busqueda..." className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </Row>
                    <div className='table-responsive'>
                        <DataTable
                            value={comments}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20]}
                            className="data-table"
                            emptyMessage={comments.length === 0 && !loading ? (
                                <div className="text-center">
                                    <img 
                                        src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} 
                                        style={{ width: "45%" }} 
                                        alt="Sin resultados" 
                                    />
                                    <br />
                                    <p className='badge badge-light-primary mt-2' style={{fontSize: "15px"}}>
                                        No hay coincidencias con la búsqueda.
                                    </p>
                                </div>
                            ) : loading ? (
                                <>
                                    <Col sm="12" md="12" lg="12" className="text-center pt-5">
                                        <img
                                            alt="Cargando..."
                                            src={require("../../assets/images/loginHelenLabs/HelenLabs.png")}
                                            className="fa-spin"
                                            style={{ width: "15%" }}
                                        />
                                    </Col>
                                    <Col sm="12" md="12" lg="12" className="text-center mt-1">
                                        <p className="txt-secondary">Cargando...</p>
                                    </Col>
                                </>
                            ) : null}
                            

                            
                        >
                            <Column
                                field="text"
                                header="Texto"
                                sortable
                                headerStyle={{
                                    backgroundColor: "#1d5b83",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    textAlign: "center",  

                                }}
                            />
                        

                        <Column
                            header="Acciones"
                            body={actions}
                            exportable={false}
                            headerStyle={{
                                backgroundColor: "#1d5b83",
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "right",  
                            }}
                        />

                        </DataTable>

                       
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button size="sm" outline color="danger" type="button" onClick={toggleTableGlobalComment}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>


            <ModalCrudGlobalComment
                {...{
                    method,
                    handleCreateGlobalComment,
                    modalCrudComment,
                    toggleCrudComment,
                    saveCreate,
                    handleInputChange,
                    text,
                    type,
                }}
            />
        </>
    );
};
