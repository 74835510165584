import React from 'react'
import { Modal, ModalHeader, Col, ModalBody, Row, Button } from 'reactstrap';
// import './StyleModalPatients.css'
import { TableStriped } from '../../components/tables/TableStriped';

export const ModalMassLoadErrors = (props) => {

    const {
        modal, toggle, modalTitle, 

        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, downloadMassiveSampligFile, 
        massiveSamplig,
        
    } = props;
    
        let _newErrorMassive = []

        massiveSamplig.records.forEach((record, index) => {
            if (record.error) {
                _newErrorMassive.push({
                    key: index,
                    name: record.nombre,
                    paternalSurname: record.apellido_paterno,
                    maternalSurname: record.apellido_materno,
                    birthdate: record.fecha_nacimiento,
                    gender: record.genero,
                    error: record.error
                })
            }
    
        });
        return (
        
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className='modal modal-xl' scrollable={true} style={{ maxWidth: "80vw", width: "80%", height: "80vh" }}>
            <ModalHeader toggle={toggle} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Col sm="12">
                <div className="table-responsive">
                        {
                            _newErrorMassive.length > 0 ? (
                                <TableStriped
                                    cabeceras={["Nombres", "Apellido paterno", "Apellido materno", "Fecha de nacimiento", "Género", "Errores"]}
                                    items={_newErrorMassive}
                                    nextPage={goNextPage}
                                    previousPage={goPreviousPage}
                                    totalPageCount={totalPages}
                                    currentPage={actualPage}
                                    goToPage={goToPage}
                                    paginingBack={true}
                                />
                            ) : (
                                <div style={{ textAlign: "-webkit-center" }}>
                                    <img
                                        className="img-fluid"
                                        src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")}
                                        style={{ width: "32%" }}
                                        alt="Sin resultados"
                                    />
                                    <br />
                                     <p className='f-14 badge badge-light-primary'>
                                        No se encontraron errores en la carga masiva.
                                    </p>
                                </div>
                            )
                        }
                    </div>
                    <br />
                </Col> 
                
                <br />
                <Row>
                    <Col className='text-right' xs="12" sm="12" md="12" lg="12" xl="12">
                    {
                        _newErrorMassive.length > 0 ? (
                            <Button 
                            type="button" 
                            outline 
                            color="primary" 
                            size='sm' 
                            className={`mt-1 text-center ${massiveSamplig.fileReport ? '' : 'disabled'}`} 
                            onClick={() => massiveSamplig.fileReport && downloadMassiveSampligFile( massiveSamplig.fileReport )}
                        >
                            Descargar
                        </Button>
                        ): null
                    }
                    </Col>
                    
                </Row>      
            </ModalBody>
        </Modal>
    )
}
