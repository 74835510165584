import { sendRequest } from "../../hooks/requests/useRequest";


export async function getAllNim (_nim)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `WorkOrder/SearchWorkOrderFromNim/${_nim}`);

    if (response.code === 200)
        return {
            code: response?.code ,
            data: response?.data,
        }
    else 
        return response;
}





