import React, { Fragment } from 'react'
import {useParams } from "react-router-dom";
import { Card, FormGroup, Row, Col, CardHeader, Button, Container, Table, Form, Label, Input } from 'reactstrap';
import 'animate.css';
import './styleCash.css';
import { useCash } from '../../hooks/cash/useCash';
import { ModalPreviewCash } from './ModalPreviewCash';
import { ModalReportCash } from './ModalReportCash';
import Select from 'react-select';
import { ModalPreviewTicket } from './ModalPreviewTicket';
import { ModalCashView } from './ModalCashView';



export const IndexCash = () => {

    let branchs = localStorage.getItem('branchName');
    let user = localStorage.getItem('nameUser');

    const {
        //*CONFIGURATION CASH
        navigatePrev,  viewTab, formCash,capture_amount,handleInputChangeCash,loading, 

        //*INDICATORS
        cancell, discountT, comments, saveCreate, formatter, totalCancell,totalPatients,

        //*HISTORIC
        dataHistoric,dateInit, dateEnd, changeDate, handleHistoric,previewCash, toggleCash, typeModal, modalC,typeAmoun, amountfin,

        //*FINAL REPORT
        dataReport, modalFullRef, handleReport, close, modalProps,totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1,dataPaymets, dataCancell, 
        dataDiscount, dataPatient, dataDevolution,mDateRange1, mDateRange2, getReport, mSelectBranch, handleSelectValuesCash,
        viewFormFilters, printCashClosing,branches, totalPaymets,exportToExcel,

        //*FINAL REPORT TYPE COMPANY
        type_company,handleClean,dataMDiscount,dataUser,ids_users,number_format,sumPayMethods,

        //*PRINT TICKETS
        modalPrint,toggleModalPrint,handleViewTickets,printTicket,loadingPrint,loadingCreate,downloadExcel,
        
        //*PREVIEW PRINT 
        modalPreview,toggleModalPreview,handleViewPreview,
         // ? CONFIGURATION
         _type_client,

         //* CASH and CREDIT
         downloadExcelCredits, downloadExcelOthers

    } = useCash();

    let wilso = amountfin[0]


    const { Admin } = useParams();



    const totalRow = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
        <td ></td>
        <td style={{textAlign:"end"}} className='labeltheadInvoice'>Total:</td>
        <td style={{textAlign:"center"}}  className='labeltheadInvoice'>${number_format(sumPayMethods ?? 0, 2)}</td>
    </tr> 
);
let _arrayNew = ""
let _total = ""

if (_type_client === "https://insan.helen-sw.com" && amountfin.length > 0) {
    _arrayNew = amountfin[0]
    _total = _arrayNew["EFECTIVO"]?.Amount    
}




    return (
        <Fragment>
            <Container fluid={true}>
                <Row className="mt-2 mb-3">
                    <Col sm="3" >
                    <Card  >
                        <CardHeader className='p-3  bg-warning'>
                          <i className='fa fa-filter fIconFilter f-20 '></i>
                          <label className='f-14'>Acciones rapidas</label>
                        </CardHeader>
                        <Col sm="12" style={{padding : "20px"}} >
                        <div className={loadingCreate ? "button-cash-loading-create" : 'button-cash-Historic'} onClick={previewCash} >
                        <i className="fa fa-calendar-o"></i>  Historico de Cortes
                        </div>
                        <br />
                        <div className={loadingCreate ? "button-cash-loading-create" : 'button-cash-success'}  onClick={saveCreate} >
                        <i className="icofont icofont-ui-check"></i>  Generar Corte
                        </div>
                        <br />
                        <div className={loadingCreate ? "button-cash-loading-create" : 'button-cash-preview'}  onClick={() => handleViewPreview()} >
                        <i className="fa fa-eye"></i>  Previsualizar Corte
                        </div>
                        <br />
                        <div className={loadingCreate ? "button-cash-loading-create" : 'button-cash-danger'}   onClick={() => navigatePrev(viewTab)} >
                        <i className="icofont icofont-ui-close"></i>  Cancelar Corte
                        </div>
                        <br />
                        <div className={loadingCreate ? "button-cash-loading-create" : 'button-cash-report'}   onClick={() => handleViewTickets()} >
                        <i className="icofont icofont-printer"></i> Reimprimir Recibos
                        </div>
                        <br />
                        {
                            Admin ===  undefined ? "" :
                            <div className='button-cash-report' ref={modalFullRef} onClick={() => handleReport()} >
                            Reporte
                            </div>
                        }
                        </Col>
                        </Card>
                
                    </Col>
                    <Col sm="9">
                        <Card>
                            <Col sm="12" md="12" lg="12"> 
                                <Row className='pt-3' >
                                    <Col sm="3" style={{padding:"10px"}} >
                                        <Col sm="12" className='b-card-cash'>
                                        <Row >
                                            <Col sm="4">
                                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/price/cash-devolution.png")} style={{ width: "50px", height: "50px" }} />
                                            </Col>
                                            <Col sm="8" className='p-l-0 p-r-0'>
                                                <label className='f-w-600 txt-warning'>Total en cancelación</label> <br />
                                                <label className='f-w-600 f-14 txt-danger'>$ {number_format(dataDevolution ?? 0 , 2)}</label>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Col>
                                    <Col sm="3" style={{padding:"10px"}} >
                                        <Col sm="12" className='b-card-cash'>
                                        <Row >
                                            <Col sm="4">
                                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/price/cash-devolution.png")} style={{ width: "50px", height: "50px" }} />
                                            </Col>
                                            <Col sm="8" className='p-l-0 p-r-0'>
                                                <label className='f-w-600 txt-warning'>Total en descuento</label> <br />
                                                <label className='f-w-600 f-14 txt-danger'>${(number_format(discountT ?? 0,2))}</label>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Col>
                                    <Col sm="3" style={{padding:"10px"}} >
                                        <Col sm="12" className='b-card-cash'>
                                        <Row >
                                            <Col sm="4">
                                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/price/cash-delete.png")} style={{ width: "50px", height: "50px" }} />
                                            </Col>
                                            <Col sm="8" className='p-l-0 p-r-0'>
                                                <label className='f-w-600 txt-warning'>Ordenes canceladas</label> <br />
                                                <label className='f-w-600  f-14'>{totalCancell ?? 0}</label>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Col>
                                    <Col sm="3" style={{padding:"10px"}} >
                                        <Col sm="12" className='b-card-cash'>
                                        <Row >
                                            <Col sm="4">
                                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/price/patients.svg")} style={{ width: "50px", height: "50px" }} />
                                            </Col>
                                            <Col sm="8" className='p-l-0 p-r-0'>
                                                <label className='f-w-600 txt-warning'>Total de pacientes</label> <br />
                                                <label className='f-w-600  f-14'>{totalPatients ?? 0}</label>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Col>
                                    
                                </Row> <br /> 
                                <Row className='pt-4'>
                                    <Col sm="5"> 
                                    <div className='hr-detail-patient'>
                                    <i className="fa fa-circle-o text-info f-12 pt-2"></i>&nbsp;<label className='f-w-600 pt-2' style={{fontSize:"13px"}}>Generar corte de caja</label>
                                    </div> <br />
                                    <div className='project-box p-1' style={{ borderWidth: '0px', opacity: "0.8" }}>
                                    <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "11px" }}>
                                            <i className="fa fa-circle text-info"></i>  Responsable: <label className='txt-secondary f-w-600'>&nbsp;{user ?? "-----"}</label>
                                        </label>
                                    </Col>
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "11px" }}>
                                            <i className="fa fa-circle text-info"></i>  Sucursal: <label className='txt-secondary f-w-600'>&nbsp;{branchs ?? "-----"}</label>
                                        </label>
                                    </Col>
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "11px" }}>
                                            <i className="fa fa-circle text-info "></i>  Monto a reportar en caja: <label className='f-w-600 f-12'>&nbsp;{formatter.format(formCash.capture_amount ?? 0)}</label>
                                        </label>
                                    </Col>
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "11px" }}>
                                            <i className="fa fa-circle text-info"></i>  Efectivo: <input disabled={_type_client === "https://insan.helen-sw.com" ?  true : false} type="number" className='inputCash f-12' placeholder={_type_client === "https://insan.helen-sw.com" ?  "" : 'Ingrese la cantidad'}  value={_type_client === "https://insan.helen-sw.com" ? _total ?? 0 :capture_amount} name="capture_amount" onChange={handleInputChangeCash} />
                                        </label>
                                    </Col>
                                    <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "11px" }}>
                                            <i className="fa fa-circle text-info"></i>  Oberservaciones: 
                                        </label>
                                        <textarea
                                        className='form-control form-control-sm input-air-primary'
                                        name="comments"
                                        rows="3"
                                        value={comments}
                                        onChange={handleInputChangeCash}
                                        style={{fontSize :"12px"}}
                                    >
                                    </textarea>
                                    </Col>
                                    </Row>
                                    </div>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="6">
                                    <div className='hr-detail-patient'>
                                    <i className="fa fa-circle-o text-info f-12 pt-2"></i>&nbsp;<label className='f-w-600 pt-2' style={{fontSize:"13px"}}>Detalles del corte</label>
                                    </div> <br />
                                    <div className="table-responsive">
                                        <Table className='table-sm table-stripped' >
                                            <thead className="theadInvoice">
                                                <tr>
                                                    <th></th>
                                                    <th style={{color : "#fff", textAlign:"center"}} >Método de pago</th>
                                                    <th style={{color : "#fff", textAlign:"center"}} >Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    typeAmoun?.length > 0 && typeAmoun?.sort() && typeAmoun?.map((_find,index) => {
                                                        return <tr key={_find} className='text-center f-w-500' style={{fontSize: "11px"}}>
                                                            <td>
                                                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/price/table-cash.png")} style={{ width: "30px", height: "30px" }} />
                                                            </td>
                                                            <td>{_find ?? "----"}</td>
                                                            <td><label className='f-12 f-w-600 text-success'>{formatter.format(wilso[_find].Amount !== undefined && wilso[_find].Amount)}</label></td>
                                                        </tr>
                                                    })
                                                } 
                                            {totalRow}
                                            </tbody>
                                        </Table>
                                    </div>
                                    </Col>
                                </Row>
                            </Col>
                            <br /> <br />
                        </Card>
                    </Col>
                </Row>
            </Container>
           
            <ModalPreviewCash
                {
                ...{ toggleCash, typeModal, modalC, handleHistoric, dateEnd, dateInit, changeDate, dataHistoric, formatter, user, branchs }
                }
            />
            <ModalPreviewTicket
                {
                    ...{modalPrint,toggleModalPrint,dataHistoric,printTicket,loadingPrint,downloadExcel,handleHistoric,dateEnd, dateInit,changeDate, downloadExcelCredits, downloadExcelOthers}          
                }
            />
            <ModalCashView
                {
                    ...{ modalPreview,toggleModalPreview,typeAmoun,formatter,wilso,number_format,formCash,sumPayMethods,discountT,cancell}          
                }
            />
            <ModalReportCash
                {...modalProps}
                close={close}
                data={dataReport}
                branchs={branchs}
                totalPageCount1={totalPageCount1}
                currentPage1={currentPage1}
                nextPage1={nextPage1}
                previousPage1={previousPage1}
                goToPage1={goToPage1}
                dataPaymets={dataPaymets}
                dataCancell={dataCancell}
                dataDiscount={dataDiscount}
                dataPatient={dataPatient}
                mDateRange1={mDateRange1}
                mDateRange2={mDateRange2}
                handleInputChangeCash={handleInputChangeCash}
                getReport={getReport}
                loading={loading}
                mSelectBranch={mSelectBranch}
                handleSelectValuesCash={handleSelectValuesCash}
                branches={branches}
                formatter={formatter}
                viewFormFilters={viewFormFilters}
                printCashClosing={printCashClosing}
                totalPaymets={totalPaymets}
                exportToExcel={exportToExcel}
                dataMDiscount={dataMDiscount}
                handleClean={handleClean}
                footer={
                    <div
                        className=""
                    >
                        <Col sm="12">
                            <Row>
                                <br />
                                <Col sm="11" className={`${viewFormFilters ? 'm-t-11' : 'd-none'} `} >
                                    <Form onSubmit={(e) => getReport(e, true)} >
                                        <Row>
                                            <Col sm="2" className='p-1' >
                                                <FormGroup>
                                                    <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Fecha de inicio</Label>
                                                    <Input type='date' value={mDateRange1} name='mDateRange1' onChange={handleInputChangeCash} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" className='p-1' >
                                                <FormGroup>
                                                    <Label className='f-w-600 f-12 badge badge-light-primary'>Fecha de cierre</Label>
                                                    <Input type='date' value={mDateRange2} name="mDateRange2" onChange={handleInputChangeCash} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" >
                                                <FormGroup>
                                                <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Sucursal</Label>
                                                    <Select
                                                        name="mSelectBranch"
                                                        isClearable={true}
                                                        placeholder="Selecciona una sucursal"
                                                        options={branches}
                                                        onChange={(e) => handleSelectValuesCash(e, 'mSelectBranch')}
                                                        value={mSelectBranch}
                                                        styles={{
                                                            zIndex : "999999999999"
                                                           
                                                          }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" >
                                                <FormGroup>
                                                <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Usuarios</Label>
                                                    <Select
                                                        name="ids_users"
                                                        isClearable={true}
                                                        placeholder="Selecciona una sucursal"
                                                        options={dataUser}
                                                        onChange={(e) => handleSelectValuesCash(e, 'ids_users')}
                                                        value={ids_users}
                                                        styles={{
                                                            zIndex : "999999999999"
                                                           
                                                          }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2" >
                                                <FormGroup>
                                                <Label type="text" className='f-w-600 f-12 badge badge-light-primary'>Tipo de Ingreso</Label>
                                                    <Select
                                                        name="type_company"
                                                        isClearable={true}
                                                        placeholder="Selecciona un cliente"
                                                        options={[
                                                            { "value": 1, "label": "Particular" },
                                                            { "value": 2, "label": "Empresas" }
                                                          
                                                        ]}
                                                        onChange={(e) => handleSelectValuesCash(e, 'type_company')}
                                                        value={type_company}
                                                        styles={{
                                                            zIndex : "999999999999"
                                                          }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="1">
                                            <Label className='f-w-600 f-12 badge badge-light-primary'>Acciones Rapidas</Label> <br />
                                                <Button type="submit" outline color="primary" size='sm' className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} >Filtrar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                }
            >
            </ModalReportCash>
        </Fragment >
    )
}
