import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal, Button, Form, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Row, Col, Input, Container, FormFeedback, } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MordalFormSection } from './ModalFormSection';

export const ModalSectionExam = (props) => {

    const {
        handleSectionExam, modalSectionExam, toggleSectionExam, titleSectionExam, data, getSections,sections,
        handleInsert, handleDeleteCommentText, loading,
        handleFormSection, modalFormSection, toggleFormSection, titleFormSection, methodFormSection,saveSamplingPlan,
        valueGroup,handleChangeGroup,    selectedSections,handleSectionChange,saveGroup,dat4,selectedSections1,handleDeleteSelection


    }=props;
        console.log("🚀 ~ ModalSectionExam ~ selectedSections1:", selectedSections1)
        console.log("🚀 ~ ModalSectionExam ~ selectedSections:", selectedSections)
        console.log("🚀 ~ ModalSectionExam ~ sections:", sections)

   


        const [info, setInfo] = useState(data);
    
        useEffect(() => {
            setInfo(data);
        }, [data]);



    
        const sectionDropdown = (rowData) => {
            const defaultProfileGroup = selectedSections1.find(
                (item) => item.id_exam === rowData.id_exam
            )?.id_profile_group;
        
            const selectedProfileGroup = selectedSections[rowData.id_exam] || defaultProfileGroup || "";
            console.log("🚀 ~ sectionDropdown ~ selectedProfileGroup:", selectedProfileGroup)
        
            return (
                <Input
                    type="select"
                    value={selectedProfileGroup}  
                    onChange={(e) => handleSectionChange(rowData.id_exam, e)}  
                >
                    <option value="">seleccionar...</option>
                    {sections.length > 0 && sections.map((section) => (
                        
                        <option key={section.value} value={section.value}>
                            {section.label}
                        </option>
                    ))}
                </Input>
            );
        };

    const actions = (rowData) => {
        return (
            <div className="d-flex justify-content-center align-items-center gap-2" style={{ margin: 5, paddingBottom: 10 }}>
                <Button 
                    className="btn btn-pill btn-air-success" 
                    color="danger" 
                    size="sm" 
                    onClick={() => handleDeleteSelection(rowData.id_exam)}  
                >
                    <span className="fa fa-trash"></span>
                </Button>
            </div>
        );
    };

            
 
    
        
    

  return (
    <Modal isOpen={modalSectionExam} toggle={toggleSectionExam} backdrop='static' keyboard={false} centered={true} className="modal-lg" style={{ maxWidth: "50vw", width: "70%", height: "70vh" }}>
        <ModalHeader toggle={toggleSectionExam} className="pb-1 pl-2 pr-2 bg-primary" >
            {"Bloque de examenes"}  
        </ModalHeader>

        <ModalBody>
    
            <div className="text-left" style={{ margin: 5, paddingBottom: 10 }}>
                <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleFormSection}>
                    <span className="fa fa-plus-circle"></span>
                </Button>
            </div>

            <Row>
                <Col sm="12">
                    <div style={{ maxHeight: "300px", overflowY: "auto" }} className="table-responsive">
                        <DataTable 
                            value={info}
                            className='data-table'
                            emptyMessage={
                                <div className="text-center">
                                    <img 
                                        src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} 
                                        style={{ width: "45%" }} 
                                        alt="Sin resultados" 
                                    />
                                    <br />
                                    <p className='badge badge-light-primary mt-2' style={{fontSize: "15px"}}>
                                        No hay coincidencias con la búsqueda.
                                    </p>
                                </div>
                            }
                        >
                            <Column 
                                field="title" 
                                header="Exámen" 
                                sortable 
                                headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize:"12px" }} 
                                style={{ fontSize:"12px" }}
                            />

                            <Column 
                                body={sectionDropdown} 
                                header="Bloque" 
                                sortable 
                                headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize:"12px" }} 
                                style={{ fontSize:"12px", textAlign: 'center' }}
                            />

                            <Column
                                header="Acciones"
                                body={actions}
                                exportable={false}
                                headerStyle={{
                                    backgroundColor: "#1d5b83",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    textAlign: "right",  
                                }}
                            />
                        </DataTable>
                    </div>
                </Col>
            </Row>
        </ModalBody>

        <ModalFooter>
            <Button outline color="primary" onClick={( )=> saveGroup()} >Guardar secciones</Button>
            <Button outline color="danger" type="button" onClick={toggleSectionExam}>Cancelar</Button>
        </ModalFooter>

        { <MordalFormSection { ...{ handleFormSection, modalFormSection, toggleFormSection, titleFormSection, methodFormSection ,saveSamplingPlan,  
          valueGroup,handleChangeGroup
} } /> }
</Modal>

  )
}

