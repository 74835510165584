import { useEffect, useState, useMemo } from 'react';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {getAllClients,getInformationPatient,getAllTypeSample,getAllExam,getAllProfile,getAllRules,saveQuoter,dowloadPdf,getInformationQuatation,updateQuoter,getListQuatations,savePatientMicro} from '../../services/microsanitaria/catalogosMic'
import {getInformationCompany,getInformationAgreement } from '../../services/company/company';
import examsValidations from '../../utils/validations/examsValidations';
import {OperationsAmounts} from '../../utils/validations/calculateWithTax';
import filterValidations from '../../utils/validations/filterValidations';


export const useQuoter = () => {

    //*LOCAL STORAGE
    let typeCommercialLine = localStorage.getItem('commercialLineId');
    let branch = localStorage.getItem('branchId')
    let roleUser = localStorage.getItem('roleUser');
    let nameBranch = localStorage.getItem('branchName')

    const history = useHistory();
    const [createSweet, sweetConfirmation, , , , , sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();
    
    const [listCompanies, setListCompanies] = useState([])
    const [checkTypeClient, setCheckTypeClient] = useState(4);

    //*SELECT TYPE CLIENT
    const [informationPatient, setInformationPatient] = useState([]);
    const [informationCompany, setInformationCompany] = useState([]);

    //*INPUTS SEARCH MICRO
    const [searchSampleState, setSearchSampleState] = useState([]);
    const [examProfileState, setExamProfileState] = useState([]);

    //*RULES
    const [listRules, setListRules] = useState([]);

    //*TOGGLE EXAMS
    const [modalExams, setModalExams] = useState(false);
    const [listExams, setListExams] = useState([]);

    //*DETAIL QUOTER
    const [viewQuoter, setViewQuoter] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)

    //*CONFIGURATION BUTTONS AND TABLE
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredClean, setIsHoveredClean] = useState(false);
    const [hoveredColumn, setHoveredColumn] = useState("");
    const [expandedRow, setExpandedRow] = useState(null);

    //*VIEW QUATATION
    const [informationQuatation, setInformationQuatation] = useState([])

    //*CONFIGURATION AGREEMENT
    const [listAgreement, setListAgreement] = useState([]);

    // * MODAL PATIENT
    const [modalPatient, setModalPatient] = useState(false);
    const [loadingPatientMicro, setLoadingPatientMicro] = useState(false);

    
    const [optionsTotal, setOptionsTotal] = useState({
        subtotal: 0,
        tax: 0,
        total: 0
      });
    
    const [total_tax, setTotal_tax] = useState(0)

    //*FOMR MIC
    const [formQuoterMic, setFormQuoterMic] = useState({
        searchPatientState:[],
        id_company: null,
        id_patient: null,
        id_type_client: null,
        observations: "",
        is_sampling : false,
        date_sample_up:moment().format("D/M/YYYY hh:mm"),
        date_sample: null,
        type_sample_select: null,
        id_exam_temp:null,
        list_type_samples: [],
        statusQuoter:false,
        id_quatation: 0,
        code_quatation:null,
        valid_sampling : false,
        search_quatation:null,
        searchPatientQuoter:[], // ? FIND CODE QUOTER
        start_date: null,
        end_date: null,
        id_patient_search: null,
        id_company_search: null,
        id_agreement: null
    });


    // * FORM PATIENT
    const [formPatient, setFormPatient] = useState({
        id_gender: 0,
        id_state_patient: 1,
        id_municipality_patient: 1,
        id_social_gender_patient: null,
        id_blood_type: null,
        id_tax_regime: null,
        id_use_cfdi: null,
        paternal_surname: null,
        maternal_surname: null,
        name: null,
        birthday: "2025-01-01",
        age: 0,
        curp: "CURPDEFAULT",
        address_patient: "",
        cp_patient: "00000",
        phone: null,
        email_patient: null,
        membership: "0",
        cardnumber: "DEFAULT",
        rfc: "DEFAULT",
        type_person: 1,
        new_social_gender: "",
        scan_ine_check: false,
        setFileINE: "DEFAULT",
        nss:0,
        matricula_buap:""
    })
  
    useEffect(() => {
        let mfrom = (formQuoterMic.start_date === null) ? moment().format("YYYY-MM-DD") : moment(formQuoterMic.start_date).format("YYYY-MM-DD");
        let mto = (formQuoterMic.end_date === null) ? moment().format("YYYY-MM-DD") : moment(formQuoterMic.end_date).format("YYYY-MM-DD");
        getRules()
        getClients(3)
        getSearchFilter(`from=${mfrom}&to=${mto}`)
    }, [])

    const formatAmount = (amount, decimals) => 
        {
            return parseFloat(
                (Math.trunc(amount * 100000000) / 100000000).toFixed(decimals),
            );
        }

    //*CALCULATE AMOUNT
      useMemo(() => {
      
        if (formQuoterMic.list_type_samples?.length > 0)
        { 
            
            let subtotal = 0;
            let taxes = 0;
            let total = 0;
            let _valid = ""

            formQuoterMic.list_type_samples.forEach(_find => {
                
                if (_find?.list_determinations !== null && _find?.list_determinations.length > 0) {

                    _find.list_determinations.forEach(_search => {
                        
                        setTotal_tax(_search?.value_iva)
                        switch (_search?.config_iva) {
                            case true:

                            if (_search?.discount !== 0) {
                                
                             _valid = OperationsAmounts(_find?.cant,_search?.price_discount,true,_search?.value_iva,_search?.discount)

                            }
                            else  _valid = OperationsAmounts(_find?.cant,_search?.price,true,_search?.value_iva,_search?.discount_par)                               

                            // ? TOTAL
                            _search.discount_tota = _valid.total
                            _search.can_total = _valid.total
                            subtotal += _valid.subtotal; 
                            taxes += _valid.total_tax 
                            total += _valid.total
                                break;
        
                            default:

                            if (_search?.discount !== 0) {
                            _valid = OperationsAmounts(_find?.cant,_search?.price_discount,false,_search?.value_iva,_search?.discount)
                              
                            }
                            else _valid = OperationsAmounts(_find?.cant,_search?.price,false,_search?.value_iva,_search?.discount_par)
                                  
                            // ? TOTAL
                            _search.discount_tota = _valid.total
                            _search.can_total = _valid.total
                            subtotal += _valid.subtotal; 
                            taxes += _valid.total_tax 
                            total += _valid.total
                                break;              
                            }
                    });
                }  
            })   
            console.log("🚀 ~ useMemo ~ total:", total)


            setOptionsTotal({
                subtotal: formatAmount(subtotal,  2),
                tax: formatAmount(taxes,  2),
                total: formatAmount(total, 2)
            })
        }
    }, [formQuoterMic])

    //*TYPE CLIENT
    const getClients = async (_type) =>
        {
            const company = await getAllClients(3);
    
            if (company?.length > 0)
            {
                const list = company?.map(_find => {
                    return {
                        value: _find?.id_company,
                        label: _find?.code + "-" + _find?.name,
                    }
                });
                setListCompanies(list);
            }
        }

    //*TYPE RULES
    const getRules = async (_type) =>
        {
            const rules = await getAllRules(_type);
    
            if (rules?.length > 0)
            {
                const list = rules?.map(_find => {
                    return {
                        code: _find?.id_mic_nom,
                        name: _find?.name,
                    }
                });
                setListRules(list);
            }
            else setListRules([]);
        }

    //*TYPE SERVICE
    const handleChangeTypeClient = (_typeClient, validClient = -1) => {

        if (validClient === -1 && _typeClient !== 4 ) {
            sweetConfirmationChangeClient("Al cambiar el tipo de ingreso, perderas los datos ingresados", "Estas seguro de realizar esta acción", "warning", _typeClient, true, handleChangeTypeClient)
            return;
        }

        if (_typeClient === 4) {
            let mfrom = (formQuoterMic.start_date === null) ? moment().format("YYYY-MM-DD") : moment(formQuoterMic.start_date).format("YYYY-MM-DD");
                let mto = (formQuoterMic.end_date === null) ? moment().format("YYYY-MM-DD") : moment(formQuoterMic.end_date).format("YYYY-MM-DD");
                getSearchFilter(`from=${mfrom}&to=${mto}`)     
        }

        if (!validClient) {
            return;
        }
        else {
            setCheckTypeClient(_typeClient)
            if (_typeClient === 1) {
                
                setFormQuoterMic({
                    ...formQuoterMic,
                    id_company: null,
                    id_patient: null,
                    id_type_client: null,
                    observations: "",
                    is_sampling : false,
                    date_sample_up:moment().format("D/M/YYYY hh:mm"),
                    date_sample: null,
                    type_sample_select: null,
                    id_exam_temp:null,
                    list_type_samples: [],
                    listQuoterTest: [],
                    statusQuoter:false,
                    id_quatation: 0,
                    code_quatation:null,
                    valid_sampling : false,
                    search_quatation:null
                }); 
                setInformationCompany([])
                setOptionsTotal({
                    subtotal: 0,
                    tax: 0,
                    total: 0
                  });
                setViewQuoter(false);
            }
            else {
                setFormQuoterMic({
                    ...formQuoterMic,
                    id_company: null,
                    id_patient: null,
                    id_type_client: null,
                    observations: "",
                    is_sampling : false,
                    date_sample_up:moment().format("D/M/YYYY hh:mm"),
                    date_sample: null,
                    type_sample_select: null,
                    id_exam_temp:null,
                    list_type_samples: [],
                    listQuoterTest: [],
                    statusQuoter:false,
                    id_quatation: 0,
                    code_quatation:null,
                    valid_sampling : false,
                    search_quatation:null,
                    searchPatientState:[]
                }); 
                setInformationPatient([])
                setInformationCompany([])
                setOptionsTotal({
                    subtotal: 0,
                    tax: 0,
                    total: 0
                  });
                setViewQuoter(false);
                
            }  
        }
    }

    //*TYPE PARTICULAR
    const handleChangePatient = async (e,unique_uuid = 0) => 
        {
            const patient_id = e?.target?.value;  

            let alternative_methods = "";

            if (unique_uuid !== 0) {
                alternative_methods = `?unique_uuid=${unique_uuid}`;
            }
    
            if (patient_id !== 0 && patient_id !== null && patient_id !== "" && patient_id !== "0")
            {
                const getPatient = await getInformationPatient(patient_id,alternative_methods);
    
                if (getPatient !== null)
                {
                    setInformationPatient({
                        birthday: getPatient?.birthday ?? "----",
                        phone: getPatient?.phone ?? "----",
                        email: getPatient?.email ?? "----",
                        name: getPatient?.name ?? "----",
                        paternal_surname: getPatient?.paternal_surname ?? "----",
                        maternal_surname: getPatient?.maternal_surname ?? "----", 
                        client: ( getPatient?.name + " " +  getPatient?.paternal_surname + " " +  getPatient?.maternal_surname )
                    });    

                    setFormQuoterMic({
                        ...formQuoterMic,
                        id_patient: getPatient?.id_patient,
                    });
                }
            }
            else {
                setInformationPatient([])
                setCheckTypeClient(1)
                setFormQuoterMic({
                    ...formQuoterMic,
                    id_patient: null,
                });
            }
        }

    //*COMPANY
    const handleChangeCompany = async (e) =>
        {
            if (e !== null && e?.value !== undefined)
            {
                const getList = await getInformationCompany(e.value);
    
                if (getList !== null)
                {
                    const getAgreement = await getInformationAgreement(e.value);
                    if (getAgreement !== null) {
                        const list = getAgreement?.map(_find => {
                            return {
                                value: _find?.id_agreement,
                                label: _find?.code,
                            }
                        });
                        setListAgreement(list);
                    }
                    
                    const list = getList.listCompanyBusinessNames?.map(_find => {                        
                        return {
                        "name": _find?.business_name ?? "----",
                        "code": getList?.code ?? "----",
                        "phone": _find?.phone ?? "----",
                        "name_company_turn": getList?.name_company_turn ?? "----",
                        "contact_email": _find?.email ?? "----",
                        "require_invoice": getList?.require_invoice ? "Si" : "No",
                        "payment": (getList?.payment_by_company && getList?.payment_by_patient) ? "Ambas partes" : (getList?.payment_by_company) ? "Empresa" : "Paciente" ?? "----"   
                        }
                    });                    
                    setInformationCompany(list); 
                    setFormQuoterMic({
                        ...formQuoterMic,
                        id_company: getList?.id_company,
                    });
                }
            }   
            else {
                setInformationCompany([])
                setFormQuoterMic({
                    ...formQuoterMic,
                    id_company: null,
                });
            }     
        }

    //*AGREEMENT
    const handleChangeAgreement = (e) => 
        {
            const _agreement  = e;  

            if (e !== null  && e?.value !== undefined && e?.value !== "" ) {

                setFormQuoterMic({
                    ...formQuoterMic,
                    id_agreement: _agreement,
                });
                
            }
   
    }
     
    //*OBSERVATIONS
    const handleChangeComments = async (e) => 
        {
            const _comment = e?.target?.value;  

            if (e !== null && e?.target?.value !== undefined) {
                setFormQuoterMic({
                    ...formQuoterMic,
                    observations: _comment,
                });
            }
            else {
                setFormQuoterMic({
                    ...formQuoterMic,
                    observations: null,
                });
            }
        }

    //*REQUIERE IS SAMPLING
    const handleChangeSampling = async (e) =>
        {
            const _check = e?.target?.checked;  
            
            if (_check) {
                setFormQuoterMic({
                    ...formQuoterMic,
                    is_sampling: _check,
                });
            
            } else {
                setFormQuoterMic({
                    ...formQuoterMic,
                    is_sampling: false,
                });
            }
        }
        
    //*DATE
    const handlePickerValues = async (e) =>
        {
            if (e && moment.isMoment(e)) {

                const formattedDate = e?.format('YYYY-MM-DD HH:mm');

                setFormQuoterMic({
                    ...formQuoterMic,
                    date_sample: formattedDate,
                }); 
            }     
            else {
                setFormQuoterMic({
                    ...formQuoterMic,
                    date_sample: null,
                }); 
            }     
        }

    //*TYPE OF SAMPLE
    const handleSelectMuestra = async (e) => 
        {
            const _determination = e?.target?.value;  

            if (_determination !== 0 && _determination !== null && _determination !== "") {

                if (formQuoterMic.id_company !== null && formQuoterMic.id_agreement === null) {
                    createSweet("create", "warning", "Determinación", "Se debe seleccionar un convenio de la empresa para continuar.");
                    return;
                }

                const getType = await getAllTypeSample();

                if (getType !== null) {
                    const list = getType?.map(_find => {
                        if (_determination === parseInt(_find?.id_mic_type_sample)) {
                            return {
                                sample_name: _find?.name,
                                origin: _find?.origin,
                                id_mic_type_sample: _find?.id_mic_type_sample
                            };
                        }
                        return null; 
                    });
                
                    const _find = list.filter(x => x !== null);
                
                    if (_find.length > 0) { 
                        
                        const listOrders = formQuoterMic.list_type_samples;
                
                        _find.forEach(item => {
                            listOrders.push({
                                id: item?.id_mic_type_sample,
                                list_determinations: [],
                                name_sample: item?.sample_name,
                                id_type_client: 0,
                                observations: "",
                                price_sample: 0,
                                cant: 1,
                                id_new: Date.now()
                            });
                        });
                        setSearchSampleState([])
                        setFormQuoterMic({
                            ...formQuoterMic,
                            list_type_samples: listOrders
                        });
                    }
                    else {
                        setSearchSampleState([])
                        setFormQuoterMic({
                            ...formQuoterMic,
                            list_type_samples: []
                        });
                    }
                }
            }
            else setSearchSampleState([])
        }

    //*TYPE DETERMINACION EXAMS AND PROFILES
    const handleSelectDetermination = async (e,_key) => {
        
        let determination = e?.target?.value;

        if (determination !== 0 && determination !== null && determination !== "") {

            determination = determination.split('-');

            let _idTest = determination[0];
            let _typeTest = determination[1];
            let _nameTypeTest = _typeTest === "0" ? "Exam" : "Profile";

            if (_nameTypeTest === "Profile") {
                //!GLOBALES
                let _id_patient = 0;
                let _id_company = 0;
              
                 //!VALIDATE TYPE COMPANY
                 _id_company = (formQuoterMic.id_company ?? 0) !== 0 ? formQuoterMic.id_company : 0;
                 _id_patient = _id_company === 0 ? formQuoterMic.id_patient : 0;
                let _alternative_method = "";
                let _idAgreement = ((formQuoterMic.id_company === null || formQuoterMic.id_company === 0) ? "" : formQuoterMic.id_agreement.value)

                //!DETERMINATION
                 if (_id_patient !== 0 || _id_company !== 0) {
                    let _listNew = null;
                    let _listTemp = formQuoterMic.list_type_samples
                    let findDeterminations = _listTemp[_key];

                    if (findDeterminations) {
                        _listTemp.map((x, ind) => {
                            if (ind === _key) {
                                _listNew = findDeterminations?.list_determinations;
                            }
                            return x;
                        });
                    } else {
                        _listNew = [];
                    }
                    setFormQuoterMic({
                        ...formQuoterMic,
                        list_type_samples: _listTemp
                    });

                    // ? CONFIGURATION AGREEMENT
                    if (formQuoterMic.id_agreement !== null) {
                    _alternative_method = (_alternative_method === "" ? `&id_agreement=${_idAgreement}` : "");
                    }

                    const getTypeAllProfile = await getAllProfile(_idTest,branch,typeCommercialLine,_id_patient,_id_company,_alternative_method); 
                    
                    if (getTypeAllProfile !== null) {


                        let _id_agreement = null;

                        if (formQuoterMic.id_agreement !== null) {
                            console.log("estrella");
                            
                            let _agreement = getTypeAllProfile?.agreement;
                            _id_agreement = getTypeAllProfile?.agreement
                            console.log("🚀 ~ handleSelectDetermination ~ getTypeAllProfile:", getTypeAllProfile)
                            console.log("🚀 ~ handleSelectDetermination ~ _agreement:", _agreement)

                            // getTypeAllProfile.agreement.forEach(_find => {
                            //     console.log("🚀 ~ handleSelectDetermination ~ _find:", _find)

                               
                        //    _id_agreement = _find?.id_agreement;



                                
                            // });

                            if (_id_agreement === 0 || _id_agreement === null ) {
                                createSweet("create", "warning", "Convenio",`El estudio ${getTypeAllProfile?.name ?? "-------"} no se encuentra dentro del convenio seleccionado` );
                                setExamProfileState([])   
                                return;
                            }
                        }

                        if (getTypeAllProfile.code === 404) {
                            createSweet("create", "warning", "Determinaciones",getTypeAllProfile.data.msg );
                            setExamProfileState([])   
                            return;
                        }
                        if (getTypeAllProfile.code === 409) {
                            createSweet("create", "warning", "Determinaciones",getTypeAllProfile.data.msg );
                            setExamProfileState([])   
                            return;
                        }
                        if (getTypeAllProfile.price === 0) {
                            createSweet("create", "warning", "Determinaciones", `No se encontro un precio para ${getTypeAllProfile.name}` );
                            setExamProfileState([])   
                            return; 
                        }
                        setExamProfileState([])   
                    
                        let _id_agreementP = null;
                        let price_sample = 0;
                        let _porcentaje_discount = 0;
                        let _descuento_cifra = 0;
                        let _contract_number = null
                        let _price_sample = 0;

                        
                        price_sample += parseFloat(getTypeAllProfile?.price.toFixed(2));
                        let newValidations = {};
                        let validTest = examsValidations(formQuoterMic,newValidations,_key,null,_idTest,getTypeAllProfile.listExams,getTypeAllProfile.name);

                        if (validTest !== -1 && validTest !== 2) {
                            sweetConfirmRepeatExam("La determinación ya existe en el tipo de muestra", validTest.message_validation, "warning");
                            price_sample -= parseFloat(getTypeAllProfile.price.toFixed(2));
                            return;
                        }
                      

                        if (_id_company > 0) {
                            if (getTypeAllProfile?.agreement !== null) {

                                getTypeAllProfile.agreement.forEach(_find => {
                                    _price_sample = _find.price;
                                    _contract_number = _find?.contract_number
                                    _id_agreementP = _find?.id_agreement;
                                    _porcentaje_discount = _find?.percentage;



                                    console.log("🚀 ~ handleSelectDetermination ~ _find:", _find)

                                    
                                });
                            


                                let _agreement = getTypeAllProfile?.agreement;
                                let _discount = (getAllProfile?.price * _porcentaje_discount) / 100;
                                _descuento_cifra = !isNaN(_discount) ? parseFloat(_discount.toFixed(2)) : 0;
                                price_sample = price_sample - _descuento_cifra;
                            
                                

                            }
                        }

                        let _new_determination = false;

                        if (formQuoterMic.id_quatation > 0) {
                            _new_determination = true;
                        }

                        console.log("🚀 ~ handleSelectDetermination ~ _id_company:", _id_company)
                        console.log("🚀 ~ handleSelectDetermination ~ _price_sample:", _price_sample)
                        console.log("🚀 ~ handleSelectDetermination ~ _price_sample:", _id_company > 0 ? "jola" : "adios")




                        _listNew.push({
                            id_profile: getTypeAllProfile?.id_profile ?? 0,
                            id_exam: null,
                            id_price: getTypeAllProfile?.id_price ?? 0,
                            price: _id_company > 0 ? _price_sample : getTypeAllProfile?.price ?? 0,
                            price_discount: _id_company > 0 ? _price_sample :  getTypeAllProfile?.price - _descuento_cifra ,
                           // price_discount: getTypeAllProfile?.price - _descuento_cifra ,
                            name: getTypeAllProfile?.name ?? "",
                            code: getTypeAllProfile?.code ?? "",
                            abbreviation: getTypeAllProfile?.abbreviation ?? "",
                            config_iva: getTypeAllProfile?.config_iva ?? 0,
                            value_iva: getTypeAllProfile?.value_iva ?? 0,
                            listExams: getTypeAllProfile?.listExams ?? 0,
                            id_agreement: _id_agreementP,
                            new_determination: _new_determination,
                            discount: !isNaN(_porcentaje_discount) ? parseFloat(_porcentaje_discount.toFixed(2)) : 0,
                            id_quoter_determination: null,
                            list_nom: [],
                            observations_det: "",
                            discount_par:0,
                            contract_number:_contract_number
                            });
                                
                            let list_type_sample_temp = formQuoterMic.list_type_samples;
                            let findDeterminations = list_type_sample_temp[_key];
                            let _validDeterminaciones = false;
                                
                            if (findDeterminations) {
                                
                                findDeterminations.list_determinations = _listNew;
                                findDeterminations.price_sample += price_sample;
                                list_type_sample_temp.map((x, inde) => {
                                    if (inde === _key) {
                                        x = findDeterminations;
                                    }
                                    return x;
                                });  
                                _validDeterminaciones = findDeterminations.list_determinations.length > 0 ? true : false

                            }
                            else {                                    
                            }
                            setExamProfileState([]);
                            setFormQuoterMic({
                                ...formQuoterMic,
                                list_type_samples: list_type_sample_temp,
                                valid_sampling: _validDeterminaciones
                            });
                    }
                    else  setExamProfileState([])  

                 }
            }
            else {
                let _id_patient = 0;
                let _id_company = 0;
                let _alternative_method = "";
                
                let _idAgreement = ((formQuoterMic.id_company === null || formQuoterMic.id_company === 0) ? "" : formQuoterMic.id_agreement.value)
               
                //!VALIDATE TYPE COMPANY
                _id_company = (formQuoterMic.id_company ?? 0) !== 0 ? formQuoterMic.id_company : 0;
                _id_patient = _id_company === 0 ? formQuoterMic.id_patient : 0;

        
                if (_id_patient !== 0 || _id_company !== 0 ) {
                    //!DETERMINATION
                    let _listNew = null
                    let _listTemp = formQuoterMic.list_type_samples
                    let findDeterminations = _listTemp[_key];

                    if (findDeterminations) {
                        _listTemp.map((x, ind) => {
                            if (ind === _key) {
                                _listNew = findDeterminations.list_determinations;
                            }
                            return x;
                        });
                    } else  _listNew = [];

                    setFormQuoterMic({
                        ...formQuoterMic,
                        list_type_samples: _listTemp
                    });

                    // ? CONFIGURATION AGREEMENT
                    if (formQuoterMic.id_agreement !== null) {
                        
                        _alternative_method = (_alternative_method === "" ? `?id_agreement=${_idAgreement}` : "");
                    }
                    
                    //!METHOD
                    const getTypeAllExam = await getAllExam(_idTest,branch,typeCommercialLine,_id_patient,_id_company,_alternative_method); 

                    if (getTypeAllExam !== null) {

                        if (getTypeAllExam?.agreement != null) {

                            let _id_agreement = null;
                            let _agreement = getTypeAllExam?.agreement;

                            _id_agreement = _agreement?.id_agreement;

                            if (_id_agreement === 0) {
                                createSweet("create", "warning", "Convenio",`El estudio ${getTypeAllExam?.name} no se encuentra dentro del convenio seleccionado`);
                                setExamProfileState([])   
                                return;
                            }
                        }

                        if (getTypeAllExam.code === 404) {
                            createSweet("create", "warning", "Determinaciones",getTypeAllExam.data.msg );
                            setExamProfileState([])   
                            return;
                        }
                        if (getTypeAllExam.code === 409) {
                            createSweet("create", "warning", "Determinaciones",getTypeAllExam.data.msg );
                            setExamProfileState([])   
                            return;
                        }

                        if (getTypeAllExam.price === 0) {
                            createSweet("create", "warning", "Determinaciones", `No se encontro un precio para ${getTypeAllExam.name}` );
                            setExamProfileState([])   
                            return; 
                        }
                        setExamProfileState([])   
                    
                        let _id_agreement = null;
                        let _porcentaje_discount= 0;
                        let _descuento_cifra = 0;
                        let price_sample = 0;
                        let _contract_number = null
                        let _price_sample = 0;
                        
                        price_sample += parseFloat(getTypeAllExam.price.toFixed(2));

                        let newValidations = {};
                        let validTest = examsValidations(formQuoterMic,newValidations,_key,_idTest);

                        if (validTest !== -1 && validTest !== 2) {
                            sweetConfirmRepeatExam("La determinación ya existe en el tipo de muestra", validTest.message_validation, "warning");
                            price_sample -= parseFloat(getTypeAllExam.price.toFixed(2));
                            return;
                        }
                        
                        if (_id_company > 0) {

                            if (getTypeAllExam?.agreement != null) {
                                
                                let _agreement = getTypeAllExam?.agreement;
                                 _porcentaje_discount = _agreement.percentage;
                                 
                                _id_agreement = _agreement.id_agreement;

                                let _discount = (getTypeAllExam?.price * _porcentaje_discount) / 100;
                                
                                let _descuento_cifra = !isNaN(_discount) ? parseFloat(_discount.toFixed(2)) : 0;                                

                                price_sample = price_sample - _descuento_cifra;
                                
                                _contract_number = _agreement?.contract_number   
                                
                                _price_sample = _agreement?.price

                            }   
                        } 

                        let _new_determination = false;

                        if (formQuoterMic.id_quatation > 0) {
                            _new_determination = true;
                        }


                        _listNew.push({
                            id_profile: null,
                            id_exam: getTypeAllExam?.id_exam ?? 0,
                            id_price: getTypeAllExam?.id_price ?? 0,
                            price: _id_company > 0 ? _price_sample : getTypeAllExam?.price ?? 0,
                           // price_discount: getTypeAllExam?.price - _descuento_cifra ,
                            price_discount: _id_company > 0 ? price_sample :  getTypeAllExam?.price - _descuento_cifra ,
                            name: getTypeAllExam?.name ?? "",
                            code: getTypeAllExam?.code ?? "",
                            abbreviation: getTypeAllExam?.abbreviation ?? "",
                            config_iva: getTypeAllExam?.config_iva ?? 0,
                            value_iva: getTypeAllExam?.value_iva ?? 0,
                            id_agreement: _id_agreement,
                            new_determination: _new_determination,
                            discount: !isNaN(_porcentaje_discount) ? parseFloat(_porcentaje_discount.toFixed(2)) : 0,
                            id_quoter_determination: null,
                            list_nom: [],
                            observations_det: "",
                            discount_par:0,
                            contract_number:_contract_number

                        });

                        let list_type_sample_temp = formQuoterMic.list_type_samples;
                        let findDeterminations = list_type_sample_temp[_key];
                        let _validDeterminaciones = false;
 
                        if (findDeterminations) {
                            findDeterminations.list_determinations = _listNew;
                            findDeterminations.price_sample += price_sample;
                            list_type_sample_temp.map((x, inde) => {
                                if (inde === _key) {
                                    x = findDeterminations;
                                }
                                return x;
                            });  
                            _validDeterminaciones = findDeterminations.list_determinations.length > 0 ? true : false
                        }
                        else {
                        }
                        setExamProfileState([])        
                        setFormQuoterMic({
                            ...formQuoterMic,
                            list_type_samples: list_type_sample_temp,
                            valid_sampling: _validDeterminaciones
                        });   

                    }
                    else  setExamProfileState([])  
                }
                else setExamProfileState([])

            }
        }
        else  setExamProfileState([])
       }

    //*OBSERVATIONS DETERMINATIONS
    const handleObservations = (e, _id_exam, _id_profile = null, _id_type_sample) => {
      
       if (e !== null && e?.target?.value !== undefined) {

        let _value = e?.target?.value;
        let _arrayComment = formQuoterMic.list_type_samples;
        let _find = _arrayComment.find(x => x.id === parseInt(_id_type_sample));
        
        if (_find) {
            const _findList = _find.list_determinations;
            _findList.forEach(x => {
                if ((_id_exam !== null && x?.id_exam === _id_exam) || 
                    (_id_exam === null && x?.id_profile === _id_profile)) {
                    x.observations_det = _value;
                }
            });
        }
        setFormQuoterMic({
            ...formQuoterMic,
            list_type_samples: _arrayComment
        });
       }
      }

    //*RULES DETERMINATIONS
    const handleSearchRules = (e, _id_exam, _id_profile = null, _key) => {
        
        if (e !== null && e?.value !== undefined) {
            
            let _value = e.value;
            let _array = listRules;
            let _section = _array
            .filter(_find => _value.some(x => x.code === _find.code))
            .map(_find => ({ name: _find.name, code: _find.code }));

            let _arrayTemp = formQuoterMic.list_type_samples;
            let _findType = _arrayTemp[_key];
            
            if (_findType) {
                _findType.list_determinations = _findType.list_determinations.map(x => {
                    if ((_id_exam !== null && x.id_exam === _id_exam) || (_id_exam === null && x.id_profile === _id_profile)) {
                        x.list_nom = _section;
                    }
                    return x;
                });
            }
            setFormQuoterMic({
                ...formQuoterMic,
                list_type_samples: _arrayTemp
            });  
        }
      }

    //*REMOVE TYPE DETERMINACION
    const handleRemoveDeterminacion = (_key,_id_exam, _id_profile = null) => {

        if (_key !== undefined && _key !== "") {

            let _id = parseInt(_key);
            let _price = 0;
            let _find = formQuoterMic.list_type_samples[_id]
    
            if (_find !== null) {

                let findTest = _find.list_determinations.find(x => (_id_exam !== null ? x.id_exam === parseInt(_id_exam) : x.id_profile === parseInt(_id_profile)));

                if (findTest !== null) {
                    let newListTest = _find.list_determinations.filter(item => {
                        if ((_id_exam !== null && item.id_exam === parseInt(_id_exam)) || (_id_profile !== null && item.id_profile === parseInt(_id_profile))) {
                            _price = item.price_discount;
                            return false;
                        }
                        else {
                            return true;
                        }
                    });
                    let _list = formQuoterMic.list_type_samples;
                    let _findDeterminacion = _list[_key];
                    if (_findDeterminacion) {
                        _findDeterminacion.list_determinations = newListTest;
                        let _priceRemove = _findDeterminacion.price_sample - _price;
                        _findDeterminacion.price_sample = parseFloat(_priceRemove.toFixed(2));
                        _list.map(x => {
                            if (x.id === _findDeterminacion.id) {
                                x = _findDeterminacion;
                            }
                            return x;
                        })
                    }
                    let _valid =  _find.list_determinations.length > 0 ? true: false
                    setFormQuoterMic({
                        ...formQuoterMic,
                        list_type_samples: _list,
                        valid_sampling : _valid
                    });  
                }  
            } 
        }
      }

    //*DELETE SAMPLE
    const handleDeleteTypeSample = (_key) => {

        if (_key !== undefined && _key !== "") {

            formQuoterMic.list_type_samples.splice(_key,1)
            setFormQuoterMic({
                ...formQuoterMic,
                list_type_samples: formQuoterMic.list_type_samples
            });     
        }
    }

    //*ADD QUANTITY
    const handleAddQuantity = (_key) => {

        let _listTypeSample = formQuoterMic?.list_type_samples;
        let _findDetermination = _listTypeSample[_key];
        console.log("🚀 ~ handleAddQuantity ~ _findDetermination:", _findDetermination)

        if (_findDetermination) {
            _findDetermination.cant += 1;

            let updatedList = _listTypeSample.map(_x => 
                _x?.id_new === _findDetermination?.id_new ? _findDetermination : _x
            );

            setFormQuoterMic({
                ...formQuoterMic,
                list_type_samples: updatedList
            });
        }
    }

    //*DELETE QUANTITY
    const handleDeleteQuantity = (_key) => {

        let _listTypeSample = formQuoterMic?.list_type_samples;
        let _findDetermination = _listTypeSample[_key];

        if (_findDetermination) {
            _findDetermination.cant -= 1;

            let updatedList = _listTypeSample.map(_x => 
                _x?.id_new === _findDetermination?.id_new ? _findDetermination : _x
            );

            setFormQuoterMic({
                ...formQuoterMic,
                list_type_samples: updatedList
            });
        }
    }

    //*PERCENTAGE DISCOUNTS
    const handleDiscount = (e, _id_exam, _id_profile = null, _key) => {

        if (e !== null && e?.target?.value !== undefined) {

            let _valor = e?.target?.value;
            let _list = formQuoterMic.list_type_samples;
            let _findSample = _list[_key];
         
            if (_findSample) {
                _findSample.list_determinations.forEach(x => {
                    let _exam = _id_exam !== null && x?.id_exam === _id_exam;
                    let _profile = _id_exam === null && x?.id_profile === _id_profile;
            
                    if (_exam || _profile) {
                        x.discount_par = _valor;
                    }
                });
            }
            setFormQuoterMic({
                ...formQuoterMic,
                list_type_samples: _list
            });
        }
    }

    //*TOGGLE EXAMS
    const handleToggleExams = () => {
        setModalExams(!modalExams)
    }

    const handleViewExamsProfile = (_key, _id_profile) => {

        if (_key !== undefined && _key !== "") {
            let _findSample = formQuoterMic.list_type_samples[_key];

            if (_findSample) {
                let _find = _findSample.list_determinations.find(x => x.id_profile === parseInt(_id_profile));
                setListExams(_find.listExams);
                handleToggleExams();
            }  
        }   
    }

    //*DEATIL QUOTER
    const handleViewQuoter = () => {
        setViewQuoter(true);
    }

    const handleClosedQuoter = () => {
        setViewQuoter(false);
    }

    //*CREATE QUATATION
    const saveQuatation  = async () => {
        setLoadingCreate(true)
        const response = await saveQuoter({
            id_company: formQuoterMic.id_company,
            id_patient: formQuoterMic.id_patient,
            id_agreement: formQuoterMic.id_company !== null ?  formQuoterMic.id_agreement.value : null,
            date_sample: formQuoterMic.date_sample,
            is_sampling: formQuoterMic.is_sampling,
            id_branch: branch,
            observations: formQuoterMic.observations,
            list_type_samples: formQuoterMic.list_type_samples,
            roleUser:roleUser         
        });

        if (response.code === 200){
            if (response.id_quoter > 0) {
                setLoadingCreate(false)
                createSweet("success", "success", "Se genero correctamente la cotización");
                setFormQuoterMic({
                    ...formQuoterMic,
                    statusQuoter: true,
                    id_quatation: response.id_quoter,
                    code_quatation: response.codeQuoter
                });
            }
        } 
    }

    const downloadQuoter  = async (_id_quoter,_tax) => {

        const response = await dowloadPdf({
            id_quoter: formQuoterMic.id_quatation === 0 ? _id_quoter :formQuoterMic.id_quatation ,
            print_result: true,
            send_email: false,
            email_client: "", 
            tax: total_tax === 0 ? _tax : total_tax
        });
        if (response.code !== 200){
        } else createSweet("warning", "warning", "No se descargo el PDF");
    }

    const handleNewQuatation = () => {
        setFormQuoterMic({
            ...formQuoterMic,
            id_company: null,
            id_patient: null,
            id_type_client: null,
            observations: "",
            is_sampling : false,
            date_sample_up:moment().format("D/M/YYYY hh:mm"),
            date_sample: null,
            type_sample_select: null,
            id_exam_temp:null,
            list_type_samples: [],
            listQuoterTest: [],
            statusQuoter:false,
            id_quatation: 0,
            code_quatation:null,
            valid_sampling : false,
            search_quatation:null
        }); 
        setInformationPatient([])
        setInformationCompany([])
        setOptionsTotal({
            subtotal: 0,
            tax: 0,
            total: 0
          });
        setViewQuoter(false);
    }

    //!SAMPLING
    const handleNavigateSampling= (_code) => {
        history.push("../sanitaria/muestreo/" + _code);
    }

    // ? UPDATE QUOTER------------------------------------------------------
 
    //*CODE QUATATION   
    const handleChangeSearch = async (e) => 
        {
            const _code = e?.target?.value;  

            if (e !== null && e?.target?.value !== undefined) {
                setFormQuoterMic({
                    ...formQuoterMic,
                    code_quatation:_code 
                });
            }
            else {
                setFormQuoterMic({
                    ...formQuoterMic,
                    code_quatation: null,
                });
            }
    }

    const getSearchQuoter = async (_code_quatation) =>
        {
            if (_code_quatation !== "" && _code_quatation !== undefined && _code_quatation !== null) {
                setLoadingCreate(true)
                
                const quoter = await getInformationQuatation(_code_quatation);
                
                if (quoter !== undefined) {
                    setLoadingCreate(false)
                    let _name = "";
                    let _tax = 0;
                    if (quoter.id_patient > 0) {
                        const list = quoter?.patient.map(_find => {   
                            setInformationPatient ({
                                birthday: _find?.birthday ?? "----",
                                phone: _find?.phone ?? "----",
                                email: _find?.email ?? "----",
                                name: _find?.name ?? "----",
                                paternal_surname: _find?.paternal_surname ?? "----",
                                maternal_surname: _find?.maternal_surname ?? "----", 
                                client: ( _find?.name + " " +  _find?.paternal_surname + " " +  _find?.maternal_surname )
                            })
                            _name = ( _find?.name + " " +  _find?.paternal_surname + " " +  _find?.maternal_surname )
                        });
                        setCheckTypeClient(1)
                    }
                    else {
                        const list = quoter?.company.map(_find => {   
                            const listC =  [{
                                "name": _find?.business_name ?? "----",
                                "code": _find?.code ?? "----",
                                "phone": _find?.phone ?? "----",
                                "name_company_turn": _find?.name_company_turn ?? "----",
                                "contact_email": _find?.email ?? "----",
                                "require_invoice": _find?.require_invoice ? "Si" : "No" ?? "----",
                                "payment": (_find?.payment_by_company && _find?.payment_by_patient) ? "Ambas partes" : (_find?.payment_by_company) ? "Empresa" : "Paciente" ?? "----"   
                            }]
                            setInformationCompany(listC)
                        });
                        setCheckTypeClient(3)                       
                    }

                    if (quoter?.list_type_samples.length > 0) {

                        const sample = quoter?.list_type_samples.map(_sample => {   

                            const determinations = _sample?.list_determinations.map(_determinations => {
                                _tax = _determinations?.config_iva ?? 0;
                                return {
                                    id_profile: _determinations?.id_profile ?? null,
                                    id_exam: _determinations?.id_exam ?? null,
                                    price: _determinations?.price ?? 0,
                                    price_discount: _determinations?.price_discount,
                                    name: _determinations?.name ?? "",
                                    code: _determinations?.code ?? "",
                                    abbreviation: _determinations?.code ?? "",
                                    config_iva: _determinations?.config_iva ?? 0,
                                    value_iva: _determinations?.value_iva ?? 0,
                                    id_agreement: _determinations?.id_agreement,
                                    new_determination: _determinations?.new_determination,
                                    discount: _determinations?.discount,
                                    id_quoter_determination: _determinations?.id_quoter_determination,
                                    list_nom: _determinations?.list_nom,
                                    observations_det: _determinations?.observations_det,
                                    discount_par: _determinations?.discount_par,
                                    contract_number: _determinations?.agreement_code ?? "---",
                                    id_price: _determinations?.id_price,

                                }
                            })
                            
                            const listOrders = formQuoterMic.list_type_samples;
                            listOrders.push({
                                id: _sample?.id,
                                list_determinations: determinations,
                                name_sample: _sample?.name_sample,
                                id_type_client: _sample?.id_type_client,
                                observations: _sample?.observations,
                                price_sample: _sample?.price_sample,
                                cant: _sample?.cant
                            });
                            setFormQuoterMic({
                                ...formQuoterMic,
                                list_type_samples: listOrders,
                            });    
                        });    
                    }
                    const _fech = moment(quoter?.date_sample, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');

                    
                    setFormQuoterMic({
                        ...formQuoterMic,
                        id_patient: quoter?.id_patient,
                        searchPatientState: {value: quoter?.id_patient ,label:_name },
                        id_quatation : quoter?.id_quoter,
                        observations: quoter?.observations,
                        is_sampling : quoter?.is_sampling,
                        date_sample:  _fech ,
                        valid_sampling : true,
                        id_company: quoter?.id_company,
                        code_quatation:_code_quatation,
                        id_agreement: quoter?.id_company > 0 ? {value: quoter?.id_agreement,label: quoter?.contract_number_agreement} : null
                    })
                }
            }
    }

    //*UPDATE QUATATION
     const updateQuatation  = async () => {
        setLoadingCreate(true)
        const response = await updateQuoter({
            id_company: formQuoterMic.id_company === null ? 0 : formQuoterMic.id_company ,
            id_patient: formQuoterMic.id_patient,
            date_sample: formQuoterMic.date_sample,
            is_sampling: formQuoterMic.is_sampling,
            id_branch: branch,
            observations: formQuoterMic.observations,
            list_type_samples: formQuoterMic.list_type_samples,
            id_quoter: formQuoterMic.id_quatation,
            updatedatesample: false ,
        });        

        if (response.code === 200){
                setLoadingCreate(false)
                setFormQuoterMic({
                    ...formQuoterMic,
                    statusQuoter: true,
                });
                createSweet("success", "success", "Se actualizo correctamente la cotización");
        } 
    }

    //*CONFIGURATION TABLE
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleExpandRow = (index) => {
        if (expandedRow === index) {
          setExpandedRow(null);
        } else {
          setExpandedRow(index);
        }
    };

    const handleMouseEnterClean = () => {
        setIsHoveredClean(true);
    };

    const handleMouseLeaveClean = () => {
        setIsHoveredClean(false);
    };
    // ? LIST QUATATIONS-----------------------------------------------------

    const handleFilterValues = async (e) => 
        {   
            if (e && e?.target?.value !== undefined && e?.target?.value !== ""  ) {

                let _value = e?.target?.value;
                let _name_input = e?.target?.name;

                switch (_name_input) {
                    case "start_date":
                        setFormQuoterMic({
                            ...formQuoterMic,
                            start_date: _value,
                        }); 
                        break;
                    case "end_date":
                        setFormQuoterMic({
                            ...formQuoterMic,
                            end_date: _value,
                        }); 
                        break
                    default:
                        setFormQuoterMic({
                            ...formQuoterMic,
                            code_quatation: _value,
                        }); 
                        break;
                }
            }       
    }

    const handleSearchPatient = async (e) => 
        {
            const _code = e?.target?.value;  

            if (e !== null && e?.target?.value !== undefined) {
                setFormQuoterMic({
                    ...formQuoterMic,
                    id_patient_search:_code 
                });
            }
            else {
                setFormQuoterMic({
                    ...formQuoterMic,
                    id_patient_search: null,
                });
            }
    }

    const handleSearchClient = async (e) => 
        {            
            const _code = e?.value;  
            const _label = e?.label;  

            if (e !== null && e?.value !== undefined) {
                setFormQuoterMic({
                    ...formQuoterMic,
                    id_company_search: {value: _code, label: _label}
                });
            }
            else {
                setFormQuoterMic({
                    ...formQuoterMic,
                    id_company_search: null,
                });
            }
    }

    const handleCleanFilter = () => {
        setFormQuoterMic({
            ...formQuoterMic,
            searchPatientQuoter:[], // ? FIND CODE QUOTER
            start_date: null,
            end_date: null,
            id_patient_search: null,
            id_company_search: null,
            code_quatation:null,
        }); 
        setInformationPatient([])
        setInformationCompany([])
    }

    const getFilter = (e, search = true) => { 
        e.preventDefault();
        if (search) {

            let _validations = filterValidations(formQuoterMic.start_date,formQuoterMic.end_date,formQuoterMic.id_company_search,formQuoterMic.id_patient_search,formQuoterMic.code_quatation);
             
            if (_validations !== "") {

                if (_validations === "1" || _validations === "2" || _validations === "3" ) {
                    
                    if (_validations === "1") {
                        createSweet("warning", "warning", "No se puede hacer la combinación de Empresa y Particular");    
                    }
                    else createSweet("warning", "warning", "Se debe seleccionar 2 rangos de fecha");
                 return;    
                }
                else getSearchFilter(_validations)  
            }
            else createSweet("warning", "warning", "Debes de seleccionar al menos un dato para la busqueda"); 
        }
    }

    const getSearchFilter= async (_query) =>
        {
            if (_query !== "" && _query !== undefined && _query !== null) {
                setLoadingCreate(true)
               
                const quoter = await getListQuatations(_query);

                if (quoter.code === 500) {
                    setLoadingCreate(false)
                    createSweet("warning", "warning", "Cotizaciones", "Sin resulatdos"); 
                    return;
                }

                if (quoter !== null) {
                    setLoadingCreate(false)
                    const list = quoter.map(_find => {
                        const _client = _find.patient.length > 0 
                            ? _find.patient.map(_patient => `${_patient?.name || ""} ${_patient?.paternal_surname || ""} ${_patient?.maternal_surname || ""}`).join(" ") 
                            : _find.company.map(_company => _company?.business_name || "------").join(" ");

                            const _tax = _find.patient.length > 0 
                            ? _find.patient.map(_patient => `${"16"}`).join(" ") 
                            : _find.company.map(_company => _company?.border_tax || "16%").join(" ");

                            const _list_determinations = _find.list_type_samples.map(_list => {
                               
                              const _list_exams =  _list?.list_determinations.map(_determination => {                                
                                return {
                                    name: _determination?.name,
                                    list_nom: _determination?.list_nom
                                }
                                })
                                return{
                                    name_sample:_list?.name_sample,
                                    list_determinations : _list_exams
                                }
                            })

                            let _taxs = _tax.trim() === "true" ? "8%" : "16%"
                            let _taxsDownload = _tax.trim() === "true" ? 8 : 16

                                 
                        return {
                            code_quoter: _find?.code_quoter ?? 0,
                            type: _find?.id_company === null ? "Particular" : "Empresa",
                            client: _client.trim() || "------",
                            date_quoter: _find?.created_at ?? "---",
                            id_quoter: _find?.id_quoter ?? 0,
                            list_determinations: _list_determinations,
                            tax: _taxs || "------",
                            download: _taxsDownload,
                            id_work_order: _find?.id_work_order
                        };
                    });                    
                    
                    setInformationQuatation(list);
                }
                else  setInformationQuatation([])
            }
    }

    // ? CONFIGURATION CREATE PATIENT

    const tooglePatient = () =>
    {
        setModalPatient(!modalPatient)
    }

    const handleCreatePatient = () => 
    {
        tooglePatient()
    }

    const handleInformationPatient = (e, _nameInput) => 
    {
        if (e !== undefined && e !== null) {
            if (e && _nameInput) {
                setFormPatient({
                    ...formPatient,
                    [_nameInput]: e?.target?.value
                });
            }   
        } 
    }
    

    const savePatient  = async () => 
    {
        setLoadingPatientMicro(true)
        const response = await savePatientMicro({
            id_gender: formPatient?.id_gender,
            id_state_patient: formPatient?.id_state_patient,
            id_municipality_patient: formPatient?.id_municipality_patient,
            id_social_gender_patient: formPatient?.id_social_gender_patient,
            id_blood_type: formPatient?.id_blood_type,
            id_tax_regime: formPatient?.id_tax_regime,
            id_use_cfdi: formPatient?.id_use_cfdi,
            paternal_surname: formPatient?.paternal_surname,
            maternal_surname: formPatient?.maternal_surname,
            name: formPatient?.name,
            birthday: formPatient?.birthday,
            age: formPatient?.age,
            curp: formPatient?.curp,
            address_patient: formPatient?.address_patient,
            cp_patient: formPatient?.cp_patient,
            colony_patient: formPatient?.colony_patient,
            phone: formPatient?.phone,
            email_patient: formPatient?.email_patient,
            membership: formPatient?.membership,
            cardnumber: formPatient?.cardnumber,
            rfc: formPatient?.rfc,
            type_person: formPatient?.type_person,
            new_social_gender: formPatient?.new_social_gender,
            scan_ine_check: formPatient?.scan_ine_check,
            setFileINE: formPatient?.setFileINE,
            nss: formPatient?.nss,
            matricula_buap:formPatient.matricula_buap
 
        });

        if (response.code === 200){
            setLoadingPatientMicro(false)
            createSweet("success", "success", "Se creo correctamente el paciente");
            tooglePatient()
                setFormPatient({
                    ...formPatient,
                    id_gender: 0,
                    id_state_patient: 1,
                    id_municipality_patient: 1,
                    id_social_gender_patient: null,
                    id_blood_type: null,
                    id_tax_regime: null,
                    id_use_cfdi: null,
                    paternal_surname: null,
                    maternal_surname: null,
                    name: null,
                    birthday: "2025-01-01",
                    age: 0,
                    curp: "CURPDEFAULT",
                    address_patient: "DEFAULT",
                    cp_patient: "00000",
                    phone: null,
                    email_patient: null,
                    membership: "0",
                    cardnumber: "DEFAULT",
                    rfc: "DEFAULT",
                    type_person: 1,
                    new_social_gender: "",
                    scan_ine_check: false,
                    setFileINE: "DEFAULT",
                    nss:0
                }); 
        } 
    }
    console.log("estrela",formQuoterMic);
    
    return {
        // * TYPE CLIENT
        handleChangeTypeClient,checkTypeClient,handleChangePatient,informationPatient,listCompanies,handleChangeCompany,informationCompany,
        
        // * QUOTER
        handleChangeComments,handlePickerValues,handleChangeSampling,

        // * TYPE OF SAMPLE
        handleSelectDetermination,searchSampleState,examProfileState,handleSelectMuestra,

        // * FORM MIC
        formQuoterMic,

        // * ACTION TYPE SAMPLE
        handleDeleteTypeSample,handleAddQuantity,handleDeleteQuantity,

        // * TABLE
        handleObservations,listRules,handleSearchRules,handleRemoveDeterminacion,handleDiscount,

        // * TOGGLE EXAMS
        handleToggleExams,handleViewExamsProfile,modalExams,listExams,

        // * PRICES
        optionsTotal,total_tax,

        // * VIEW QUOTER
        handleViewQuoter,handleClosedQuoter,viewQuoter,nameBranch,loadingCreate,

        // * CREATE AND ACTIONS
        saveQuatation,downloadQuoter,handleNewQuatation,handleNavigateSampling,

        // * UPDATE QUOTER
        handleChangeSearch,getSearchQuoter,updateQuatation,

        // * CONFIGURATION BUTTONS
        isHovered,handleMouseEnter,handleMouseLeave,handleMouseEnterClean,handleMouseLeaveClean,isHoveredClean,

        // * CONFIGURATION TABLE
        informationQuatation,handleExpandRow,hoveredColumn,setHoveredColumn,expandedRow,

        //  *FILTER
        handleFilterValues,handleSearchPatient,handleSearchClient,getFilter,handleCleanFilter,

        // * AGREEMENT
        listAgreement,handleChangeAgreement,

        // * CREATE PATIENT
        modalPatient,tooglePatient,handleCreatePatient,formPatient,handleInformationPatient,savePatient,loadingPatientMicro
    }
}
