import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app';
import AppMicrositio from './components/appMicrositio';
import AppTomaMuestra from './components/appTomaMuestra';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { routes } from './route';
import { routesMicrositio } from './routeMicrositio';
import { RoutesTomaMuestra } from './routeTomaDeMuestra';
import ConfigDB from './data/customizer/config'
import { classes } from './data/layouts';
import Login from './views/auth/Login';
import LoginRemoto from './views/auth/LoginRemoto';
import RecoverPassword from './views/auth/RecoverPassword';
import { ResultsFromNimPublic } from './views/results/ResultsFromNimPublic';
import LoginMicrosite from './views/auth/LoginMicrosite';
import LoginTomaDeMuestra from './views/auth/LoginTomaDeMuestra';
import { LoginSeguimiento } from './views/auth/LoginSeguimiento';
import { refreshToken } from './hooks/tokens/useToken';
import LoginEnvioMuestras from './views/auth/LoginEnvioMuestras';
import { PrivacyPolicy } from './views/results/PrivacyPolicy';
import { IndexReportsMicrositio } from './views/reports/cxc/IndexReportsMicrositio';





//BORRAR
const Root = (props) => {

  const { fetch: originalFetch } = window;

  window.fetch = async (...args) => {
    let [resource, config] = args;
    if (resource.toLowerCase().endsWith('/api/users/login') || resource.toLowerCase().endsWith('/api/users/refresh')) {
      return await originalFetch(resource, config);
    }
    else if (typeof config.retry == 'undefined' && config.retry != false) {
      const response = await originalFetch(resource, config);

      if (response.status == 401) {
        let refreshed = await refreshToken();
        if (refreshed) {
          let token = localStorage.getItem("token")
          config.headers.set("authorization", " Bearer " + token)
          config.retry = false;
          const response = await originalFetch(resource, config);
          if (response.status == 401) {
            localStorage.removeItem("token");
            setTimeout(() => {
              window.location.href = `${process.env.PUBLIC_URL}/login`
            }, 500);
            return response;
          }
          return response;
        }
        else {
          localStorage.removeItem("token");
          setTimeout(() => {
            window.location.href = `${process.env.PUBLIC_URL}/login`
          }, 500);
        }
      }
      return response;
    }
    else {
      const response = await originalFetch(resource, config);
      if (response.status == 401) {
        localStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = `${process.env.PUBLIC_URL}/login`
        }, 500);
      }
      return response;
    }
  };

  const [anim, setAnim] = useState("");
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
  const abortController = new AbortController();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const [currentUser, setCurrentUser] = useState(false);

  const jwt_token = localStorage.getItem('token');
  const empresa = localStorage.getItem('empresa');
  const tomaMuestra = localStorage.getItem('tomaMuestra');
  const analitica = localStorage.getItem('nameUser');



  useEffect(() => {
    setAnim(animation)
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    }
  }, []);

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
            <Route path={`${process.env.PUBLIC_URL}/loginRemoto/:token`} component={LoginRemoto} />
            <Route path={`${process.env.PUBLIC_URL}/recover`} component={RecoverPassword} />
            <Route path={`${process.env.PUBLIC_URL}/consultar-resultados/:nim/:password`} component={ResultsFromNimPublic} />
            <Route path={`${process.env.PUBLIC_URL}/consultar-resultados`} component={ResultsFromNimPublic} />
            <Route path={`${process.env.PUBLIC_URL}/micrositio`} component={LoginMicrosite} />
            <Route path={`${process.env.PUBLIC_URL}/toma`} component={LoginTomaDeMuestra} />
            <Route path={`${process.env.PUBLIC_URL}/seguimiento`} component={LoginSeguimiento} />
            <Route path={`${process.env.PUBLIC_URL}/envios`} component={LoginEnvioMuestras} />
            <Route path={`${process.env.PUBLIC_URL}/informacion-institucional`} component={PrivacyPolicy} />
            <Route path={`${process.env.PUBLIC_URL}/estadoCuenta`} component={IndexReportsMicrositio} />


            {
              jwt_token !== null
                ?
                empresa === "1"
                  ?
                  <AppMicrositio>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                      return (<Redirect to={`${process.env.PUBLIC_URL}`} />)
                    }} />
                    <TransitionGroup>
                      {routesMicrositio.map(({ path, Component }) => (
                        <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                          {({ match }) => (
                            <CSSTransition
                              in={match != null}
                              timeout={100}
                              classNames={anim}
                              unmountOnExit
                            >
                              <div><Component /></div>
                            </CSSTransition>
                          )}
                        </Route>
                      ))}
                    </TransitionGroup>
                  </AppMicrositio>
                  :
                  tomaMuestra === "2"
                    ?
                    <AppTomaMuestra>
                      <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                        return (<Redirect to={`${process.env.PUBLIC_URL}`} />)
                      }} />
                      <TransitionGroup>
                        {RoutesTomaMuestra.map(({ path, Component }) => (
                          <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                            {({ match }) => (
                              <CSSTransition
                                in={match != null}
                                timeout={100}
                                classNames={anim}
                                unmountOnExit
                              >
                                <div><Component /></div>
                              </CSSTransition>
                            )}
                          </Route>
                        ))}
                      </TransitionGroup>
                    </AppTomaMuestra>
                    :
                    tomaMuestra === "3"
                      ?
                      <AppTomaMuestra>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                          return (<Redirect to={`${process.env.PUBLIC_URL}`} />)
                        }} />
                        <TransitionGroup>
                          {RoutesTomaMuestra.map(({ path, Component }) => (
                            <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                              {({ match }) => (
                                <CSSTransition
                                  in={match != null}
                                  timeout={100}
                                  classNames={anim}
                                  unmountOnExit
                                >
                                  <div><Component /></div>
                                </CSSTransition>
                              )}
                            </Route>
                          ))}
                        </TransitionGroup>
                      </AppTomaMuestra>
                      :
                      tomaMuestra === "4"
                        ?
                        <AppTomaMuestra>
                          <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                            return (<Redirect to={`${process.env.PUBLIC_URL}`} />)
                          }} />
                          <TransitionGroup>
                            {RoutesTomaMuestra.map(({ path, Component }) => (
                              <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                                {({ match }) => (
                                  <CSSTransition
                                    in={match != null}
                                    timeout={100}
                                    classNames={anim}
                                    unmountOnExit
                                  >
                                    <div><Component /></div>
                                  </CSSTransition>
                                )}
                              </Route>
                            ))}
                          </TransitionGroup>
                        </AppTomaMuestra>
                        :
                        <App>
                          <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                            return (<Redirect to={`${process.env.PUBLIC_URL}`} />)
                          }} />
                          <TransitionGroup>
                            {routes.map(({ path, Component }) => (
                              <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                                {({ match }) => (
                                  <CSSTransition
                                    in={match != null}
                                    timeout={100}
                                    classNames={anim}
                                    unmountOnExit
                                  >
                                    <div><Component /></div>
                                  </CSSTransition>
                                )}
                              </Route>
                            ))}
                          </TransitionGroup>
                        </App>
                :
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            }
          </Switch>
        </BrowserRouter>
      </Provider>
    </Fragment>
  )
}
ReactDOM.render(<Root />,
  document.getElementById('root')
);
serviceWorker.unregister();



