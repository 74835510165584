import React, { useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, Col, FormGroup, FormText, Input, Label, Row,Alert } from 'reactstrap'
import { BtnClientEmpresa, BtnClientPublic, BtnSampling} from '../../../constant'
import { SearchAsync } from '../../../components/elements/SearchAsync';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { ListOptionsPaymentBy, OptionPaymentByCompany, OptionPaymentByPatient } from '../../../constant/workOrder';
import {buap, cedimi,insan} from '../../../constant/globalConstantsTypeClients';
import { useLocation } from "react-router-dom";
import { ModalTypeService } from '../../../components/workOrders/ModalTypeService';
import { ModalAutorization } from '../../../components/workOrders/ModalAutorization';



export const IncomeTypeWorkOrder = (props) => {

    const {
        // ?ALL VALIDATIONS
        patientValid, companyValid,

        // ? DOCTORS
        handleCreateDoctor,_config_doctors,

        // ? EDIT WORK ORDER 
        isEditWorkOrder, loadingEditWorkOrder,

        // ? WORK ORDER VARIABLES
        checkTypeClient, modalScannQrPatient,isPayForCompany, 

        // ? WORK ORDERS METHODS
        handleChangeTypeClient, handleChangeGeneratePay, handleChangeClient,handleChangeAgreement,

        // ? PATIENT VARIABLES
        id_patient,inputReadQrCode, focusQrPatient, user_unique_code,searchPatientState, informationPatient,

        // ? PATIENT METHODS
        handleChangePatient,handleScannQrPatient, handleCreatePatient, handleSelectValuesQr,handleUpdatePatient,onKeyPressQr,qrCodeAutoFocus,

        // ? CONFIGURATION DOCTOR
        searchDoctorState, informationDoctor, doctList, workOrderId,handleChangeDoctor, handleUpdateDoctor, handleDeleteDoctor, handleSaveDoctor,

        // ? COMPANY
        id_company,listCompanies,listAgreements,idAgreementCompany,handleSelectValuesWorkOrder, 

        // ? CANCELLATION WORK ORDER 
        handleCancelWorkOrderUpdate,nim_Notification,validPorcentejeSpecial,_branch_name,
      
        // ? GLOBAL PRINT 
        validHopitalInterfaced,id_printer_point,listPrinterPoint,PlaceHolderOption,id_printer_point_valid,LabelPrinterPoint,

        // ? CONFIGURATION TYPE SERVICE SERVICE
        handleAutorizationTypeService,id_work_order_type_service,listType,loadingTypeService,id_work_order_type_service_valid,

        // ? MODAL TYPE SERVICE
        modalAurhorization,toggleAutorization,handleInputChangeMethod,user_method,user_password_method,validatePasswordTypeService,

        // ? CONFIGURATION GLOBAL
        CheckTextPrint,handleInputChangeWorkOrder,checkPrint,checkPartial,checkInvoice,CheckTextEmail,checkEmail,checkDoctor,enable_whatsapp,CheckTextWhats,checkWhats,
        observations,observations_sample,

        // ? PDF MEDICAL
        showClassSD,showMessageWF,handleOnChange,DeleteFile,showClass,viewWorkOrder,

        // ? MOADAL AUTORIZATION
        modalAutorization,toggleModalAutorization,PreviewAutorization,handleCancelAutorization,name_User, configuration_role,
        handleSendNotificationInvoices, validatePasswordFinance,
        id_patient_company,

        is_micro,
        // ? MASSIVE LOAD
         massiveLoad, handleFileUpload, toggleModalErrorMasiveLoad, handleDeleteMassive, massiveSamplig, handleDownload,

         requiere_invoice_patient_update

    } = props;

    const history = useHistory();
    let url_helen = localStorage.getItem('url_helen');
    const location = useLocation();
    const isRegistration = location.pathname.includes("/registro"); 

    console.log("🚀 ~ IncomeTypeWorkOrder ~ requiere_invoice_patient_update:", requiere_invoice_patient_update)


    // ? CONFUGURATION INSAN
    const AlertItem = props => {
        const { item } = props;

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={true}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <label>{item.alerttxt}</label>
            </Alert>
        );
    }
    

    return (
        <div className={is_micro ? "d-none" : ""}  hidden={isEditWorkOrder ? (loadingEditWorkOrder ? true : false) : false}>
            <Col sm="12" md="12" lg="12" className=  'p-l-0 p-r-0' >
            <Row>
                <Col sm="12" md="4" lg="4" className=' p-r-0' >
                <div className="email-left-aside">
                    <Card>
                        <CardBody className='pt-3 pl-4 pr-3 pb-1' >
                            <div className="email-app-sidebar left-bookmark">
                                {
                                    (isEditWorkOrder )
                                    ?
                                    <Col sm="12" md="12" lg="12" className='p-r-0' >
                                          <Col sm="12" md="12" lg="12" className= {informationDoctor.length === 0 ? 'pb-4 p-l-0 p-r-0' : 'pb-5 p-l-0 p-r-0'}  style={{textAlign:"end"}} >
                                          <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="f-w-600 mt-1">{checkTypeClient === 1 ? "Particular" : "Empresa"}</h5>
                                                
                                                <ButtonGroup size="sm">
                                                    <Button color="dark" type="button" outline onClick={() => history.goBack()}>
                                                        <i className="icofont icofont-arrow-left"></i>&nbsp; Regresar
                                                    </Button>
                                                    <Button color="danger" type="button" outline onClick={() => handleCancelWorkOrderUpdate()}>
                                                        <i className="fa fa-times"></i> &nbsp; Cancelar
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                    </Col>
                                        <div className="media">
                                        <div className="media-size-email">
                                              <img 
                                                className="mr-3 rounded-circle" 
                                                src={
                                                    informationPatient.gender === "Masculino"
                                                        ? require("../../../assets/images/user/hombre.png")
                                                        : informationPatient.gender === "Femenino"
                                                        ? require("../../../assets/images/user/mujer.png")
                                                        : require("../../../assets/images/user/user.png") 
                                                }
                                                style={{ width: "45px", height: "45px" }}
                                                alt="Avatar"
                                            />
                                            </div>
                                            <div className="media-body">
                                                {checkTypeClient === 1 && informationPatient.name !== null &&
                                                    <Col className='mt-1'>
                                                        <Button outline color="primary" onClick={handleUpdatePatient} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 1 }} >
                                                            <i className="icofont icofont-ui-edit "></i>
                                                        </Button>
                                                    </Col>
                                                }   
                                                <h5 className="f-w-600" style={{fontSize: "13px"}} >{"Tipo de Ingreso:"} </h5>
                                                <label className='f-w-500' >{checkTypeClient === 1 ? "Particular" : "Empresa"} </label> <br />
                                                <b>{checkTypeClient === 1 ? "" : ""} {checkTypeClient === 1 ?  <label className='txt-info'>{informationPatient.name + " " + informationPatient.paternal_surname + " " + informationPatient.maternal_surname} </label> : <label className='f-w-600' > { (id_company.label !== undefined ? <label className='txt-secondary' >{id_company.label} </label> : "")} </label>} </b>  <br />
                                                <label  ><strong>NIM:</strong>&nbsp;<label >{nim_Notification ?? "-----"}</label> </label>   
                                            </div>
                                        </div>
                                        {checkTypeClient === 3 &&<Col sm="12" className='p-l-0 p-r-0 pt-3 pb-3' >
                                            <Row>
                                                <Col sm="6">
                                                <div>
                                                    <i className='fa fa-circle iconResults txt-info'></i>
                                                    <Label className='labelPatientSize'>&nbsp;  Generar pago a</Label>&nbsp;
                                                    <br />
                                                    {
                                                        id_company?.value && isPayForCompany && <b className='txt-info'>La empresa</b>
                                                    }
                                                    {
                                                        id_company?.value && !isPayForCompany && <b className='txt-info'>El paciente</b>
                                                    }                                            
                                                </div>   
                                                </Col>
                                                <Col sm="6">
                                                <div>
                                                    <i className='fa fa-circle iconResults txt-info'></i>
                                                    <Label className='labelPatientSize'>&nbsp;  Generar factura a</Label>&nbsp;
                                                    <br />
                                                    {
                                                        id_company?.value && id_company?.require_invoice && <b className='txt-info'>La empresa</b>
                                                    }
                                                    {
                                                        id_company?.value && !id_company?.require_invoice && id_company?.available_invoice_patient  && <b className='txt-warning'>El paciente (opcional)</b>
                                                    }                                            
                                                    {
                                                        id_company?.value && !id_company?.require_invoice && !id_company?.available_invoice_patient && !requiere_invoice_patient_update  && <b className='labelPatientSize text-warning'>¡No generar factura!</b>
                                                    }  
                                                    {
                                                        isEditWorkOrder && requiere_invoice_patient_update && <b className='txt-warning'>¡El paciente requirio!</b>


                                                    }                                          
                                                </div>      
                                                </Col>
                                            </Row>
                                            </Col>
                                        }
                                        {
                                        (checkTypeClient === 1 && informationPatient.name !== null) &&  <Col sm="12" className='p-l-0 p-r-0 pt-3 pb-4' >
                                        <Row>
                                        <Col sm="3"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Sexo</Label>&nbsp;
                                                <br />
                                                <b style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.gender}</b>                                     
                                            </div> 
                                        </Col>
                                        <Col sm="3"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Edad</Label>&nbsp;
                                                <br />
                                                <b style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.age}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="4"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;F. nacimiento</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.birthday}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="3" className='pt-3'  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Télefono:</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.phone}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="6" className='pt-3' >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Email:</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"11px"}}>{informationPatient.email}</b>
                                                </div> 
                                        </Col>
                                        {
                                            url_helen === buap &&  <Col sm="4" className='pt-3' >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Matrícula:</Label>&nbsp;
                                                <br />
                                                {informationPatient.matricula_buap ? informationPatient.matricula_buap : <b>Sin Matrícula</b> }
                                            </div> 
                                            </Col>
                                        }
                                      
                                        {/* <Col sm={url_helen === buap ? "12" : "3" } >
                                            <Button outline title='Editar datos del paciente' color="primary" onClick={handleUpdatePatient} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                <i className="icofont icofont-ui-edit "></i>
                                            </Button>
                                        </Col> */}
                                        </Row>
                                        </Col>
                                        }
                                        {
                                            checkTypeClient === 3 && <Col sm="12" className='p-l-0 p-r-0 pt-3 pb-3' >
                                                <label className='f-w-600' >Cliente Seleccionado</label>
                                                <Select
                                                isDisabled={true}
                                                classNamePrefix='select'
                                                name="id_company"
                                                value={id_company}
                                                options={listCompanies}
                                                onChange={(e) => handleChangeClient(e)}
                                                placeholder={PlaceHolderOption}
                                                valid
                                                theme={
                                                    (theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#898989'
                                                        }
                                                    })
                                                }
                                            />
                                            </Col>
                                        }
                                        {
                                            checkTypeClient === 3 && <Col sm="12 p-l-0 p-r-0 pt-2 pb-4" >
                                                 <Label className='f-w-600' >Paciente seleccionado</Label>
                                                 <Select
                                                isDisabled={true}
                                                name="id_patient_company"
                                                value={id_patient_company}
                                                options={listCompanies}
                                                onChange={(e) => handleChangeClient(e)}
                                                placeholder={PlaceHolderOption}
                                                valid
                                                theme={
                                                    (theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#898989'
                                                        }
                                                    })
                                                } 
                                                 />
                                            </Col>
                                        }
                                        {validHopitalInterfaced === "True" &&
                                            <Col className='mt-2' sm="12" >                                          
                                                <FormGroup>
                                                <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0' >
                                                    <Row>
                                                        <Col sm="6">
                                                            <Label style={{fontSize : "11px"}}  className='col-form-label f-w-600'>Selecciona el tipo de servicio <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                                        </Col>
                                                        <Col sm="6"className={isEditWorkOrder ? "" : "d-none"} >
                                                            {
                                                                <Button id='btn-tooltip-filter-p'  outline color='secondary' onClick={handleAutorizationTypeService} className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 15 }}>
                                                                <i className="icofont icofont-ui-edit"></i>
                                                                </Button>
                                                            }
                                                    </Col>
                                                    </Row>
                                                </Col>
                                                    <Select
                                                        classNamePrefix="select"
                                                        name="id_work_order_type_service"
                                                        value={id_work_order_type_service}
                                                        options={listType}
                                                        placeholder={PlaceHolderOption}
                                                        onChange={(e) => handleSelectValuesWorkOrder(e, "id_work_order_type_service")}
                                                        isClearable={true}
                                                        isDisabled={(isEditWorkOrder && !loadingTypeService) ? true : false}
                                                        menuPortalTarget={document.body}
                                                    />
                                                    <FormText color='danger' className='m-t-10' hidden={!id_work_order_type_service_valid}>Selecciona una opción</FormText>
                                                </FormGroup>
                                            </Col>
                                            }
                                            <Col sm="12" className={ (informationDoctor.length === 0) ? "p-l-0 p-r-0 pb-2" : ( informationDoctor.length === 1) ?   "p-l-0 p-r-0 pb-2" : "p-l-0 p-r-0 pb-2" }     >
                                            <FormGroup >
                                                <Col sm="12" className='p-l-0 p-r-0' >
                                                <Row>
                                                    <Col sm="6">
                                                    <Label className='f-w-600 mt-3' >Selecciona un doctor</Label>
                                                    </Col>
                                                    <Col sm="6">
                                                    {
                                                    _config_doctors === "True" ? ""
                                                        :
                                                        <div className='text-right'>
                                                        <Button
                                                            outline 
                                                            color='primary' 
                                                            size='xs' 
                                                            onClick={handleCreateDoctor}
                                                            >
                                                            <i className="fa fa-user-md p-1"></i> 
                                                            <Label className='labelcreatePatient p-1'> Nuevo Médico</Label>
                                                        </Button>
                                                    </div>
                                                    }
                                                    </Col>
                                                </Row>
                                                </Col>
                                                <SearchAsync
                                                    cacheOptions={false}
                                                    name="id_doctor"
                                                    value={doctList}
                                                    method={handleChangeDoctor}
                                                    loincState={searchDoctorState}
                                                    url="Doctors/SearchDoctor"
                                                    maxLenght={3}
                                                    mMultiple={true}
                                                />     
                                            </FormGroup>
                                        
                                            </Col>
                                            {
                                                checkTypeClient === 3 && <Col sm="12" className='pb-5 pt-2' >
                                                </Col>
                                            }
                                            <Col>
                                            </Col>
                                        
                                     
                                    </Col>
                                    :
                                    <>
                                    <Col sm="12" md="12" lg="12" className='pb-3 p-l-0 p-r-0' style={{textAlign:"end"}} >

                                        <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="f-w-600 mt-1">
                                            {checkTypeClient === 1 ? "Paciente" : checkTypeClient === 2 ? "Muestreo" : "Cliente"}
                                        </h5>

                                            <ButtonGroup size='sm' >
                                        
                                                <Button color='primary' type='button' onClick={() => handleChangeTypeClient(1)} outline={checkTypeClient === 1 ? false : true}><i className="icofont icofont-user-alt-5"></i>&nbsp; {BtnClientPublic}</Button>
                                                <Button color='primary' type='button' onClick={() => handleChangeTypeClient(3)} outline={checkTypeClient === 3 ? false : true}><i className="icofont icofont-industries-alt-4"></i> &nbsp; {BtnClientEmpresa}</Button>
                                            </ButtonGroup>
                                        </div>

                                    </Col>
                                    <Col sm="12" className='p-l-0 p-r-0' >
                                    { validPorcentejeSpecial === insan &&<AlertItem
                                                 item={
                                                     {
                                                         alerttxt:"Sucursal:" + " " +   _branch_name,
                                                         alertcolor: 'danger inverse',
                                                         btnclose: 'default',
                                                         icon: <i className="icon-alert"></i>,
                                                     }
                                                 }
                                             />}
                                    </Col>
                                    <br />
                                    <div className="media">
                                        <div className="media-size-email">
                                            {
                                                checkTypeClient === 1 && informationPatient.name !== null &&
                                                <img 
                                                className="mr-3 rounded-circle" 
                                                src={
                                                    informationPatient.gender === "MASCULINO"
                                                        ? require("../../../assets/images/user/hombre.png")
                                                        : informationPatient.gender === "FEMENINO"
                                                        ? require("../../../assets/images/user/mujer.png")
                                                        : require("../../../assets/images/user/user.png") 
                                                }
                                                style={{ width: "45px", height: "45px" }}
                                                alt="Avatar"
                                            />
                                            }
                                        </div>

                                        <div className="media-body">
                                            {checkTypeClient === 1 && informationPatient.name !== null &&
                                                <Col className='mt-1'>
                                                    <Button outline color="primary" onClick={handleUpdatePatient} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 1 }} >
                                                        <i className="icofont icofont-ui-edit "></i>
                                                    </Button>
                                                </Col>
                                            }   
                                            {/* <h5 className="f-w-600 mt-2">{ checkTypeClient === 1 ? "Paciente" : "Cliente"}</h5> */}
                                            {checkTypeClient === 3 &&<Col sm="12" className='p-l-0 p-r-0' >
                                            <Row>
                                                <Col sm="6">
                                                <div>
                                                    <i className='fa fa-circle iconResults txt-info'></i>
                                                    <Label className='labelPatientSize'>&nbsp;  Generar pago a</Label>&nbsp;
                                                    <br />
                                                    {
                                                        id_company?.value && isPayForCompany && <b className='txt-info'>La empresa</b>
                                                    }
                                                    {
                                                        id_company?.value && !isPayForCompany && <b className='txt-info'>El paciente</b>
                                                    }                                            
                                                </div>   
                                                </Col>
                                                <Col sm="6">
                                                <div>
                                                    <i className='fa fa-circle iconResults txt-info'></i>
                                                    <Label className='labelPatientSize'>&nbsp;  Generar factura a</Label>&nbsp;
                                                    <br />
                                                    {
                                                        id_company?.value && id_company?.require_invoice && <b className='txt-info'>La empresa</b>
                                                    }
                                                    {
                                                        id_company?.value && !id_company?.require_invoice && id_company?.available_invoice_patient && <b className='txt-info'>El paciente (opcional)</b>
                                                    }                                            
                                                    {
                                                        id_company?.value && !id_company?.require_invoice && !id_company?.available_invoice_patient && <b className='labelPatientSize text-warning'>¡No generar factura!</b>
                                                    }                                            
                                                </div>      
                                                </Col>
                                            </Row>
                                            </Col>
                                            }
                                            {
                                                checkTypeClient === 1 && <Col sm="12" className='p-l-0 p-r-0 mt-3' >
                                                    {
                                                        informationPatient.name !== null &&  <Row>
                                                            <Col sm="12">
                                                            <div>
                                                            <b  style={{fontSize :"11px"}} className='txt-info'>{informationPatient.name + " " + informationPatient.paternal_surname + " " + informationPatient.maternal_surname}</b>                                     
                                                        </div> 
                                                            </Col>
                                                        
                                                    </Row>
                                                    }
                                                </Col>
                                            }
                                        </div>
                                    </div>
                                    {
                                        // ! INFORMATION PATIENT
                                    }
                                    {
                                        (checkTypeClient === 1 && informationPatient.name !== null) &&  <Col sm="12" className='p-l-0 p-r-0 pt-4 pb-3' >
                                        <Row>
                                        <Col sm="3"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Sexo</Label>&nbsp;
                                                <br />
                                                <b style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.gender}</b>                                     
                                            </div> 
                                        </Col>
                                        <Col sm="3"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Edad</Label>&nbsp;
                                                <br />
                                                <b style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.age}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="4"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;F. nacimiento</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.birthday}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="3" className='pt-3'  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Télefono:</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.phone}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="6" className='pt-3' >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Email:</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"11px"}}>{informationPatient.email}</b>
                                                </div> 
                                        </Col>
                                        {
                                            url_helen === buap &&  <Col sm="4" className='pt-3' >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Matrícula:</Label>&nbsp;
                                                <br />
                                                {informationPatient.matricula_buap ? informationPatient.matricula_buap : <b>Sin Matrícula</b> }
                                            </div> 
                                            </Col>
                                        }
                                      
                                        {/* <Col sm={url_helen === buap ? "12" : "3" } >
                                            <Button outline color="primary" onClick={handleUpdatePatient} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                <i className="icofont icofont-ui-edit "></i>
                                            </Button>
                                        </Col> */}
                                        </Row>
                                    </Col>
                                    } 
                                    {
                                        checkTypeClient === 3 && <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0 pt-5 pb-5'>
                                        {
                                            <FormGroup>
                                            <Label className='f-w-600' >Selecciona un cliente</Label>
                                            <Select
                                                classNamePrefix='select'
                                                name="id_company"
                                                value={id_company}
                                                options={listCompanies}
                                                onChange={(e) => handleChangeClient(e)}
                                                placeholder={PlaceHolderOption}
                                                valid
                                                theme={
                                                    (theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#898989'
                                                        }
                                                    })
                                                }
                                            />
                                        <FormText color="danger" hidden={!companyValid}>Debes de seleccionar aun cliente</FormText>
                                        </FormGroup>
                                        }
                                        {
                                            (id_company?.payment_by_company && id_company?.payment_by_patient ) && <FormGroup>
                                            <Label className='f-w-600' >generar pago a</Label>
                                            <Select
                                                classNamePrefix='select'
                                                name="payment_by"
                                                options={ListOptionsPaymentBy}
                                                value={isPayForCompany ? OptionPaymentByCompany : OptionPaymentByPatient }
                                                onChange={(e) => handleChangeGeneratePay(e)}
                                                placeholder={PlaceHolderOption}
                                                valid
                                                theme={
                                                    (theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#898989'
                                                        }
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                        }
                                        {
                                            validPorcentejeSpecial !== "https://grupo-acosta.helen-sw.com" && <FormGroup>
                                            <Label className='f-w-600' >Selecciona el convenio deseado</Label>
                                            <Select
                                                classNamePrefix='select'
                                                name="idAgreementCompany"
                                                options={listAgreements}
                                                value={idAgreementCompany }
                                                onChange={(e) => handleChangeAgreement(e)}
                                                placeholder={PlaceHolderOption}
                                                valid
                                                theme={
                                                    (theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#898989'
                                                        }
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                        }
                                        {
                                            <FormGroup>
                                                <Col sm="12" className='p-l-0 p-r-0' >
                                                <Row>
                                                    <Col sm="6">
                                                    <Label className='f-w-600 mt-3' >Selecciona un Paciente</Label>
                                                    </Col>
                                                    <Col sm="6" style={{textAlign:"end"}} >
                                                   

                                                     <Button 
                                                            outline 
                                                            color='primary' 
                                                            size='xs' 
                                                            onClick={handleCreatePatient}
                                                            >
                                                            <i className="fa fa-user p-1"></i> 
                                                            <Label className='labelcreatePatient p-1'> Nuevo Paciente</Label>
                                                        </Button>

                                                    </Col>
                                                    <Col className='mt-1'>
                                                    
                                                    </Col>
                                                </Row>
                                                </Col>
                                            <SearchAsync
                                                cacheOptions={false}
                                                value={id_patient}
                                                name="id_patient"
                                                method={handleChangePatient}
                                                loincState={searchPatientState}
                                                url="Patient/SearchPatient"
                                                maxLenght={3}
                                                placeholder='Búsqueda por nombre o curp'
                                            />
                                            <FormText color='danger' hidden={!patientValid}>Debes seleccionar un paciente</FormText>
                                        </FormGroup>
                                        }
                                        {   
                                        <FormGroup>
                                                 <Col sm="12" className='p-l-0 p-r-0 pb-2' >
                                                 <Row>
                                                    <Col sm="6">
                                                    <Label className='f-w-600 mt-3' >Selecciona un doctor</Label>
                                                    </Col>
                                                    <Col sm="6">
                                                    {
                                                    _config_doctors === "True" ? ""
                                                        :
                                                        <div className='text-right'>
                                                        <Button 
                                                            outline 
                                                            color='primary' 
                                                            size='xs' 
                                                            onClick={handleCreateDoctor}
                                                            >
                                                            <i className="fa fa-user-md p-1"></i> 
                                                            <Label className='labelcreatePatient p-1'> Nuevo Médico</Label>
                                                        </Button>
                                                    </div>
                                                    }
                                                    </Col>
                                                 </Row>
                                                 </Col>
                                            <SearchAsync
                                                cacheOptions={false}
                                                name="id_doctor"
                                                value={doctList}
                                                method={handleChangeDoctor}
                                                loincState={searchDoctorState}
                                                url="Doctors/SearchDoctor"
                                                maxLenght={3}
                                                mMultiple={true}
                                            />     
                                        </FormGroup>
                                        }
                                        <Col sm="12" className={ (!isEditWorkOrder && informationDoctor.length === 1)  ? 'pb-4 pt-4' : (!isEditWorkOrder && informationDoctor.length === 2) ? 'pb-5 pt-5' : "" }   >
                                        </Col>
                                        </Col>
                                    }
                                    {
                                        checkTypeClient === 1 && <Col sm="12" md="12" lg="12" hidden={isEditWorkOrder} className='p-l-0 p-r-0 pt-1 pb-3'>
                                            {
                                                (informationPatient.name === null || massiveLoad)  && <div className='text-right'>
                                                <ButtonGroup size='xs'>
                                                    {
                                                        url_helen === buap &&( 
                                                            <Button style={{ textAlignLast: "center" }} color='primary' size='xs' onClick={() => handleChangeTypeClient(2)} outline={checkTypeClient === 2 ? false : true}>
                                                                <i class="fa fa-pie-chart"></i>&nbsp; <Label className='labelcreatePatient'>{BtnSampling}</Label>
                                                            </Button>

                                                        )
                                                    }

                                        
                                                    <Button style={{ textAlignLast: "center" }} outline color='primary' size='xs' onClick={handleScannQrPatient}>
                                                        <i className="icofont icofont-qr-code p-1"></i>
                                                        &nbsp;<Label className='labelcreatePatient p-1'>
                                                            {
                                                                modalScannQrPatient ? "Cancelar escaneo" : "Escanear Qr"
                                                            }
                                                        </Label>
                                                    </Button>
                                                    <Button style={{ textAlignLast: "center" }} outline color='primary' size='xs' onClick={handleCreatePatient}>
                                                        <i className="fa fa-user"></i>
                                                        &nbsp; <Label className='labelcreatePatient p-1'>Nuevo paciente</Label>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                            }
                                             
                                        {
                                            modalScannQrPatient 
                                            ?
                                            <>
                                            <Row className='m-b-5 m-t-5'>
                                                <Col sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                    <br /><br />
                                                    <Label className='f-w-600 f-14 badge badge-light-primary'>Escanea el código QR del cliente</Label>
                                                </Col>
                                            </Row>
                                            <Row className='m-b-5'>
                                                <Col sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                    <img src={require('../../../assets/images/loginHelenLabs/TomaDeMuestra/Barra1.gif')} style={{ width: '150px', height: '150px', filter: "invert(100%) sepia(100%) saturate(1000%) hue-rotate(170deg)" }} />
                                                    <div style={{ opacity: "0" }}>
                                                        <input
                                                            ref={inputReadQrCode}
                                                            id='codeQrPatient'
                                                            type="text"
                                                            autoComplete='off'
                                                            autoFocus={focusQrPatient}
                                                            value={user_unique_code}
                                                            onChange={(e) => handleSelectValuesQr(e.target.value, "user_unique_code")}
                                                            onKeyDown={onKeyPressQr}
                                                            onBlur={qrCodeAutoFocus}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            </>
                                            : 
                                            <>
                                            <Row className='m-t-10' hidden={isEditWorkOrder}>
                                            {validHopitalInterfaced === "True" &&
                                            <Col className='mt-2' sm="12" >                                          
                                                <FormGroup>
                                                <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0' >
                                                    <Row>
                                                        <Col sm="6">
                                                            <Label style={{fontSize : "11px"}}  className='col-form-label f-w-600'>Selecciona el tipo de servicio <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                                        </Col>
                                                        <Col sm="6"className={isEditWorkOrder ? "" : "d-none"} >
                                                            {
                                                                <Button id='btn-tooltip-filter-p'  outline color='secondary' onClick={handleAutorizationTypeService} className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 15 }}>
                                                                <i className="icofont icofont-ui-edit"></i>
                                                                </Button>
                                                            }
                                                    </Col>
                                                    </Row>
                                                </Col>
                                                    <Select
                                                        classNamePrefix="select"
                                                        name="id_work_order_type_service"
                                                        value={id_work_order_type_service}
                                                        options={listType}
                                                        placeholder={PlaceHolderOption}
                                                        onChange={(e) => handleSelectValuesWorkOrder(e, "id_work_order_type_service")}
                                                        isClearable={true}
                                                        isDisabled={(isEditWorkOrder && !loadingTypeService) ? true : false}
                                                        menuPortalTarget={document.body}
                                                    />
                                                    <FormText color='danger' className='m-t-10' hidden={!id_work_order_type_service_valid}>Selecciona una opción</FormText>
                                                </FormGroup>
                                            </Col>
                                            }
                                                <Col sm="12" md="12" lg="12" xl="12" className={(checkTypeClient === 1 && informationDoctor.length === 1 ) ? "pb-2" : "" }  >
                                                <Label className='f-w-600' >Selecciona un paciente</Label>
                                                    <SearchAsync
                                                        cacheOptions={false}
                                                        value={id_patient}
                                                        name="id_patient"
                                                        method={handleChangePatient}
                                                        loincState={searchPatientState}
                                                        url="Patient/SearchPatient"
                                                        maxLenght={3}
                                                        placeholder='Búsqueda por nombre o curp'
                                                    />
                                                    <FormText color='danger' hidden={!patientValid}>Debes seleccionar un paciente</FormText>
                                                </Col> 
                                                <Col sm="12" md="12" lg="12" xl="12" className={(checkTypeClient === 1 && informationDoctor.length === 1 ) ? 'pt-3 pb-4' : (checkTypeClient === 1 && informationDoctor.length === 2 )  ?  'pb-5 pt-3' : 'pt-3' }  >
                                                <Col sm="12" className='p-l-0 p-r-0' >
                                                <Row>
                                                    <Col sm="6" >
                                                    <Label className='f-w-600 mt-3' >Selecciona un doctor </Label>
                                                    </Col>
                                                    <Col sm="6">
                                                    {
                                                    _config_doctors === "True" ? ""
                                                        :
                                                        <div className='text-right'>
                                                        <Button 
                                                            outline 
                                                            color='primary' 
                                                            size='xs' 
                                                            onClick={handleCreateDoctor}
                                                            >
                                                            <i className="fa fa-user-md p-1"></i> 
                                                            <Label className='labelcreatePatient p-1'> Nuevo Médico</Label>
                                                        </Button>

                                                    </div>
                                                    }
                                                    </Col>
                                                </Row>
                                                <Col sm="12" className='mt-2'>
                                                </Col>
                                                </Col>
                                                    <SearchAsync
                                                        cacheOptions={false}
                                                        name="id_doctor"
                                                        value={doctList}
                                                        method={handleChangeDoctor}
                                                        loincState={searchDoctorState}
                                                        url="Doctors/SearchDoctor"
                                                        maxLenght={3}
                                                        mMultiple={true}
                                                    />
                                                </Col>
                                                          
                                            </Row>

                                            </>
                                        }


                                        </Col>
                                    }
                                    
                                    {/* BUAP sampling */}
                                    {
                                        checkTypeClient === 2 && <Col sm="12" md="12" lg="12" className='p-l-0 p-r-0 pt-3 pb-3'>
                                        {
                                            <ButtonGroup size='xs'>
                                               
                                               <Button outline color='success' size='xs' onClick={handleDownload}>
                                                    <i className="fa fa-download"></i>
                                                    &nbsp; <Label className='labelcreatePatient p-1'>Descargar Plantilla</Label>
                                                </Button>

                                                {
                                                    url_helen === buap &&( 
                                                        <>
                                                            <input
                                                                type="file"
                                                                id="fileUpload"
                                                                accept=".csv"
                                                                style={{ display: "none" }}
                                                                onChange={handleFileUpload}
                                                            />
                                                            <Button
                                                                style={{ textAlignLast: "center" }}
                                                                outline
                                                                color="primary"
                                                                size="xs"
                                                                onClick={() => document.getElementById('fileUpload').click()}
                                                            >
                                                                <i className="fa fa-file-excel-o"></i>
                                                                &nbsp; <Label className="labelcreatePatient p-1">Cargar Plantilla</Label>
                                                            </Button>
                                                        </>
                                                    )
                                                }     
                                                <br />                                       
                                            </ButtonGroup>

                                        }
                                        

                                        {!massiveLoad && massiveSamplig.numPatients > 0 && ( 
                                            <>

                                                <Row sm="12" md="12" lg="12" className='p-l-0 p-r-0 pt-2 pb-2'>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                        <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                        <label className='labelPatientSize'> &nbsp;Total de pacientes:&nbsp; <b style={{ fontSize: "11px" }} className='labelPatientSize'>{massiveSamplig.numPatients}</b> </label>
                                                        <br />
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                        <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                        <label className='labelPatientSize'>&nbsp;No. pacientes nuevos:&nbsp; <b style={{ fontSize: "11px" }} className='labelPatientSize'>{massiveSamplig.numNewPatients}</b></label>                                                        
                                                    </Col>
                                                </Row>
                                                <Row sm="12" md="12" lg="12" className='p-l-0 p-r-0 pt-2 pb-2'>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                        <i className='fa fa-circle iconResults iconPatien'></i>
                                                        <Label className='labelPatientSize'>&nbsp;Pacientes existentes:&nbsp;  <b style={{ fontSize: "11px" }} className='labelPatientSize'>{massiveSamplig.numExistingPatients}</b></Label>
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                        <i className='fa fa-circle iconResults iconPatien'></i>
                                                        <Label className='labelPatientSize'>&nbsp; % Pacientes nuevos: &nbsp;  <b style={{ fontSize: "11px" }} className='labelPatientSize'>{massiveSamplig.percNewPatients + "%"}</b></Label>
                                                        </Col>
                                                </Row>
                                                <Row sm="12" md="12" lg="12" className='p-l-0 p-r-0 pt-2 pb-2'>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                        <i className='fa fa-circle iconResults iconPatien'></i>
                                                        <Label className='labelPatientSize'>&nbsp; Pacientes con errores:&nbsp; <b style={{ fontSize: "11px" }} className='labelPatientSize'>{massiveSamplig.numPatientsWithError}</b> </Label>
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                        <i className='fa fa-circle iconResults iconPatien'></i>
                                                        <Label className='labelPatientSize'>&nbsp; Pacientes válidos:&nbsp; <b style={{ fontSize: "11px" }} className='labelPatientSize'>{massiveSamplig.numValidPatients}</b></Label>
                                                    </Col>
                                                </Row>

                                                <Row  sm="12" md="12" lg="12" className='p-l-0 p-r-0 pt-2 pb-2'>
                                                    <Col className='mr-3' xs="11" sm="11" md="11" lg="11" xl="11">
                                                        <Button outline color="primary"  onClick={toggleModalErrorMasiveLoad} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                        <i className="icofont icofont-eye "></i>
                                                        </Button>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Button outline color="danger" className="p-3" onClick={handleDeleteMassive} style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                        <i className="icofont icofont-ui-delete "></i>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <br /><br />
                                            </>

                                            
                                        )}

                                        {!massiveLoad && massiveSamplig.numPatients > 0 && (
                                            <Col sm="12" className={ (informationDoctor.length === 0) ? "p-l-0 p-r-0 pb-2" : ( informationDoctor.length === 1) ?   "p-l-0 p-r-0 pb-5" : "p-l-0 p-r-0 pb-5" }     >
                                                <FormGroup >
                                                    <Col sm="12" className='p-l-0 p-r-0' >
                                                    <Row>
                                                        <Col sm="6">
                                                        <Label className='f-w-600 mt-3' >Selecciona un doctor</Label>
                                                        </Col>
                                                        <Col sm="6">
                                                        {
                                                        _config_doctors === "True" ? ""
                                                            :
                                                            <div className='text-right'>
                                                            <Button
                                                                outline 
                                                                color='primary' 
                                                                size='xs' 
                                                                onClick={handleCreateDoctor}
                                                                >
                                                                <i className="fa fa-user-md p-1"></i> 
                                                                <Label className='labelcreatePatient p-1'> Nuevo Médico</Label>
                                                            </Button>
                                                        </div>
                                                        }
                                                        </Col>
                                                    </Row>
                                                    </Col>
                                                    <SearchAsync
                                                        cacheOptions={false}
                                                        name="id_doctor"
                                                        value={doctList}
                                                        method={handleChangeDoctor}
                                                        loincState={searchDoctorState}
                                                        url="Doctors/SearchDoctor"
                                                        maxLenght={3}
                                                        mMultiple={true}
                                                    />     
                                                </FormGroup>
                                                        
                                            </Col>
                                        )}

                        
                                        </Col>
                                    }
                                    </>    
                                }
                            </div>
                        </CardBody>
                    </Card>
                </div>
                </Col>
                <Col sm="12" md="8" lg="8" className= {viewWorkOrder ? '' : 'd-none' } >
                <Card>
                    <CardBody className= {isEditWorkOrder && informationDoctor.length === 0 ? "pt-3 pl-4 pr-3 pb-5" : " pt-3 pl-4 pr-3 pb-1"}  >
                        <Row className={!isEditWorkOrder && informationDoctor.length === 0 ? "" : "" } >
                        <Col sm="7" style={{ borderRight: "1px solid #00000024"}} >
                        {
                            (checkTypeClient === 3) && <Col sm="11" className='p-l-0 p-r-0'  >
                            <h5 className='f-w-600' >Datos del Paciente</h5>
                             {
                                (informationPatient.name !== null) &&  <Col sm="12" className='p-l-0 p-r-0 pt-3' style={{border :"1px solid #efefef" ,padding:"10px"}} >
                                    <Row>
                                        <Col sm="12" className='p-l-0 p-r-0 pt-3'  >
                                        {
                                            informationPatient.name !== null &&  
                                                <Col sm="12" className={ isEditWorkOrder ? "d-none" : ""} >
                                                    <b  style={{fontSize :"11px"}} className='txt-info'>{informationPatient.name + " " + informationPatient.paternal_surname + " " + informationPatient.maternal_surname}</b>                                     
                                                </Col>
                                        }
                                        </Col>
                                        <Col sm="3"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Sexo</Label>&nbsp;
                                                <br />
                                                <b style={{fontSize :"10px"}} className='labelPatientSize'>{informationPatient.gender}</b>                                     
                                            </div> 
                                        </Col>
                                        <Col sm="3"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Edad</Label>&nbsp;
                                                <br />
                                                <b style={{fontSize :"10px"}} className='labelPatientSize'>{informationPatient.age}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="4"  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;F. nacimiento</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"10px"}} className='labelPatientSize'>{informationPatient.birthday}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="3" className='pt-3'  >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Télefono:</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"10px"}} className='labelPatientSize'>{informationPatient.phone}</b>
                                            </div> 
                                        </Col>
                                        <Col sm="5" className='pt-3' >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Email:</Label>&nbsp;
                                                <br />
                                                <b  style={{fontSize :"10px"}}>{informationPatient.email}</b>
                                                </div> 
                                        </Col>
                                        {
                                            url_helen === buap &&  <Col sm="4" className='pt-3' >
                                            <div>
                                                <i className='fa fa-circle iconResults txt-info'></i>
                                                <Label className='labelPatientSize'>&nbsp;Matrícula:</Label>&nbsp;
                                                <br />
                                                {informationPatient.matricula_buap ? informationPatient.matricula_buap : <b>Sin Matrícula</b> }
                                            </div> 
                                            </Col>
                                        }
                                        <Col sm="12" className='mt-2' >
                                            <Button outline color="primary" onClick={handleUpdatePatient} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                <i className="icofont icofont-ui-edit "></i>
                                            </Button>
                                        </Col>
                                        <br /><br />
                                        </Row>
                                    </Col>
                                    }

                            </Col>
                        }
                        <div className={(checkTypeClient === 3  )  ? "pt-4 pb-5" : ""} >
                        <h5 className='f-w-600' >Médico solicitante</h5>
                        <Col sm="11" className={isEditWorkOrder && informationDoctor.length === 0 ? "p-l-0 p-r-0 pt-3  pb-4" : "p-l-0 p-r-0 pt-3 " }  style={{border :"1px solid #efefef" ,padding:"10px"}} >
                        {
                            informationDoctor.length > 0  ? 
                            <>
                             {
                             informationDoctor.length > 0 && informationDoctor.map(_doctor => {
                                 return (
                                     <Row key={`doctorId-${_doctor.id_doctor}`} >

                                         <Col xs="6" sm="6" md="6" xl="6">
                                             <i className="fa fa-circle iconResults txt-info"></i>&nbsp;
                                             <label className=''> Nombre </label>
                                             <br />
                                             <b  style={{fontSize :"10px"}} >{_doctor.name}</b>
                                         </Col>

                                         <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                            <div>
                                                <i className="fa fa-circle iconResults txt-info"></i>&nbsp;
                                                <label className=''> Convenio </label>
                                                <br />
                                                <b  style={{fontSize :"10px"}} className=''>
                                                    {
                                                        _doctor.isCommercialPartner ? <Label  style={{fontSize :"11px"}}>Si</Label> : <Label  style={{fontSize :"11px"}}>No</Label>
                                                    }
                                                </b>
                                            </div>
                                            
                                        </Col>
                                        
                                    
                                        { _doctor.isCommercialPartner ?(
                                                <Col className='mt-3'  xs="6" sm="6" md="6" lg="6" xl="6">
                                                <i className="fa fa-circle iconResults txt-info"></i>&nbsp;
                                                <label className=''> No. de Colegiado</label>
                                                <br />
                                                <b  style={{fontSize :"10px"}} className=''>{_doctor.code}</b>

                                            </Col>

                                        ): null}
                                         
                                        <Col className='mt-3' xs="6" sm="6" md="6" lg="6" xl="6">
                                             <i className="fa fa-circle iconResults txt-info"></i>&nbsp;
                                             <label className=''> Correo </label>
                                             <br />
                                             {
                                                _doctor.email === null || _doctor.email === "" ?
                                                     <b  style={{fontSize :"10px"}} className=''>No esta registrado</b>
                                                     :
                                                     <b  style={{fontSize :"10px"}} className=''>{_doctor.email}</b>
                                             }
                                         </Col>
                                      

                                        <Col className="d-flex justify-content-end align-items-center " xs="12" sm="12" style={{gap: '15px'}}>
                                            {!isRegistration && (
                                                <Button
                                                    outline
                                                    onClick={() => handleSaveDoctor(workOrderId, _doctor.id_doctor)}
                                                    color="primary"
                                                    className="p-3"
                                                    style={{
                                                        borderRadius: 40,
                                                        height: 30,
                                                        width: 30,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                    title="Guardar"
                                                >
                                                    <i className="icofont icofont-save"></i>
                                                </Button>
                                            )}

                                            {url_helen !== cedimi && (
                                                <Button
                                                    outline
                                                    color="primary"
                                                    onClick={() => handleUpdateDoctor(_doctor.id_doctor)}
                                                    className="p-3"
                                                    style={{
                                                        borderRadius: 40,
                                                        height: 30,
                                                        width: 30,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                    title="Editar"
                                                >
                                                    <i className="icofont icofont-ui-edit"></i>
                                                </Button>
                                            )}

                                        
                                            {(() => {
                                                const relatedDoc = doctList.find(doc => doc.id_doctor === _doctor.id_doctor);
                                                if (relatedDoc) {
                                                    return (
                                                        <Button
                                                            outline
                                                            color="danger"
                                                            onClick={() => handleDeleteDoctor(relatedDoc.id_work_order_doctor)}
                                                            className="p-3"
                                                            style={{
                                                                borderRadius: 40,
                                                                height: 30,
                                                                width: 30,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                            title="Eliminar"
                                                        >
                                                            <i className="icofont icofont-ui-delete"></i>
                                                        </Button>
                                                    );
                                                }
                                            })()}
                                        </Col>
                                         <br />
                                     </Row>
                                 )

                             })
                         }
                            </>
                            :
                            <>
                            <label className='txt-danger f-w-500' ><i className="fa fa-warning "></i> Esta orden no cuenta con el anexo de un doctor</label>
                            </>
                        }
                        </Col>
                        <Col sm="11"   className={ (checkTypeClient === 1 && informationDoctor.length === 0  && !isEditWorkOrder ) ? "p-l-0 p-r-0 pt-3 pb-5 "   : (checkTypeClient === 1 && informationDoctor.length === 0 && isEditWorkOrder ) ? "p-l-0 p-r-0 pt-3" : (checkTypeClient === 1 && informationDoctor.length === 1 ) ? "p-l-0 p-r-0 pt-3":   "d-none" } >
                        <h5 className='f-w-600' >Observaciones generales</h5>
                        <Row>
                        <Col sm="12" className='p-l-0 p-r-0 ' style={{textAlign:"right"}} >
                        <Label htmlFor="medical_order" className={showClassSD.ClassSD} outline color='primary' size="xs">
                        {showMessageWF.labelWF} <span className="icofont icofont-upload-alt"></span>
                            <Input type="file" accept="image/*,.pdf" id="medical_order" name="medical_order" onChange={handleOnChange} style={{ display: 'none' }} />
                            </Label> &nbsp; &nbsp; &nbsp;
                        <Button onClick={DeleteFile} className={showClass.ClassName} outline color="danger" size="xs" type="button"><i className="fa fa-trash"></i></Button>&nbsp; &nbsp;
                        </Col>
                            <Col sm="12">
                            <label htmlFor="">Hoja de trabajo</label>
                            <textarea
                                name="observations"
                                className='form-control form-control-sm input-air-primary'
                                rows="2"
                                onChange={handleInputChangeWorkOrder}
                                value={observations}
                            >
                            </textarea>
                            </Col>
                            <Col sm="12" className='pt-3 pb-4' >
                            <label htmlFor="">Observación para flebotomista</label>
                            <textarea
                                name="observations_sample"
                                className='form-control form-control-sm input-air-primary'
                                rows="2"
                                onChange={handleInputChangeWorkOrder}
                                value={observations_sample}
                            >
                            </textarea>
                            </Col>
                        </Row>
                        </Col>
                        </div>
                        </Col>
                        <Col sm="5" className=' p-r-0'>
                        <h5 className='f-w-600 ' >Configuraciones</h5>
                        <Col sm="12" className='p-l-0 ' >
                        <Label style={{fontSize : "11px"}} className='labelPriority f-w-600'>{LabelPrinterPoint} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <FormGroup>
                                <Select
                                    classNamePrefix="select"
                                    name="id_printer_point"
                                    value={id_printer_point}
                                    options={listPrinterPoint}
                                    onChange={(e) => handleSelectValuesWorkOrder(e, "id_printer_point")}
                                    placeholder={PlaceHolderOption}
                                    isClearable={true}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            fontSize: '11px',
                                            }),
                                        }}
                                />
                                <FormText color='danger' className='m-t-10' hidden={!id_printer_point_valid}>Selecciona una opción</FormText>
                            </FormGroup>
                         </Col>
                      
                        <Col sm="12" className='p-l-0' >
                        <Row>
                            <Col sm="6">
                            <Label style={{fontSize : "11px"}} className="labelCheckPrint">{CheckTextPrint}</Label> <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="checkPrint" onChange={handleInputChangeWorkOrder} checked={checkPrint} />
                                    <span className="slider round"></span>
                                </Label>
                            </Col>
                            <Col sm="6">
                             <Label style={{fontSize : "11px"}} className="labelCheckPrint">Resultados parciales</Label> <br />
                                <Label className="switch disabled" disabled >
                                    <Input className='disabled' disabled type="checkbox" name="checkPartial" onChange={handleInputChangeWorkOrder} checked={checkPartial} />
                                    <span className="slider round"  ></span>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                           
                        <Col sm="6">
                        <Label style={{fontSize : "11px"}} className="labelCheckPrint">{CheckTextEmail}</Label> <br />
                            <Label className="switch">
                                <Input type="checkbox" name="checkEmail" onChange={handleInputChangeWorkOrder} checked={checkEmail} />
                                <span className="slider round"></span>
                            </Label>
                        </Col>

                          {
                                informationDoctor.length > 0
                                ?
                                <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                    <Label style={{fontSize : "11px"}} className="labelCheckPrint ">Envio a médico</Label> <br />
                                        <Label className="switch">
                                        <Input type="checkbox" name="checkDoctor" onChange={handleInputChangeWorkOrder} checked={checkDoctor} />
                                        <span className="slider round"></span>
                                    </Label>
                                </Col>
                                : ""
                            } 
                           
                            {
                            enable_whatsapp === "True"
                            ?
                            <Col xs="6" sm="6" md="6" lg="6" xl="6" >
                            <Label style={{fontSize : "11px"}} className="labelCheckPrint">{CheckTextWhats}</Label> <br />
                            <Label className="switch">
                                <Input type="checkbox" name="checkWhats" onChange={handleInputChangeWorkOrder} checked={checkWhats} />
                                <span className="slider round"></span>
                            </Label>
                            </Col>
                            : ""
                            }

                            {
                                (checkTypeClient === 1 ||  (id_company?.value && !id_company?.require_invoice && id_company?.available_invoice_patient && requiere_invoice_patient_update)) && <Col xs="6" sm="6" md="6" lg="6" xl="6">


                                <Label style={{fontSize : "11px"}} className="labelCheckPrint">
                                    <b>¿Requiere factura?</b>
                                </Label> <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="checkInvoice" onChange={(e) => handleInputChangeWorkOrder(e)} checked={checkInvoice} />
                                    <span className="slider round"></span>
                                </Label>
                                </Col>

                            }
                        </Row>

                

                        </Col>
                        {!(checkTypeClient === 1 && (informationDoctor.length === 0 || informationDoctor.length === 1)) && (
                                <>
                                    <h5 className='f-w-600'>Observaciones generales</h5>
                                    <Col sm="12" className='p-l-2 p-r-0' style={{ textAlign: "right" }}>
                                        <Label htmlFor="medical_order" className={showClassSD.ClassSD} outline color='primary' size="xs">
                                            {showMessageWF.labelWF} <span className="icofont icofont-upload-alt"></span>
                                            <Input type="file" accept="image/*,.pdf" id="medical_order" name="medical_order" onChange={handleOnChange} style={{ display: 'none' }} />
                                        </Label> &nbsp; &nbsp; &nbsp;
                                        <Button onClick={DeleteFile} className={showClass.ClassName} outline color="danger" size="xs" type="button">
                                            <i className="fa fa-trash"></i>
                                        </Button>&nbsp; &nbsp;
                                    </Col>
                                    <Row>
                                        <Col sm="12" className='p-l-1 p-r-1'>
                                            <label htmlFor="">Hoja de trabajo</label>
                                            <textarea
                                                name="observations"
                                                className='form-control form-control-sm input-air-primary'
                                                rows="2"
                                                onChange={handleInputChangeWorkOrder}
                                                value={observations}
                                            />
                                        </Col>
                                        <Col sm="12" className='p-l-1 p-r-1'>
                                            <label htmlFor="">Observación para flebotomista</label>
                                            <textarea
                                                name="observations_sample"
                                                className='form-control form-control-sm input-air-primary'
                                                rows="2"
                                                onChange={handleInputChangeWorkOrder}
                                                value={observations_sample}
                                            />
                                        </Col>
                                    </Row>
                                    <br /><br />
                                </>
                            )}
                        </Col>
                        {/* <Col sm="12"   className={ (checkTypeClient === 1 && (informationDoctor.length === 0 || informationDoctor.length === 1)) ? "d-none"  : "pt-4 pb-4" } >
                        <h5 className='f-w-600' >Observaciones generales</h5>
                        <Col sm="12" className='p-l-0 p-r-0 ' style={{textAlign:"right"}} >
                        <Label htmlFor="medical_order" className={showClassSD.ClassSD} outline color='primary' size="xs">
                        {showMessageWF.labelWF} <span className="icofont icofont-upload-alt"></span>
                            <Input type="file" accept="image/*,.pdf" id="medical_order" name="medical_order" onChange={handleOnChange} style={{ display: 'none' }} />
                            </Label> &nbsp; &nbsp; &nbsp;
                        <Button onClick={DeleteFile} className={showClass.ClassName} outline color="danger" size="xs" type="button"><i className="fa fa-trash"></i></Button>&nbsp; &nbsp;
                        </Col>
                        <Row>
                            <Col sm="6"  >
                            <label htmlFor="">Hoja de trabajo</label>
                            <textarea
                                name="observations"
                                className='form-control form-control-sm input-air-primary'
                                rows="2"
                                onChange={handleInputChangeWorkOrder}
                                value={observations}
                            >
                            </textarea>
                            </Col>
                            <Col sm="6">
                            <label htmlFor="">Observación para flebotomista</label>
                            <textarea
                                name="observations_sample"
                                className='form-control form-control-sm input-air-primary'
                                rows="2"
                                onChange={handleInputChangeWorkOrder}
                                value={observations_sample}
                            >
                            </textarea>
                            </Col>
                        </Row>
                        </Col> */}
                        </Row>
                    </CardBody>
                    
                </Card>
                </Col>
            </Row>
            </Col>
            <ModalTypeService
                {
                    ...{ modalAurhorization,toggleAutorization,handleInputChangeMethod,user_method,user_password_method,validatePasswordTypeService,loadingTypeService}
                }
            />
            <ModalAutorization
                {
                    ...{user_method, user_password_method, handleInputChangeMethod, modalAutorization,toggleModalAutorization, PreviewAutorization, 
                        handleCancelAutorization, name_User, configuration_role,
                        handleSendNotificationInvoices, validatePasswordFinance}
                }
            />
        </ div>

        
    )
}
