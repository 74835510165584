import React, { useState, useEffect,useRef } from 'react';
import { Table, Row, Col, Card, CardBody, Nav, FormText, TabContent, TabPane, ButtonGroup,Input,Button} from 'reactstrap'
import { ConfigServer } from '../../../data/config';
import { useHistory } from 'react-router-dom';



export const IndexTypeMicro = (props) => {

    const {
        checkTypeClient,informationPatient,id_company,informationSampling,nim_Notification,listTest,handleCancelWorkOrderUpdate,
        number_format,totalW,listPayMethods,handleDinamicInputWorkOrder,validTotalCredit,AvailablePayMethodByCompany,_adminFinance,
        handleGiveChange,handlePayBill,handleDeletePayMethod,isPayForCompany,
        listPayMethods_valid,subTotalW,totalTaxesW,listPayMethod,valueTax,downloadQuoter,
        checkInvoice
    } = props;

    const history = useHistory();
    var id_quoter = 0
    const [activeTab, setActiveTab] = useState('2'); 
    const [orgActiveTab, setOrgActiveTab] = useState('1');
    const [isFirstLoad, setIsFirstLoad] = useState(true); 

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false); 
        }
    }, [isFirstLoad]);

        const groupedData = informationSampling.map(sample => {
            // ! ID_QUOTER
            id_quoter =sample.id_quoter

            return (sample.listSamples || []).map(_sample => {
                if (_sample?.id_mic_detail_sample === 0) {
                    return null;
                }
                const relatedTests = listTest.filter(test => test.id_mic_detail_sample === _sample.id_mic_detail_sample);
                return {
                    id_mic_detail_sample: _sample?.id_mic_detail_sample,
                    folio: _sample?.folio ?? "-----",
                    name_mic_sample: _sample?.name_mic_sample ?? "------",
                    name_mic_sampling: _sample?.name_mic_sampling ?? "----",
                    name_mic_site: _sample?.name_mic_site ?? "-------",
                    tests: relatedTests.length > 0
                        ? relatedTests.map(test => ({
                            test_name: test?.name ?? "------",
                            price: test?.price ?? 'No Disponible',
                            contract_number: test?.contract_number ?? 'N/A',
                            id_agreement: test?.id_agreement ?? 0,
                            price_Agremment: test?.price_Agremment ?? 0,
                            config_iva: test?.config_iva ?? false,
                        }))
                     
                        : [{
                            test_name: '',
                            price: '',
                            contract_number: ''
                        }]
                };
            }).filter(item => item !== null); 
        }).flat(); 
        
        

    return (
        <>
            <div className="email-wrap bookmark-wrap">
                <Row>
                    <Col xl="4" className="box-col-6 p-r-0" >
                        <div className="email-left-aside">
                            <Card>
                                <CardBody>
                                    <Col sm="12" md="12" lg="12" className='p-r-0 pb-5'>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="f-w-600 mt-1">{checkTypeClient === 1 ? "Particular" : "Empresa"}</h5>
                                            <ButtonGroup size="sm">
                                                <Button color="dark" type="button" outline onClick={() => history.goBack()}>
                                                <i className="icofont icofont-arrow-left"></i>&nbsp; Regresar
                                                </Button>
                                                <Button color="danger" type="button" outline onClick={() => handleCancelWorkOrderUpdate()}>
                                                <i className="fa fa-times"></i> &nbsp; Cancelar
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </Col>
                                    <div className="media">
                                        <div className="media-size-email">
                                            <img className="mr-3 rounded-circle" src={require("../../../assets/images/user/user.png")} alt="" />
                                        </div>
                                        <div className="media-body">
                                            <h5 className="f-w-600" style={{fontSize: "13px"}} >{"Tipo de Ingreso:"} </h5>
                                            <label className='f-w-500' >{checkTypeClient === 1 ? "Particular" : "Empresa"} </label> <br />
                                            <b>{checkTypeClient === 1 ? "" : ""} {checkTypeClient === 1 ?  <label className='txt-info'>{informationPatient?.name + " " + informationPatient?.paternal_surname + " " + informationPatient?.maternal_surname} </label> : <label className='f-w-600' > { (id_company?.label !== undefined ? <label className='txt-secondary' >{id_company?.label} </label> : "")} </label>} </b>  <br />
                                            <label  ><strong>NIM:</strong>&nbsp;<label >{nim_Notification ?? "-----"}</label> </label>   
                                        </div>
                                    </div>
                                    <Col sm="12" className='p-l-0 p-r-0  pt-4' >
                                        {
                                            informationSampling.length > 0 && informationSampling.map(_find => {
                                                return  <Col sm="12" className='pt-0' style={{textAlign:"center"}}  >
                                                    <Row>
                                                        <Col sm="6" >
                                                            <label style={{fontSize:"12px"}} className=''><i className="fa fa-circle text-success"></i> Código de la cotización</label> <br />
                                                            <label className='txt-success f-w-600' >{_find?.name_quoter ?? "-----"} </label>
                                                        </Col>
                                                        <Col sm="6">
                                                            <label style={{fontSize:"12px"}} className=''><i className="fa fa-circle text-success"></i> Descargar Cotización</label> <br />
                                                            <Button  size='xs' onClick={() => downloadQuoter(id_quoter,valueTax)} outline color='danger' ><i className='fa fa-file-pdf-o ' ></i></Button>
                                                        </Col>      
                                                    </Row>
                                                    </Col>                  
                                                })
                                        }
                                        </Col>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col xl="8" md="12" className="box-col-12">
                        <div className="email-right-aside bookmark-tabcontent contacts-tabs">
                            <div className="email-body radius-left">
                                <div className="pl-0">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="2">
                                            {isFirstLoad ? (
                                                <>
                                                </>
                                              
                                            ) : (
                                                <Card>
                                                        <Col sm="12" className=' pt-4' >
                                                        <Row>
                                                           
                                                            <Col sm="11"  >
                                                            <h5 className='f-w-600' >Datos generales de la cotización</h5>

                                                            </Col>
                                                        </Row>
                                                        </Col>
                                                    <CardBody className='pl-4 pr-4 pt-4 pb-5' >
                                                        <Row className="list-persons">
                                                            <Col xl="4 xl-50" md="5" className='p-l-0' >
                                                                <Nav className="flex-column nav-pills" id="v-pills-tab1" role="tablist" aria-orientation="vertical">
                                                                    {
                                                                        informationSampling.length > 0 && informationSampling.map(_find => {
                                                                            return  <Col sm="12" className='pt-0'  >
                                                                            <Row>
                                                                                <Col sm="6">
                                                                                <label style={{fontSize:"12px"}} ><i className="fa fa-circle text-success"></i> Plan de muestreo</label> <br />
                                                                                <label>{_find?.sampling_plan ?? "------"} </label>
                                                                                </Col>
                                                                                <Col sm="12" className='pt-5' >
                                                                                <label style={{fontSize:"12px"}}><i className="fa fa-circle text-success"></i> Observaciones del muestreo:</label> <br />
                                                                                <br />
                                                                                <textarea 
                                                                                    className='form-control form-control-sm input-air-primary'
                                                                                    rows="2"
                                                                                    value={_find?.observation ?? "-----"}
                                                                                    readOnly
                                                                               ></textarea>
                                                                                </Col>
                                                                            </Row>
                                                                            </Col>
                                                                        })
                                                                    }   
                                                                </Nav>
                                                            </Col>
                                                            <Col xl="8 xl-50" md="7">
                                                                <TabContent activeTab={orgActiveTab}>
                                                                    <TabPane tabId="1">
                                                                        <Col sm="12" style={{textAlign:"center"}} >
                                                                        <label style={{fontSize:"12px"}} ><i className="fa fa-circle text-success"></i> Firmas de los que autorizaron el muestreo</label> 
                                                                        {
                                                                             informationSampling.length > 0 && informationSampling.map(_find => {
                                                                                return    <Row className='pt-5' >
                                                                                <Col sm="6">
                                                                                <label className='f-w-600' > Responsable del muestreo </label>
                                                                                <br />
                                                                                <img width={"100%"} alt="flask" src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + _find?.firm_sampler} />
                                                                                </Col>
                                                                                <Col sm="6">
                                                                                <label className='f-w-600' >Firma del contacto/cliente </label>
                                                                                <img width={"100%"} alt="flask" src={ConfigServer.serverUrl + "/api/QuoterDeterminations/GetPictureFirm/" + _find?.firm_client} />

                                                                                </Col>
                                                                            </Row>
                                                                             })
                                                                        }
                                                                        </Col>
                                                                    </TabPane>
                                                                </TabContent>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            )}
                                        </TabPane>
                                   
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Card>
                            <div className='pt-4 pb-4' >
                                <Col sm="12"  >
                                <Row>
                                    <Col sm="5" className='pt-3' >
                                    <h5 className=' f-w-600' >Listado de Muestras</h5>
                                    </Col>
                                </Row>
                                </Col>
                                <Col sm="12" className='pt-3' >
                                <Table className="b-r-5 table table-sm">
                                <thead className="b-r-5" style={{ textAlign: "center" }}>
                                    <tr className='text-center border-bottom-info' style={{ backgroundColor: "#fff", borderTop: "#e3ebf3" }}>
                                    <th><b>Folio</b> </th>
                                    <th> <b>Tipo de Muestra</b> </th>
                                    <th> <b>Muestra</b> </th>
                                    <th> <b>Determinaciones</b> </th>
                                    <th> <b>Precio</b> </th>
                                    <th className={checkTypeClient === 1 ? "d-none" :""} ><b>Convenio</b> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {groupedData.map((item, index) => (

                                    <tr key={index} style={{ textAlign: "center", border: "1px solid #ddd" }}>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }} className="txt-success f-w-600">{item.folio}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.name_mic_sample}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.name_mic_sampling}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                        {item.tests.map((_find, i) => (
                                        <div key={i} style={{ padding: "4px 0" }}>
                                            {_find?.test_name}
                                            {i !== item.tests.length - 1 && <hr style={{ margin: "4px 0", borderColor: "#ddd" }} />}
                                        </div>
                                        ))}
                                    </td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                        {item.tests.map((_find, i) => (
                                        <div key={i} style={{ padding: "4px 0" }}>
                                            {
                                                _find?.config_iva
                                                ?  (_find?.id_agreement === null || _find?.id_agreement === 0) 
                                                ?`$ ${number_format(_find?.price, 2)}`
                                                :`$ ${number_format((_find?.price_Agremment ).toFixed(2))}`
                                                : (_find?.id_agreement === null || _find?.id_agreement === 0) 
                                                    ? `$ ${number_format((_find?.price * (valueTax === 16 ? 1.16 : 1.08 ) ).toFixed(2))}`
                                                    : `$ ${number_format((_find?.price_Agremment * (valueTax === 16 ? 1.16: 1.08 )).toFixed(2))}`
                                            }
                                            {i !== item.tests.length - 1 && <hr style={{ margin: "4px 0", borderColor: "#ddd" }} />}
                                        </div>
                                        ))}
                                    </td>
                                    <td className={checkTypeClient === 1 ? "d-none" :""} style={{ border: "1px solid #ddd", padding: "8px" }}>
                                        {item.tests.map((_find, i) => (
                                        <div key={i} style={{ padding: "4px 0" }}>
                                            {_find?.contract_number}
                                            {i !== item.tests.length - 1 && <hr style={{ margin: "4px 0", borderColor: "#ddd" }} />}
                                        </div>
                                        ))}
                                    </td>
                                    </tr>
                                ))}
                                </tbody>
                                </Table>

                                </Col>
                            </div>
                        </Card>
                        </Col>
                </Row>
                <Row>
                    <Col sm="12">
                    <Card>
                    <Row>
                    <Col sm="4" className='pr-0' >
                        <Col sm="12" className='pt-5 pb-3 pr-4 pl-4' >
                        <h5 className='f-w-600' >Datos de cobranza</h5>
                        <Row className='pt-3' >
                            <div className='deni-react-treeview-container-micro ' >
                            <Col sm="12" className='pt-4' >
                                <Row>
                                    <Col sm="6">
                                    <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-success"></i>Generar pago a</label> <br />
                                    <label className='f-w-300' >{isPayForCompany ? "Empresa" : "Paciente"} </label> <br />
                                    </Col>
                                    <Col sm="6">
                                    <label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-success"></i>Generar factura a</label> <br />
                                    <label  className='f-w-300'>Empresa</label> <br />
                                    </Col> 
                                        <Col sm="6" className='pt-3' >
                                    <label style={{fontSize:"12px"}} className='f-w-600  '><i className="fa fa-circle text-success"></i>Requiere factura:  </label> <br /> <label className='f-w-500  txt-danger' >{checkInvoice ? "Si" : "No"}</label>
                                    </Col>
                                    <Col sm="6">
                                    {
                                        informationSampling.length > 0 && informationSampling.map(_find => {
                                        return  <Col sm="12" className='pt-0 p-l-0 p-r-0 '  >
                                        <label className='f-w-600' style={{fontSize:"12px"}} ><i className="fa fa-circle text-success"></i> Configuración del Iva</label> <br />
                                        <label className={_find?.tax_border ? "txt-secondary f-w-600 f-14" : " f-14 txt-info f-w-600"} >{!_find?.tax_border ? "Iva al 16%" : "Iva al 8%"} </label>
                                    </Col>                                          
                                    })
                                    }
                                    </Col>
                                </Row>
                            </Col>
                            </div>
                        </Row>
                        </Col>
                    </Col>
                    <Col sm="8" className='pl-0' >
                    <Col sm="12" className='pt-5 pb-3 pr-4 pl-4' >
                    <h5 className='f-w-600' >Detalles del pago
                    </h5>
                    <Row className='m-t-5 p-1'>
                                    <Col sm="12" md="6" lg="6" xl="6" className=' mt-2 pl-4'>
                                        <Row className='m-t-15'>
                                        <Table style={{ fontSize: "11px" }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: "#51bb25" }}>
                                                        <td colSpan={3} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
                                                            Detalle de Pagos
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: "5px", textAlign:"center" }}>
                                                            <label className=''>Método de pago</label>
                                                        </td>
                                                        <td className="text-center" style={{ padding: "5px" }}>
                                                            <label>Monto</label>
                                                        </td>
                                                        <td style={{ padding: "5px" }}>
                                                            <label className=''>Acciones</label>
                                                        </td>
                                                    </tr>
                                                    {
                                                        listPayMethods.map((_paymethod, keyPayment) => {
                                                            return (
                                                                <tr key={`method-${keyPayment}`} className={_paymethod.deleted ? 'd-none' : ''}>
                                                                    <td>
                                                                        <Input type="select" required={true} className='form-control form-control-sm input-air-primary p-1' value={_paymethod.id_pay_method} data-id={keyPayment}
                                                                            onChange={(e) => handleDinamicInputWorkOrder(e, "id_pay_method", "listPayMethods")} disabled={validTotalCredit ? true : (_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW >= 0 ? false : true)}>
                                                                            <option value={0}>Selecciona una opción</option>
                                                                            {
                                                                                listPayMethod.map(_pMethod => {
                                                                                    if (checkTypeClient === 3 && isPayForCompany) {
                                                                                        
                                                                                        if (_pMethod.name === AvailablePayMethodByCompany) {
                                                                                            return (
                                                                                                <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                    else if (_pMethod.abbreviation !== "CONV") {

                                                                                        if (_adminFinance === "True" ) {
                                                                                            return (
                                                                                                <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            if (_pMethod.visibility_particular === true) {                                                                                                    
                                                                                                return (
                                                                                                    <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                )
                                                                                            }
                                                                                        }                                                                                         
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </td>
                                                                    <td>
                                                                        <Input type='number' name="amount" data-id={keyPayment} value={_paymethod.amount} placeholder='$ 0.0' step="any"
                                                                            className='form-control input-air-primary form-control-sm' onChange={(e) => handleGiveChange(e, "amount", "listPayMethods")}
                                                                            disabled={validTotalCredit ? true : (_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW >= 0 ? false : true)}
                                                                        />
                                                                    </td>
                                                                    <td className={validTotalCredit ? "d-none" : 'p-1 text-center' }  style={{ verticalAlign: "middle" }} colSpan={3}  >
                                                                        {

                                                                            (_paymethod.fully_paid !== undefined && !_paymethod.fully_paid) ?
                                                                                <Button type='button' className='btn-air-success' outline color='success' size='xs' onClick={() => handlePayBill(_paymethod.id_work_order_pay, _paymethod.amount)}>
                                                                                    <i className="fa fa-money"></i>
                                                                                </Button>
                                                                                :
                                                                                <Button type='button' className='btn-air-danger' outline color='danger' size='xs' onClick={() => handleDeletePayMethod(keyPayment)}
                                                                                    disabled={(_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW > 0 ? false : true)}
                                                                                >
                                                                                    <i className='icofont icofont-ui-delete'></i>
                                                                                </Button>
                                                                        }
                                                                       
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                
                                            
                                                </tbody>
                                                {
                                                 !validTotalCredit  && 
                                                     <tfoot>
                                                     <tr>
                                                         <td colSpan={3} className="text-xs-center">
                                                             <FormText color='danger' hidden={!listPayMethods_valid}>Selecciona un metodo de pago o ingresa la cantidad correcta</FormText>
                                                         </td>
                                                     </tr>
                                                 </tfoot>
                                                }
                                            </Table>
                                        </Row>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="12" md="5" lg="5" xl="5" className=' p-l-0 mt-2'>
                                        <Row className='m-t-15' style={{ textAlignLast: 'center' }}>
                                            <Col sm="12">
                                            <Table style={{ fontSize: "11px" }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: "#51bb25" }}>
                                                        <td colSpan={2} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
                                                            Detalle de movimientos
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: "5px" }}>
                                                            <label className='f-12'>Subtotal</label>
                                                        </td>
                                                        <td className="text-center" style={{ padding: "5px" }}>
                                                            <label>$ {number_format(subTotalW, 2)}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: "5px" }}>
                                                        <label style={{fontSize:"13px"}} className={valueTax === 16 ? 'f-w-600 txt-primary' : 'f-w-600 txt-secondary' }  >I.V.A({valueTax}%)</label>

                                                        </td>
                                                        <td className="text-center" style={{ padding: "5px" }}>
                                                            <label>$ {number_format(totalTaxesW, 2)}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: "5px" }}>
                                                            <label style={{fontSize:"13px"}} className='f-w-600'>Total</label>
                                                        </td>
                                                        <td className="text-center f-w-600 bg-dark" style={{ padding: "5px" }}>
                                                            <label style={{fontSize:"13px"}} className='f-w-600'>$ {number_format(totalW, 2)}</label>
                                                        </td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </Table>
                                            </Col>
                                        </Row>
                                      
                                    </Col>
                                </Row>

                    </Col>
                    </Col>
                   
                        
                    </Row>

                    </Card>
                   
                    </Col>
                </Row>
                <br />
                <br />
            </div>
        </>
    );
};