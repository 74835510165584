import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormSection } from './FormSection';

export const MordalFormSection = (props) => {
    const {
        handleFormSection,
        modalFormSection,
        toggleFormSection,
        titleFormSection,
        methodFormSection,
        saveSamplingPlan,
        valueGroup,handleChangeGroup,
        selectedSections,handleSectionChange


    } = props;

    return (
        <Modal 
            isOpen={modalFormSection} 
            toggle={toggleFormSection} 
            backdrop='static' 
            size="md" 
            keyboard={false} 
            centered={true}
        >
            <ModalHeader toggle={toggleFormSection} className="bg-primary">
                {methodFormSection === "create" 
                    ? "Agregar Bloque de Exámenes" 
                    : "Editar Bloque de Exámenes"}
            </ModalHeader>

            <FormSection
                button={methodFormSection === "create" ? "Crear nuevo médico" : "Actualizar médico"}
                {...{ handleFormSection, modalFormSection, toggleFormSection, titleFormSection, methodFormSection,saveSamplingPlan,
                        valueGroup,handleChangeGroup,    selectedSections,handleSectionChange

                }}
            />
        </Modal>
    );
};
