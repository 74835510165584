import React from 'react'
import {Button, Modal, ModalBody, CardBody, Input,Col,Row } from 'reactstrap';

export const ModalSearch = (props) => {

    const {modalSearch,toggleModalSearch,searchNim,handleInputChange,nim} = props;

    
    return (
        <Modal isOpen={modalSearch} toggle={toggleModalSearch}  backdrop='static' keyboard={false} centered={true}>
                <ModalBody>   
                <CardBody className="p-l-0 p-b-0 p-r-0">
                    <Col sm="12">
                    <Row>
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <b className='txt-info' >Ingresar Nim</b>
                        <Input 
                            type="text" 
                            name="paternal_surname" 
                            className="form-control form-control-sm input-air-primary"
                            value={nim} 
                            onChange={handleInputChange} 
                            />
                        </Col>
                        
                    </Row>          
                    </Col>
                    <Col sm="12" style={{textAlign:"right"}} className='pt-3' >
                    <Row>
                        <Col sm="9">
                        <Button outline color='danger' size='sm' onClick={toggleModalSearch} >Cancelar</Button>

                        </Col>
                        <Col sm="2">
                        <Button outline color='info' onClick={ () => searchNim() } > Buscar</Button>

                        </Col>
                    </Row>
                   

                    </Col>
                </CardBody>   
                </ModalBody>
        </Modal>
    )
}
