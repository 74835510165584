import React, { Fragment,useEffect,useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Input, Label, Badge, Row, Table } from 'reactstrap'
import Chart from 'react-apexcharts'
import { useReportMicrositio } from '../../../hooks/reports/useReportMicrositio'
import ReactApexChart from 'react-apexcharts'



export const IndexReportsMicrositio = () => {

    const {
        loadingTotalIngress, loadingOutsideBalance, loadingIngressInvoiced,
        loadingDiscount, loadingClients,
        loadingExams, loadingProfiles, loadingTableClients, loadingExpiredWallet,

        lblTotalIngress, lblOutSideBalance,lblTotalClients,lblCurrentPaidInvoices, 

        lblCurrentTotalInvoices, lblCurrentPaymentMonth, lblTotalInvoicesNextMonth,
        lblCurrentTotalBilling, lblCurrentPaymentBilling, lblCurrentPendingPaymentBilling,
        lblBeforeTotalBilling, lblBeforePaymentBilling, lblBeforePendingPaymentBilling,

        viewBeforeMonth, viewCurrentMonth, viewAfterMonth,
        detailTransactionsByClients, listDetailTransactionsByClients, listIndicatorsDetail,
        dateFilter, setDateFilter,

        //CHARTS
        ChartAgePayable, ChartPaymentConditionsTable,

        handleFilterByClient, handleFilterDate, number_format,
        handleCleanFilterByClient, handleViewTransactions,
        handleFilterTransactions,

        MakeExcelTransactionMontly,

        // * FILTERS
        filterCredit, setFilterCredit,
        filterAccount, setFilterAccount,
        filterPayment, setFilterPayment,
        filterPendingPayment, setFilterPendingPayment,
        filterInvoiced, setFilterInvoiced,
        filterPendigInvoiced, setFilterPendigInvoiced,
        filterPreInvoice, setFilterPreInvoice,
        filterPpd, setFilterPpd,
        filterPue, setFilterPue,
        filterOutValidity, 
        filterCurrent, 
        filterPaid, 
        filterViewAll, 

        //* DOWNLOAD REPORT CXC
        DownloadReportCxc,
        
        // ? FILTER PAYMETHODS
        filterAmountCredit, setFilterAmountCredit,
        filterAmountDebit, setFilterAmountDebit,
        filterAmountTcredit, setFilterAmountTcredit,
        filterAmountTrasferencia, setFilterAmountTrasferencia,
        filterAmount, setFilterAmount,
        lblTotalPercentageIngress,lblTotalPaidIngress,lblTotalPatient,
        lbPercentageInvoice,detailSeriesExpiredWallet
    } = useReportMicrositio();

    const [total, setTotal] = useState(0)


    const calcularTotal = () => {
        const datosFiltrados = listDetailTransactionsByClients.filter(transaction => {
            if (filterCredit && transaction.paymentCondition === "Crédito") return true;
            if (filterAccount && transaction.paymentCondition === "Pago de contado") return true;
            if (filterPayment && transaction.paymentStatus === "Pagado") return true;
            if (filterPendingPayment && transaction.paymentStatus === "Pendiente") return true;
            if (filterInvoiced && transaction.financialStatus === "Facturado") return true;
            if (filterPendigInvoiced && transaction.financialStatus === "Pendiente") return true;
            if (filterPreInvoice && transaction.financialStatus === "Prefactura") return true;
            if (filterPpd && transaction.payMethod === "PPD") return true;
            if (filterPue && transaction.payMethod === "PUE") return true;
            if (filterAmount && transaction.id_Pay_method === 1) return true;
            if (filterAmountCredit && transaction.id_Pay_method === 10) return true;
            if (filterAmountDebit && transaction.id_Pay_method === 4) return true;
            if (filterAmountTcredit && transaction.id_Pay_method === 2) return true;
            if (filterAmountTrasferencia && transaction.id_Pay_method === 8) return true;


            if (!filterCredit && !filterAccount && !filterPayment && !filterPendingPayment && !filterInvoiced && !filterPendigInvoiced && !filterPreInvoice && !filterPpd && !filterPue && !filterAmount && !filterAmountCredit && !filterAmountDebit && !filterAmountTcredit && !filterAmountTrasferencia ) return true;
            return false;
        });

        const total = datosFiltrados.reduce((suma, item) => {
            const _total = item.totalWorkOrder + item.totalTax || 0;
            return suma + _total;
        }, 0);

        setTotal(total);
    };

    useEffect(() => {
        calcularTotal();
    }, [filterCredit, filterAccount, filterPayment, filterPendingPayment, filterInvoiced, filterPendigInvoiced, filterPreInvoice, filterPpd, filterPue, listDetailTransactionsByClients,filterAmount,filterAmountCredit,filterAmountDebit,filterAmountTcredit,filterAmountTrasferencia]);


    const totalRowPatient = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
        <td ></td>
        <td></td>
        <td ></td>
        <td></td>
        <td style={{textAlign:"end"}} colSpan={3} className='labeltheadInvoice'><label className='f-12' >Total Pendiente:</label> </td>
        <td colSpan={2} style={{textAlign:"left"}}  className='labeltheadInvoice'><label className='f-12' > ${number_format(total, 2)}</label> </td>
        <td></td>
        <td ></td>
        <td></td>
        <td ></td>
        <td></td>
        <td></td>       
    </tr> 
    );


    const [activeTab, setActiveTab] = useState(1);

	const toggleTab = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	}

    return (
        <Fragment>
            <Col sm="12" className='p-l-0 p-r-0' >
            {
                // * HEADER FILTERS
            }
            <Row>
                <Col sm="12" md="6" lg="12">
                    <Card>
                        <CardBody className='p-3'>
                            <Row>
                                <Col sm="7" md="7" lg="7" className='text-center f-s-italic text-info p-2'>
                                
                                    <h5 className='f-14 f-w-700'>Reporte cuentas por cobrar - Empresas </h5>
                                </Col>
                                <Col sm="3" md="3" lg="3" className='d-flex'>
                                    <Input type='month' value={dateFilter} className=' mr-2' onChange={(e) => {
                                        setDateFilter(e.target.value)
                                    }} />
                                </Col>
                                <Col sm="1" md="1" lg="1" className='d-flex'>
                                    <Button color='info' size='xs' outline onClick={handleFilterDate}>Filtrar periodo</Button>
                                </Col>
                                <Col sm="1" md="1" lg="1" className='d-flex'>
                                    <Button color='danger' size='xs' outline onClick={handleCleanFilterByClient}>Limpiar periodo</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                // * END HEADER FILTERS
            }
            {
                // * INDICADORES
            }
            {/* <Card>
                <CardBody className='pl-2 pb-2 pt-2' >
                    <Row>
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}} >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcInvoicesPaid.svg")} style={{ width: "60px", height: "60px" }} />
                            </Col>
                            <Col sm="9" >
                            <label className='f-w-500 ' style={{fontSize:"13"}} >Ingresos Mensuales</label>
                            <div className='text-center widgets-ind'>
                                {
                                loadingTotalIngress && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                !loadingTotalIngress && <h4 className='text-success'><i className="fa fa-usd"></i> {lblTotalIngress}</h4>
                                }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}} >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcInvoicesPaid.svg")} style={{ width: "60px", height: "60px", filter: "sepia(1)" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Saldo mensual pendiente </label>
                            <div className='text-center widgets-ind'>
                                {
                                    loadingOutsideBalance && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingOutsideBalance && <h4 className='text-danger'><i className="icofont icofont-cur-dollar-minus"></i> {lblOutSideBalance}</h4>
                                }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}}>
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcInvoices.svg")} style={{ width: "60px", height: "60px" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Ingreso mensual pagado</label>
                            <div className='text-center widgets-ind'>
                                {
                                    loadingOutsideBalance && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingOutsideBalance && <h4 className='text-info'><i className="icofont icofont-cur-dollar-minus"></i> {lblTotalPaidIngress}</h4>
                                }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        <Col sm="3" >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPercentage.svg")} style={{ width: "60px", height: "60px" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Porcentaje de cumplimiento</label>
                            <div className=' widgets-ind '>
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingDiscount && <h4 className='text-warning f-w-600'>{lblTotalPercentageIngress}</h4>
                                }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                    </Row>
                </CardBody>
            </Card> */}
            </Col>
            {
                // ? REPORT
            }
            {/* <Row>
                <Col sm="12" >
                <Card>
                    <CardBody className='pl-1 pb-1 pt-0' >
                        <Row>
                          
                            <Col sm="10">
                            <Col className='scroll-container-cancell' style={{maxHeight:"420px"}} >
                            <Table striped size='sm'>
                                <thead>
                                <tr>
                                    <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>#</th>
                                    <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Cliente</th>
                                    <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Credito</th>
                                    <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Contado</th>
                                    <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Descuento</th>
                                    <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Pagado</th>
                                    <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Pendiente</th>
                                    <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Reporte</th>
                                    <th style={{ backgroundColor: "#1f9ad9", color: "#fff", fontWeight: "bold" }}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    loadingTableClients && <tr>
                                        <td colSpan={7} className='text-center'>
                                            <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                        </td>
                                    </tr>
                                }
                                {
                                    detailTransactionsByClients?.length > 0 && detailTransactionsByClients?.map((_orders, _indexOrder) => (
                                        <tr key={_orders?.id_client}  >
                                            <td>
                                                <b>{_indexOrder + 1}</b>
                                            </td>
                                            <td style={{ cursor: "pointer" }} onClick={() => handleFilterByClient(_orders?.id_client)}>
                                                <b><i className="icofont icofont-building-alt"></i> {_orders?.name_client ?? ""}</b>
                                            </td>
                                            <td style={{color:"#33a9eb"}}>
                                                <b>${number_format((_orders?.totalCredit ?? 0), 2)}</b>
                                            </td>
                                            <td style={{color:"#6c757d"}}>
                                                <b>${number_format((_orders?.totalCash ?? 0), 2)}</b>
                                            </td>
                                            <td className='text-danger' >
                                                <b>${number_format((_orders?.totalDiscount ?? 0), 2)}</b>
                                            </td>
                                            <td className='f-w-500'>
                                                <b>${number_format((_orders?.totalPaid ?? 0), 2)}</b>
                                            </td>
                                            <td className='f-w-500 txt-danger'>
                                            <label>${number_format((_orders?.pruebe ?? 0), 2)}</label>


                                            </td>
                                        

                                    <td  style={{ cursor: "pointer", textAlign: "center" }} onClick={() => DownloadReportCxc(_orders?.id_client)}>
                                                <Badge color='success'> <i className='icofont icofont-file-excel f-16' ></i> </Badge>
                                            </td>
                                            <td className='text-center'>
                                                <ReactApexChart options={ChartPaymentConditionsTable?.options} series={[
                                                    (_orders?.totalCredit ?? 0),
                                                    (_orders?.totalCash ?? 0),
                                                    (_orders?.totalDiscount ?? 0)
                                                ]} type='donut' height={45} width={45} />
                                            </td>
                                        </tr>
                                        
                                        ))
                                    }
                                    {detailTransactionsByClients?.length > 0 && (
                                        <tr  className="table-footer" style={{backgroundColor:"#000"}} >
                                            <td colSpan={2} className='text-right' style={{color:"#fff"}}>
                                                <b>Totales:</b>
                                            </td>
                                            <td  style={{color:"#fff"}} >
                                                    ${number_format(
                                                        detailTransactionsByClients.reduce(
                                                            (sum, _orders) => sum + (_orders?.totalCredit ?? 0),
                                                            0
                                                        ),
                                                        2
                                                    )}
                                            </td>
                                            
                                            <td style={{color:"#fff"}} >
                                                    ${number_format(
                                                        detailTransactionsByClients.reduce(
                                                            (sum, _orders) => sum + (_orders?.totalCash ?? 0),
                                                            0
                                                        ),
                                                        2
                                                    )}
                                            </td>
                                            <td style={{color:"#fff"}} >
                                                    ${number_format(
                                                        detailTransactionsByClients.reduce(
                                                            (sum, _orders) => sum + (_orders?.totalDiscount ?? 0),
                                                            0
                                                        ),
                                                        2
                                                    )}
                                            </td>
                                            <td style={{color:"#fff"}}>
                                                    ${number_format(
                                                        detailTransactionsByClients.reduce(
                                                            (sum, _orders) => sum + (_orders?.totalPaid ?? 0),
                                                            0
                                                        ),
                                                        2
                                                    )}
                                            </td>
                                            <td style={{color:"#fff"}}>
                                                    ${number_format(
                                                        detailTransactionsByClients.reduce(
                                                            (sum, _orders) => sum + (_orders?.pruebe ?? 0),
                                                            0
                                                        ),
                                                        2
                                                    )}
                                            </td>
                                        
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                
                                </tbody>  
                            </Table>
                            </Col>
                            </Col>
                            <Col sm="2" className='p-l-0 p-r-0' > <br />
                            <Col sm="12"  className='col-selected-1 p-l-0 p-r-0 ' style={{border:"solid 1px #ecf3fa", height: "60px"}}>
                            <Col  sm="12" className='p-l-0 p-r-0' style={{textAlign:"center"}} >
                            <label className='f-w-500' >Total de pacientes</label>
                            </Col>
                            <Row>
                                <Col sm="4" style={{textAlign:"right"}} >
                                <i className="fa fa-users f-32 txt-info" ></i>
                                </Col>
                                <Col sm="8">
                                <h4 className=''>{lblTotalPatient} </h4>

                                </Col>
                            </Row>

                            </Col> <br /> <br />
                            <Col sm="12"  className='col-selected-1 p-l-0 p-r-0 ' style={{border:"solid 1px #ecf3fa", height: "70px"}}>
                            <Col  sm="12" className='p-l-0 p-r-0' style={{textAlign:"center"}} >
                            <label className='f-w-500' >Total de Clientes</label>
                            </Col>
                            <Row>
                                <Col sm="4" style={{textAlign:"right"}} >
                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcCompany.svg")} style={{ width: "40px", height: "40px" }} />
                                </Col>
                                <Col sm="8">
                                <h4 className='pt-2'>{lblTotalClients} </h4>

                                </Col>
                            </Row>

                            </Col>
                           
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
                </Col>
            </Row> */}
            {
                // ? END REPORT
            }
            {
                // ? TABLE
            }
            <Row className='mt-2'>
                <Col sm="12" md="12" lg="12">
                    <Card>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Cargos pendientes - saldados</h5>
                        </CardHeader>
                        <CardBody className='p-2' >
                            <div className='text-left'>
                                <p>Mostrando - {listDetailTransactionsByClients?.length} resultados</p>
                            </div>
                            <Col sm="12" className='p-l-0 p-r-0'>
                            <Row>
                                <Col sm="4" className='p-r-0' >
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmount ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmount(!filterAmount) }} >Efectivo</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountCredit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountCredit(!filterAmountCredit) }} >Crédito</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountDebit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountDebit(!filterAmountDebit) }} >T.D</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountTcredit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountTcredit(!filterAmountTcredit) }} >T.C</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountTrasferencia ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountTrasferencia(!filterAmountTrasferencia) }} >Transferencia</Label>
                                </Col>
                                <Col sm="8" className='p-l-0' style={{textAlign:"right"}} >
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterCredit ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterCredit(!filterCredit) }} >Crédito</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAccount ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterAccount(!filterAccount) }} >Contado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPayment ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPayment(!filterPayment) }} >Pagado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPendingPayment ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPendingPayment(!filterPendingPayment) }} >Pendiente pagado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterInvoiced ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterInvoiced(!filterInvoiced) }} >Facturado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPendigInvoiced ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPendigInvoiced(!filterPendigInvoiced) }} >Pendiente de facturar</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPreInvoice ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPreInvoice(!filterPreInvoice) }} >Prefactura</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPpd ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPpd(!filterPpd) }} >PPD</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPue ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPue(!filterPue) }} >PUE</Label>
                                </Col>
                            </Row>
                            </Col>
                        
                            <div style={{ height: '440px', overflowY: "scroll", scrollBehavior: "smooth" }} className='table-wrapper-patient'>
                                <Table className='b-r-5 table-striped sm  -hover'>
                                    <thead className='b-r-5'>
                                        <tr style={{ backgroundColor: "#033d5b", fontSize:"11px" }}>
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>Cliente</th>
                                            <th className='labeltheadInvoice'>NIM</th>
                                            <th className='labeltheadInvoice'>Paciente</th>
                                            <th className='labeltheadInvoice'>Condición de pago</th>
                                            <th className='labeltheadInvoice'>Estado del pago</th>
                                            <th className='labeltheadInvoice'>Estatus facturación</th>
                                            <th className='labeltheadInvoice'>Total</th>
                                            <th className='labeltheadInvoice'>Importe</th>
                                            <th className='labeltheadInvoice'>Descuento</th>
                                            <th className='labeltheadInvoice'>Impuesto (IVA 16%)</th>
                                            <th className='labeltheadInvoice'>Serie</th>
                                            <th className='labeltheadInvoice'>Folio</th>
                                            <th className='labeltheadInvoice'>Método Pago</th>
                                            <th className='labeltheadInvoice'>Método</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loadingTableClients && <tr>
                                                <td colSpan={13} className='text-center'>
                                                    <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            listDetailTransactionsByClients?.length > 0 &&
                                            listDetailTransactionsByClients?.filter(_transaction => {

                                                if (filterAmount) {
                                                    return _transaction?.id_Pay_method === 1
                                                }
                                                if (filterAmountCredit) {
                                                    return _transaction?.id_Pay_method === 10
                                                }
                                                if (filterAmountDebit) {
                                                    return _transaction?.id_Pay_method === 4
                                                }
                                                if (filterAmountTcredit) {
                                                    return _transaction?.id_Pay_method === 2
                                                }
                                                if (filterAmountTrasferencia) {
                                                    return _transaction?.id_Pay_method === 8
                                                }

                                                if (filterCredit) {
                                                    return _transaction?.paymentCondition == "Crédito";
                                                }

                                                if (filterAccount) {
                                                    return _transaction?.paymentCondition == "Pago de contado";
                                                }

                                                if (filterPayment) {
                                                    return _transaction?.paymentStatus == "Pagado";
                                                }

                                                if (filterPendingPayment) {
                                                    return _transaction?.paymentStatus == "Pendiente";
                                                }

                                                if (filterInvoiced) {
                                                    return _transaction?.financialStatus == "Facturado";
                                                }

                                                if (filterPendigInvoiced) {
                                                    return _transaction?.financialStatus == "Pendiente";
                                                }

                                                if (filterPreInvoice) {
                                                    return _transaction?.financialStatus == "Prefactura";
                                                }

                                                if (filterPpd) {
                                                    return _transaction?.payMethod == "PPD";
                                                }

                                                if (filterPue) {
                                                    return _transaction?.payMethod == "PUE";
                                                }

                                                if (!filterCredit && !filterAccount && !filterPayment && !filterPendingPayment && !filterInvoiced && !filterPendigInvoiced && !filterPreInvoice && !filterPpd && !filterPue) {
                                                    return _transaction;
                                                }
                                            })
                                                ?.map((_order, _index) => {

                                                    let paymentMethod;

                                                    switch (_order.id_Pay_method) {
                                                        case 1:
                                                            paymentMethod = "Efectivo";
                                                            break;
                                                        case 2:
                                                            paymentMethod = "T.C";
                                                            break;
                                                        case 4:
                                                            paymentMethod = "T.D";
                                                            break;
                                                        case 8:
                                                            paymentMethod = "T.E";
                                                            break;
                                                        case 10:
                                                            paymentMethod = "Crédito";
                                                            break;
                                                        case 28:
                                                            paymentMethod = "Deposito Bancario";
                                                            break;
                                                        default:
                                                            paymentMethod = "Desconocido"; 
                                                            break;
                                                    }
                                                    
                                                    let _total =  _order?.totalWorkOrder
                                                    let _tax =  _order?.totalTax

                                                    const _totalWorkOrder = _total += _tax
                                                    return <tr key={_order?.idWorkOrder} className={_order?.financialStatus == "S/Factura" ? "tableSF text-white" : ""}>
                                                        <td style={{fontSize : "11px"}}>{_index + 1}</td>
                                                        <td style={{fontSize : "11px"}}>{_order?.nameClient}</td>
                                                        <td style={{fontSize : "11px",color:"#0079C7"}} className='f-w-600' >{_order?.nim}</td>
                                                        <td style={{fontSize : "11px"}} >{_order?.namePatient}</td>
                                                        <td><Label type="text"  style={{fontSize : "11px"}} className={`f-w-600  badge badge-${_order?.paymentCondition === "Cortesia" ? 'danger' : _order?.paymentCondition === "Crédito" ? 'warning' : "secondary"}`}>{_order?.paymentCondition}</Label></td>
                                                        <td ><Label type="text" style={{fontSize : "11px"}} className={`f-w-600  badge badge-light-${_order?.paymentStatus === "Pendiente" ? 'warning' : "success"}`}>{_order?.paymentStatus}</Label></td>
                                                        <td ><Label type="text" style={{fontSize : "11px"}} className={`f-w-600  badge badge-light-${_order?.financialStatus === "Pendiente" ? 'danger' : _order?.financialStatus == "Prefactura" ? "warning" : "success"}`}>{_order?.financialStatus}</Label></td>
                                                        <td  style={{fontSize : "11px"}} className='f-w-700'>${number_format(_totalWorkOrder?? 0,2)}</td>
                                                        <td style={{fontSize : "11px"}} className='f-w-600'>$ {number_format(_order?.totalWorkOrder, 2)}</td>
                                                        <td style={{fontSize : "11px"}} className='font-danger f-w-600'>$ {number_format(_order?.totalDiscount, 2)}</td>
                                                        <td style={{fontSize : "11px"}} className='f-w-600 text-seconday'>$ {number_format(_order?.totalTax, 2)}</td>
                                                        <td style={{fontSize : "11px"}}>{_order?.serie}</td>
                                                        <td style={{fontSize : "11px"}}>{_order?.folio}</td>
                                                        <td style={{fontSize : "11px"}} className='f-w-700'>{_order?.payMethod}</td>
                                                        <td style={{fontSize : "11px"}} className='f-w-700 txt-success'>{paymentMethod}</td>
                                                        </tr>
                                                })
                                        }
                                        {totalRowPatient}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                // ? END TABLE
            }
            {
                // ? INVOICE
            }
            {/* {
                <Row className='pt-3' >
                <Col sm="12"  >
                <Card>
                <CardBody className='pl-2 pb-2 pt-2' >
                    <Row>
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}} >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcInvoicesStamp.svg")} style={{ width: "60px", height: "60px" }} />
                            </Col>
                            <Col sm="9" >
                            <label className='f-w-500 ' style={{fontSize:"13"}} >Facturas emitidas</label>
                            <div className=' widgets-ind'>
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                            {
                                !loadingIngressInvoiced && <h4>
                                {lblCurrentTotalInvoices}
                                </h4>
                            }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}} >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPaids.svg")} style={{ width: "60px", height: "60px" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Facturas cobradas en mes actual </label>
                            <div className=' widgets-ind '> 
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                !loadingProfiles && <h4 className='text-success'>{lblCurrentPaymentMonth}</h4>
                            }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}}>
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPaid.svg")} style={{ width: "60px", height: "60px" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Facturas actualmente pagadas</label>
                            <div className=' widgets-ind'>
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingExams && <h4 className='text-info'> {lblCurrentPaidInvoices}</h4>
                                }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        <Col sm="3" >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPercentage.svg")} style={{ width: "60px", height: "60px" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Porcentaje de cumplimiento</label>
                            <div className=' widgets-ind'>
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingDiscount && <h4 className='text-warning'> {lbPercentageInvoice}</h4>
                                }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
                </Col>

                </Row>
            } */}
            {/* {
                // * AMOUNT LABEL'S
            }
            <Row className='mt-2'>
                <Col sm="12" md="6" lg="6">
                    <Card>
                        <CardHeader className='text-center p-1 bg-info'>
                            <h6 className='pt-1'>Facturado Mes anterior</h6>
                        </CardHeader>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Facturado mes anterior</b>
                                    <h5>${ number_format(lblBeforeTotalBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pagado mes anterior</b>
                                    <h5>${ number_format(lblBeforePaymentBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='b-r-1 text-center'>
                                    <b>Saldo pendiente mes anterior</b>
                                    <h5>${ number_format(lblBeforePendingPaymentBilling, 2)}</h5>
                                </Col>                            
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="6">
                    <Card>
                        <CardHeader className='text-center p-1 bg-success'>
                            <h6 className='pt-1'>Facturado Mes actual</h6>
                        </CardHeader>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Facturado</b>
                                    <h5>${ number_format(lblCurrentTotalBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pagado</b>
                                    <h5>${ number_format(lblCurrentPaymentBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pendiente</b>
                                    <h5>${ number_format(lblCurrentPendingPaymentBilling, 2)}</h5>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col >
            </Row>
            {
                // * END AMOUNT LABEL'S
            } */}
            {
                // ? Report Invoice
            }
            {/* <Row className='pb-2' >
                <Col sm="12">
                <Card>
                    <CardBody  className='pl-1 pb-1 pt-0'>
                        <Row>
                            <Col sm="10">
                            <Col sm="12" md="12" lg="12">
                        <Row className='mt-2'>
                            <Col sm="6" md="6" lg="6">
                                <ButtonGroup className='p-2'>
                                    <Button outline={!viewBeforeMonth} onClick={() => handleViewTransactions(true, false, false)}><i className="fa fa-angle-double-left"></i> Anterior Més</Button>
                                    <Button outline={!viewCurrentMonth} onClick={() => handleViewTransactions(false, true, false)}>Més actual</Button>
                                    <Button outline={!viewAfterMonth} onClick={() => handleViewTransactions(false, false, true)}>Siguiente Més <i className="fa fa-angle-double-right"></i></Button>
                                </ButtonGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6" className='text-right'>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterViewAll ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(true, false, false, false) }} >Todo</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterOutValidity ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, true, false, false) }} >Vencida</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPaid ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, false, false, true) }} >Pagada</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterCurrent ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, false, true, false) }} >Vigente</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge-success`} style={{ cursor: "pointer" }} onClick={() => { MakeExcelTransactionMontly(viewBeforeMonth, viewCurrentMonth, filterViewAll, filterOutValidity, filterPaid, filterCurrent) }} >Descargar excel</Label>
                            </Col>
                        </Row>
                        <Card className='p-2' hidden={!viewBeforeMonth}>    
                            <Col sm="12" className='p-l-0 p-r-0 scroll-container-cancell'  style={{maxHeight:"420px"}} >
                            <Table className='b-r-5 table-striped table-hover table-xs' >
                                    <thead className='b-r-5'>
                                        <tr style={{ backgroundColor: "#033d5b" }}>
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>Fecha emisión</th>
                                            <th className='labeltheadInvoice'>N° factura</th>
                                            <th className='labeltheadInvoice'>Cliente</th>
                                            <th className='labeltheadInvoice'>Monto facturado</th>
                                            <th className='labeltheadInvoice'>Días de crédito</th>
                                            <th className='labeltheadInvoice'>F. de Vencimiento</th>
                                            <th className='labeltheadInvoice'>Estatus</th>
                                            <th className='labeltheadInvoice'>F. de Pago</th>
                                            <th className='labeltheadInvoice'>Complemento</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            loadingTableClients && <tr>
                                                <td colSpan={13} className='text-center'>
                                                    <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            listIndicatorsDetail?.length > 0 &&
                                            listIndicatorsDetail?.filter(_invoice => {
                                                if (_invoice?.beforeMonth) {
                                                    if (filterOutValidity && _invoice?.status === "VENCIDA") {
                                                        return _invoice;
                                                    }
                                                    else if (filterPaid && _invoice?.status === "PAGADA") {
                                                        return _invoice;
                                                    }
                                                    else if (filterCurrent && _invoice?.status === "VIGENTE") {
                                                        return _invoice;
                                                    }
                                                    else if (filterViewAll) {
                                                        return _invoice;
                                                    }
                                                }
                                            })
                                                ?.map((_invoice, _index) => {
                                                    return <tr key={_invoice?.idInvoice} className={ _invoice?.isStamp ? "f-w-700" : "f-w-500" }>
                                                        <td>{_index + 1}</td>
                                                        <td>{_invoice?.dateStampInvoice}</td>
                                                        <td>{_invoice?.serie ?? ""} {_invoice?.folio ?? ""}</td>
                                                        <td>{_invoice?.nameClient}</td>
                                                        <td>$ {number_format(_invoice?.totalInvoice, 2)}</td>
                                                        <td>{_invoice?.creditDays}</td>
                                                        <td>{_invoice?.validityDate}</td>
                                                        <td>
                                                            <Label type="text" className={`f-w-600 f-12 badge badge-${_invoice?.status === "PAGADA" ? 'success' : _invoice?.status === "VIGENTE" ? "secondary" : "danger"}`}>
                                                                {_invoice?.status}
                                                            </Label>
                                                        </td>
                                                        <td>{_invoice?.paymentDate ?? ""}</td>
                                                        <td>{_invoice?.idComplement ?? "---"}</td>
                                                    </tr>
                                                })
                                        }
                                    </tbody>
                                </Table>
                            </Col>                                    
                            
                        </Card>
                        <Card className='p-2' hidden={!viewCurrentMonth}>
                            <Col sm="12" className='p-l-0 p-r-0 scroll-container-cancell' style={{maxHeight:"420px"}} >
                            <Table className='b-r-5 table-striped table-hover table-xs'>
                                    <thead className='b-r-5'>
                                        <tr style={{ backgroundColor: "#033d5b" }}>
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>Fecha emisión</th>
                                            <th className='labeltheadInvoice'>N° factura</th>
                                            <th className='labeltheadInvoice'>Cliente</th>
                                            <th className='labeltheadInvoice'>Monto facturado</th>
                                            <th className='labeltheadInvoice'>Días de crédito</th>
                                            <th className='labeltheadInvoice'>F. de Vencimiento</th>
                                            <th className='labeltheadInvoice'>Estatus</th>
                                            <th className='labeltheadInvoice'>F. de Pago</th>
                                            <th className='labeltheadInvoice'>Complemento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loadingTableClients && <tr>
                                                <td colSpan={13} className='text-center'>
                                                    <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            listIndicatorsDetail?.length > 0 &&
                                            listIndicatorsDetail?.filter(_invoice => {
                                                if (_invoice?.currentMonth) {
                                                    if (filterOutValidity && _invoice?.status == "VENCIDA") {
                                                        return _invoice;
                                                    }
                                                    else if (filterPaid && _invoice?.status == "PAGADA") {
                                                        return _invoice;
                                                    }
                                                    else if (filterCurrent && _invoice?.status == "VIGENTE") {
                                                        return _invoice;
                                                    }
                                                    else if (filterViewAll) {
                                                        return _invoice;
                                                    }
                                                }
                                            })
                                                ?.map((_invoice, _index) => {
                                                    return <tr key={_invoice?.idInvoice} className={ _invoice?.isStamp ? "f-w-700" : "f-w-500" }>
                                                        <td>{_index + 1}</td>
                                                        <td>{_invoice?.dateStampInvoice}</td>
                                                        <td>{_invoice?.serie ?? ""} {_invoice?.folio ?? ""}</td>
                                                        <td>{_invoice?.nameClient}</td>
                                                        <td>$ {number_format(_invoice?.totalInvoice, 2)}</td>
                                                        <td>{_invoice?.creditDays}</td>
                                                        <td>{_invoice?.validityDate}</td>
                                                        <td>
                                                            <Label type="text" className={`f-w-600 f-12 badge badge-${_invoice?.status === "PAGADA" ? 'success' : _invoice?.status === "VIGENTE" ? "secondary" : "danger"}`}>
                                                                {_invoice?.status}
                                                            </Label>
                                                        </td>
                                                        <td>{_invoice?.paymentDate ?? ""}</td>
                                                        <td>{_invoice?.idComplement ?? "---"}</td>
                                                    </tr>
                                                })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                               
                        </Card>
                    </Col>
                            </Col>
                            <Col sm="2" className='p-l-0 p-r-0' > <br />
                            <Col sm="12"  className='col-selected-1 p-l-0 p-r-0 ' style={{border:"solid 1px #ecf3fa", height: "70px"}}>
                            <Col  sm="12" className='p-l-0 p-r-0' style={{textAlign:"center"}} >
                            <label className='f-w-500' >Facturas del siguiente mes</label>
                            </Col>
                            <Row>
                                <Col sm="4" style={{textAlign:"right"}} >
                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPdf.svg")} style={{ width: "40px", height: "40px" }} />
                                </Col>
                                <Col sm="8">
                                <h4 className='pt-2'><i className="fa fa-angle-double-right txt-danger"></i>{lblTotalInvoicesNextMonth}  </h4>
                                </Col>
                            </Row>
                            </Col>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row className='pb-5' >
            <Col sm="12" md="12" lg="12" id='chart' className='p-l-0 p-r-0' >
                <Card>
                    <CardHeader className='p-2 text-center'>
                        <h5 className='card-title f-14'>Indicador Global de facturas vencidas</h5>
                    </CardHeader>
                    <CardBody className='p-1 text-center '>
                        {
                            loadingExpiredWallet && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                        }
                        {
                            !loadingExpiredWallet && <center>
                                <Col sm="12">
                                <Chart options={ChartAgePayable?.options} series={ChartAgePayable?.series} type="bar" height={100} width={"100%"} />
                                <br />
                                <Row>
                                    <Col sm="8">
                                    <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: '#033d5b', color:"#fff", fontSize:"11px" }}>
                                        <thead style={{ backgroundColor: 'rgba(135, 206, 235, 0.8)' }}>
                                            <tr>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Periodo</th>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>N° factura</th>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>F. Emisión</th>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Monto facturado</th>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Cliente</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailSeriesExpiredWallet.map((item, index) => (
                                                item.folio.length > 0 ? (
                                                    <tr key={index}>
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            {item?.name ?? "----"}
                                                        </td>
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            {item?.folio.map((folio, idx) => (
                                                                <div key={idx}>
                                                                    {folio}
                                                                </div>
                                                            ))}
                                                        </td>
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign:"center" }}>
                                                            {item?.fech.map((fech, idx) => (
                                                                <div key={idx}>
                                                                    {fech}
                                                                </div>
                                                            ))}
                                                        </td>
                                                       
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            {item?.amount.map((amount, idx) => (
                                                                <div key={idx}>
                                                                {amount.split("-").map((part, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {i > 0 && <br />}
                                                                        {`${part}`}
                                                                    </React.Fragment>
                                                                ))}
                                                            </div>
                                                            ))}
                                                        </td>
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            {item?.company?.map((company, idx) => (
                                                                <div key={idx}>
                                                                    {company.split("-").map((part, i) => (
                                                                        <React.Fragment key={i}>
                                                                            {i > 0 && <br />}
                                                                            {`${part}`}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                ) : null
                                            ))}
                                        </tbody>
                                    </table>
                                    </Col>
                                </Row>
                                </Col>
                            </center>
                        }
                    </CardBody>
                </Card>
            </Col>

            </Row> */}
           
        </Fragment>
        
    )
}

