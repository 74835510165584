import React from 'react'
import Select from 'react-select';
import { Form, ModalBody, Row, Col, ModalFooter, Button, Label, Input, FormFeedback, FormGroup } from 'reactstrap';
import DeniReactTreeView from "deni-react-treeview"

export const FormCedula = (props) => {
    let {
        loading, button,
        toggleCedula, professional_license, userId , saveProfessionalLicense, handleInputChangeC, formValuesC, typeCedula
        
    } = (props)
        console.log("🚀 ~ FormCedula ~ toggleCedula:", toggleCedula)
        console.log("🚀 ~ FormCedula ~ userId:", userId)

    //console.log(formUser)   
    

    return (
        <Form className="" role="form" onSubmit={saveProfessionalLicense}>
            <ModalBody>
                <Row md="12">
                   
                    <Col md="12">
                        <Label className="col-form-label">Cédula:</Label>
                        <Input type="text" autoComplete="off" className="form-control form-control-sm input-air-primary"
                            name="professional_license" value={professional_license} onChange={handleInputChangeC}
                        />
                    </Col>
                
                </Row>
                <br />

                

            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    size="sm"
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} disabled={loading}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggleCedula}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}


