import React from 'react'
import {Button, Modal, ModalBody, CardBody, Table,Col, Form } from 'reactstrap';

export const ModalListDevolution = (props) => {

    const {modalDevolution,toggleModalDevolution,historicDevolution,amountDevolution} = props;

    
    return (
        <Modal isOpen={modalDevolution} toggle={toggleModalDevolution} size='lg' backdrop='static' keyboard={false} centered={true}>
                <ModalBody>   
                    <b className='txt-secondary f-w-600 f-16' > Historico de devoluciones</b>
                <CardBody className="p-l-0 p-b-0 p-r-0">
                    <Col sm="12">
                    <Table className="table-sm">
                        <thead>
                            <tr className="text-center" style={{ backgroundColor: "#002940", color: "#fff" }}>
                            <th style={{ color: "#fff", textAlign: "center" }}>Examen / Perfil</th>
                            <th style={{ color: "#fff", textAlign: "center" }}>Motivo </th>
                            <th style={{ color: "#fff", textAlign: "center" }}>Responsable</th>
                            <th style={{ color: "#fff", textAlign: "center" }}>Fecha</th>
                            <th style={{ color: "#fff", textAlign: "center" }}>Total </th>
                            </tr>
                        </thead>
                        <tbody>
                            {historicDevolution.length > 0 &&
                            historicDevolution.map((_search) => {
                                return (
                                <tr key={_search.id}>
                                    <td style={{ textAlign: "center" }}>
                                    <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/typeService.svg")} style={{ width: "21px", height: "21px" }}/>{_search?.exam_profile ?? "--"}</td>
                                    <td style={{ textAlign: "center" }}>{_search?.name_devolution ?? "--"}</td>
                                    <td style={{ textAlign: "center" }}>{_search?.name_user_autorized ?? "--"}</td>
                                    <td style={{ textAlign: "center" }}>{_search?.fech ?? "--"}</td>
                                    <td style={{ textAlign: "center" }}>
                                    <b>
                                        ${parseFloat(_search?.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                    </b>
                                    </td>
                                </tr>
                                );
                            })}
                            <tr style={{ fontWeight: "bold" }}>
                            <td colSpan="4" style={{ textAlign: "right" }}>
                                Total:
                            </td>
                            <td style={{ backgroundColor: "#000", color: "#fff", textAlign: "center" }}>
                                ${parseFloat(amountDevolution).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </td>
                            </tr>
                        </tbody>
                    </Table>
                    </Col> <br /> <br />
                    <Col sm="12" style={{textAlign:"right"}} className='pt-2' >
                    <Button outline color='danger' size='sm' onClick={toggleModalDevolution} >Cancelar</Button>
                    </Col>
                </CardBody>   
                </ModalBody>
        </Modal>
    )
}
