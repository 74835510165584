import React, { useEffect, useState } from 'react';
import { TableStriped } from '../tables/TableStriped';
import { useHistory } from 'react-router';
import { Button, Collapse, Form, ModalBody, ModalHeader, FormGroup, Label, Row, Col, Input, ListGroupItem, ListGroup, Table } from 'reactstrap';
import { sendRequest } from '../../hooks/requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';

export const ModalComplex = (props) => {
    const { history } = useHistory();
    const {
        analytesAvailables, refreshFormComplex, responseComplexText, refreshResultComplex, preSavedFormComplex, analytesComplex, exam, methodActive, name
    } = props;

    const [typeComplex, setTypeComplex] = useState("local");
    const [arrayLocalInputs, setArrayLocalInputs] = useState([]);
    const [localAnalytes, setLocalAnalytes] = useState([]);
    const [valuesLocal, setValuesLocal] = useState({});
    const [formula, setFormula] = useState("");
    const [validacionFormula, setValidacionFormula] = useState({
        status: false,
        msg: ""
    });
    const [examActiveId, setExamActiveId] = useState(exam.id_exam);
    const [externalAnalytes, setExternalAnalytes] = useState([]);
    const [search, setSearch] = useState("");
    const [localExams, setLocalExams] = useState([]);
    const [exams, setExams] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const changeType = (type) => {
        setTypeComplex(type);
    }

    const handleInputLocal = ({ target }, tipoDato) => {
        setValuesLocal({
            ...valuesLocal,
            [target.name]: {
                ...valuesLocal[target.name],
                [tipoDato]: target.value.toLowerCase(),
            }
        });
    }

    const validarVariables = ({ target }) => {
        let arrayAux = valuesLocal;
        let validacion = true;
        let validacionNombre = true;
        let validacionValor = true;
        Object.keys(arrayAux).forEach(function (key) {
            if (key !== target.name && arrayAux[key].variable === target.value) {
                validacion = false;
            }

            if (arrayAux[key].variable.trim().length <= 0) {
                validacionNombre = false;
            }
            if (parseFloat(arrayAux[key].valor) === 0 || isNaN(parseFloat(arrayAux[key].valor))) {
                validacionValor = false;
            }
        });
        if (validacionNombre) {
            if (validacion) {
                if (validacionValor) {
                    setValidacionFormula({
                        status: true,
                        msg: ""
                    });
                } else {
                    setValidacionFormula({
                        status: false,
                        msg: "Los valores de prueba no pueden quedarse en 0, se debe asignar un valor mayor o menor a 0."
                    })
                }
            } else {
                setValidacionFormula({
                    status: false,
                    msg: "Existen variables repetidas, es necesario que cada variable sea única."
                })
            }
        } else {
            setValidacionFormula({
                status: false,
                msg: "No se pueden quedar vacios los campos de variable."
            })
        }
    }

    const validarVariablesLocales = () => {
        refreshResultComplex();
        let validacion = true;
        let validacionValor = true;
        if (validacionFormula.status === false) {
            return;
        }
        if (valuesLocal.length <= 0) {
            setValidacionFormula({
                status: false,
                msg: "No se pueden quedar vacios los campos de variable."
            })
        } else {
            Object.keys(valuesLocal).forEach(function (key) {
                if (valuesLocal[key].variable.trim().length <= 0) {
                    validacion = false;
                }

                if (parseFloat(valuesLocal[key].valor) === 0) {
                    validacionValor = false;
                }
            })

            if (validacion) {
                if (validacionValor) {

                    setValidacionFormula({
                        status: true,
                        msg: ""
                    })
                } else {
                    setValidacionFormula({
                        status: false,
                        msg: "Los valores de prueba no pueden quedarse en 0, se debe asignar un valor mayor o menor a 0."
                    })
                }
            } else {
                setValidacionFormula({
                    status: false,
                    msg: "No se pueden quedar vacios los campos de variable."
                })
            }
        }
    }

    const addLocalInput = (id) => {
        let arrayAux = arrayLocalInputs;
        let analyte = null;
        let name_code = "";
        let name = "";
        let code = "";
        let variable = "";
        if (typeComplex === "local") {
            analyte = localAnalytes.find(a => a.id_analyte === parseInt(id));
            let newLocalAnalytes = localAnalytes.filter(la => la.id_analyte !== analyte.id_analyte);
            setLocalAnalytes(newLocalAnalytes);
            name_code = exam.abbreviation + "→" + analyte.code;
            name = analyte.name;
            code = analyte.code;
            variable = exam.abbreviation.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "_" + analyte.code.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        } else {
            analyte = externalAnalytes.find(a => a.id_analyte === parseInt(id));
            let newExternalAnalytes = externalAnalytes.filter(la => la.id_analyte !== analyte.id_analyte);
            setExternalAnalytes(newExternalAnalytes);
            let exam = exams.find(e => e.id_exam === parseInt(examActiveId));
            name_code = exam.code + "→" + analyte.code;
            name = analyte.name;
            code = analyte.code;
            variable = exam.abbreviation.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + "_" + analyte.code.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        }

        let obj = {
            id_analyte: analyte.id_analyte,
            name_code: name_code,
            name: name,
            code: code,
            variable: variable
        }
        setValuesLocal({
            ...valuesLocal,
            [`${obj.id_analyte}`]: {
                id_analyte: obj.id_analyte,
                variable: variable,
                valor: 1
            }
        });
        setValidacionFormula({
            status: true,
            msg: ""
        });
        refreshResultComplex();
        setArrayLocalInputs([...arrayAux, obj]);
    }

    const removeLocalInput = (id) => {
        let arrayAux = arrayLocalInputs.filter(obj => {
            if (obj.id_analyte !== parseInt(id)) {
                return obj;
            }
            return false;
        });
        let newValues = valuesLocal;
        delete newValues[id];
        setValuesLocal(newValues);
        setArrayLocalInputs([...arrayAux]);

        analytesAvailables.forEach(function (element) {
            if (element.id_analyte === id) {
                setLocalAnalytes([...localAnalytes, element]);
            }
        });


        if (typeComplex === "externo") {
            getAnalytesAvailableFromExam(examActiveId, id);
        }

    }

    const handleExternal = (id_exam) => {
        changeType("externo")
        getAnalytesAvailableFromExam(id_exam);
    }

    const handleLocal = (id_exam) => {
        changeType("local")
        setExamActiveId(id_exam);
    }

    const getExams = async() => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Exam/List?EnablePagination=false");

        if (respuesta.code === 200) {
            let examenes = respuesta.data.results;
            setExams(examenes);
        }
    }

    const getAnalytesAvailableFromExam = async (id_exam, id_analyte_aux = 0) => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Exam/GetAnalytesAvailableFromExam/" + id_exam);

        if (respuesta.code === 200) {
            setExamActiveId(parseInt(id_exam));
            ////console.log(respuesta.data);
            let analytes = respuesta.data;
            arrayLocalInputs.forEach(({ id_analyte }) => {
                if (id_analyte_aux !== id_analyte) {
                    analytes = analytes.filter(a => a.id_analyte !== id_analyte);
                }
            });
            ////console.log("Exteros");
            ////console.log(analytes);
            setExternalAnalytes(analytes);
        }
        else {
            validarSesion(history, respuesta.code, getAnalytesAvailableFromExam);
        }
    }

    const handleSearch = ({ target }) => {
        setSearch(target.value);
        let newExams = exams.filter(function (obj) {
            if (obj.name.toLowerCase().includes(target.value)) {
                return obj;
            }
            return false;
        });
        setLocalExams(newExams);
    }

    useEffect(() => {
        getExams()
    }, [])

    useEffect(() => {
        setLocalAnalytes(analytesAvailables);
    }, [analytesAvailables])

    useEffect(() => {
        if (typeof (analytesComplex) === "object") {
            if (analytesComplex.complexValueAnalytes !== undefined) {
                let arrayLocalInputsAux = [];
                let valuesLocalAux = {};
                let analytesLocal = [...localAnalytes];
                analytesComplex.complexValueAnalytes.forEach(element => {
                    arrayLocalInputsAux.push(element);
                    analytesLocal = analytesLocal.filter(a => a.id_analyte !== element.id_analyte);
                    valuesLocalAux = {
                        ...valuesLocalAux,
                        [element.id_analyte]: {
                            id_analyte: element.id_analyte,
                            variable: element.variable,
                            valor: 1,
                            name_code: element.name + " [" + element.code + "]",
                            name: element.name,
                            code: element.code
                        }
                    }
                });
                setValuesLocal(valuesLocalAux)
                setArrayLocalInputs(arrayLocalInputsAux);
                setFormula(analytesComplex.formula);
                setLocalAnalytes(analytesLocal);
                setValidacionFormula({
                    status: true,
                    msg: ""
                });
            }
        }
    }, [analytesComplex])

    useEffect(() => {
        setExamActiveId(exam.id_exam);
    }, [exam])

    useEffect(() => {
        setLocalExams(exams);
        setSearch("");
    }, [exams])

    useEffect(() => {
        if (methodActive === "create") {
            setSearch("");
            setExamActiveId(exam.id_exam);
            setTypeComplex("local");
            setLocalAnalytes(analytesAvailables);
            setArrayLocalInputs([]);
            setFormula("");
        }
    }, [methodActive]);

    const stylesTd = {
        width: 200, whiteSpace: "break-spaces"
    }

    return (
        <div className="pl-5 pr-5 pt-3">
            <ModalHeader className="pb-1 pl-2 pr-2">
                Gestión de fórmula del analito : <b>{name}</b>
            </ModalHeader>
            <ModalBody className="pt-1 border-top border-light pb-0">
                <Form role="form" onSubmit={null}>
                    <Row>
                        <FormGroup className={`mb-3 col-7`}>
                            <Row>
                                <Col sm="6" className="border-right border-light h-100vh overflow-auto">
                                    <h5>Examen actual</h5>
                                    <ListGroup>
                                        <ListGroupItem
                                            style={{ cursor: "pointer" }}
                                            className={`list-group-item-action text-sm ${examActiveId === exam.id_exam ? "active" : ""}`}
                                            onClick={() => handleLocal(exam.id_exam)}
                                        >
                                            {`${exam.name}[${exam.abbreviation}]`}
                                        </ListGroupItem>
                                    </ListGroup>
                                    <h5>Otros examenes</h5>
                                    <FormGroup className="m-0 p-0">
                                        <Input
                                            bsSize="sm"
                                            type="text"
                                            name="id_type_analyte"
                                            placeholder="Buscar examen..."
                                            value={search}
                                            onChange={handleSearch}
                                        />
                                    </FormGroup>
                                    <ListGroup>
                                        {
                                            localExams.length > 0 ?
                                                localExams.map((obj, key) => {
                                                    return <ListGroupItem
                                                        key={key}
                                                        style={{ cursor: "pointer" }}
                                                        className={`list-group-item-action text-sm ${examActiveId === obj.id_exam ? "active" : ""}`}
                                                        onClick={() => handleExternal(obj.id_exam)}
                                                    >
                                                        {obj.name}[{obj.code}]
                                                    </ListGroupItem>
                                                })
                                                :
                                                <h3>0 examenes encontrados</h3>
                                        }
                                    </ListGroup>
                                </Col>
                                <Col sm="6" className="h-100vh overflow-auto">
                                    <h5>Analitos disponibles</h5>
                                    {
                                        typeComplex === "local"
                                            ?
                                            <TableStriped
                                                notMaped={["valor", "variable", "name"]}
                                                cabeceras={["Analito", "Código"]}
                                                items={localAnalytes}
                                                methodsActions={true}
                                                methodsAbsolutes={
                                                    [
                                                        {
                                                            type: "linkId",
                                                            method: addLocalInput,
                                                            icon: "fa fa-plus",
                                                            backgroundColor: "#5e72e4",
                                                            color: "#fff"
                                                        },
                                                    ]
                                                }
                                            />
                                            :

                                            <TableStriped
                                                notMaped={["valor", "variable", "name"]}
                                                cabeceras={["Analito"]}
                                                items={externalAnalytes}
                                                methodsActions={true}
                                                methodsAbsolutes={
                                                    [
                                                        {
                                                            type: "linkId",
                                                            method: addLocalInput,
                                                            icon: "fa fa-plus",
                                                            backgroundColor: "#5e72e4",
                                                            color: "#fff"
                                                        },
                                                    ]
                                                }
                                            />
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                        <Col sm="5" className="border-left border-light">
                            <Row>
                                {
                                    arrayLocalInputs.length > 0 &&
                                    arrayLocalInputs.map((obj, key) => {
                                        return <Col sm="12" key={obj.id_analyte}>
                                            <Row>
                                                <Col sm="10">
                                                    <h4 className="text-sm">{obj.name_code}</h4>
                                                </Col>
                                                <Col sm="2" className="text-right">
                                                    <Button onClick={() => removeLocalInput(obj.id_analyte)} color="danger" size="sm" type="button">
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                </Col>
                                                <FormGroup className={`mb-2 col-6`}>
                                                    <Label className="text-sm">Nombre de la variable</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder="Nombre de la variable"
                                                        type="text"
                                                        readOnly={true}
                                                        name={`${obj.id_analyte}`}
                                                        autoComplete="off"
                                                        onChange={(e) => handleInputLocal(e, "variable")}
                                                        // onBlur={validarVariables}
                                                        style={{ textTransform: "lowercase" }}
                                                        defaultValue={obj.variable}
                                                    />
                                                </FormGroup>
                                                <FormGroup className={`mb-2 col-6`}>
                                                    <Label className="text-sm">Valor de prueba</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        placeholder="Valor de prueba de la variable"
                                                        type="number"
                                                        name={`${obj.id_analyte}`}
                                                        autoComplete="off"
                                                        onChange={(e) => handleInputLocal(e, "valor")}
                                                        onBlur={validarVariables}
                                                        defaultValue={1}
                                                    />
                                                </FormGroup>
                                            </Row>
                                        </Col>
                                    })
                                }
                            </Row>
                            <Row>
                                {
                                    arrayLocalInputs.length > 0 &&
                                    <>
                                        <FormGroup className={`mb-2 mt-2 col-12 border-top border-light`}>
                                            <Label className="text-sm">Fórmula</Label>
                                            <Input
                                                bsSize="sm"
                                                placeholder="Fórmula"
                                                type="text"
                                                name="formula"
                                                value={formula}
                                                autoComplete="off"
                                                onChange={(e) => setFormula(e.target.value)}
                                                onFocus={validarVariablesLocales}
                                            // readOnly={!validacionFormula.status}
                                            />
                                            {
                                                validacionFormula.status ?
                                                    <>
                                                        <Button
                                                            color="info"
                                                            type="button"
                                                            className="btn btn-sm btn-block mt-2 mb-2"
                                                            onClick={() => refreshFormComplex(valuesLocal, formula)}>
                                                            Probar fórmula
                                                        </Button>
                                                        <p onClick={toggle} style={{ cursor: "pointer" }} className="text-blue text-right">Tabla de funciones disponibles</p>
                                                        <Collapse isOpen={isOpen}>
                                                            <Table
                                                                striped
                                                                className="align-items-center"
                                                                responsive
                                                            >
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th scope="col">Nombre</th>
                                                                        <th scope="col">Función</th>
                                                                        <th scope="col" >Descripción</th>
                                                                        <th scope="col">Ejemplo</th>
                                                                        <th scope="col">Resultado</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Exponencial</td>
                                                                        <td>Pow(x,y)</td>
                                                                        <td style={stylesTd}>Devuelve el valor de <i> x </i> elevado a la potencia <i>y</i></td>
                                                                        <td>Pow(2,4)</td>
                                                                        <td>16</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Logaritmo10</td>
                                                                        <td>Log10(x)</td>
                                                                        <td style={stylesTd}>Devuelve el valor logaritmo base 10 de <i> x </i> </td>
                                                                        <td>Log10(100)</td>
                                                                        <td>2</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Promedio</td>
                                                                        <td>Avg(x,y,z,...)</td>
                                                                        <td style={stylesTd}>Devuelve el valor promedio de todos los argumentos dados</td>
                                                                        <td>Avg(3,3,6)</td>
                                                                        <td>4</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Raíz cuadrada</td>
                                                                        <td>Sqrt(x)</td>
                                                                        <td style={stylesTd}>Devuelve un valor que es la raíz cuadrada del valor dado</td>
                                                                        <td>Sqrt(4)</td>
                                                                        <td>2</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Collapse>
                                                    </>
                                                    :
                                                    <h6 className="text-danger">{validacionFormula.msg}</h6>
                                            }
                                            {
                                                responseComplexText.validacion ?
                                                    <>
                                                        Resultado de la prueba: {responseComplexText.result}
                                                        <br />
                                                        <Button
                                                            type="button"
                                                            onClick={() => preSavedFormComplex(valuesLocal, formula)}
                                                            color="primary"
                                                            className="btn btn-sm btn-block mt-2 mb-2"
                                                        >
                                                            Confirmar fórmula
                                                        </Button>
                                                    </>
                                                    :
                                                    <h6 className="text-danger">{responseComplexText.msg}</h6>
                                            }
                                        </FormGroup>
                                    </>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </div>
    )
}
