import React, { Fragment,useEffect,useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Input, Label, Badge, Row, Table } from 'reactstrap'
import Chart from 'react-apexcharts'
import { useReportCxc } from '../../../hooks/reports/useReportCxc'
import ReactApexChart from 'react-apexcharts'
import './styleReport.css';
//import '.././styleDataTable.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";





export const IndexReportCxc = () => {

    const {
        loadingTotalIngress, loadingOutsideBalance, loadingIngressInvoiced,
        loadingDiscount, loadingClients,
        loadingExams, loadingProfiles, loadingTableClients, loadingExpiredWallet,

        lblTotalIngress, lblOutSideBalance,lblTotalClients,lblCurrentPaidInvoices, 

        lblCurrentTotalInvoices, lblCurrentPaymentMonth, lblTotalInvoicesNextMonth,
        lblCurrentTotalBilling, lblCurrentPaymentBilling, lblCurrentPendingPaymentBilling,
        lblBeforeTotalBilling, lblBeforePaymentBilling, lblBeforePendingPaymentBilling,

        viewBeforeMonth, viewCurrentMonth, viewAfterMonth,
        detailTransactionsByClients, listDetailTransactionsByClients, listIndicatorsDetail,
        dateFilter, setDateFilter,

        //CHARTS
        ChartAgePayable, ChartPaymentConditionsTable,

        handleFilterByClient, handleFilterDate, number_format,
        handleCleanFilterByClient, handleViewTransactions,
        handleFilterTransactions,

        MakeExcelTransactionMontly,

        // * FILTERS
        filterCredit, setFilterCredit,
        filterAccount, setFilterAccount,
        filterPayment, setFilterPayment,
        filterPendingPayment, setFilterPendingPayment,
        filterInvoiced, setFilterInvoiced,
        filterPendigInvoiced, setFilterPendigInvoiced,
        filterPreInvoice, setFilterPreInvoice,
        filterPpd, setFilterPpd,
        filterPue, setFilterPue,
        filterOutValidity, 
        filterCurrent, 
        filterPaid, 
        filterViewAll, 

        //* DOWNLOAD REPORT CXC
        DownloadReportCxc,
        
        // ? FILTER PAYMETHODS
        filterAmountCredit, setFilterAmountCredit,
        filterAmountDebit, setFilterAmountDebit,
        filterAmountTcredit, setFilterAmountTcredit,
        filterAmountTrasferencia, setFilterAmountTrasferencia,
        filterAmount, setFilterAmount,
        lblTotalPercentageIngress,lblTotalPaidIngress,lblTotalPatient,
        lbPercentageInvoice,detailSeriesExpiredWallet,
        date1,loadingIdR

    } = useReportCxc();
        console.log("🚀 ~ IndexReportCxc ~ loadingIdR:", loadingIdR)
        console.log("🚀 ~ IndexReportCxc ~ detailTransactionsByClients:", detailTransactionsByClients)
        console.log("🚀 ~ IndexReportCxc ~ date1:", date1)

    const [total, setTotal] = useState(0)


    const calcularTotal = () => {
        const datosFiltrados = listDetailTransactionsByClients.filter(transaction => {
            if (filterCredit && transaction.paymentCondition === "Crédito") return true;
            if (filterAccount && transaction.paymentCondition === "Pago de contado") return true;
            if (filterPayment && transaction.paymentStatus === "Pagado") return true;
            if (filterPendingPayment && transaction.paymentStatus === "Pendiente") return true;
            if (filterInvoiced && transaction.financialStatus === "Facturado") return true;
            if (filterPendigInvoiced && transaction.financialStatus === "Pendiente") return true;
            if (filterPreInvoice && transaction.financialStatus === "Prefactura") return true;
            if (filterPpd && transaction.payMethod === "PPD") return true;
            if (filterPue && transaction.payMethod === "PUE") return true;
            if (filterAmount && transaction.id_Pay_method === 1) return true;
            if (filterAmountCredit && transaction.id_Pay_method === 10) return true;
            if (filterAmountDebit && transaction.id_Pay_method === 4) return true;
            if (filterAmountTcredit && transaction.id_Pay_method === 2) return true;
            if (filterAmountTrasferencia && transaction.id_Pay_method === 8) return true;


            if (!filterCredit && !filterAccount && !filterPayment && !filterPendingPayment && !filterInvoiced && !filterPendigInvoiced && !filterPreInvoice && !filterPpd && !filterPue && !filterAmount && !filterAmountCredit && !filterAmountDebit && !filterAmountTcredit && !filterAmountTrasferencia ) return true;
            return false;
        });

        const total = datosFiltrados.reduce((suma, item) => {
            const _total = item.totalWorkOrder + item.totalTax || 0;
            return suma + _total;
        }, 0);

        setTotal(total);
    };

    useEffect(() => {
        calcularTotal();
    }, [filterCredit, filterAccount, filterPayment, filterPendingPayment, filterInvoiced, filterPendigInvoiced, filterPreInvoice, filterPpd, filterPue, listDetailTransactionsByClients,filterAmount,filterAmountCredit,filterAmountDebit,filterAmountTcredit,filterAmountTrasferencia]);


    const totalRowPatient = (
        <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
        <td ></td>
        <td></td>
        <td ></td>
        <td></td>
        <td style={{textAlign:"end"}} colSpan={3} className='labeltheadInvoice'><label className='f-12' >Total Pendiente:</label> </td>
        <td colSpan={2} style={{textAlign:"left"}}  className='labeltheadInvoice'><label className='f-12' > ${number_format(total, 2)}</label> </td>
        <td></td>
        <td ></td>
        <td></td>
        <td ></td>
        <td></td>
        <td></td>       
    </tr> 
    );

    
        const [expandedRows, setExpandedRows] = useState(null);
    
        const rowExpansionTemplate = (data) => {
            console.log("🚀 ~ rowExpansionTemplate ~ data:", data)
            const columnsPerRow = 5;
        
            const rows = [];
            for (let i = 0; i < data.length; i += columnsPerRow) {
                rows.push(data.slice(i, i + columnsPerRow));
            }
        }

        const [globalFilter, setGlobalFilter] = useState(null)
        console.log("🚀 ~ IndexReportCxc ~ globalFilter:", globalFilter)

        

      
   
    return (
        <Fragment> 
            {
                // ? GLOBAL CARDS
            } 
            {
                <Col sm="12" className='p-l-0 p-r-0' >
                    <Row>
                    <Col sm="3">
                        <div className='card-Reportcxc'>
                            <Col sm="12" className='p-l-0 p-r-0'>
                                <Row>
                                    <Col sm="6" className='p-l-0 p-r-0'>
                                        <label className='f-14' style={{ color: "#6b7280" }}>Ingresos mensuales</label>
                                        <div className="text-left widgets-ind">
                                            <label className='f-w-600'>
                                                <i className="fa fa-usd"></i> {lblTotalIngress}
                                            </label>
                                        </div>
                                    </Col>

                                    <Col sm="6" className="d-flex justify-content-end">
                                        <div className='bg-blue-100-cxc d-flex justify-content-center align-items-center'>
                                            {loadingTotalIngress ? (
                                                <i className="fa fa-spinner fa-spin f-24"></i>  
                                            ) : (
                                                <i className="fa fa-database f-1"></i>  
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className='card-Reportcxc'>
                            <Col sm="12" className='p-l-0 p-r-0'>
                                <Row>
                                    <Col sm="6" className='p-l-0 p-r-0'>
                                        <label className='f-14' style={{ color: "#6b7280" }}>Saldo Pendiente</label>
                                        <div className="text-left widgets-ind">
                                            <label className='f-w-600'>
                                                <i className="fa fa-usd"></i> {lblOutSideBalance}
                                            </label>
                                        </div>
                                    </Col>

                                    <Col sm="6" className="d-flex justify-content-end">
                                        <div className='bg-blue-100-cxc1 d-flex justify-content-center align-items-center'>
                                            {loadingTotalIngress ? (
                                                <i className="fa fa-spinner fa-spin f-24"></i>  
                                            ) : (
                                                <i className="fa fa-database f-1"></i>  
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Col> <Col sm="3">
                        <div className='card-Reportcxc'>
                            <Col sm="12" className='p-l-0 p-r-0'>
                                <Row>
                                    <Col sm="6" className='p-l-0 p-r-0'>
                                        <label className='f-14' style={{ color: "#6b7280" }}>Ingreso Pagado</label>
                                        <div className="text-left widgets-ind">
                                            <label className='f-w-600'>
                                                <i className="fa fa-usd"></i> {lblTotalPaidIngress}
                                            </label>
                                        </div>
                                    </Col>

                                    <Col sm="6" className="d-flex justify-content-end">
                                        <div className='bg-blue-100-cxc2 d-flex justify-content-center align-items-center'>
                                            {loadingTotalIngress ? (
                                                <i className="fa fa-spinner fa-spin f-24"></i>  
                                            ) : (
                                                <i className="fa fa-database f-1"></i>  
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Col> <Col sm="3">
                        <div className='card-Reportcxc'>
                            <Col sm="12" className='p-l-0 p-r-0'>
                                <Row>
                                    <Col sm="6" className='p-l-0 p-r-0'>
                                        <label className='f-14' style={{ color: "#6b7280" }}>Cumplimiento</label>
                                        <div className="text-left widgets-ind">
                                            <label className='f-w-600'>
                                                % {lblTotalPercentageIngress}
                                            </label>
                                        </div>
                                    </Col>

                                    <Col sm="6" className="d-flex justify-content-end">
                                        <div className='bg-blue-100-cxc3 d-flex justify-content-center align-items-center'>
                                            {loadingTotalIngress ? (
                                                <i className="fa fa-spinner fa-spin f-24"></i>  
                                            ) : (
                                                <i className="fa fa-database f-1"></i>  
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Col>    
                    </Row>
                </Col>
            }
            <Row>
            {
                // ? GLOBAL TABLE
            } 
            <Col sm="9">
                <Card>
                    <CardBody className='pl-1 pb-1 pt-0 pr-1'>
                        <Row>
                            <Col sm="12">
                                <DataTable
                                    value={detailTransactionsByClients}
                                    expandedRows={expandedRows}
                                    onRowToggle={(e) => setExpandedRows(e.data)}
                                    rowExpansionTemplate={rowExpansionTemplate}
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    className="data-table"
                                    emptyMessage={
                                        <div className="text-center">
                                            <img
                                                src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")}
                                                style={{ width: "45%" }}
                                                alt="Sin resultados"
                                            />
                                            <br />
                                            <p className="badge badge-light-primary mt-2" style={{ fontSize: "10px" }}>
                                                No hay coincidencias con la búsqueda.
                                            </p>
                                        </div>
                                    }
                                    style={{ fontSize: "8px" }}
                                    scrollable
                                    scrollHeight="330px"
                                    globalFilter={globalFilter}
                                    header={
                                        <Col sm="12" className='p-l-0 p-r-0'>
                                            <Row>
                                                <Col sm="7"></Col>
                                                <Col sm="1">
                                                <button
                                                    className="p-button p-button-danger"
                                                    onClick={() => handleCleanFilterByClient()}  
                                                    title="Limpiar filtro"
                                                    style={{
                                                        alignItems: "center",
                                                        padding: "5px 8px",
                                                        height: "35px",
                                                        width: "35px",
                                                        fontSize: "0.9rem",
                                                    }}
                                                >
                                                    <i className="pi pi-trash" style={{ fontSize: "1rem", marginRight: "5px" }}></i>
                                                </button>
                                                </Col>
                                                <Col sm="1" style={{ textAlign: "right" }}>
                                                    {
                                                        loadingIdR === 0 ? <i className="fa fa-spin fa-circle-o-notch f-24 txt-success"></i> :
                                                            <button
                                                                className="p-button p-button-success"
                                                                onClick={() => DownloadReportCxc(null, 0)}
                                                                title="Reporte completo de empresas"
                                                                style={{
                                                                    alignItems: "center",
                                                                    padding: "5px 8px",
                                                                    height: "35px",
                                                                    width: "35px",
                                                                    fontSize: "0.9rem",
                                                                }}
                                                            >
                                                                <i className="pi pi-file-excel" style={{ fontSize: "1rem", marginRight: "5px" }}></i>
                                                            </button>
                                                    }
                                                </Col>
                                                <Col sm="2">
                                                    <span className="p-input-icon-left">
                                                        <i className="pi pi-search" />
                                                        <InputText
                                                            type="search"
                                                            className="pl-6"
                                                            onInput={(e) => setGlobalFilter(e.target.value)}
                                                            style={{ width: "auto" }}
                                                        />
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                >
                                    <Column
                                        field="business_name"
                                        header="Razón social"
                                        sortable
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            width: "20%",
                                        }}
                                    />
                                    <Column
                                        field="name_client"
                                        header="Cliente"
                                        sortable
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            width: "15%",
                                        }}
                                    />
                                    <Column
                                        field="rfc_company"
                                        header="Rfc"
                                        sortable
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                            width: "10%",
                                        }}
                                    />
                                    <Column
                                        field="totalCredit"
                                        header="Crédito"
                                        sortable
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                            width: "10%",
                                        }}
                                    />
                                    <Column
                                        field="totalCash"
                                        header="Contado"
                                        sortable
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                            width: "10%",
                                        }}
                                    />
                                    <Column
                                        field="totalDiscount"
                                        header="Descuento"
                                        sortable
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                            width: "10%",
                                        }}
                                    />
                                    <Column
                                        field="totalPaid"
                                        header="Pagado"
                                        sortable
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                            width: "10%",
                                        }}
                                    />
                                    <Column
                                        field="pruebe"
                                        header="Pendiente"
                                        sortable
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                            width: "10%",
                                        }}
                                    />
                                    <Column
                                        header="Acciones"
                                        body={(rowData) => (
                                            <div className="d-flex justify-content-center">
                                                {/* Botón de descarga */}
                                                <button
                                                    className="p-button p-button-success p-button-sm"
                                                    onClick={() => DownloadReportCxc(rowData.id_client)}
                                                    title="Descargar Reporte en Excel"
                                                    style={{
                                                        border: "none",
                                                        background: "transparent",
                                                        cursor: "pointer",
                                                        width: "50px", // Reduce el ancho de la columna de acciones
                                                    }}
                                                    disabled={loadingIdR === rowData.id_client}
                                                >
                                                    {loadingIdR === rowData.id_client ? (
                                                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1.2rem", color: "green" }}></i>
                                                    ) : (
                                                        <i className="pi pi-file-excel" style={{ fontSize: "1.2rem", color: "green" }}></i>
                                                    )}
                                                </button>

                                                {/* Botón de lupa */}
                                                <button
                                                    className="p-button p-button-info p-button-sm ml-2"
                                                    onClick={() => handleFilterByClient(rowData.id_client)}
                                                    title="Filtrar por Cliente"
                                                    style={{
                                                        border: "none",
                                                        background: "transparent",
                                                        cursor: "pointer",
                                                        width: "50px", // Ancho similar al botón de descarga
                                                    }}
                                                >
                                                    <i className="pi pi-search" style={{ fontSize: "1.2rem", color: "blue" }}></i>
                                                </button>
                                            </div>
                                        )}
                                        headerStyle={{
                                            backgroundColor: "#1d5b83",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            padding: "5px",
                                            textAlign: "center",
                                        }}
                                        style={{
                                            textAlign: "center",
                                            width: "10%",  // Ajustar el tamaño de la columna de Acciones
                                        }}
                                    />
                                </DataTable>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            {
                // ? GLOBAL FILTER AND AMOUNTS
            } 
            <Col sm="3">
                <Card className="custom-card">
                    <label htmlFor="">Filtros</label>
                    <Row className="align-items-center mt-2">
                        <Col sm="8" className="p-r-0">
                            <Input
                                type="month"
                                value={dateFilter}
                                className="mr-2"
                                onChange={(e) => setDateFilter(e.target.value)}
                            />
                        </Col>
                        <Col sm="1">
                        </Col>

                        <Col sm="2" className="p-l-0 p-r-0">
                            <Row>
                                <Col sm="6" className="mb-2">
                                    <Button color="info" size="xs" outline onClick={handleFilterDate}>
                                        Filtrar
                                    </Button>
                                </Col>

                                {/* <Col sm="6" className="mb-2">
                                    <Button color="danger" size="xs" outline onClick={handleCleanFilterByClient}>
                                        Limpiar
                                    </Button>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Card>

                <Card className="custom-card">
                    <label htmlFor="">Indicador Global</label>
                    <Row className="mt-3">
                        <Col sm="12" className="mb-3">
                            <div className="bar-container">
                                <div className="bar" style={{ width: "70%", backgroundColor: "#4CAF50" }}></div>
                                <div className="bar-label">
                                    <label>Crédito</label>
                                    <span>
                                        ${number_format(
                                            detailTransactionsByClients.reduce(
                                                (sum, _orders) => sum + (_orders?.totalCredit ?? 0),
                                                0
                                            ),
                                            2
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Col>

                        <Col sm="12" className="mb-3">
                            <div className="bar-container">
                                <div className="bar" style={{ width: "50%", backgroundColor: "#2196F3" }}></div>
                                <div className="bar-label">
                                    <label>Contado</label>
                                    <span>
                                        ${number_format(
                                            detailTransactionsByClients.reduce(
                                                (sum, _orders) => sum + (_orders?.totalCash ?? 0),
                                                0
                                            ),
                                            2
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Col>

                        <Col sm="12" className="mb-3">
                            <div className="bar-container">
                                <div className="bar" style={{ width: "60%", backgroundColor: "#FFC107" }}></div>
                                <div className="bar-label">
                                    <label>Descuento</label>
                                    <span>
                                        ${number_format(
                                            detailTransactionsByClients.reduce(
                                                (sum, _orders) => sum + (_orders?.totalDiscount ?? 0),
                                                0
                                            ),
                                            2
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Col>

                        <Col sm="12" className="mb-3">
                            <div className="bar-container">
                                <div className="bar" style={{ width: "80%", backgroundColor: "#FF5722" }}></div>
                                <div className="bar-label">
                                    <label>Pagado</label>
                                    <span>
                                        ${number_format(
                                            detailTransactionsByClients.reduce(
                                                (sum, _orders) => sum + (_orders?.totalPaid ?? 0),
                                                0
                                            ),
                                            2
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Col>

                        <Col sm="12" className="mb-3">
                            <div className="bar-container">
                                <div className="bar" style={{ width: "40%", backgroundColor: "#9E9E9E" }}></div>
                                <div className="bar-label">
                                    <label>Pendiente</label>
                                    <span>
                                        ${number_format(
                                            detailTransactionsByClients.reduce(
                                                (sum, _orders) => sum + (_orders?.pruebe ?? 0),
                                                0
                                            ),
                                            2
                                        )}
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            </Row>
            <Row className='mt-2'>
                <Col sm="12" md="12" lg="12">
                    <Card>
                        <CardHeader className='p-2 text-center'>
                            <h5 className='card-title f-14'>Cargos pendientes - saldados</h5>
                        </CardHeader>
                        <CardBody className='p-2' >
                            <div className='text-left'>
                                <p>Mostrando - {listDetailTransactionsByClients?.length} resultados</p>
                            </div>
                            <Col sm="12" className='p-l-0 p-r-0'>
                            <Row>
                                <Col sm="4" className='p-r-0' >
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmount ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmount(!filterAmount) }} >Efectivo</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountCredit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountCredit(!filterAmountCredit) }} >Crédito</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountDebit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountDebit(!filterAmountDebit) }} >T.D</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountTcredit ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountTcredit(!filterAmountTcredit) }} >T.C</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAmountTrasferencia ? "" : "-light"}-success`} style={{ cursor: "pointer" }} onClick={() => { setFilterAmountTrasferencia(!filterAmountTrasferencia) }} >Transferencia</Label>
                                </Col>
                                <Col sm="8" className='p-l-0' style={{textAlign:"right"}} >
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterCredit ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterCredit(!filterCredit) }} >Crédito</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterAccount ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterAccount(!filterAccount) }} >Contado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPayment ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPayment(!filterPayment) }} >Pagado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPendingPayment ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPendingPayment(!filterPendingPayment) }} >Pendiente pagado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterInvoiced ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterInvoiced(!filterInvoiced) }} >Facturado</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPendigInvoiced ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPendigInvoiced(!filterPendigInvoiced) }} >Pendiente de facturar</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPreInvoice ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPreInvoice(!filterPreInvoice) }} >Prefactura</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPpd ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPpd(!filterPpd) }} >PPD</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPue ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { setFilterPue(!filterPue) }} >PUE</Label>
                                </Col>
                            </Row>
                            </Col>
                        
                            <div style={{ height: '440px', overflowY: "scroll", scrollBehavior: "smooth" }} className='table-wrapper-patient'>
                                <Table className='b-r-5 table-striped sm  -hover'>
                                    <thead className='b-r-5'>
                                        <tr style={{ backgroundColor: "#033d5b", fontSize:"11px" }}>
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>Cliente</th>
                                            <th className='labeltheadInvoice'>NIM</th>
                                            <th className='labeltheadInvoice'>Paciente</th>
                                            <th className='labeltheadInvoice'>Condición de pago</th>
                                            <th className='labeltheadInvoice'>Estado del pago</th>
                                            <th className='labeltheadInvoice'>Estatus facturación</th>
                                            <th className='labeltheadInvoice'>Total</th>
                                            <th className='labeltheadInvoice'>Importe</th>
                                            <th className='labeltheadInvoice'>Descuento</th>
                                            <th className='labeltheadInvoice'>Impuesto (IVA 16%)</th>
                                            <th className='labeltheadInvoice'>Serie</th>
                                            <th className='labeltheadInvoice'>Folio</th>
                                            <th className='labeltheadInvoice'>Método Pago</th>
                                            <th className='labeltheadInvoice'>Método</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loadingTableClients && <tr>
                                                <td colSpan={13} className='text-center'>
                                                    <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            listDetailTransactionsByClients?.length > 0 &&
                                            listDetailTransactionsByClients?.filter(_transaction => {

                                                if (filterAmount) {
                                                    return _transaction?.id_Pay_method === 1
                                                }
                                                if (filterAmountCredit) {
                                                    return _transaction?.id_Pay_method === 10
                                                }
                                                if (filterAmountDebit) {
                                                    return _transaction?.id_Pay_method === 4
                                                }
                                                if (filterAmountTcredit) {
                                                    return _transaction?.id_Pay_method === 2
                                                }
                                                if (filterAmountTrasferencia) {
                                                    return _transaction?.id_Pay_method === 8
                                                }

                                                if (filterCredit) {
                                                    return _transaction?.paymentCondition == "Crédito";
                                                }

                                                if (filterAccount) {
                                                    return _transaction?.paymentCondition == "Pago de contado";
                                                }

                                                if (filterPayment) {
                                                    return _transaction?.paymentStatus == "Pagado";
                                                }

                                                if (filterPendingPayment) {
                                                    return _transaction?.paymentStatus == "Pendiente";
                                                }

                                                if (filterInvoiced) {
                                                    return _transaction?.financialStatus == "Facturado";
                                                }

                                                if (filterPendigInvoiced) {
                                                    return _transaction?.financialStatus == "Pendiente";
                                                }

                                                if (filterPreInvoice) {
                                                    return _transaction?.financialStatus == "Prefactura";
                                                }

                                                if (filterPpd) {
                                                    return _transaction?.payMethod == "PPD";
                                                }

                                                if (filterPue) {
                                                    return _transaction?.payMethod == "PUE";
                                                }

                                                if (!filterCredit && !filterAccount && !filterPayment && !filterPendingPayment && !filterInvoiced && !filterPendigInvoiced && !filterPreInvoice && !filterPpd && !filterPue) {
                                                    return _transaction;
                                                }
                                            })
                                                ?.map((_order, _index) => {

                                                    let paymentMethod;

                                                    switch (_order.id_Pay_method) {
                                                        case 1:
                                                            paymentMethod = "Efectivo";
                                                            break;
                                                        case 2:
                                                            paymentMethod = "T.C";
                                                            break;
                                                        case 4:
                                                            paymentMethod = "T.D";
                                                            break;
                                                        case 8:
                                                            paymentMethod = "T.E";
                                                            break;
                                                        case 10:
                                                            paymentMethod = "Crédito";
                                                            break;
                                                        case 28:
                                                            paymentMethod = "Deposito Bancario";
                                                            break;
                                                        default:
                                                            paymentMethod = "Desconocido"; 
                                                            break;
                                                    }
                                                    
                                                    let _total =  _order?.totalWorkOrder
                                                    let _tax =  _order?.totalTax

                                                    const _totalWorkOrder = _total += _tax
                                                    return <tr key={_order?.idWorkOrder} className={_order?.financialStatus == "S/Factura" ? "tableSF text-white" : ""}>
                                                        <td style={{fontSize : "11px"}}>{_index + 1}</td>
                                                        <td style={{fontSize : "11px"}}>{_order?.nameClient}</td>
                                                        <td style={{fontSize : "11px",color:"#0079C7"}} className='f-w-600' >{_order?.nim}</td>
                                                        <td style={{fontSize : "11px"}} >{_order?.namePatient}</td>
                                                        <td><Label type="text"  style={{fontSize : "11px"}} className={`f-w-600  badge badge-${_order?.paymentCondition === "Cortesia" ? 'danger' : _order?.paymentCondition === "Crédito" ? 'warning' : "secondary"}`}>{_order?.paymentCondition}</Label></td>
                                                        <td ><Label type="text" style={{fontSize : "11px"}} className={`f-w-600  badge badge-light-${_order?.paymentStatus === "Pendiente" ? 'warning' : "success"}`}>{_order?.paymentStatus}</Label></td>
                                                        <td ><Label type="text" style={{fontSize : "11px"}} className={`f-w-600  badge badge-light-${_order?.financialStatus === "Pendiente" ? 'danger' : _order?.financialStatus == "Prefactura" ? "warning" : "success"}`}>{_order?.financialStatus}</Label></td>
                                                        <td  style={{fontSize : "11px"}} className='f-w-700'>${number_format(_totalWorkOrder?? 0,2)}</td>
                                                        <td style={{fontSize : "11px"}} className='f-w-600'>$ {number_format(_order?.totalWorkOrder, 2)}</td>
                                                        <td style={{fontSize : "11px"}} className='font-danger f-w-600'>$ {number_format(_order?.totalDiscount, 2)}</td>
                                                        <td style={{fontSize : "11px"}} className='f-w-600 text-seconday'>$ {number_format(_order?.totalTax, 2)}</td>
                                                        <td style={{fontSize : "11px"}}>{_order?.serie}</td>
                                                        <td style={{fontSize : "11px"}}>{_order?.folio}</td>
                                                        <td style={{fontSize : "11px"}} className='f-w-700'>{_order?.payMethod}</td>
                                                        <td style={{fontSize : "11px"}} className='f-w-700 txt-success'>{paymentMethod}</td>
                                                        </tr>
                                                })
                                        }
                                        {totalRowPatient}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                // ? END TABLE
            }
            {
                // ? INVOICE
            }
            {
                <Row className='pt-3' >
                <Col sm="12"  >
                <Card>
                <CardBody className='pl-2 pb-2 pt-2' >
                    <Row>
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}} >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcInvoicesStamp.svg")} style={{ width: "60px", height: "60px" }} />
                            </Col>
                            <Col sm="9" >
                            <label className='f-w-500 ' style={{fontSize:"13"}} >Facturas emitidas</label>
                            <div className=' widgets-ind'>
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                            {
                                !loadingIngressInvoiced && <h4>
                                {lblCurrentTotalInvoices}
                                </h4>
                            }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}} >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPaids.svg")} style={{ width: "60px", height: "60px" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Facturas cobradas en mes actual </label>
                            <div className=' widgets-ind '> 
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                !loadingProfiles && <h4 className='text-success'>{lblCurrentPaymentMonth}</h4>
                            }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        
                        <Col sm="3" style={{borderRight : "1px solid rgba(0, 0, 0, 0.14)"}}>
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPaid.svg")} style={{ width: "60px", height: "60px" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Facturas actualmente pagadas</label>
                            <div className=' widgets-ind'>
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingExams && <h4 className='text-info'> {lblCurrentPaidInvoices}</h4>
                                }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                        <Col sm="3" >
                        <Col sm="12" className='p-l-0 p-r-0' >
                        <Row>
                            <Col sm="3">
                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPercentage.svg")} style={{ width: "60px", height: "60px" }} />

                            </Col>
                            <Col sm="9">
                            <label className='f-w-500'>Porcentaje de cumplimiento</label>
                            <div className=' widgets-ind'>
                                {
                                    loadingClients && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                }
                                {
                                    !loadingDiscount && <h4 className='text-warning'> {lbPercentageInvoice}</h4>
                                }
                            </div>
                            </Col>
                        </Row>
                        </Col>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
                </Col>

                </Row>
            }
            {
                // * AMOUNT LABEL'S
            }
            <Row className='mt-2'>
                <Col sm="12" md="6" lg="6">
                    <Card>
                        <CardHeader className='text-center p-1 bg-info'>
                            <h6 className='pt-1'>Facturado Mes anterior</h6>
                        </CardHeader>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Facturado mes anterior</b>
                                    <h5>${ number_format(lblBeforeTotalBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pagado mes anterior</b>
                                    <h5>${ number_format(lblBeforePaymentBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='b-r-1 text-center'>
                                    <b>Saldo pendiente mes anterior</b>
                                    <h5>${ number_format(lblBeforePendingPaymentBilling, 2)}</h5>
                                </Col>                            
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="6">
                    <Card>
                        <CardHeader className='text-center p-1 bg-success'>
                            <h6 className='pt-1'>Facturado Mes actual</h6>
                        </CardHeader>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Facturado</b>
                                    <h5>${ number_format(lblCurrentTotalBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pagado</b>
                                    <h5>${ number_format(lblCurrentPaymentBilling, 2) }</h5>
                                </Col>
                                <Col sm="12" md="4" lg="4" className='text-center'>
                                    <b>Saldo pendiente</b>
                                    <h5>${ number_format(lblCurrentPendingPaymentBilling, 2)}</h5>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col >
            </Row>
            {
                // * END AMOUNT LABEL'S
            }
            {
                // ? Report Invoice
            }
            <Row className='pb-2' >
                <Col sm="12">
                <Card>
                    <CardBody  className='pl-1 pb-1 pt-0'>
                        <Row>
                            <Col sm="10">
                            <Col sm="12" md="12" lg="12">
                        <Row className='mt-2'>
                            <Col sm="6" md="6" lg="6">
                                <ButtonGroup className='p-2'>
                                    <Button outline={!viewBeforeMonth} onClick={() => handleViewTransactions(true, false, false)}><i className="fa fa-angle-double-left"></i> Anterior Més</Button>
                                    <Button outline={!viewCurrentMonth} onClick={() => handleViewTransactions(false, true, false)}>Més actual</Button>
                                    <Button outline={!viewAfterMonth} onClick={() => handleViewTransactions(false, false, true)}>Siguiente Més <i className="fa fa-angle-double-right"></i></Button>
                                </ButtonGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6" className='text-right'>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterViewAll ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(true, false, false, false) }} >Todo</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterOutValidity ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, true, false, false) }} >Vencida</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterPaid ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, false, false, true) }} >Pagada</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge${filterCurrent ? "" : "-light"}-secondary`} style={{ cursor: "pointer" }} onClick={() => { handleFilterTransactions(false, false, true, false) }} >Vigente</Label>
                                <Label type="text" className={`f-w-600 f-12 badge badge-success`} style={{ cursor: "pointer" }} onClick={() => { MakeExcelTransactionMontly(viewBeforeMonth, viewCurrentMonth, filterViewAll, filterOutValidity, filterPaid, filterCurrent) }} >Descargar excel</Label>
                            </Col>
                        </Row>
                        <Card className='p-2' hidden={!viewBeforeMonth}>    
                            <Col sm="12" className='p-l-0 p-r-0 scroll-container-cancell'  style={{maxHeight:"420px"}} >
                            <Table className='b-r-5 table-striped table-hover table-xs' >
                                    <thead className='b-r-5'>
                                        <tr style={{ backgroundColor: "#033d5b" }}>
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>Fecha emisión</th>
                                            <th className='labeltheadInvoice'>N° factura</th>
                                            <th className='labeltheadInvoice'>Cliente</th>
                                            <th className='labeltheadInvoice'>Monto facturado</th>
                                            <th className='labeltheadInvoice'>Días de crédito</th>
                                            <th className='labeltheadInvoice'>F. de Vencimiento</th>
                                            <th className='labeltheadInvoice'>Estatus</th>
                                            <th className='labeltheadInvoice'>F. de Pago</th>
                                            <th className='labeltheadInvoice'>Complemento</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            loadingTableClients && <tr>
                                                <td colSpan={13} className='text-center'>
                                                    <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            listIndicatorsDetail?.length > 0 &&
                                            listIndicatorsDetail?.filter(_invoice => {
                                                if (_invoice?.beforeMonth) {
                                                    if (filterOutValidity && _invoice?.status === "VENCIDA") {
                                                        return _invoice;
                                                    }
                                                    else if (filterPaid && _invoice?.status === "PAGADA") {
                                                        return _invoice;
                                                    }
                                                    else if (filterCurrent && _invoice?.status === "VIGENTE") {
                                                        return _invoice;
                                                    }
                                                    else if (filterViewAll) {
                                                        return _invoice;
                                                    }
                                                }
                                            })
                                                ?.map((_invoice, _index) => {
                                                    return <tr key={_invoice?.idInvoice} className={ _invoice?.isStamp ? "f-w-700" : "f-w-500" }>
                                                        <td>{_index + 1}</td>
                                                        <td>{_invoice?.dateStampInvoice}</td>
                                                        <td>{_invoice?.serie ?? ""} {_invoice?.folio ?? ""}</td>
                                                        <td>{_invoice?.nameClient}</td>
                                                        <td>$ {number_format(_invoice?.totalInvoice, 2)}</td>
                                                        <td>{_invoice?.creditDays}</td>
                                                        <td>{_invoice?.validityDate}</td>
                                                        <td>
                                                            <Label type="text" className={`f-w-600 f-12 badge badge-${_invoice?.status === "PAGADA" ? 'success' : _invoice?.status === "VIGENTE" ? "secondary" : "danger"}`}>
                                                                {_invoice?.status}
                                                            </Label>
                                                        </td>
                                                        <td>{_invoice?.paymentDate ?? ""}</td>
                                                        <td>{_invoice?.idComplement ?? "---"}</td>
                                                    </tr>
                                                })
                                        }
                                    </tbody>
                                </Table>
                            </Col>                                    
                            
                        </Card>
                        <Card className='p-2' hidden={!viewCurrentMonth}>
                            <Col sm="12" className='p-l-0 p-r-0 scroll-container-cancell' style={{maxHeight:"420px"}} >
                            <Table className='b-r-5 table-striped table-hover table-xs'>
                                    <thead className='b-r-5'>
                                        <tr style={{ backgroundColor: "#033d5b" }}>
                                            <th className='labeltheadInvoice'>#</th>
                                            <th className='labeltheadInvoice'>Fecha emisión</th>
                                            <th className='labeltheadInvoice'>N° factura</th>
                                            <th className='labeltheadInvoice'>Cliente</th>
                                            <th className='labeltheadInvoice'>Monto facturado</th>
                                            <th className='labeltheadInvoice'>Días de crédito</th>
                                            <th className='labeltheadInvoice'>F. de Vencimiento</th>
                                            <th className='labeltheadInvoice'>Estatus</th>
                                            <th className='labeltheadInvoice'>F. de Pago</th>
                                            <th className='labeltheadInvoice'>Complemento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loadingTableClients && <tr>
                                                <td colSpan={13} className='text-center'>
                                                    <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            listIndicatorsDetail?.length > 0 &&
                                            listIndicatorsDetail?.filter(_invoice => {
                                                if (_invoice?.currentMonth) {
                                                    if (filterOutValidity && _invoice?.status == "VENCIDA") {
                                                        return _invoice;
                                                    }
                                                    else if (filterPaid && _invoice?.status == "PAGADA") {
                                                        return _invoice;
                                                    }
                                                    else if (filterCurrent && _invoice?.status == "VIGENTE") {
                                                        return _invoice;
                                                    }
                                                    else if (filterViewAll) {
                                                        return _invoice;
                                                    }
                                                }
                                            })
                                                ?.map((_invoice, _index) => {
                                                    return <tr key={_invoice?.idInvoice} className={ _invoice?.isStamp ? "f-w-700" : "f-w-500" }>
                                                        <td>{_index + 1}</td>
                                                        <td>{_invoice?.dateStampInvoice}</td>
                                                        <td>{_invoice?.serie ?? ""} {_invoice?.folio ?? ""}</td>
                                                        <td>{_invoice?.nameClient}</td>
                                                        <td>$ {number_format(_invoice?.totalInvoice, 2)}</td>
                                                        <td>{_invoice?.creditDays}</td>
                                                        <td>{_invoice?.validityDate}</td>
                                                        <td>
                                                            <Label type="text" className={`f-w-600 f-12 badge badge-${_invoice?.status === "PAGADA" ? 'success' : _invoice?.status === "VIGENTE" ? "secondary" : "danger"}`}>
                                                                {_invoice?.status}
                                                            </Label>
                                                        </td>
                                                        <td>{_invoice?.paymentDate ?? ""}</td>
                                                        <td>{_invoice?.idComplement ?? "---"}</td>
                                                    </tr>
                                                })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                               
                        </Card>
                    </Col>
                            </Col>
                            <Col sm="2" className='p-l-0 p-r-0' > <br />
                            <Col sm="12"  className='col-selected-1 p-l-0 p-r-0 ' style={{border:"solid 1px #ecf3fa", height: "70px"}}>
                            <Col  sm="12" className='p-l-0 p-r-0' style={{textAlign:"center"}} >
                            <label className='f-w-500' >Facturas del siguiente mes</label>
                            </Col>
                            <Row>
                                <Col sm="4" style={{textAlign:"right"}} >
                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPdf.svg")} style={{ width: "40px", height: "40px" }} />
                                </Col>
                                <Col sm="8">
                                <h4 className='pt-2'><i className="fa fa-angle-double-right txt-danger"></i>{lblTotalInvoicesNextMonth}  </h4>
                                </Col>
                            </Row>
                            </Col>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row className='pb-5' >
            <Col sm="12" md="12" lg="12" id='chart' className='p-l-0 p-r-0' >
                <Card>
                    <CardHeader className='p-2 text-center'>
                        <h5 className='card-title f-14'>Indicador Global de facturas vencidas</h5>
                    </CardHeader>
                    <CardBody className='p-1 text-center '>
                        {
                            loadingExpiredWallet && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                        }
                        {
                            !loadingExpiredWallet && <center>
                                <Col sm="12">
                                <Chart options={ChartAgePayable?.options} series={ChartAgePayable?.series} type="bar" height={100} width={"100%"} />
                                <br />
                                <Row>
                                    <Col sm="8">
                                    <table style={{ width: '100%', borderCollapse: 'collapse', backgroundColor: '#033d5b', color:"#fff", fontSize:"11px" }}>
                                        <thead style={{ backgroundColor: 'rgba(135, 206, 235, 0.8)' }}>
                                            <tr>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Periodo</th>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>N° factura</th>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>F. Emisión</th>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Monto facturado</th>
                                                <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>Cliente</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailSeriesExpiredWallet.map((item, index) => (
                                                item.folio.length > 0 ? (
                                                    <tr key={index}>
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            {item?.name ?? "----"}
                                                        </td>
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            {item?.folio.map((folio, idx) => (
                                                                <div key={idx}>
                                                                    {folio}
                                                                </div>
                                                            ))}
                                                        </td>
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign:"center" }}>
                                                            {item?.fech.map((fech, idx) => (
                                                                <div key={idx}>
                                                                    {fech}
                                                                </div>
                                                            ))}
                                                        </td>
                                                       
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            {item?.amount.map((amount, idx) => (
                                                                <div key={idx}>
                                                                {amount.split("-").map((part, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {i > 0 && <br />}
                                                                        {`${part}`}
                                                                    </React.Fragment>
                                                                ))}
                                                            </div>
                                                            ))}
                                                        </td>
                                                        <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            {item?.company?.map((company, idx) => (
                                                                <div key={idx}>
                                                                    {company.split("-").map((part, i) => (
                                                                        <React.Fragment key={i}>
                                                                            {i > 0 && <br />}
                                                                            {`${part}`}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                ) : null
                                            ))}
                                        </tbody>
                                    </table>
                                    </Col>
                                </Row>
                                </Col>
                            </center>
                        }
                    </CardBody>
                </Card>
            </Col>

            </Row>
           
        </Fragment>
        
    )
}

