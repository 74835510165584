import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Form, FormGroup, Input, Label, Button, InputGroup,InputGroupAddon,InputGroupText, NavItem, NavLink, Nav, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { sendRequest } from '../../hooks/requests/useRequest';
import { useForm } from '../../hooks/forms/useForm';
import { classes } from '../../data/layouts';
import '../../index.scss';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import jwt_decode from "jwt-decode";
import './StyleMicrositeCompany.css';
import Select from 'react-select';



const LoginMicrosite = (props) => {
    const [loading, setLoading] = useState(false);
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  
    const [loged, setLoged] = useState(false);
    const jwt_token = localStorage.getItem('token');
     const empresa = localStorage.getItem('empresa');
    const id_company = localStorage.getItem('id_company');
    const [createSweet] = useSweetAlert();




    useEffect(() => {
        if (loged === true) {
        //    let pruebas = `${process.env.PUBLIC_URL}`
            window.location.href = `${process.env.PUBLIC_URL}`;
        ////console.log('pasa? ', pruebas);
        }
    }, [loged]);


    const [formValues, handleInputChange,reset, handleUpdateValues,  handleSelectValues] = useForm({
        userName: "",
        password: "",
        userType: 0,
    });

    const { userName, password,userType } = formValues;
    const [showText, setShowText] = useState(false);


    const [userTypes, setUserTypes] = useState([
        {
            value: 1,
            label: "EMPRESAS"
        },
        {
            value: 2,
            label: "MÉDICOS"
        }
    ]);
    
    const iniciarSesion = async (e) => {
        e.preventDefault();
        setShowText(false);
        setLoading(true);
        let id = formValues.userType

        let _copy = 
            {
                "userName": formValues.userName,
                "password": formValues.password,
                "userType": id.value
            }
            
        let raw = JSON.stringify(_copy);

        let requestOptions = {
            method: 'POST',
            body: raw,
           
        };
        const respuesta = await sendRequest(requestOptions, "Company/LoginMicrosite");

        if (respuesta.code === 200) {

            
            ////console.log("pasa2");

            setLoading(false)

            ////console.log(respuesta.data);
          
            let { Name,Username,Empresa ,IdCompany } = jwt_decode(respuesta.data.token);
                localStorage.setItem("token", respuesta.data.token);
                localStorage.setItem("companyName", Name);
                localStorage.setItem("nameUser", Username);
                 localStorage.setItem("empresa", Empresa);
                localStorage.setItem("id_company", respuesta.data.id_company);
                localStorage.setItem("userType", respuesta.data.userType);
                setLoged(true);          
        }
        else {
            setLoading(false)

          ////console.log("pasa");
            createSweet("error", "error", "Usuario y/o contraseña incorrectos");

            
           
        }
    }

    
    useEffect(() => {
      
        if (jwt_token !== null && empresa !== null) {
            props.history.replace(`${process.env.PUBLIC_URL}/dashboard/defaultMicrositio`);  
        }
    }, [props.history])
    
    const imageSrc =
    userType && userType.value === 1
      ? require('../../assets/images/loginHelenLabs/MicrositeCompany/login123.png')
      : require('../../assets/images/loginHelenLabs/MicrositeCompany/login.png');

      const [showPassword, setShowPassword] = useState(false);

      const toggleShowPassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
    return (
        <Container fluid={true}>
            <Col sm="12">
            </Col>
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="8"
                    lg="8"
                    xl="8"
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: '110px' }}
                >
                    <img
                    src={imageSrc}
                    alt="Login Microsite"
                    className="img-fluid"
                    style={{ objectFit: 'contain' }}
                    />
                </Col>
                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <div className='hijoMicrosite'>
                        <Row>
                            <Col xs="12" sm="12" md="11" lg="10" xl="9" className='text-center'>
                            <p className="title m-b-0" style={{ fontWeight: "lighter" }}> <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} alt="helenLogo" style={{width: "10%"}} /> &nbsp;&nbsp;&nbsp;<label className='LabelLogin p-1 p-t-0'>HELEN</label> <label className='LabelLogin2 p-1 p-t-0'>LABS</label></p>
                            </Col>
                        </Row> <br />
                        <Col xs="12" sm="12" md="9" lg="10" xl="9" style={{ textAlign: "-webkit-center" }}>
                        <h5>Accede a tus Resultados</h5>
                        </Col> <br />
                        <Col sm="12" className='p-l-3 p-r-0 marginLdiv'>
                            <Form className='p-l-3 p-r-0'  onSubmit={iniciarSesion} >
                               <Col xs="12" sm="12" md="9" lg="10" xl="9">
                                    <FormGroup>
                                    <Label className="labelMenu">{`Tipo de usuario`}</Label>
                                    <Select
                                    className="form-control-sm input-air-primary btn-pill mb-5"
                                     style={{ border: "#0079C7 2px solid" }}
                                      name="userType"
                                      placeholder = {"Selecciona un tipo de usuario..."}
                                      options = {userTypes}
                                      value = {userType}
                                      isClearable={true}
                                      onChange={(e) => handleSelectValues(e, 'userType')}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="12" md="9" lg="10" xl="9">
                                    <FormGroup>
                                    <Label className="labelMenu1">{`Usuario`}</Label>
                                    <Input className="form-control-sm input-air-primary btn-pill" style={{ border: "#0079C7 2px solid" }} value={userName} name={`userName`} type="text" onChange={handleInputChange}/>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="12" md="9" lg="10" xl="9">
                                    <FormGroup>
                                    <Label className="labelMenu">{`Contraseña`}</Label>
                                    {/* <Input className="form-control-sm input-air-primary btn-pill" style={{ border: "#0079C7 2px solid" }} name="password" value={password} onChange={handleInputChange} /> */}
                                    <InputGroup>
                                        <Input
                                            type={showPassword ? 'text' : 'password'}
                                            className="form-control-sm input-air-primary btn-pill" 
                                            style={{ border: "#0079C7 2px solid" }}
                                            name="password"
                                            value={password}
                                            onChange={handleInputChange}
                                            placeholder="Contraseña"
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                                <span
                                                    className={`fa fa-eye${showPassword ? '-slash' : ''}`}
                                                    onClick={toggleShowPassword}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="12" md="9" lg="10" xl="9" style={{ textAlign : "-webkit-center" }}>
                                <Button type='submit'  color='primary' className={loading ? "disabled progress-bar-animated progress-bar-striped btn-pill" : "btn-pill"} >
                                Iniciar sesión
                                </Button>
                                </Col>
                            </Form>
                        </Col>
                    </div>
                </Col>
            </Row>   
        </Container>
    );
}

export default LoginMicrosite;


