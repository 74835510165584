import React, { useState } from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import "react-datetime/css/react-datetime.css";
import {cedimi} from '../../constant/globalConstantsTypeClients'


export const FormDoctor = (props) => {

    const {
        toggle, loading,
        specialties, id_specialty, name, professional_license,
        phone, address, email, handleInputChange,
        methodAction, button,
        nameInputs,validaciones1, 
        addSpecialty, name_specialty, validaciones, isCommercialPartner,
        // ? 
        code, username, password_microsite, generateRandomPassword

    } = props;
    const {
        id_specialty: validacionIdSpecialty,
        name: validacionName,
        name_specialty: validacionNameSpecialty
        
    } = validaciones;
    const {
        name: nameInput,
        id_specialty: id_specialtyInput,
        professional_license: professional_licenseInput,
        phone: phoneInput,
        address: addressInput,
        email: emailInput,
        code: codeInput,
        username: Inputusername,
        password_microsite:password_micrositeInput

        // isCommercialPartner: isCommercialPartner
    } = nameInputs;

    // ////console.log(validacionIdSpecialty);
    // ////console.log("pasa aqui");
    // ////console.log(validaciones1);
    // ////console.log("pasa aqui");

    let _typeClient = localStorage.getItem('url_helen');
	const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(prev => !prev); 
    };

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-6">
                        <Label className="col-form-label" >Nombre del médico <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            placeholder="Nombre del médico"
                            type="text"
                            name={nameInput}
                            value={name} 
                            required={true}
                            autoComplete="nope"
                            onChange={handleInputChange}
                            invalid={validacionName}
                            className="form-control form-control-sm input-air-primary"
                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6 p-1">
                        <Label className="col-form-label">Especialidad </Label>
                        <Input
                            type="select"
                            name="id_specialty"
                            value={id_specialty}
                            required={true}
                            onChange={handleInputChange} 
                            className="form-control form-control-sm input-air-primary"

                        >
                            <option value={0}>Selecciona una especialidad</option>
                         
                            {
                              
                                specialties.map((x, key) => {
                                    return <option
                                        key={key}
                                        value={x.id_specialty}
                                    >
                                        {x.name}
                                    </option>
                                })
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup className={`mb-3 col-12 ${(addSpecialty !== undefined && id_specialty === "new") ? "" : "d-none"}`}>
                        <Label className="text-sm">Nombre de la nueva especialidad <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            // placeholder="Nueva especialidad"
                            type="text"
                            name="name_specialty"
                            className="form-control form-control-sm input-air-primary"
                            value={name_specialty}
                            required={false}
                            autoComplete="off"
                            onChange={handleInputChange}
                            invalid={validacionNameSpecialty}
                        />
                        <FormFeedback>La especialidad debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Licencia profesional </Label>
                        <Input
                            // placeholder="Licencia profesional"
                            type="text"
                            name={professional_licenseInput}
                            value={professional_license}
                            autoComplete="off"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"

                        />
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Télefono </Label>
                        <Input
                            // placeholder="Télefono"
                            type="text"
                            name={phoneInput}
                            value={phone}
                            autoComplete="off"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"

                        />
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Dirección </Label> 
                        <Input
                            type="text"
                            name={addressInput}
                            value={address}
                            autoComplete="off"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary "

                        />
                    </FormGroup>
                    
                    <FormGroup className="mb-3 col-3">
                        <Label className="col-form-label">¿Cuenta con Convenio?</Label>
                        <br />
                        <Label className="switch">
                        <Input
                            type="checkbox"
                            name="isCommercialPartner"
                            onChange={handleInputChange}
                            checked={isCommercialPartner}
                        />
                        <span className="slider round"></span>
                        </Label>
                    </FormGroup>

                    {isCommercialPartner && (
                        <FormGroup className="mb-3 col-3">
                        {_typeClient === cedimi ? (
                            <Label className="col-form-label">No. Colegiado</Label>

                        ) : (
                            <Label className="col-form-label">Código</Label>
                        )}

                        <Input
                            type="text"
                            name="code"
                            value={code}
                            autoComplete="nope"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"
                        />
                        </FormGroup>
                    )}

                    { isCommercialPartner && (
                        <>
                            <FormGroup className="mb-3 col-6">
                                <Label className="text-sm"> Usuario</Label>
                                <Input
                                    type="text"
                                    name={Inputusername}
                                    value={username}
                                    autoComplete="off"
                                    onChange={handleInputChange}
                                    className="form-control form-control-sm input-air-primary"
        
                                />
                            </FormGroup>
                            <FormGroup className="mb-3 col-6">
                                <Label className="text-sm">Contraseña</Label>
                                
                                <InputGroup>
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control form-control-sm input-air-primary"
                                        name={password_micrositeInput}
                                        value={password_microsite}
                                        onChange={handleInputChange}
                                        placeholder="Contraseña"
                                    />
                                     <InputGroupAddon addonType="append">
                                        <InputGroupText onClick={toggleShowPassword} style={{ cursor: "pointer" }}>
                                            <span className={`fa fa-eye${showPassword ? "-slash" : ""}`} />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <InputGroupAddon addonType="append">
                                        <InputGroupText onClick={generateRandomPassword}  style={{ cursor: "pointer" }}>
                                            <span className={`fa fa-lock`}/>
                                        </InputGroupText>
                                
                                    </InputGroupAddon>

                                  
                                </InputGroup>
                            </FormGroup>
                        </>
                        ) 
                    }
                                        
                    <FormGroup className="mb-3 col-12">
                        <Label className="text-sm">Correo electrónico:&nbsp; </Label> 
                        <span style={{fontSize:"11px"}} className='txt-secondary'>En caso de requerir más de un correo separar con el símbolo ,</span>
                 
                        <Input
                            type="text"
                            name={emailInput}
                            value={email}
                            autoComplete="off"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"
                        />
                    </FormGroup>
        
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    // color="primary"
                    outline color="primary"
                    type="submit"
                    size="sm"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button
                    size="sm"
                    className="btn-air-light"
                    outline color="danger"
                    type="button"
                    onClick={toggle}
                >
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
