import React, { useState } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, FormText, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';

export const FormPatient = (props) => {

    const urlHelen = localStorage.getItem('url_helen');


    const {
        button, method, toggle, loading,

        validationsPatient, validHopitalInterfaced,
        methodAction,
        handleBirthDateChange, handleAgeChange,
        handleInputChange, handleSelectValues, handleDate,

        id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
        id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
        curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender, expedient_number,
        listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender, nss,

        handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender, getAge, getBirthday, handleWheel,

        isInvoiceService, setIsInvoiceService, validPorcentejeSpecial,
        viewNewSocialGender, setViewNewSocialGender,
        scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE, handleAgeC, handleDateB,matricula_buap,
    } = props;

    const {
        id_gender_valid,
        paternal_surname_valid,
        maternal_surname_valid,
        name_valid,
        birthday_valid,
        age_valid,
        phone_valid,
        email_valid,
        curp_valid,
        cp_patient_valid,
        id_tax_regime_valid,
        rfc_valid,
        id_use_cfdi_valid,
        municipality_valid,
        state_valid
    } = validationsPatient;

    return (
        <>

            <ModalBody className='p-b-0' >
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <span className='link text-sm text-primary badge badge-light-primary f-12'>Información general</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" md="3" lg="3">
                        <FormGroup>
                            <Label className='col-form-label'>Nombres <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='text' name="name" value={name} autoComplete='off' onChange={handleInputChange} invalid={name_valid} className='form-control form-control-sm input-air-primary' />
                            <FormFeedback>Ingrese un nombre</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="3" md="3" lg="3">
                        <FormGroup>
                            <Label className='col-form-label'>Apellido paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" name='paternal_surname' value={paternal_surname} autoComplete='off' onChange={handleInputChange} invalid={paternal_surname_valid} className='form-control form-control-sm input-air-primary' />
                            <FormFeedback>Ingrese un apellido</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="3" md="3" lg="3" className='p-1' >
                        <FormGroup>
                            <Label className='col-form-label'>Apellido materno </Label>
                            <Input type="text" name='maternal_surname' value={maternal_surname} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                        </FormGroup>
                    </Col>

                    {urlHelen === 'https://cdb.helen-sw.com' && (
                        <Col sm="3" md="3" lg="3" className='p-1'>
                            <FormGroup>
                                <Label className='col-form-label'>Matrícula </Label>
                                <Input 
                                    type="text" 
                                    name='matricula_buap' 
                                    value={matricula_buap} 
                                    autoComplete='off' 
                                    onChange={handleInputChange} 
                                    className='form-control form-control-sm input-air-primary' 
                                />
                            </FormGroup>
                        </Col>
                    )}
                    
                </Row>
                <Row>
                    <Col sm="3" md="3" lg="3">
                        <Label className='col-form-label'>
                            Fecha de nacimiento <span className='f-16' style={{ color: "#0079C7" }}>*</span> <span style={{ color: "#0079C7" }}><b>(Dia/Mes/Año)</b></span>
                        </Label>
                        <Input
                            type="date"
                            name="birthday"
                            value={birthday}
                            invalid={birthday_valid}
                            onChange={(e) => handleDateB(e.target.value)} 
                        />
                    <FormFeedback>Ingrese la fecha de nacimiento</FormFeedback>

                    </Col>
                    <Col sm="3" md="3" lg="3" className='p-1'>
                        <FormGroup>
                            <Label className='col-form-label'>Edad <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="number"
                                min={0}
                                max={110}
                                name="age"
                                value={age}
                                onChange={(e) => getAge(e.target.value)}
                                invalid={!!age_valid}  // Convierte a booleano para asegurar la validación visual
                                />
                        </FormGroup>
                        {age_valid && <FormFeedback>Ingrese una edad válida</FormFeedback>}

                    </Col>

                    <Col sm="3" md="3" lg="3">
                        <FormGroup>
                            <Label className='col-form-label'>Género <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='select' name="id_gender" onChange={handleInputChange} className='form-control form-control-sm input-air-primary' value={id_gender} invalid={id_gender_valid}>
                                <option value={0}>Selecciona una opción</option>
                                <option value={1}>Femenino</option>
                                <option value={2}>Masculino</option>
                            </Input>
                            <FormFeedback>Selecciona una opción</FormFeedback>
                        </FormGroup>
                    </Col>
                    {/* <Col sm="3" md="3" lg="3">
                        <Label className='col-form-label'>Fecha de nacimiento <span className='f-16' style={{ color: "#0079C7" }}>*</span> <span style={{ color: "#0079C7" }}><b>(Dia/Mes/Año)</b></span></Label>
                        <MaskedInput
                            mask={[
                                /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/
                            ]}
                            className='form-control form-control-sm input-air-primary'
                            placeholder='Dia/Mes/Año'
                            name='birthday'
                            value={birthday}
                            guide={false}
                            onChange={(e) => handleDate(e, "birthday")}
                        />
                        <FormText color='danger' hidden={!birthday_valid}>Ingresa una fecha valida</FormText>
                    </Col>
                    <Col sm="3" md="3" lg="3" className='p-1'>
                        <FormGroup>
                            <Label className='col-form-label'>Edad</Label>
                            <Input
                                type="number"
                                min={0}
                                max={110}
                                name='age'
                                value={age}
                                autoComplete='off'
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (Number(value) >= 0 && Number(value) <= 110) {
                                        handleInputChange(e);
                                        getAge(value, null);
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace' && age.length === 1) {
                                        const newValue = '0';
                                        handleInputChange({ target: { value: newValue } });
                                        getAge(newValue, e.key); 
                                    } else {
                                        getAge(e.target.value, e.key);
                                    }
                                }}
                                onWheel={(e) => e.preventDefault()} // Prevenir el scroll en el input numérico
                                className='form-control form-control-sm input-air-primary no-arrows'
                            />
                        </FormGroup>
                    </Col> */}
                    <Col sm="3" md="3" lg="3">
                        <FormGroup>
                            <Label className='col-form-label'>Teléfono <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                            <Input type='tel' name='phone' value={phone} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                            <FormFeedback>Completa este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    {
                        validHopitalInterfaced === "True" &&
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Núm. Expediente <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                <Input type='expedient_number' name='expedient_number' value={expedient_number} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                <FormFeedback>Completa este campo</FormFeedback>
                            </FormGroup>
                        </Col>
                    }
                    <Col sm="3" md="3" lg="3" className="d-none">
                        <FormGroup>
                            <Label className='col-form-label'>Escanear INE</Label>
                            <br />
                            <Label className='switch'>
                                <Input type='checkbox' name='scan_ine_check' checked={scan_ine_check} onChange={() => setScan_ine_check()} />
                                <span className='slider round'></span>
                            </Label>
                        </FormGroup>
                    </Col>

                    {scan_ine_check == true ?
                        <Col sm="5" md="5" lg="5" xl="5">
                            <FormGroup>
                                <Label htmlFor="file_INE" className="primary btn-primary btn-sm" outline color='primary' size="xs">
                                    Cargar INE <span className="icofont icofont-upload-alt"></span>
                                    <Input type="file" accept="image/*" id="file_INE" name="file_INE" onChange={handleOnChangeINE} style={{ display: 'none' }} />
                                </Label> &nbsp;
                                <Label className='col-form-label'>{showClassINE.NameFile}</Label>
                                <Button onClick={DeleteINE} className={showClassINE.ClassNameINE} outline color="danger" size="xs" type="button">
                                    <i className="fa fa-trash"></i>
                                </Button>&nbsp; &nbsp;
                            </FormGroup>
                        </Col> :
                        <Col>
                        </Col>
                    }
                </Row>
                {
                    validPorcentejeSpecial === "https://grupo-acosta.helen-sw.com" && <>
                        <Row>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>
                                        NSS <span className='f-16' style={{ color: "#0079C7" }}></span>
                                    </Label>
                                    <Input
                                        type='text'
                                        name='nss'
                                        value={nss}
                                        autoComplete='off'
                                        maxLength={30}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 0 && value.length <= 30) {
                                                handleInputChange(e);
                                            }
                                        }}
                                        //invalid={nss.length !== 30 && nss.length > 0} // Validar si no tiene 11 dígitos
                                        className='form-control form-control-sm input-air-primary'
                                    />
                                    <FormFeedback>El NSS incompleto</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                }
                {
                    method !== 'create_mic_patient' && <>
                        <Row className='mt-1'>
                            <Col sm="12" md="12" lg="12">
                                <br />
                                <Row>
                                    <Col sm="9">
                                        <span className='link text-sm text-primary badge badge-light-primary f-12'>Información adicional</span>
                                    </Col>
                                    <Col className='p-l-0' sm="3" style={{ textAlign: "center" }}>
                                        <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos de facturación</span>

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Género social  <span hidden={!viewNewSocialGender} className='link text-sm text-primary badge badge-light-primary f-12' onClick={() => setViewNewSocialGender(!viewNewSocialGender)}>Regresar</span></Label>
                                    {
                                        !viewNewSocialGender
                                            ?
                                            <Select
                                                name="id_social_gender"
                                                value={id_social_gender}
                                                isClearable={true}
                                                placeholder="Selecciona una opción"
                                                options={listSocialGender}
                                                className="input-air-primary input-sm"
                                                onChange={(e) => handleChangeSocialGender(e)}
                                            />
                                            : <Input type='text' name='new_social_gender' value={new_social_gender} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Tipo sanguineo </Label>
                                    <Select
                                        name="id_blood_type"
                                        value={id_blood_type}
                                        isClearable={true}
                                        placeholder="Selecciona una opción"
                                        options={listBloodType}
                                        className="input-air-primary input-sm"
                                        onChange={(e) => handleSelectValues(e, "id_blood_type")}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>CURP <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input type='text' name='curp' value={curp} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' invalid={curp_valid} />
                                    <FormFeedback>Completa este campo</FormFeedback>
                                </FormGroup>
                            </Col>

                            <Col sm="3" md="3" lg="3" style={{ textAlign: "center" }}>
                                <br />
                                <FormGroup>
                                    <Label className='col-form-label'>¿Capturar datos de facturación?</Label>
                                    <br />

                                    <Label className='switch'> <br /> <br />
                                        <Input type='checkbox' name='isInvoiceService' checked={isInvoiceService} onChange={() => setIsInvoiceService(!isInvoiceService)} />
                                        <span className='slider round'></span>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Col sm="12" md="12" lg="12">
                                <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos de contacto</span>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Estado <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                    <Select
                                        name="id_state"
                                        value={id_state}
                                        isClearable={true}
                                        placeholder="Selecciona una opción"
                                        options={listStates}
                                        className="input-air-primary input-sm"
                                        onChange={(e) => handleChangeState(e)}
                                    />
                                    {/* <FormText color='danger' hidden={!state_valid}>Selecciona un estado</FormText> */}
                                </FormGroup>
                            </Col>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Municipio<span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                    <Select
                                        name="id_municipality"
                                        value={id_municipality}
                                        isClearable={true}
                                        placeholder="Selecciona una opción"
                                        options={listMunicipalities}
                                        className="input-air-primary input-sm"
                                        onChange={(e) => handleSelectValues(e, "id_municipality")}
                                    />
                                    {/* <FormText color='danger' hidden={!municipality_valid}>Selecciona un municipio</FormText> */}
                                </FormGroup>
                            </Col>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Dirección</Label>
                                    <Input type='text' name='address' value={address} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                </FormGroup>
                            </Col>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Colonia</Label>
                                    <Input type='text' name='colony' value={colony} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Código postal <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                    <Input type='number' name='cp' value={cp} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' invalid={cp_patient_valid} />
                                    <FormFeedback>Completa este campo</FormFeedback>
                                </FormGroup>
                            </Col>

                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Correo1<span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                    <Input type='text' name='email' value={email} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' invalid={email_valid} />
                                    <FormFeedback>Ingresa un correo valido</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Membresia</Label>
                                    <Input type='text' autoComplete='off' className='form-control form-control-sm input-air-primary' name='membership' value={membership} onChange={handleInputChange} />
                                </FormGroup>
                            </Col>
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'># Tarjeta QR</Label>
                                    <Input type='text' autoComplete='off' className='form-control form-control-sm input-air-primary' name='cardnumber' value={cardnumber} onChange={handleInputChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>


                            {/* <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'># Tarjeta QR</Label>
                                    <Input type='text' autoComplete='off' className='form-control form-control-sm input-air-primary' name='cardnumber' value={cardnumber} onChange={handleInputChange} />
                                </FormGroup>
                            </Col> */}
                        </Row>
                        <Row className='mt-1'>
                            {/* <Col sm="12" md="12" lg="12">
                                <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos de facturación</span>
                            </Col> */}
                        </Row>
                        <Row>
                            {/* <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>¿Capturar datos de facturación?</Label>
                                    <br />
                                    <Label className='switch'>
                                        <Input type='checkbox' name='isInvoiceService' checked={isInvoiceService} onChange={() => setIsInvoiceService(!isInvoiceService)} />
                                        <span className='slider round'></span>
                                    </Label>
                                </FormGroup>
                            </Col> */}
                            {
                                isInvoiceService === true && <>
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>RFC <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Input type='text' name="rfc" value={rfc} className='form-control form-control-sm input-air-primary' onChange={handleInputChange} invalid={rfc_valid} placeholder='XAXX010101000' />
                                            <FormFeedback>Completa este campo</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>Tipo de persona <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Input type="select" name='type_person' value={type_person} onChange={(e) => handleChangeTypePerson(e)} className='form-control input-air-primary'>
                                                <option value="1">Física</option>
                                                <option value="2">Moral</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                        </Row>
                        {
                            isInvoiceService === true && <>
                                <Row>
                                    <Col sm="6" md="6" lg="6">
                                        <FormGroup>
                                            <Label className='col-form-label'>Régimen fiscal <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Select
                                                name="id_tax_regime"
                                                value={id_tax_regime}
                                                isClearable={true}
                                                placeholder="Selecciona una opción"
                                                options={listTaxRegime}
                                                className="input-air-primary input-sm"
                                                onChange={(e) => handleChangeTaxRegime(e)}
                                            />
                                            <FormText color='danger' hidden={!id_tax_regime_valid}>Selecciona una opción</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6" lg="6">
                                        <FormGroup>
                                            <Label className='col-form-label'>Uso de CFDI <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Select
                                                name="id_use_cfdi"
                                                value={id_use_cfdi}
                                                isClearable={true}
                                                placeholder="Selecciona una opción"
                                                options={listUseCfdi}
                                                className="input-air-primary input-sm"
                                                onChange={(e) => handleSelectValues(e, "id_use_cfdi")}
                                            />
                                            <FormText color='danger' hidden={!id_use_cfdi_valid}>Selecciona una opción</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                }
            </ModalBody>
            <ModalFooter>
                <Button size='sm' outline color='primary' type="button" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} onClick={methodAction}>
                    {button}
                </Button>
                <Button size='sm' className='btn-air-light' outline color='danger' type='button' onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </>
    )
}