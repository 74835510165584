import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormGlobalComment } from './FormGlobalComment';

export const ModalCrudGlobalComment = (props) => {

    const {
        method, modalCrudComment, toggleCrudComment, saveCreate, handleInputChange, text, type
        
    } = props;

    return (
        <Modal isOpen={modalCrudComment} toggle={toggleCrudComment} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggleCrudComment} className="bg-primary">
                {
                    method === "create" ?
                        "Comentario Global"
                        : (method === "update") ?
                            "Actualizar Comentario"
                            : "Eliminar Comentario"
                }
            </ModalHeader>
            {
                method === "create" ? 
                    <FormGlobalComment
                        button="Crear comentario global"
                        {...{ toggleCrudComment, saveCreate, handleInputChange, text, type}}
                    />
                    : (method === "update") ?
                        <FormGlobalComment
                            button="Actualizar comentario"
                            {...{toggleCrudComment, saveCreate, handleInputChange, text, type}}
                        />
                        : <FormGlobalComment
                            {...{  }}
                        />
            }
        </Modal>
    )
}

