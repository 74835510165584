import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useForm } from '../forms/useForm';
import moment from 'moment';
import 'moment/locale/es';
import { useEffect, useState } from 'react';
import {getAllBranches } from '../../services/commercialLine/commercialLine'
import { usePagination } from '../pagination/usePagination';


export const usePendingWork = () => {

    const history = useHistory();

    let id_branch = localStorage.getItem('branchId');

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const [branches, setBranches] = useState([]);
    const [sections, setSections] = useState([]);
    const [reportPendingExams, setReportPendingExams] = useState([]);


    useEffect(() => {
        getBranches();
        getSections();
    }, [])

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        mDateRange1: "",
        mDateRange2: "",
        mSelectBranch: 0,
        mSelectSections: 0,

    });


    const {
        mDateRange1, mDateRange2, mSelectBranch, mSelectSections,

    } = formValues;



    //#region Branch 

      useEffect(() => {
        if (branches.length > 0) {

            let _find = branches.find(x => x.value === parseInt(id_branch))
            handleUpdateValues({
                ...formValues,
                mSelectBranch: _find
            });
        }
    }, [branches]);


    const getBranches = async () => {
        const branches = await getAllBranches();
        if (branches !== null && branches.length > 0) {
            // let _allOption = {
            //     value: 0,
            //     label: "TODAS LAS SUCURSALES"
            // };
    
            let _newBranches = []; 
    
            branches.forEach((branch) => {
                _newBranches.push({
                    value: branch.id_branch,
                    label: branch.name
                });
            });
    
            setBranches(_newBranches);
        } else {
            setBranches([]);
        }
    }


    //#endregion Branch

    //#region Sections
    
    const getSections = async () => {
        const _list = await handleRequest("GET", "Section/List/false", "Secciones");
        
        if (_list !== null && _list.length > 0) {
            let _list_sections = [];
            
            _list.forEach(_section => {
                _list_sections.push(
                    {
                        label: _section.name, 
                        value: _section.id_section,
                        abbreviation: _section.abbreviation,
                        state: false
                    }
                );
            });
            
            // console.log("🚀 ~ getSections ~ _list_sections:", _list_sections)
            setSections(_list_sections);
        } else {
            setSections([]); 
        }
    }

    //#endregion Sections


    //#region Filter Reports Pending 

    const getPedingWork = (e, search = true) => {
        if (e) e.preventDefault();
                
        if (search) {
            let query = "";
            let _mfrom = (mDateRange1) ? moment(mDateRange1).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
            let _mto = (mDateRange2) ? moment(mDateRange2).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
            
            let _idBranch = mSelectBranch ? mSelectBranch.value : '';
            let _idSections = mSelectSections ? mSelectSections.value : '';
    
            if (_mfrom !== "") {
                query += `start=${_mfrom}`;
            }
    
            if (_mto !== "") {
                query += `&end=${_mto}`;  
            }
    
            if (_idBranch !== "") {
                query += `&id_branch=${_idBranch}`;
            }
    
            if (_idSections !== "") {
                query += `&id_section=${_idSections}`; 
            }
            setLoading(true);
    
            getReportPendingExams(true, query);
        }
    }
    
    const getReportPendingExams = async (_search = false, _filter) => {
        let _listTmp = {};
        let _alterUrl = "";
    
        if (_search === false) {
            _listTmp = await handleRequest("GET", `Reports/ReportPendingExams?id_branch=${id_branch}` +_alterUrl, 'Report');
        } else {
            _listTmp = await handleRequest("GET", `Reports/ReportPendingExams?${_filter}`, 'Report');
        }
    
        // console.log("🚀 Datos recibidos:", _listTmp);
    
        if (_listTmp && Array.isArray(_listTmp)) {
            const updateList = _listTmp.map(item => ({
                ...item,
                pendingExams: item.num_exams - item.num_exams_released
            }));
            setReportPendingExams(updateList);
            setPedingWork(updateList);
        } else {
            setReportPendingExams([]);
        }
        
        setLoading(false);
    };
    useEffect(() => {
        getReportPendingExams();
    }, []);


    const handleClean =() => {

        handleUpdateValues({
            mDateRange1: "",
            mDateRange2: "",
            mSelectBranch: 0,
            mSelectSections: 0
        });
        reportPendingExams.length = 0;
        // getReportPendingExams(false)
    }

    useEffect(() => {
        getReportPendingExams();
    }, []);

    
    //*

    

    //#endregion

    //#region GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    // #endregion

    
    useEffect (() =>{
        if(mSelectSections) {
            getPedingWork(null, true);
        }
    }, [mSelectSections]);

    // #region Indicators

    const totalExam = () => {
        return reportPendingExams.reduce((total, item) => total + item.num_exams, 0);
    };

    const totalExamsReleased = () => {
        return reportPendingExams.reduce((total, item) => total + item.num_exams_released,0);
    }

    const totalPendingExams = () => {
        return reportPendingExams.reduce((total, item) => total + item.pendingExams,0)
    }

    const pendingExamsPercentage = () => {
        const total = totalExam();
        return total > 0 ? Math.round((totalExamsReleased() / total) * 100) : 0;
    };
    
    
    // #endregion


    // #region Search Exams
    const [pedingWork, setPedingWork] = useState([]);

    const handleSearchPedingWork = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        pedingWork.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setReportPendingExams(newMethod);
    }

    // #endregion

    
    return {

       handleClean,handleInputChange, branches, sections,
        // filter
        mDateRange1, mDateRange2, loading, handleSelectValues, mSelectBranch,reportPendingExams, mSelectSections,
        getPedingWork,
        
        // Indicators
        totalExam, totalExamsReleased, totalPendingExams, pendingExamsPercentage,

        // search
        handleSearchPedingWork

       

    }
}
