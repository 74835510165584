import React from 'react'
import { Button, Modal, ModalBody, CardBody, Table, Col, Form, Input, FormFeedback, Row, FormGroup } from 'reactstrap';
import moment from 'moment';
import Datetime from 'react-datetime';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import './styleDataTable.css';
import '.././admission/viewWorkOrderDay/styleDataTable.css'
import { cedimi } from '../../constant/globalConstantsTypeClients';

export const ModalPreviewTicket = (props) => {

    const { modalPrint, toggleModalPrint, dataHistoric, printTicket, loadingPrint, downloadExcelOthers, downloadExcelCredits, downloadExcel, changeDate, handleHistoric, dateInit, dateEnd } = props;
    console.log("🚀 ~ ModalPreviewTicket ~ dataHistoric:", dataHistoric)


    const downloadExcelCash = (rowData) => {
        return (
            <img
                src={require("../../assets/images/price/cash.svg")}
                alt="Excel"
                title='Excel de Contado'
                style={{ cursor: 'pointer', width: '36px', height: '36px' }}
                onClick={() => downloadExcelOthers(rowData.id_cash_flow,0)}
            />


        );
    };


    const downloadExcelCredit = (rowData) => {
        return (
            <img
                src={require("../../assets/images/price/credit.svg")}
                alt="Excel"
                title='Excel de Crédito'
                style={{ cursor: 'pointer', width: '36px', height: '36px' }}
                onClick={() => downloadExcelCredits(rowData.id_cash_flow,0)}
            />


        );
    };


    const downloadPdfTicket = (rowData) => {
        const today = moment().format("YYYY-MM-DD");
    
        const rowDate = moment(rowData.fech, "DD/MM/YYYY hh:mm:ss a").format("YYYY-MM-DD"); 
    
        console.log("Fecha BD:", rowData.fech, "→", rowDate, "| Hoy:", today); 
    
        const pdfIcon = rowDate === today 
            ? require("../../assets/images/price/pdf.svg") 
            : require("../../assets/images/price/pdf-gris.svg");
    
        return (
            <img
                src={pdfIcon}
                alt="PDF"
                title="Ticket de Corte PDF"
                style={{ cursor: "pointer", width: "36px", height: "36px" }}
                onClick={() => printTicket(rowData.id_cash_flow, 0)}
            />
        );
    };
    


    const urlHelen = localStorage.getItem('url_helen');


    return (
        <Modal isOpen={modalPrint} toggle={toggleModalPrint} backdrop='static' keyboard={false} centered={true} size="lg">
            <Form className="form theme-form" role="form">
                <ModalBody>
                    <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                        {
                            <>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="12" className='text-center p-l-0 p-r-0' >
                                            <label className='text-center f-14 f-w-600'>  HISTORIAL DE RECIBOS</label>
                                            <div className='hr-detail-patient' >
                                            </div>
                                        </Col>
                                        <Col sm="3" className='p-1 mt-3'>
                                            <i className="fa fa-calendar fa-lg text-info mr-1"></i> <b>Filtrar por fecha:</b>
                                        </Col>

                                        <Col sm="3" className='p-l-0 mt-3'>
                                            <FormGroup className="">
                                                <Datetime
                                                    inputProps={{
                                                        placeholder: 'YYYY-MM-DD',
                                                        name: "workFrom",
                                                        autoComplete: "false",
                                                        className: "form-control digits input-air-primary form-control-sm",
                                                    }}
                                                    timeFormat={false}
                                                    dateFormat="YYYY-MM-DD"
                                                    value={dateInit}
                                                    locale="es"
                                                    initialValue={new moment().format('YYYY-MM-DD')}
                                                    onChange={(e) => changeDate(e, "init")}
                                                    closeOnSelect={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" className='mt-3'>
                                            <FormGroup >
                                                <Datetime
                                                    inputProps={{
                                                        placeholder: 'YYYY-MM-DD',
                                                        name: "workFrom",
                                                        autoComplete: "false",
                                                        className: "form-control digits input-air-primary form-control-sm ",
                                                        size: "sm"
                                                    }}
                                                    timeFormat={false}
                                                    dateFormat={"YYYY-MM-DD"}
                                                    value={dateEnd}
                                                    locale="es"
                                                    initialValue={new moment().format('YYYY-MM-DD')}
                                                    onChange={(e) => changeDate(e, "end")}
                                                    closeOnSelect={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" className='p-0 mt-3'>
                                            <Button type="button" onClick={() => handleHistoric(true)} outline color="primary" size='sm' className={("text-center")}> FILTRAR HISTORIAL</Button>
                                        </Col>
                                        
                                        <div className='table-wrapper mt-2'>
                                        {
                                                urlHelen === cedimi ? (
                                                    <DataTable 
                                                        value={dataHistoric} 
                                                        paginator 
                                                        rows={10} 
                                                        className='data-table'
                                                        emptyMessage={
                                                            <div className="text-center">
                                                                <img 
                                                                    src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} 
                                                                    style={{ width: "45%" }} 
                                                                    alt="Sin resultados" 
                                                                />
                                                                <br />
                                                                <p className='badge badge-light-primary mt-2' style={{fontSize: "15px"}}>
                                                                    No hay coincidencias con la búsqueda.
                                                                </p>
                                                            </div>
                                                        }
                                                    >
                                                          <Column 
                                                            header="#" 
                                                            body={(rowData,{ rowIndex }) => rowIndex + 1} 
                                                            headerStyle={{ backgroundColor: "#1d5b83", color: "#ffffff", fontWeight: "bold", fontSize:"12px" }} 
                                                            style={{fontSize:"12px", textAlign: 'center'}}
                                                        />

                                                        <Column 
                                                            field="fech" 
                                                            header="Fecha" 
                                                            headerStyle={{ backgroundColor: "#1d5b83", color: "#ffffff", fontWeight: "bold", fontSize:"12px" }} 
                                                            style={{fontSize:"12px", textAlign: 'center'}}
                                                        />
                                                        <Column 
                                                            body={downloadExcelCash} 
                                                            header="Contado" 
                                                            headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize: "12px" }} 
                                                            style={{fontSize:"12px", textAlign: 'center'}}
                                                        />
                                                        <Column 
                                                            body={downloadExcelCredit}
                                                            header="Crédito" 
                                                            headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize: "12px" }} 
                                                            style={{fontSize:"12px", textAlign: 'center'}}
                                                        />
                                                        <Column 
                                                            body={downloadPdfTicket} 
                                                            header="Ticket" 
                                                            headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize: "12px" }} 
                                                            style={{fontSize:"12px", textAlign: 'center'}}
                                                        />
                                                    </DataTable>
                                                ) : (
                                                    <Table className="table table-hover table-bordered b-r-5 table-sm">
                                                        <thead className='b-r-5'>
                                                            <tr style={{ backgroundColor: "#469ed7" }}>
                                                                <th className='labeltheadInvoice' style={{textAlign:"center"}} >#</th>
                                                                <th className='labeltheadInvoice' style={{textAlign:"center"}} >Fecha</th>
                                                                <th className='labeltheadInvoice' style={{textAlign:"center"}} >Acción</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!loadingPrint && (!dataHistoric || dataHistoric.length === 0) && (
                                                                <tr>
                                                                    <td colSpan={3} className="text-center">
                                                                        <label className="f-w-600 f-12">SIN HISTORIAL DE RECIBOS</label>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            
                                                            {!loadingPrint && dataHistoric?.map((_find, _key) => (
                                                                <tr key={_key}>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        <label className='f-w-600 f-12'>{_key + 1}</label>
                                                                    </td>

                                                                    <td style={{ textAlign: "center" }}>
                                                                        <label className='f-w-600 f-12'>{_find?.fech ?? "----"}</label>
                                                                    </td>

                                                                    <td style={{ textAlign: "center" }}>
                                                                        <img
                                                                            src={require("../../assets/images/price/downloadExcel.png")}
                                                                            alt="Excel"
                                                                            title='Descargar Excel'
                                                                            style={{ cursor: 'pointer', width: '36px', height: '36px' }}
                                                                            onClick={() => downloadExcel(_find.id_cash_flow)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                )
                                            }

                                        </div>

                                    </Row>
                                </Col>
                                <Col sm="12" style={{ textAlign: "right" }} className='p-l-0 p-r-0' > <br />

                                    <Row>
                                        <Col sm="8">
                                        </Col>

                                        <Col sm="12" className="d-flex justify-content-end mt-1">
                                            <Button color="danger text-center" type="button" onClick={toggleModalPrint}>Cancelar</Button>
                                        </Col>

                                    </Row>

                                </Col>
                            </>
                        }
                    </CardBody>
                </ModalBody>
            </Form>
        </Modal>
    )
}
