import { useState, useEffect } from "react"
import React from 'react'
import { useForm } from '../forms/useForm';
import 'moment/locale/es';
import moment from 'moment';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import {getGlobalCash,createCashGlobal} from '../../services/cashFlow/cashFlow'


export const useCashGlobal = () => {
        
    // * GLOBALES
    let _commercialLine = localStorage.getItem('CommercialLineShort');
    let _nameUser= localStorage.getItem('nameUser');
    let _branchName= localStorage.getItem('branchName');
    let _admin_cash= localStorage.getItem('admin_cash');
    let _active_micro = localStorage.getItem('active_microsanitary');
    let _url_helen = localStorage.getItem('url_helen');


   
    const [createSweet] = useSweetAlert();
    
    // ? GLOBAL CASH
    const [dataAllGlobal, setDataAllGlobal] = useState([])
    const [amount1, setAmount1] = useState(0);
    const [loadingGlobal, setLoadingGlobal] = useState(false)

    // ? AUTHORIZATION CASH
    const [selectedKey, setSelectedKey] = useState(null); 
    const [modalAuthorization, setModalAuthorization] = useState(false);
    const [viewAmount, setViewAmount] = useState(null)
    const [user, setUser] = useState(null)

    // ? PREVIEW
    const [viewDetail, setViewDetail] = useState(false);
    const [id_cash, setId_cash] = useState(null)


    // ? FORM CASH
    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        date1: "",
        date2: "",
    });

    const {date1,date2} = formValues;

    // * CONFIGURATION GLOBAL
    const today = moment().format("YYYY-MM-DD");
    let _date1 = (date1 === '') ? today : moment(date1).format("YYYY-MM-DD");
    let _date2 = (date2 === '') ? today : moment(date2).format("YYYY-MM-DD");

    // *CONFIGURATION DATE
    const now = new Date();
    const fecha = now.toLocaleDateString(); 
    const hora = now.toLocaleTimeString(); 
    let _new = (fecha + " " + hora)

    // * CONFIGURATION PREVIEW

    const handleOpenPreview = () => 
    {
        setViewDetail(true)
    }

    const handleClosedPreview = () => 
    {
        setViewDetail(false)
    }


    // * EFFECTS
    useEffect(() => {
        handleGetGlobalCash()
    }, [])

    // * TOOGLE
    const toogleAuthorization = () => 
    {
        setModalAuthorization(!modalAuthorization)
    }

    // ? METHOD GLOBAL
    const handleGetGlobalCash = async () => 
    {
        setLoadingGlobal(true)
        const allCash = await getGlobalCash(_date1, _date2);
    
        if (allCash?.length > 0) {
            setLoadingGlobal(false)

            let _update = [];
            
            allCash.forEach(_branch => {
                let _sum_branch = 0;
                let _sum_amountCash = 0;  
    
                _branch.detailBranches.forEach(_pay => {
                    _pay.pay_methods.forEach(_amount => {
                        if (_amount?.id_pay_method === 1) {
                            _sum_branch += _amount?.amount;
                        }
                    });

                    _pay.cash.forEach(_cashItem => {
                        _sum_amountCash += _cashItem?.amount ?? 0 
                    });
                });
    
                _update.push({
                    ..._branch,
                    amount: _sum_branch,      
                    amountCash: _sum_amountCash, 
                });
            });
    
            setDataAllGlobal(_update);
            setAmount1(_update.reduce((acc, branch) => acc + branch.amount, 0)); 
        } else {
            setDataAllGlobal([]);
        }
    };

    // ? CONFIGURATION AUTHORIZATION CASH
    const handleSearchAuthorization = (_key_user, _key_branch,_key_detail,_amounts,_user,_id_cash) => 
    {
        const key = `${_key_user}-${_key_branch}-${_key_detail}`;
    
        setSelectedKey(prevSelectedKey => (prevSelectedKey === key ? null : key));
        setModalAuthorization(!modalAuthorization)
        setViewAmount(_amounts)
        setUser(_user)
        setId_cash(_id_cash)
    };

    // ? SAVE CHANGUES
    const saveCourtesyPatient = async (_authorization_date,_id_cash) => 
    {
        let _new = moment(now).format("YYYY-MM-DD HH:mm:ss"); 

        const response = await createCashGlobal(_new,_id_cash);

        if (response.code === 200) {
            createSweet("success", "success", "Autorizado", "Se a validado correctamente este corte");
            handleGetGlobalCash()
            toogleAuthorization()
        }
    }
    

    // * FUNCTION FORMAT
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })



    return {
        // ? CONFIGURATION
        _commercialLine,_nameUser,_branchName,_new,_admin_cash,

        // ? FORM 
        date1, date2, handleInputChange,
        
        // ? TYPE FORMAT
        formatter,

        // ? GLOBAL CASH
        dataAllGlobal,handleGetGlobalCash,amount1,loadingGlobal,

        // ? AUTHORIZATION CASH
        handleSearchAuthorization,selectedKey,toogleAuthorization,modalAuthorization,handleOpenPreview,handleClosedPreview,viewDetail,viewAmount,user,id_cash,saveCourtesyPatient,
        _active_micro,_url_helen
    }
}
