import { Home, Percent, Link, Users, Settings, Menu, Edit, Search, BarChart, DollarSign, File, CreditCard, User, Shield,Calendar } from 'react-feather';
import React, {useState,useEffect} from 'react';
import './sidebar.css'
import { getAllPbi } from '../../services/Pbi/pbiService';


const pending_branches = localStorage.getItem("pending_branches");
const interfaced = localStorage.getItem("interfaced") === "True";
const client = localStorage.getItem("url_helen");
const activeMicrosanitary = localStorage.getItem('active_microsanitary') === "True";

const name_menu = client  === "https://insan.helen-sw.com" ? 'Financiero' : 'Microbiologia'
const name_menu_mac = client  === "https://mac.helen-sw.com" ? 'Tiempos de entrega' : 'Doctores'



var arrayPbi = []

const MENUITEMSS = [
  {
    menutitle: "General",
    menucontent: "",
    code: "dashboard",
    Items: [
      {
        code: "dashboard",
        title: 'Dashboard', icon: Home, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard/default`,
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Bi",
    code: "bi",
    Items: [
      {
        title: 'Bi', icon: Users, type: 'sub', active: false, children: [
          {
            code: "view2",
            path: `${process.env.PUBLIC_URL}/Pbi/1`, title: 'Pacientes', type: 'link'
          },
          {
            code: "view2",
            path: `${process.env.PUBLIC_URL}/Pbi/2`, title: 'Operativo', type: 'link'
          },
          {
            code: "view2",
            path: `${process.env.PUBLIC_URL}/Pbi/3`, title: name_menu_mac , type: 'link'
          },
          {
            code: "view2",
            path: `${process.env.PUBLIC_URL}/Pbi/4`, title: name_menu, type: 'link'
          },
          interfaced && {
            code: "view2",
            path: `${process.env.PUBLIC_URL}/Pbi/5`, title: 'Reporte por servicio', type: 'link'
          },
          {
            code: "view_finance",
            path: `${process.env.PUBLIC_URL}/Pbi/6`, title: "Financiero", type: 'link'
          },
        ]
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Ingreso de pacientes",
    code: "admision",
    Items: [
      {
        title: 'Admisión', icon: Users, type: 'sub', active: false, children: [
          {
            code: "admision_pacientes",
            path: `${process.env.PUBLIC_URL}/admisiones/registro`, title: 'Admisión de Pacientes', type: 'link'
          },
          {
            code: "cotizador",
            path: `${process.env.PUBLIC_URL}/dashboard/Cotizador`, title: 'Cotizador', type: 'link'
          }
        ]
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Consulta de trabajo del día",
    code: "consulta",
    Items: [
      {
        title: 'Consulta', icon: Search, type: 'sub', active: false, children: [
          {
            code: "trabajo_dia",
            path: `${process.env.PUBLIC_URL}/busqueda/trabajo-dia`, title: 'Trabajo del Día', type: 'link'
          },
          {
            code: "hojas_trabajo",
            path: `${process.env.PUBLIC_URL}/busqueda/hojas-trabajo-dia`, title: 'Hojas de Trabajo', type: 'link',
          },
          // {
          //   code: "pendientes_dia",
          //   path: `${process.env.PUBLIC_URL}/busqueda/status-del-dia`, title: 'Pendientes del Día', type: 'link',
          // },
          {
            code: "doble_validacion",
            path: `${process.env.PUBLIC_URL}/busqueda/doble-validacion`, title: 'Doble validación', type: 'link',
          },

          {
            code: "pendientes_dia",
            path: `${process.env.PUBLIC_URL}/busqueda/trabajo-pendiente`, title: 'Trabajos Pendientes', type: 'link',
          },
        ]
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Listado de catálogos",
    code: "catalogos",
    Items: [
      {
        title: 'Catálogos', icon: Menu, type: 'sub', active: false, children: [
          {
            code: "cat_pacientes",
            path: `${process.env.PUBLIC_URL}/catalogos/pacientes`, title: 'Pacientes', type: 'link'
          },
          {
            code: "cat_clientes",
            path: `${process.env.PUBLIC_URL}/catalogos/clientes`, title: 'Clientes', type: 'link'
          },
          {
            code: "cat_medicos",
            path: `${process.env.PUBLIC_URL}/catalogos/medicos`, title: 'Médicos', type: 'link'
          },
          {
            code: "cat_lab_externos",
            path: `${process.env.PUBLIC_URL}/catalogos/laboratoriesExternal`, title: 'Laboratorios Externos', type: 'link'
          },
          {
            code: "cat_vendedores",
            path: `${process.env.PUBLIC_URL}/catalogos/vendedores`, title: 'Vendedores', type: 'link'
          }
        ]
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Listado de convenios",
    code: "convenios",
    Items: [
      {
        title: 'Convenios', icon: Link, type: 'sub', active: false, children: [
          {
            code: "convenios_lista",
            path: `${process.env.PUBLIC_URL}/convenios`, title: 'Listado', type: 'link'
          },
          {
            code: "convenios_clientes",
            path: `${process.env.PUBLIC_URL}/clientes/convenios`, title: 'Clientes', type: 'link'
          }
        ]
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Configuración de Exámenes",
    code: "diseno",
    Items: [
      {
        title: 'Diseño', icon: Edit, type: 'sub', active: false, children: [
          {
            code: "diseno_examen",
            path: `${process.env.PUBLIC_URL}/diseno/examenes`, title: 'Diseño de Examen', type: 'link'
          },
          {
            code: "diseno_perfiles",
            path: `${process.env.PUBLIC_URL}/diseno/perfiles`, title: 'Diseño de Perfiles', type: 'link'
          },
          {
            code: "diseno_analitos",
            path: `${process.env.PUBLIC_URL}/diseno/analitos`, title: 'Diseño de Analitos', type: 'link'
          }
        ]
      }
    ]
  },
  {
    menutitle: "Micro",
    menucontent: "Gestión de micro",
    code: "micro",
    Items: [
      {
        title: 'Micro', icon: Edit, type: 'sub', active: false, children: [
          {
            code: "micro_examen",
            path: `${process.env.PUBLIC_URL}/diseno/examenes/Micro`, title: 'Diseño de Exámen', type: 'link'
          },
          {
            code: "micro_bacterias",
            path: `${process.env.PUBLIC_URL}/micro/bacterium`, title: 'Bacterias', type: 'link'
          },
          {
            code: "micro_morfo",
            path: `${process.env.PUBLIC_URL}/micro/morpholgy`, title: 'Morfologias', type: 'link'
          },
          {
            code: "micro_antibioticos",
            path: `${process.env.PUBLIC_URL}/micro/antibiotic`, title: 'Antibioticos', type: 'link'
          },
          {
            code: "micro_antibiogramas",
            path: `${process.env.PUBLIC_URL}/micro/antibiogram`, title: 'Antibiogramas', type: 'link'
          },
          {
            code: "micro_cultivos",
            path: `${process.env.PUBLIC_URL}/micro/cultivo`, title: 'Cultivo', type: 'link'
          },
          {
            code: "micro_antibiogramas",
            path: `${process.env.PUBLIC_URL}/micro/resistanceMechanism`, title: 'Mecanismos de resistencia', type: 'link'
          },
          {
            code: "micro_antibiogramas",
            path: `${process.env.PUBLIC_URL}/micro/stainGrams`, title: 'Grams', type: 'link'
          },
          {
            code: "micro_antibiogramas",
            path: `${process.env.PUBLIC_URL}/micro/bacteriumCount`, title: 'Bacterias', type: 'link'
          },
          {
            code: "micro_antibiogramas",
            path: `${process.env.PUBLIC_URL}/micro/tincion`, title: 'Tinciones', type: 'link'
          },
          {
            code: "micro_antibiogramas",
            path: `${process.env.PUBLIC_URL}/micro/tincionResult`, title: 'Resultados', type: 'link'
          },
        ]
      }
    ]
  },
//*detail corte de caja 
  {
    menutitle: "",
    menucontent: "Control de Cobranza",
    code: "cobranza",
    Items: [
      {
        title: 'Cobranza', icon: DollarSign, type: 'sub', active: false, children: [
          {
            code: "cobranza_por_cobrar",
            path: `${process.env.PUBLIC_URL}/facturacion/reporte`, title: 'Cobranza de particulares', type: 'link'
          },
          {
            code: "cobranza_cliente",
            path: `${process.env.PUBLIC_URL}/reportes/cxc`, title: 'Cuentas por Cobrar', type: 'link'
          },
          {
            code: "cobranza_reporte",
            path: `${process.env.PUBLIC_URL}/deposits/reports`, title: 'Depositos Bancarios', type: 'link'
          },
          {
            code: "cobranza_corte_caja",
            path: `${process.env.PUBLIC_URL}/balance/corte/:User`, title: 'Corte de caja', type: 'link'
          },
          // {
          //   code: "cierre_global",
          //   path: `${process.env.PUBLIC_URL}/cierre/global`, title: 'Cierre Global', type: 'link'
          // },
          {
            code: "reporte_caja",
            path: `${process.env.PUBLIC_URL}/Reporte/:Admin`, title: 'Reportes de Caja', type: 'link'
          }
        ]
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Sanitaria",
    code: "sanitaria",
    Items: [
      {
        title: 'Sanitaria', icon: Shield, type: 'sub', active: false, children: [
          {
            code: "sanitaria_sitios",
            path: `${process.env.PUBLIC_URL}/sanitaria/sitios`, title: 'Sitios', type: 'link'
          },
          {
            code: "sanitaria_muestras",
            path: `${process.env.PUBLIC_URL}/sanitaria/muestras`, title: 'Muestras', type: 'link'
          },
          {
            code: "sanitaria_areas",
            path: `${process.env.PUBLIC_URL}/sanitaria/areas`, title: 'Areas', type: 'link'
          },
          {
            code: "sanitaria_tipo_muestras",
            path: `${process.env.PUBLIC_URL}/sanitaria/tipo-muestra`, title: 'Tipo de muestra', type: 'link'
          },
          {
            code: "sanitaria_tipo_muestras",
            path: `${process.env.PUBLIC_URL}/sanitaria/normas`, title: 'Normas', type: 'link'
          },
          {
            code: "sanitaria_agenda",
            path: `${process.env.PUBLIC_URL}/sanitaria/agenda`, title: 'Agenda', type: 'link'
          },
          {
            code: "sanitaria_cotizacion",
            path: `${process.env.PUBLIC_URL}/sanitaria/cotizacion`, title: 'Cotizaciones', type: 'link'
          },
          {
            code: "hojas_muestreo",
            path: `${process.env.PUBLIC_URL}/sanitaria/hojas-muestreo`, title: 'Hojas de Muestreo', type: 'link'
          },
          {
            code: "lista_hojas_muestreo",
            path: `${process.env.PUBLIC_URL}/sanitaria/lista-hojas-muestreo`, title: 'Lista de Hojas de Muestreo', type: 'link'
          },
          {
            code: "cat_equipos_sanitaria",
            path: `${process.env.PUBLIC_URL}/sanitaria/cat_equipos_sanitaria`, title: 'Equipos y consumibles', type: 'link'
          },
          {
            code: "cat_lote_medios_sanitaria",
            path: `${process.env.PUBLIC_URL}/sanitaria/cat_lote_medios_sanitaria`, title: 'Lote de Medios', type: 'link'
          },
          {
            code: "hojas_trabajo_sanitary",
            path: `${process.env.PUBLIC_URL}/sanitaria/hojas_trabajo_sanitary`, title: 'Hojas de Trabajo', type: 'link'
          },
          activeMicrosanitary && {
            code: "sanitaria_reports",
            path: `${process.env.PUBLIC_URL}/sanitaria/sanitaria_reports`, title: 'Reporte microsanitaria', type: 'link'
          },
          
      
        ]
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Gestión de Descuentos",
    code: "descuentos",
    Items: [
      {
        title: 'Descuentos', icon: Percent, type: 'sub', active: false, children: [
          {
            code: "descuentos_especiales",
            path: `${process.env.PUBLIC_URL}/descuentos/especiales`, icon: Percent, title: 'Especiales', type: 'link'
          },
          {
            code: "descuentos_campana",
            path: `${process.env.PUBLIC_URL}/descuentos/campañas`, icon: Percent, title: 'Campañas', type: 'link'
          },
          {
            code: "descuentos_utilizados",
            path: `${process.env.PUBLIC_URL}/descuentos/usados`, icon: Percent, title: 'Utilizados', type: 'link'
          },
          {
            code: "descuentos_especiales",
            path: `${process.env.PUBLIC_URL}/catalogo/descuentos`, icon: Percent, title: 'Catalógo de descuentos', type: 'link'
          },
        ]
      }
    ]
  },

  {
    menutitle: "",
    menucontent: "Administración de catálogos ",
    code: "configuraciones",
    Items: [
      {
        title: 'Configuraciones', icon: Settings, type: 'sub', bullet: `status-circle offline bullet-menu ${pending_branches <= 0 && "d-none"}`, active: false, children: [
          {
            code: "config_equipos",
            path: `${process.env.PUBLIC_URL}/configuraciones/equipos`, title: 'Equipos', type: 'link'
          },
          {
            code: "config_especimenes",
            path: `${process.env.PUBLIC_URL}/configuraciones/especimenes`, title: 'Especímenes', type: 'link'
          },
          
          {
            code: "config_metodo_pago",
            path: `${process.env.PUBLIC_URL}/configuraciones/metodos-pago`, title: 'Métodos de Pago', type: 'link'
          },
          {
            code: "config_puntos_impresion",
            path: `${process.env.PUBLIC_URL}/configuraciones/puntos-impresion`, title: 'Puntos de Impresión', type: 'link'
          },
          {
            code: "config_razones_sociales",
            path: `${process.env.PUBLIC_URL}/configuraciones/razon-social`, title: 'Razones Sociales', type: 'link'
          },
          {
            code: "config_secciones",
            path: `${process.env.PUBLIC_URL}/configuraciones/secciones`, title: 'Secciones', type: 'link'
          },
          {
            code: "config_sucursales",
            path: `${process.env.PUBLIC_URL}/configuraciones/sucursales`, title: 'Sucursales', type: 'link', bullet: `status-circle offline bullet-submenu ${pending_branches <= 0 && "d-none"}`,
          },
          {
            code: "config_tarifarios",
            path: `${process.env.PUBLIC_URL}/configuraciones/tarifario`, title: 'Tarifarios', type: 'link'
          },
          {
            code: "config_textos_precodificados",
            path: `${process.env.PUBLIC_URL}/configuraciones/textos-precodificados`, title: 'Textos Precodificados', type: 'link'
          },
          {
            code: "config_textos_precodificados",
            path: `${process.env.PUBLIC_URL}/configuraciones/catalogos`, title: 'Catalógo de Cancelaciones ', type: 'link'
          },
          {
            code: "config_transportes_gradillas",
            path: `${process.env.PUBLIC_URL}/configuraciones/transportes`, title: 'Transportes y Gradillas', type: 'link'
          },
          {
            code: "config_unidades",
            path: `${process.env.PUBLIC_URL}/configuraciones/unidades`, title: 'Unidades', type: 'link'
          },
          // {
          //   code: "config_margen_ganancias",
          //   path: `${process.env.PUBLIC_URL}/configuraciones/margen-minimo`, title: 'Margen Minímo de Ganancias', type: 'link'
          // },
          {
            code: "config_indicaciones",
            path: `${process.env.PUBLIC_URL}/configuraciones/indicaciones`, title: 'Indicaciones', type: 'link'
          },
          {
            code: "config_tiempo_cruvas",
            path: `${process.env.PUBLIC_URL}/configuraciones/tiempos-curvas`, title: 'Timpos - Curvas', type: 'link'
          },
          {
            code: "config_apps",
            path: `${process.env.PUBLIC_URL}/configuraciones/interfaz-analizador`, title: 'Configurar Apps', type: 'link'
          },
          {
            code: "config_logs",
            path: `${process.env.PUBLIC_URL}/configuraciones/logs`, title: 'Logs', type: 'link'
          },
          {
            code: "config_roles",
            path: `${process.env.PUBLIC_URL}/configuraciones/roles`, title: 'Roles', type: 'link'
          },
          {
            code: "config_servicios",
            path: `${process.env.PUBLIC_URL}/configuraciones/servicios`, title: 'Servicios', type: 'link'
          },
        ]
      }
    ]
  },

  {
    menutitle: "",
    menucontent: "Administración de Facturas realizas",
    code: "facturacion",
    Items: [
      {
        title: 'Facturación', icon: File, type: 'sub', active: false, children: [
          {
            code: "facturacion_visualizador",
            path: `${process.env.PUBLIC_URL}/facturacion/Emitidos`, title: "Facturas emitidas", type: 'link'
          },
          {
            code: "facturacion_cliente",
            path: `${process.env.PUBLIC_URL}/invoice/service`, title: 'Facturación', type: 'link'
          },
          {
            code: "complementos_pago",
            path: `${process.env.PUBLIC_URL}/facturacion/complemento-pago`, title: "Complementos de pago", type: 'link'
          },
          {
            code: "notas_credito",
            path: `${process.env.PUBLIC_URL}/facturacion/notas-credito`, title: "Notas de crédito", type: 'link'
          },
        ]
      }
    ]
  },
  {
    menutitle: "",
    menucontent: "Control de Toma de Muestra",
    code: "toma_muestra",
    Items: [
      {
        title: 'Toma de Muestra', icon: User, type: 'sub', active: false, children: [
          {
            code: "toma_config",
            path: `${process.env.PUBLIC_URL}/configuraciones/toma-de-muestra`, title: 'Configuraciones', type: 'link'
          },
          {
            code: "toma_consentimiento",
            path: `${process.env.PUBLIC_URL}/consentimiento-informado`, title: 'Consentimiento informado', type: 'link'
          },
          {
            code: "toma_consentimiento",
            path: `${process.env.PUBLIC_URL}/satisfaction/encuestas`, title: 'Encuestas', type: 'link'
          }
          
        ]
      }
    ]
  },
 
]

function filtermenu() {
  var access = [];




  try {
    var json = localStorage.getItem("access");
    if (json == null || json === "") json = "[]";
    access = JSON.parse(json);
  } catch (error) {
    access = [];
  }

  var response = [];
  MENUITEMSS.forEach(m => {
    var index = access.findIndex(a => a.code === m.code);
    if (index >= 0) {
      var codes = access[index].views.map(x => x.code);
      var items = [];
      m.Items.forEach(i => {
        if (i.hasOwnProperty('children')) {
          var children = i.children.filter(c => codes.indexOf(c.code) >= 0);
          if (children.length > 0) items.push({ ...i, children: children });
        } else if (codes.indexOf(i.code) >= 0) items.push(i);
      });
      if (items.length > 0) response.push({ ...m, Items: items });
    }
  });

  return response;
}

export default function MenuComponent() {
  const [openMenus, setOpenMenus] = useState({});
  const [isHorizontal, setIsHorizontal] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsHorizontal(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Llamar al cargar

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = (index) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Alternar el estado del menú
    }));
  };

  return (
    <div className={`sidebar-wrapper ${isHorizontal ? 'horizontal-mode' : ''}`}>
      <div className="sidebar-menu">
        {MENUITEMS && MENUITEMS.length > 0 ? (
          MENUITEMS.map((menu, index) => (
            <div className="menu-item" key={index}>
              {menu.menutitle && <h4>{menu.menutitle}</h4>}
              {menu.Items && menu.Items.length > 0 ? (
                menu.Items.map((item, itemIndex) => (
                  <div key={itemIndex} className="menu-link" onClick={() => toggleMenu(itemIndex)}>
                    {item.title}
                    {item.children && openMenus[itemIndex] && (
                      <div className="submenu">
                        {item.children.map((child, childIndex) => (
                          <div key={childIndex} className="submenu-item">
                            <a href={child.path}>{child.title}</a>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>No items found</div>
              )}
            </div>
          ))
        ) : (
          <div>No menus found</div>
        )}
      </div>
    </div>
  );
}

export const MENUITEMS = filtermenu();


















