import React, {useState} from 'react';
import { Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import { Col, Row, Table,Form,FormGroup,Input,CardBody,Media} from 'reactstrap';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import reportCxcCompanyAltImage from '../../assets/images/price/company-Ban.svg'
import reportCxcCompanyImage from '../../assets/images/price/reportCxcCompany.svg'

import * as XLSX from 'xlsx';


export const ModalCredit = (
    { close,companyInfo,formatter,navigateNim,valid_view,cancellPatient,countPatient,
      ...props }) => {
     

    // ? CONFIGURATION SEARCH AND CHART 
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedUser, setSelectedUser] = useState(""); 


    const handleCompanySelect = (company) => {
      setSelectedCompany(company);
      setSelectedPatients(company.patientDetails); 
    };

    // ! CHART
    const chartData = companyInfo.map(company => {
      const totalAmount = company.patientDetails.reduce((total, patient) => total + (patient.amount), 0);
      return {
        name: company.nameCompany,
        y: totalAmount
      };
    });

    // ! OPTIONS CHART
    const options = {
      chart: {
        type: "pie",
      },
      title: {
        text: "Monto de creditos en empresas",
      },
      plotOptions: {
        pie: {
          innerSize: "50%",
          dataLabels: {
            enabled: true,
            format: "{point.name}: {point.percentage:.1f}%",
          },
        },
      },
      series: [
        {
          name: "Empresa",
          colorByPoint: true,
          data: chartData,
        },
      ],
    };

    const filteredCompanies = companyInfo.filter((company) =>
      company.nameCompany.toLowerCase().includes(search.toLowerCase())
    );

    const handleDownloadExcel = () => 
    {
      if (!selectedPatients.length) {
        return;
      }
    
      const ws = XLSX.utils.json_to_sheet(selectedPatients.map(patient => ({

        Nim: patient.nim,
        Sucursal: patient.branchs,
        Paciente: patient.name_patient,
        Monto: patient.amount
      })));
    
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Pacientes");
    
      XLSX.writeFile(wb, "pacientes.xlsx");
    };

    const sumMonto = selectedPatients.reduce((total, patient) => total + (patient.amount || 0), 0);

    const groupedData = cancellPatient.reduce((acc, curr) => {
      const { user_autorized, amount } = curr;
      if (acc[user_autorized]) {
        acc[user_autorized].totalAmount += amount;
        acc[user_autorized].count += 1;  
      } else {
        acc[user_autorized] = { totalAmount: amount, count: 1 };
      }
      return acc;
    }, {});
  
    const lineData = Object.keys(groupedData).map(user => ({
      x: Object.keys(groupedData).indexOf(user),  
      y: groupedData[user].totalAmount
    }));
    
    const optionss = {
      chart: {
        type: 'line'  
      },
      title: {
        text: 'Ingreso Cancelado por Usuario'
      },
      xAxis: {
        categories: Object.keys(groupedData),
        title: {
          text: 'Usuarios'
        }
      },
      yAxis: {
        title: {
          text: 'Monto Total Cancelado'
        },
        labels: {
          formatter: function() {
            return '$' + this.value.toLocaleString(); 
          }
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>${point.y}</b>'  
      },
      series: [{
        name: 'Total Cancelado',
        data: lineData,
        marker: {
          enabled: true  
        }
      }]
    };
    
    const totalCancelaciones = Object.keys(groupedData).map(user => ({
      user,
      cancelaciones: groupedData[user].count
    }));

    const handleUserSelection = (user) => {
      setSelectedUser(user === "Todos" ? "" : user); 
    };
  
    const filteredPatients = selectedUser ? cancellPatient.filter(patient => patient.user_autorized === selectedUser) : cancellPatient; 
  
    return (
      <Modal {...props} padding={false} closeButton={false}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div className="pt-2"></div>
        {
          !valid_view &&  <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Row className="pt-4">
            <Col sm="5" className="call-chat-sidebar p-r-0" style={{ maxWidth: "420px" }}>
              <CardBody className="chat-body">
                <div className="chat-box">
                  <div className="chat-left-aside">
                    <div className="media" style={{ backgroundColor: "#1f9ad96b", color:"#fff" }}>
                      <div className="">
                        <div className="name f-w-600 f-14" >Listado de Clientes</div>
                        <div className="status"></div>
                      </div>
                    </div>

                    <div className="people-list">
                      <div className="search">
                        <Form className="theme-form">
                          <FormGroup className="form-group">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Buscar empresa"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)} 
                          />                            
                          <i className="fa fa-search"></i>
                          </FormGroup>
                        </Form>
                      </div>
                        {filteredCompanies.length > 0 ? (
                        <ul className="list">
                          {filteredCompanies.map((company, index) => (
                            <li key={company.id_company} onClick={() => handleCompanySelect(company)}>
                              <img
                              src={index % 2 === 0 ? reportCxcCompanyImage : reportCxcCompanyAltImage}
                              className="rounded-circle user-image"
                                alt=""
                              />
                              <div className={`status-circle ${company.amount > 0 ? 'online' : 'offline'}`}></div>
                              <div className="about">
                                <div className="name">{company.nameCompany}</div>
                                <div className="status f-w-600 f-12 txt-danger">
                                  {formatter.format(company?.amount ?? 0)}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <Media className="img-fluid m-auto" alt="No data" />
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Col>
            <Col sm="8" className="call-chat-body p-l-0 p-r-0">
              <CardBody className="p-0">
                <Row className="chat-box">
                  <Col className="pr-0 chat-right-aside p-l-0">
                    <div className="chat">
                      <div className="chat-header clearfix">
                          {selectedCompany &&<Media src={require("../../assets/images/price/reportCxcCompany.svg")} alt="" />}
                          <div className="name f-16 f-w-600 txt-secondary">
                          <Col ms="12" className='p-l-0 p-r-0' >
                                <Row>
                                  <Col sm="9" className='p-l-0 p-r-0' >
                                  {selectedCompany ? selectedCompany.nameCompany : ""}
                                  </Col>
                                  <Col sm="3" className='p-l-0 p-r-0' style={{textAlign:"right"}}  >
                                  {selectedCompany ?  <button onClick={handleDownloadExcel} className="btn btn-primary">
                                    Generar Excel
                                  </button> : ""}
                                  </Col>
                                </Row>
                                </Col>
                          </div>
                          <div className="status digits"></div>
                      </div>

                      <div className="chat-history chat-msg-box custom-scrollbar">
                        {selectedPatients.length > 0 ? (
                          <div>
                           <Table striped size='sm'>
                            <thead>
                              <tr>
                                <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>#</th>
                                <th style={{ backgroundColor: "#6c757d", color: "#fff", fontWeight: "bold" }}>Nim</th>
                                <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Sucursal</th>
                                <th style={{ backgroundColor: "#6c757d", color: "#fff", fontWeight: "bold" }}>Paciente</th>
                                <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Monto</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedPatients.map((_find, _key) => (

                                <tr key={_find?.nimm}>
                                  <th>{_key + 1}</th>
                                  <th> <b className='txt-info pointer' onClick={ () => navigateNim(_find.id_work_order)} >{_find?.nim}</b> </th>
                                  <th>{_find?.branchs}</th>
                                  <th>{_find?.name_patient}</th>
                                  <th>{formatter.format(_find.amount)}</th>
                                </tr>
                              ))}

                              <tr>
                                <td colSpan="4" style={{ textAlign: "right", fontWeight: "bold" }}>Total:</td>
                                <td style={{ fontWeight: "bold" }}>{formatter.format(sumMonto)}</td>
                              </tr>
                            </tbody>
                          </Table>
                          </div>
                        ) : (
                          <div className="chat-history chat-msg-box custom-scrollbar">
                            <HighchartsReact highcharts={Highcharts} options={options} />
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </div>
        }
        {
          valid_view && <div className='pt-4' style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Col sm="12">
            <Row>
              <Col sm="8" className='scroll-container-cancell'>
                <Table striped size='sm'>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>#</th>
                      <th style={{ backgroundColor: "#0079c7", color: "#fff", fontWeight: "bold" }}>Nim</th>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Tipo de ingreso</th>
                      <th style={{ backgroundColor: "#6c757d", color: "#fff", fontWeight: "bold" }}>Nombre del paciente</th>
                      <th style={{ backgroundColor: "#002940", color: "#fff", fontWeight: "bold" }}>Sucursal</th>
                      <th style={{ backgroundColor: "#1f9ad96b", color: "#fff", fontWeight: "bold" }}>Monto</th>
                      <th style={{ backgroundColor: "#1f9ad9", color: "#fff", fontWeight: "bold" }}>Responsable</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPatients.map((_find, _key) => (
                      <tr key={_key}>
                        <th>{_key + 1}</th>
                        <th>{_find?.nim ?? "-----"}</th>
                        <th>{_find?.type ?? "-----"}</th>
                        <th>{_find?.name_patient ?? "-----"}</th>
                        <th>{_find?.branch ?? "------"}</th>
                        <th>{formatter.format(_find?.amount ?? 0)}</th>
                        <th>{_find?.user_autorized ?? "-----"}</th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              <Col sm="4" className=''>
                <div className='pb-3'>
                  <h5>Número de pacientes: <b className='txt-secondary'>{filteredPatients.length}</b></h5>
                  <h5>Total cancelado: <b className='txt-secondary'>{formatter.format(countPatient ?? 0)}</b></h5>
                </div>
                <HighchartsReact highcharts={Highcharts} options={optionss} />
                <div>
                  <ul>
                    {totalCancelaciones.map((data, index) => (
                      <li key={index} onClick={() => handleUserSelection(data?.user)} style={{ cursor: 'pointer', color: selectedUser === data?.user ? 'blue' : 'black' }}>
                        <label className='txt-secondary f-w-500'>{data?.user}:</label> {data?.cancelaciones} cancelaciones
                      </li>
                    ))}
                     <li onClick={() => handleUserSelection("Todos")} style={{ cursor: 'pointer', color: selectedUser === "" ? 'blue' : 'black' }}>
                      <label className='txt-secondary f-w-500'>Todos:</label> Ver todos los registros
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          </div>
        }
        <div style={{ marginTop: "auto" }}>
          <button className="butto-full-modal" onClick={close}>
            <label className="f-20">X</label>
          </button>
        </div>
      </div>
    </Modal>
    )
}


