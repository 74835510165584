import React from 'react'
import Select from 'react-select';
import { Modal, ModalBody, Form, CardBody, Col, Media, Table, Button } from 'reactstrap';
import { PlaceHolderOption } from '../../constant';

export const ModalDevolution = (props) => {

    const {toggleDevolutions, devolutionsState, modalDevolutions, number_format, listReturnReason, handleChangeReturnReason,saveReturnReason,canceldevolution,loadingDevolution} = props;



 
    let versionLayout = localStorage.getItem('layout_version');


     
     return (
         <Modal isOpen={modalDevolutions} toggle={toggleDevolutions} backdrop='static' keyboard={false} centered={true}>
             <Form className="" role="form">
                 <ModalBody>   
                 <CardBody className=" p-l-0 p-b-0 p-r-0">
                    
                          <Media><Media className="img-100 img-fluid m-r-20 rounded-circle" src={require("../../assets/images/typeService.svg")}alt="" />
                                 <Media body>
                                     <h6 className="font-primary f-w-600">Devoluciones</h6> <br /> <span className="d-block"><span><span className="px-2 f-w-600">{"Selecciona un motivo del catalógo"}
                                     <Select
                                        classNamePrefix="select"
                                        name="id_return_reason"
                                        value={devolutionsState.id_return_reason}
                                        options={listReturnReason}
                                        placeholder={PlaceHolderOption}
                                        isClearable={true}
                                        onChange={(e) => handleChangeReturnReason(e)}
                                        theme={
                                            (theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: (versionLayout === 'dark-only') ? '#4c9aff' : "#deebff"
                                                }
                                            })
                                        }
                                    />

                                    </span></span></span>
                                  
                                 
                                 </Media>
                         </Media>
                         <Col sm="12" className='pt-4' >
                         <b className='f-w-600 pb-2' >Listado de devolución</b> <br />
                                    <Table className='table-sm'>
                                <thead>
                                    <tr className='text-center' style={{ backgroundColor: "#002940",color:"#fff" }} >
                                        <th style={{color:"#fff" ,textAlign:"justify"}} >Examen / Perfil</th>
                                        <th style={{color:"#fff" ,textAlign:"right"}} >Total de devolución</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devolutionsState.listTestDevolution.length > 0
                                        ?
                                            devolutionsState.listTestDevolution.map( (_amount) => {
                                                return (
                                                    <tr key={_amount?.id_work_order_exam === null ? _amount?.id_work_order_profile : _amount?.id_work_order_exam}>
                                                        <td>{ _amount?.code+" "+_amount?.name }</td>
                                                        <td className='text-right'> {_amount?.config_iva ?  <b>$ { `${number_format(_amount?.amount, 2)}` }</b> : <b>$ { `${number_format(Math.round(_amount?.amount + _amount?.extra + _amount?.iva), 2)}` }</b>
 }</td>
                                                    </tr>
                                                )
                                            })
                                        :
                                            <tr>
                                                <td className='text-center p-1' colSpan={2}>
                                                    <p>No se ha registrado ninguna devolución</p>
                                                    <p className='text-danger'><i className="fa fa-times-circle"></i></p>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                              
                              
                            </Table>

                                    </Col>
                         <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                             <div className="btngroup">
                                 <Button disabled={loadingDevolution ? true : false} color="danger text-center" type="button" onClick={canceldevolution} >{"Cancelar"}</Button>
                                 <Button disabled={loadingDevolution ? true : false} color="success text-center" type="button" onClick={saveReturnReason} >{"Confirmar"}</Button>  
                             </div> 
                         </Col>
                       
                 </CardBody>   
                 </ModalBody>
             </Form>
         </Modal>
     )
 }
 
