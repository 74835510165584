import React, { useEffect, useState } from 'react';
import { getAllCompanyBusiness } from '../../services/typeService/typeSampleReportSanitary';
import moment from 'moment';
import 'moment/locale/es';
import { useSweetAlert } from "../../hooks/sweetAlerts/useSweetAlert";

export const useSampleReportSanitary = () => {

    //*COMPANY BUSINESS
    const [listCompanyBusiness, setListCompanyBusiness] = useState([]);
    const [createSweet] = useSweetAlert();
    const [formValues, setFormValues] = useState({
        start_date: "",
        end_date: "",
        id_company_business: 0
    });

    const { start_date, end_date, id_company_business } = formValues;

    //*COMPANY BUSINESS
    useEffect(() => {
        GetListCompanyBusiness();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSelectChange = (selectedOption) => {
        setFormValues({
            ...formValues,
            id_company_business: selectedOption ? selectedOption.value : 0
        });
    };

    const GetListCompanyBusiness = async (_id_company_business) => {
        const listBusiness = await getAllCompanyBusiness(_id_company_business);

        if (listBusiness?.length > 0) {
            const list = listBusiness.map(company => ({
                value: company?.id_company,
                label: company?.name
            }));
            setListCompanyBusiness(list);
        }
    };

    const DownloadWorkOrdersByCompany = async () => {
        let mfrom = moment(start_date).format("YYYY-MM-DD");
        let mto = moment(end_date).format("YYYY-MM-DD");
        let _token = localStorage.getItem("token");

        try {
            const response = await fetch(`/api/ReportsSanitary/DownloadWorkOrdersByCompany/${id_company_business}?start_date=${mfrom}&end_date=${mto}`, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + _token,
                    "Content-Type": "application/vnd.ms-excel"
                })
            });

            const responseClone = response.clone();
            const textResponse = await responseClone.text();


            if (!response.ok || textResponse.includes("No se contienen datos para este rango de fechas y empresa.")) {
                createSweet("error", "warning", "Reportes", "No se encuentran datos");
                return;
            }


            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Reporte_Microsanitaria.xlsx");
            document.body.appendChild(link)
;
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error al descargar el archivo:", error);
            createSweet("error", "error", "Reportes", "Ocurrió un error al descargar el reporte.");
        }
    };

    return {
        listCompanyBusiness,
        handleInputChange,
        handleSelectChange,
        GetListCompanyBusiness,
        DownloadWorkOrdersByCompany,
        start_date,
        end_date,
        id_company_business,
    };
};
