export default function FormatNumbers (cantidad, decimals) 
{
    cantidad += '';
    cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

    decimals = decimals || 0;

    if (isNaN(cantidad) || cantidad === 0) {
        return parseFloat(0).toFixed(decimals);
    }

    cantidad = '' + cantidad.toFixed(decimals);

    let cantidad_parts = cantidad.split('.'),
        regexp = /(\d+)(\d{3})/;

    while (regexp.test(cantidad_parts[0]))
        cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

    return cantidad_parts.join('.');
}

export function FormatAmount (amount, decimals)
{
    return parseFloat(
        (Math.trunc(amount * 100000000) / 100000000).toFixed(decimals),
    );
}

export function OperationAmounts(
    quantity,
    total,
    isObjectTax,
    isObjectIeps,
) {
    const objectResponse = {};
  
    let unitaryPrice = total / quantity;
  
    unitaryPrice = parseFloat(
      (Math.trunc(unitaryPrice * 100000000) / 100000000).toFixed(6),
    );
  
    let importP = 0;
    let subtotal = 0;
    let importIeps = 0;
    let importIva = 0;
    let totalp = 0;
  
    // ? REMOVE TAXES
    if (isObjectIeps) {
      unitaryPrice = parseFloat(
        (Math.trunc((unitaryPrice / 1.08) * 100000000) / 100000000).toFixed(6),
      );
    }
  
    if (isObjectTax) {
      unitaryPrice = parseFloat(
        (Math.trunc((unitaryPrice / 1.16) * 100000000) / 100000000).toFixed(6),
      );
    }
  
  
    importP = parseFloat(
      (Math.trunc(unitaryPrice * quantity * 100000000) / 100000000).toFixed(6),
    );
    subtotal = importP;
  
    if (isObjectIeps) {
      importIeps = parseFloat(
        (Math.trunc(subtotal * 0.08 * 100000000) / 100000000).toFixed(6),
      );
  
      subtotal = parseFloat(
        (Math.trunc((subtotal + importIeps) * 100000000) / 100000000).toFixed(6),
      );
    }
  
    if (isObjectTax) {
      importIva = parseFloat(
        (Math.trunc(subtotal * 0.16 * 100000000) / 100000000).toFixed(6),
      );
    }
  
    totalp = parseFloat(
      (Math.trunc((subtotal + importIva) * 100000000) / 100000000).toFixed(6),
    );
  
    objectResponse.unitary_price = unitaryPrice;
    objectResponse.import = importP;
    objectResponse.subtotal = subtotal;
    objectResponse.total_ieps = importIeps;
    objectResponse.total_iva = importIva;
    objectResponse.total = totalp;
  
    return objectResponse;
}

export function OperationsAmountCreditNote (
  quantity,
  total,
  isObjectRetentions,
  isObjectTax,
  decimalTax = 0.16,
  decimals = 6
) {
  const objectResponse = {};
  const multiplierDecimalTax = 1 + decimalTax;

  let unitaryPrice = total / quantity;
  let importe = 0;
  let subtotal = 0;
  let importIva = 0;
  let importRetention = 0;
  let totalO = 0;  

  unitaryPrice = parseFloat(
    (Math.trunc(unitaryPrice * 100000000) / 100000000).toFixed(decimals)  
  );

  if (isObjectTax)
  {
    unitaryPrice = parseFloat(
      (Math.trunc((unitaryPrice / multiplierDecimalTax) * 100000000) / 100000000).toFixed(decimals)
    );

    importe = parseFloat(
      (Math.trunc((unitaryPrice * quantity) * 100000000) / 100000000).toFixed(decimals)
    );

    subtotal = importe;

    if (isObjectTax)
      importIva = parseFloat(
        (Math.trunc(( subtotal * decimalTax) * 100000000) / 100000000).toFixed(decimals)
      );

    totalO = parseFloat(
      (Math.trunc((subtotal + importIva) * 100000000) / 100000000).toFixed(decimals)
    );

    if (isObjectRetentions)
      importRetention = parseFloat(
        (Math.trunc((totalO  * 0.0125) * 100000000) / 100000000).toFixed(decimals)
      );
  }


  objectResponse.unitary_price = unitaryPrice;
  objectResponse.import = importe;
  objectResponse.subtotal = subtotal;
  objectResponse.total_iva = importIva;
  objectResponse.total_retentions = importRetention;
  objectResponse.total = totalO;

  return objectResponse;
} 
