import React from 'react'
import { Col, Modal, ModalBody, Row,ModalFooter,Button,ModalHeader} from 'reactstrap';
import 'animate.css';



export const ModalPreviewInvoicePatient = (props) => {
    
    const { loadingPreviewInvoicesP, showPreviewInvoicesPatient, handlePreviewPatientGlobal,handleCancelPatient,saveInvoicesPatient,loadingGlobalPatient } = props;

    return (
        <Modal isOpen={showPreviewInvoicesPatient} toggle={handlePreviewPatientGlobal} centered={true} size={loadingPreviewInvoicesP ? 'sm' : 'lg'} scrollable={true}>
             {!loadingPreviewInvoicesP &&<ModalHeader className='bg-danger'>
               Factura Paciente particular
            </ModalHeader>}
            <ModalBody>
                {
                    loadingPreviewInvoicesP && (
                        <>
                            <Row>
                                <Col sm="12" md="12" lg="12" className='text-center'>
                                    <img alt='modal-preview' src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "20%" }} />
                                </Col>
                                <Col sm="12" md="12" lg="12" className='text-center mt-1'>
                                    <p>Cargando ...</p>
                                </Col>
                            </Row>
                        </>
                    )
                }
                {!loadingPreviewInvoicesP && <Col sm="12" style={{textAlign:"center"}} className='p-l-0  p-r-0' >
                <img alt='modal-preview' src={require("../../assets/images/price/warnig-invoices.svg")}style={{ width: "15%" }} /> <br />
                <label className='f-14 f-w-600 txt-danger pt-1' >Esta seguro que estos nims, se facturaran a nombre del paciente</label>
                </Col>}
            </ModalBody>  
            {!loadingPreviewInvoicesP &&<ModalFooter>
            <Col sm="12">
            <Row>
                <Col sm="8">
                </Col>
                <Col sm="2" style={{textAlign: "end"}} >
                    <Button disabled={loadingGlobalPatient ? true : false} outline color="primary" onClick={ () => saveInvoicesPatient()} >Aceptar</Button>
                </Col>
                <Col sm="2">
                    <Button disabled={loadingGlobalPatient ? true : false} outline color="danger" onClick={() => handleCancelPatient()}   >Cancelar</Button>
                </Col>
            </Row>
            </Col>
            </ModalFooter>}

        </Modal>
            )
        }
