import React, { Fragment } from 'react';
import { Container, Col, Row, Card, CardBody, Button, Input, Form, FormGroup } from 'reactstrap';
import { TableStriped } from '../../../../components/tables/TableStriped';
import Breadcrumb from '../../../../layout/breadcrumb'
import { useExamMicrobiology } from '../../../../hooks/micro/useExamMicrobiology';
import { ModalExamMicrobiology } from '../../../../components/examsMicrobiology/ModalExamMicrobiology';
import { ModalIndicationExamMicrobiology } from '../../../../components/examsMicrobiology/ModalIndicationExamMicrobiology';
import { ModalReleaseExamMicrobiology } from '../../../../components/examsMicrobiology/ModalReleaseExamMicrobiology';



export const IndexExamsMicrobiology = () => {
    const {
        exams, method, handleInputChange, validaciones,
        sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
        id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced, listExternalLaboratories, is_critic,
        toggle, modal, modalTitle, loading, examId, getUpdateDefaultExternalLaboratory,
        changeSelect, handleSelectValues,
        handleCreate, saveCreate, saveUpdate,
        handleDelete, saveDelete,
        navigateCreateOrUpdateAnalytes,
        navigateConfigExam, navigateCostProduction, navigateCreateExamSimple, navigateEditExamSimple,
        handleChangePrice,
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages,
        formValues, handleSearch,
        //INDICATION
        toggle2, modal2, handleListProfile, listIndications, indication, handleChangeIndication,
        id_exam_indication, indicationn, listIndication, selectIndication, removeExamProfile,
        CreateOrUpdateExamIndications,
        handleGetValidReleaseExam, releaseExam, modalReleaseExam, toggleRelease, loadingReleaseExam,
        saveUpdateStatusComplete,
        //filter
        is_referencedf, is_simplef, individual_salef, is_criticf, is_completef,
        handleInputChangeFilter, handleFilters, id_sectionf, listSection, handleChangeSection, clearFilter,
        handleSelectValuesIndication, dataIndication,
        //IMAGENOLOGIA
        methodImagenolofia, handleCreateExamImagenologia, titleImagenologia, modalImagenologia, toggleImagenologia,
        //nuevo_campo,nuevo_campo2
        sections2, number_projections, enable_analyte_configuration, handleUpdateImagenology,
        isImagenologyy, examEditIm, navigateMicroAnalytes,use_antibiogram, print_new_page

    } = useExamMicrobiology();
        console.log("🚀 ~ IndexExamsMicrobiology ~ subSections:", subSections)

    ////console.log(use_antibiogram);

   

    return (
        <Fragment>
            <Container fluid={true} className="p-4 p-l-0 p-r-0">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de examenes
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    {/* <Button className="btn-pill btn-air-secondary" outline color="secondary" onClick={navigateCreateExamSimple}><span className="fa fa-plus-circle"></span> Examen sencillo</Button> */}
                                    <Button className="btn-pill btn-air-secondary" outline color="secondary" onClick={handleCreate}><span className="fa fa-plus-circle"></span> Examen de Microbiologia</Button>
                                    {/* <Button className="btn-pill btn-air-secondary" outline color="secondary" onClick={handleCreateExamImagenologia}  ><span className="icofont icofont-heartbeat"></span> Examen Imagenología</Button> */}
                                    <br />  <br />
                                    <Row>
                                        <Col sm="8"></Col>
                                        <Col sm="4">
                                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                                        </Col>
                                    </Row>
                                </div> <br />
                                <div className="table-responsive">
                                    <TableStriped
                                        responsive={true}
                                        notMaped={["alias", "name_subsection", "visibleTable", "id_section", "id_subsection", "id_specimen", "id_specimen_provenance", "id_specimen_origin", "is_referenced", "is_critic", "listExternalLaboratories",
                                            "select_specimen_provenance", "select_transports", "select_specimen", "id_info_analyte", "id_app_code_method_default", "name_method_default", "is_simple", "validation", "is_curve", "name_specimen_provenance",
                                            "enable_analyte_configuration", "number_projections", "is_imagenology", "is_micro",
                                            "individual_sale", "name_section", "name_specimen", "delivery_time", "use_antibiogram","print_new_page","listExternalLaboratories","alias_sticker","external_laboratory_info"]}
                                        methodsActions={true}
                                        methodsModal={false}
                                        methodActiveDropdown={false}
                                        cabeceras={["Clave", "Nombre", "Abreviatura", "Exámen Terminado", "Tipo de Exámen",]}
                                        items={exams}
                                        {...{
                                            handleDelete
                                        }}

                                        methodsAbsolutes={
                                            [
                                                {
                                                    type: "linkId",
                                                    method: navigateConfigExam,
                                                    icon: "fa fa-cogs",
                                                    backgroundColor: "#70cbe8",
                                                    color: "#fff",
                                                    name: "navigateConfigExam",
                                                    tooltip: "Método del Exámen"
                                                },
                                                {
                                                    type: "linkId",
                                                    method: navigateMicroAnalytes,
                                                    icon: "fa fa-cog",
                                                    backgroundColor: "#0ba1d6",
                                                    color: "#fff",
                                                    name: "navigateMicroAnalytes",
                                                    tooltip: "Agregar Análito"
                                                },
                                                {
                                                    type: "linkId",
                                                    method: navigateCostProduction,
                                                    icon: "icofont icofont-cur-dollar-plus",
                                                    backgroundColor: "#ecde03",
                                                    color: "#fff",
                                                    name: "navigateCostProduction",
                                                    tooltip: "Costo de Producción"

                                                },
                                                {
                                                    type: "linkId",
                                                    method: handleGetValidReleaseExam,
                                                    icon: "fa fa-check",
                                                    backgroundColor: "#01839d",
                                                    color: "#fff",
                                                    name: "navigateCompleteExam",
                                                    tooltip: "Liberar examen"
                                                },
                                                {
                                                    type: "linkId",
                                                    method: navigateEditExamSimple,
                                                    icon: "fa fa-edit",
                                                    backgroundColor: "#1875c5",
                                                    color: "#fff",
                                                    name: "navigateEditExamSimple",
                                                    tooltip: "Editar Exámen"
                                                },
                                                {
                                                    type: "linkId",
                                                    method: handleListProfile,
                                                    icon: "icofont icofont-prescription",
                                                    backgroundColor: "#016392",
                                                    color: "#fff",
                                                    tooltip: "Indicaciones"
                                                },

                                            ]
                                        }
                                        nextPage={goNextPage}
                                        previousPage={goPreviousPage}
                                        totalPageCount={totalPages}
                                        currentPage={actualPage}
                                        goToPage={goToPage}
                                        paginingBack={true}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalExamMicrobiology
                {
                ...{
                    formValues, method, handleInputChange, validaciones, handleSelectValues,
                    sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
                    id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced,
                    toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, is_critic,
                    changeSelect,
                    saveCreate,
                    saveUpdate,
                    saveDelete,
                    handleChangePrice, isImagenologyy,use_antibiogram, print_new_page

                }
                }
            />

            <ModalIndicationExamMicrobiology
                {
                ...{
                    toggle2, modal2, modalTitle, loading, method, listIndications, indication, handleChangeIndication,
                    id_exam_indication, indicationn, listIndication, selectIndication, removeExamProfile,
                    CreateOrUpdateExamIndications,
                    handleSelectValuesIndication, dataIndication
                }
                }
            />

            <ModalReleaseExamMicrobiology
                {
                ...{
                    releaseExam, modalReleaseExam, toggleRelease, loadingReleaseExam,
                    saveUpdateStatusComplete
                }
                }
            />

        </Fragment >
    )
}