import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import moment from 'moment';
import { ConfigServer } from "../../data/config";

export const useCaptureResults = () => {
    const { nim } = useParams();
    const history = useHistory();

    let id_branch = localStorage.getItem('branchId');
    let enable_interface_imagenology = localStorage.getItem('enable_interface_imagenology');
    enable_interface_imagenology = (enable_interface_imagenology == "true" ? true : false);

    const [createSweet] = useSweetAlert();

    const [profiles, setProfiles] = useState([]);
    const [sections, setSections] = useState([]);
    const [patient, setPatient] = useState({});
    const [observations, setObservations] = useState("");
    const [commentsFlebo, setCommentsFlebo] = useState("");
    const [analytes, setAnalytes] = useState([]);
    const [workOrderId, setWorkOrderId] = useState(0);
    const [type_file, setType_file] = useState("");
    const [workMedicalOrderPDF, setworkMedicalOrderPDF] = useState("");
    const [workMedicalOrder, setWorkMedicalOrder] = useState("");
    const [analytesComplex, setAnalytesComplex] = useState([]);
    const [printAvailable, setPrintAvailable] = useState(false);
    const [dobleValidacion, setDobleValidacion] = useState(false);
    const [resultsPartial, setResultsPartial] = useState(false);
    const [idp, setIdp] = useState(0)
    const [commentsPrintResult, setCommentsPrintResult] = useState("");
    const [funcionesFormulas, setFuncionesFormulas] = useState([]);

    const nameUser = localStorage.getItem("nameUser");
    const userSections = localStorage.getItem("sections");
    const userFilterSections = localStorage.getItem("filter_sections");
    const [nimPatient, setNimPatient] = useState([]);


    const [microBacteriums, setMicroBacteriums] = useState([]);
    const [microAntibiograms, setMicroAntibiograms] = useState([]);


    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle1, setModalTitle1] = useState("");
    const [typeModal, setTypeModal] = useState("view")
  


    //!CONFIGURATION NAVIGATE VIEW
    const [backNim, setBackNim] = useState(null);
    const [nextNim, setNextNim] = useState(null);
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);




    const toggle = () => {
        setModal(!modal);
    }
    const handleCreate = () => {

        setTypeModal("view");
        setModalTitle1("Orden Medica")
        toggle();

    }
    const getViewPreview = () => {

        setTypeModal("view");
        toggle();

    }


    const [modalRelease, setModalRelease] = useState(false);
    const [loadingRelease, setLoadingRelease] = useState(false);

    const toggleRelease = () => {
        setLoadingRelease(false);
        setModalRelease(!modalRelease);
    }

    const [modalViewImagenology, setModalViewImagenology] = useState(false);
    const [modalTitleImg, setModalTitleImg] = useState("");
    const [documentImagenology, setDocumentImagenology] = useState("");

    const toggleModalViewImagenology = () => {
        setModalViewImagenology(!modalViewImagenology);
    }

    const handleGetDocumentImagenology = async (id_exam, id_work_order_exam) => {

        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/Exam/DownloadResultsImagenology/" + id_exam + "/" + id_work_order_exam, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el documento");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                let objectUrl = window.URL.createObjectURL(blob);

                setDocumentImagenology(objectUrl);
                toggleModalViewImagenology();
                setModalTitleImg("visualizar documento");
            })
    }

    const getFuncionesFormula = async () => {
        let requestOptions = {
            method: 'GET',
        };
        const respuesta = await sendRequest(requestOptions, 'Exam/GetFuncionesFormula');
        if (respuesta.code === 200) {
            // ////console.log(respuesta.data);
            setFuncionesFormulas(respuesta.data)
            return respuesta.data;
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getFuncionesFormula);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }

    // ////console.log(userSections);



    const getData = async (funcionesFormulasAux = []) => {

        setLoadingPatient(false);

        let nuevas_secciones = JSON.parse(userSections);
        let filtro_secciones = JSON.parse(userFilterSections);

        nuevas_secciones = nuevas_secciones.filter(x => {
            if (filtro_secciones.find(y => y.id_section === x)) {
                return x
            }
        })

        let payload = {
            "nim": nim,
            "sections_array": nuevas_secciones,
            "id_branch": id_branch
        }

        let data = JSON.stringify(payload);

        let requestOptions = {
            method: 'POST',
            body: data

        };

        const respuesta = await sendRequest(requestOptions, "CaptureResult/GetCaptureResults");
        setLoadingPatient(true);
        if (respuesta.code === 200) {
            // ////console.log(respuesta);
            ////console.log(respuesta.data, "Esto es lo que viene");
            if (respuesta.data.observations !== null) {
                setObservations(respuesta.data.observations);
            }

            if (respuesta.data.comments !== null) {
                setCommentsFlebo(respuesta.data.comments);
            }

            setCommentsPrintResult(respuesta.data.comments_print_result);
            setNimPatient(respuesta.data.nim)

            let validacion_impresion = true;
            let secciones = [];
            setWorkOrderId(respuesta.data.id_work_order);
            setWorkMedicalOrder(respuesta.data.medical_order);
            setType_file(respuesta.data.type_file);
            // setMedicalOrder(respuesta.data.medical_order);
            // ////console.log(workMedicalOrder);
            setIdp(respuesta.data.patient === null ? 0 : respuesta.data.patient.id_patient)

            if (respuesta.data.exams.length > 0) {
                respuesta.data.exams.forEach(exam => {
                    let seccion = secciones.find(x => x.id === exam.id_section);
                    if (seccion === undefined) {
                        secciones.push({
                            id: exam.id_section,
                            name: exam.name_section,
                            exams: [exam],
                            profiles: []
                        });
                    } else {
                        secciones.map(obj => {
                            if (obj.id === exam.id_section) {
                                obj.exams = [
                                    ...obj.exams,
                                    exam
                                ]
                            }
                            return obj;
                        })
                    }
                });
            }
            secciones.map(x => {
                x.exams = x.exams.sort((a, b) => a.id_mic_detail_sample - b.id_mic_detail_sample);
                return x;
            })
            // ////console.log(secciones);
            setSections(secciones);
            let cumpleanios = respuesta.data.patient === null ? "" : new Date(respuesta.data.patient.birthday);
            let age = respuesta.data.patient === null ? 1 : calculateAge(cumpleanios);
            // ////console.log(respuesta.data.patient, age, "quie da por aca");
            let format_age = "";
            let days_age = 0;
            if (age <= 0) {
                format_age = calculateAgeMonths(cumpleanios);
                let fecha1 = moment(respuesta.data.patient.birthday);
                let fecha2 = moment();
                days_age = fecha2.diff(fecha1, 'days');
            }

            let paciente = {
                ...respuesta.data.patient,
                age: age,
                format_age: format_age,
                days_age: days_age
            }
            // ////console.log(paciente, "PACIENTE--");
            setPatient(paciente);

            let analitos = [];
            let microbacterias = [];
            let microantibiogramas = [];

            if (respuesta.data.profiles.length > 0) {
                // ////console.log(respuesta.data.profiles);
                respuesta.data.profiles.forEach(profile => {
                    let readyToValidateProfile = true;
                    let readyToReleaseProfile = true;
                    profile.exams.forEach(exam => {

                        if (!enable_interface_imagenology && exam.is_imagenology) {
                            if (exam.imagenology !== null) {
                                if (exam.imagenology.isCaptured === false) {
                                    readyToValidateProfile = false;
                                }
                                if (exam.imagenology.userValidate === null) {
                                    readyToReleaseProfile = false
                                }
                            }
                        }

                        exam.analytes !== null && exam.analytes.forEach(element => {
                            if (element.required) {
                                if (element.isCaptured === false) {
                                    readyToValidateProfile = false;
                                }
                                if (element.userValidate === null) {
                                    readyToReleaseProfile = false;
                                }
                            }
                        });
                        // console.group("Datos")
                        // ////console.log(exam, "datos del examen-----------");
                        if (!exam.isRelease) {
                            validacion_impresion = false;
                        }

                        let exam_method = "";
                        let id_exam_method = "";
                        let id_method = "";
                        if (exam.method_selected > 0) {
                            exam_method = exam.method_selected;
                        } else {
                        }

                        if (!exam.is_imagenology) {
                            try {
                                id_exam_method = exam.methods[0].id_exam_method;
                                id_method = exam.methods[0].id_method;
                            } catch (error) {
                                //console.log('El metodo ha sido eliminado', exam.methods)
                            }
                        }

                        exam.analytes.forEach(analyte => {
                            // ////console.log(analyte);

                            if (analyte.id_type_analyte === 7) {
                                // ////console.log("{asa?")
                                getDataAnalyteComplex(analyte.id_analyte, funcionesFormulasAux);//gato
                                //chartCaptureresul(analyte.id_analyte)                               
                            }

                            if (analyte.compute !== "" && analyte.id_type_analyte === 7) {
                                let formula = analyte.compute;
                                if (funcionesFormulasAux.length > 0) {
                                    funcionesFormulasAux.forEach(form => {
                                        let form_js = form.toLowerCase();
                                        for (let index = 0; index < 5; index++) {
                                            formula = formula.replace(form, "Math." + form_js);
                                        }
                                    })
                                } else {
                                    funcionesFormulas.forEach(form => {
                                        let form_js = form.toLowerCase();
                                        for (let index = 0; index < 5; index++) {
                                            formula = formula.replace(form, "Math." + form_js);
                                        }
                                    })
                                }
                                // ////console.log(formula);
                                analyte.compute = formula;
                            }

                            let valor_nuevo = "";
                            let nuevo_comentario = '';
                            let print_chart = false;
                            let is_interface = false;
                            let is_file = false;
                            let file_base64 = "";
                            if (analyte.capture !== null) {
                                if (analyte.id_type_analyte === 1 || analyte.id_type_analyte === 6 || analyte.id_type_analyte === 14) {

                                    valor_nuevo = analyte.capture.text;

                                    if (analyte.id_type_analyte === 14) {
                                        is_file = true;
                                        file_base64 = analyte.capture.file_base64;
                                    }

                                } else {
                                    valor_nuevo = analyte.capture.number;
                                }
                                is_interface = analyte.capture.is_interface;
                                nuevo_comentario = analyte.capture.comments;
                                print_chart = analyte.capture.print_chart;
                            }

                            if (analyte.id_type_analyte === 14) {
                                is_file = true;
                            }

                            if (profile.curves !== null && profile.curves !== undefined && profile.curves.length > 0) {
                                profile.curves.forEach((curva, llaveCurva) => {
                                    if (analyte.sub_id === curva.time.value) {
                                        analitos.push({
                                            "id": analyte.id_analyte,
                                            "sub_id": curva.time.value,
                                            "capture": valor_nuevo,
                                            "send": analyte.send,
                                            "id_exam": exam.id_exam,
                                            "id_type_analyte": analyte.id_type_analyte,
                                            "id_exam_method": exam_method,
                                            "id_exam_method_main": id_method,
                                            "id_app_code_or_method_exam": id_exam_method,
                                            "comment": nuevo_comentario,
                                            "id_work_order_exam": exam.id_work_order_exam,
                                            "is_exam": false,
                                            "is_interface": is_interface,
                                            "integers": analyte.integers,
                                            "decimals": analyte.decimals,
                                            "id_profile": profile.id_profile,
                                            "file_base64": analyte.file_base64,
                                            "is_file": analyte.is_file,
                                            "print_chart": print_chart,
                                            "is_captured": analyte.isCaptured,
                                            // "id_profile" : 
                                        });
                                        analyte.id_analyte_references = null;

                                    }
                                });
                            } else {
                                // ////console.log(analyte, "[asa?---")
                                let dataMeter = {
                                    "id": analyte.id_analyte,
                                    "capture": valor_nuevo,
                                    "send": analyte.send,
                                    "id_exam": exam.id_exam,
                                    "id_type_analyte": analyte.id_type_analyte,
                                    "id_exam_method": exam_method,
                                    "id_exam_method_main": id_method,
                                    "id_app_code_or_method_exam": id_exam_method,
                                    "comment": nuevo_comentario,
                                    "id_work_order_exam": exam.id_work_order_exam,
                                    "is_exam": false,
                                    "id_profile": profile.id_profile,
                                    "id_section": exam.id_section,
                                    "is_interface": is_interface,
                                    "integers": analyte.integers,
                                    "decimals": analyte.decimals,
                                    "is_file": is_file,
                                    "file_base64": file_base64,
                                    "print_chart": print_chart,
                                    "is_captured": analyte.isCaptured
                                };
                                analyte.id_analyte_references = null;
                                analitos.push(dataMeter);
                            }
                        })


                        //BACTERIAS Y ANTIBIOGRAMAS
                        if (exam.bacteriums !== undefined && exam.bacteriums !== null) {
                            exam.bacteriums.forEach(bacteria => {
                                //REGRESAR
                                let nuevo = {
                                    "id_temp": null,
                                    "gram": bacteria.gram,
                                    "id_bacterium": bacteria.id_mic_bacterium,
                                    "cantidad_unidades": bacteria.cantidad_numero,
                                    "cantidad_texto": bacteria.cantidad_texto,
                                    "id_mic_identification_test": bacteria.id_mic_identification_test,
                                    "id_work_order_exam": exam.id_work_order_exam,
                                    "label": bacteria.name_bacterium,
                                    "value": bacteria.id_mic_bacterium
                                };

                                if (bacteria.antibiogram !== "") {

                                    let antibiogramData = JSON.parse(bacteria.antibiogram);

                                    let antibiogram = {
                                        "id_temp": null,
                                        "id_mic_bacterium": bacteria.id_mic_bacterium,
                                        "id_mic_antibiogram": antibiogramData.id_mic_antibiogram,
                                        "antibiotics": antibiogramData.antibiotics,
                                        "id_work_order_exam": exam.id_work_order_exam
                                    }

                                    microantibiogramas.push(antibiogram);
                                }
                                microbacterias.push(nuevo);
                            });
                        }
                    });
                    profile.readyToValidateProfile = readyToValidateProfile;
                    profile.readyToReleaseProfile = readyToReleaseProfile;
                    profile.id_work_order = respuesta.data.id_work_order;
                });
                // ////console.log(respuesta.data.profiles, "PERFILES");
                setProfiles(respuesta.data.profiles);

            }

            secciones.forEach(seccion => {
                seccion.exams.forEach(exam => {
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    let exam_method = "";
                    let id_exam_method = "";
                    let id_method = "";
                    if (exam.method_selected > 0) {
                        exam_method = exam.method_selected;
                    } else {
                    }

                    if (!exam.is_imagenology) {
                        try {
                            id_exam_method = exam.methods[0].id_exam_method;
                            id_method = exam.methods[0].id_method;
                        } catch (error) {
                            //console.log('El metodo ha sido eliminado', exam.methods)
                        }
                    }

                    exam.analytes !== null && exam.analytes.forEach(analyte => {
                        if (analyte.id_type_analyte === 7) {
                            getDataAnalyteComplex(analyte.id_analyte, funcionesFormulasAux);
                            //chartCaptureresul(analyte.id_analyte)
                        }

                        let valor_nuevo = "";
                        let nuevo_comentario = '';
                        let print_chart = false;
                        let is_interface = false;
                        let is_file = false;
                        let file_base64 = "";

                        if (analyte.capture !== null) {
                            if (analyte.id_type_analyte === 1 || analyte.id_type_analyte === 6 || analyte.id_type_analyte === 14) {

                                valor_nuevo = analyte.capture.text;

                                if (analyte.id_type_analyte === 14) {
                                    is_file = true;
                                    file_base64 = analyte.capture.file_base64;
                                }

                            } else {
                                valor_nuevo = analyte.capture.number;
                            }
                            is_interface = analyte.capture.is_interface;
                            nuevo_comentario = analyte.capture.comments;
                            print_chart = analyte.capture.print_chart;
                        }

                        if (analyte.id_type_analyte === 14) {
                            is_file = true;
                        }
                        if (analyte.compute !== "" && analyte.id_type_analyte === 7) {
                            let formula = analyte.compute;
                            if (funcionesFormulasAux.length > 0) {
                                funcionesFormulasAux.forEach(form => {
                                    let form_js = form.toLowerCase();
                                    for (let index = 0; index < 5; index++) {
                                        formula = formula.replace(form, "Math." + form_js);
                                    }
                                })
                            } else {
                                funcionesFormulas.forEach(form => {
                                    let form_js = form.toLowerCase();
                                    for (let index = 0; index < 5; index++) {
                                        formula = formula.replace(form, "Math." + form_js);
                                    }
                                })
                            }
                            // ////console.log(formula);
                            analyte.compute = formula;
                        }
                        analitos.push({
                            "id": analyte.id_analyte,
                            "capture": valor_nuevo,
                            "send": analyte.send,
                            "id_exam": exam.id_exam,
                            "id_type_analyte": analyte.id_type_analyte,
                            "id_exam_method": exam_method,
                            "id_exam_method_main": id_method,
                            "id_app_code_or_method_exam": id_exam_method,
                            "decimals": analyte.decimals,
                            "compute": analyte.compute,
                            "variable": (analyte.id_analyte_references !== null ? exam.abbreviation.replaceAll(" ", "").replace(/[^a-zA-Z0-9]/g, '').toLowerCase() : exam.abbreviation.replaceAll(" ", "").replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) + "_" + analyte.code.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                            // "variable": (analyte.id_analyte_references !== null ? analyte.abbreviation_exam_reference.replaceAll(" ", "").replace(/[^a-zA-Z0-9]/g, '').toLowerCase() : exam.abbreviation.replaceAll(" ", "").replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) + "_" + analyte.code.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                            "comment": nuevo_comentario,
                            "id_work_order_exam": exam.id_work_order_exam,
                            "is_exam": true,
                            "id_section": exam.id_section,
                            "is_interface": is_interface,
                            "integers": analyte.integers,
                            "decimals": analyte.decimals,
                            "is_file": is_file,
                            "file_base64": file_base64,
                            "print_chart": print_chart,
                            "is_captured": analyte.isCaptured
                        });
                        analyte.id_analyte_references = null;
                        // debugger
                        // ////console.log(analitos);
                    })

                    //BACTERIAS Y ANTIBIOGRAMAS
                    if (exam.bacteriums !== undefined && exam.bacteriums !== null) {
                        exam.bacteriums.forEach(bacteria => {
                            //REGRESAR
                            let nuevo = {
                                "id_temp": null,
                                "gram": bacteria.gram,
                                "id_bacterium": bacteria.id_mic_bacterium,
                                "cantidad_unidades": bacteria.cantidad_numero,
                                "cantidad_texto": bacteria.cantidad_texto,
                                "id_mic_identification_test": bacteria.id_mic_identification_test,
                                "id_work_order_exam": exam.id_work_order_exam,
                                "label": bacteria.name_bacterium,
                                "value": bacteria.id_mic_bacterium
                            };

                            if (bacteria.antibiogram !== "") {

                                let antibiogramData = JSON.parse(bacteria.antibiogram);

                                let antibiogram = {
                                    "id_temp": null,
                                    "id_mic_bacterium": bacteria.id_mic_bacterium,
                                    "id_mic_antibiogram": antibiogramData.id_mic_antibiogram,
                                    "antibiotics": antibiogramData.antibiotics,
                                    "id_work_order_exam": exam.id_work_order_exam,
                                }

                                microantibiogramas.push(antibiogram);
                            }
                            microbacterias.push(nuevo);
                        });
                    }
                })
            });
            // debugger
            setAnalytes([...analitos]);
            setMicroBacteriums([...microbacterias]);
            setMicroAntibiograms([...microantibiogramas])
            setDobleValidacion(respuesta.data.doble_validacion);
            setPrintAvailable(respuesta.data.is_released);
            setResultsPartial(respuesta.data.results_partial);

        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getData);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
  
    }
    // ////console.log(profiles, "-=--asdas");
    const getDataAnalyteComplex = async (id_analyte, funcionesFormulasAux = []) => {
        // ////console.log("Cuantas veces pasa?", id_analyte);
        // Exam/GetDataAnalyteComplex/1025
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/GetDataAnalyteComplex/' + id_analyte);

        if (respuesta.code === 200) {
            let aux = analytesComplex;
            let datos = respuesta.data;
            let formula = respuesta.data.formula;
            if (funcionesFormulasAux.length > 0) {
                funcionesFormulasAux.forEach(form => {
                    let form_js = form.toLowerCase();
                    formula = formula.replace(form, "Math." + form_js);
                })
            } else {
                funcionesFormulas.forEach(form => {
                    let form_js = form.toLowerCase();
                    formula = formula.replace(form, "Math." + form_js);
                })
            }
            datos.formula = formula;
            aux.push(datos);
            setAnalytesComplex([...aux]);
            // createSweet("success", "success", "Se ha liberado el examen");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getDataAnalyteComplex);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
      
    }

    const saveIndividualAnalyte = async (obj) => {
        let analitos = analytes;
 
        analitos.forEach(element => {
            if (element.variable !== undefined) {
                if (element.id === obj.id_analyte) {
                    window[element.variable] = obj.valor !== "" && parseFloat(obj.valor);
                } else {
                    window[element.variable] = element.capture !== "" && parseFloat(element.capture);
                }
            }
        });
        analitos.map(x => {
         
            if (x.id === obj.id_analyte && x.id_work_order_exam === obj.id_work_order_exam) {
                if (x.sub_id !== undefined) {
                    if (x.sub_id === obj.sub_id) {
                        x.capture = obj.valor;
                        x.id_exam_method = obj.id_exam_method;
                        x.id_exam_method_main = obj.id_exam_method_main;
                        x.send = obj.send;
                        x.id_app_code_or_method_exam = obj.id_app_code_or_method_exam;
                        x.validate_format = obj.validate_format;
                        x.is_file = obj.is_file;
                        x.id_analyte_age_gender_range = obj.id_analyte_age_gender_range;
                        x.id_analyte_number_alert = obj.id_analyte_number_alert;
                        x.ids_analyte_text_alerts = obj.ids_analyte_text_alerts;
                    }
                } else {
                    x.capture = obj.valor;
                    x.id_exam_method = obj.id_exam_method;
                    x.id_exam_method_main = obj.id_exam_method_main;
                    x.send = obj.send;
                    x.id_app_code_or_method_exam = obj.id_app_code_or_method_exam;
                    x.validate_format = obj.validate_format;
                    x.is_file = obj.is_file;
                    x.id_analyte_age_gender_range = obj.id_analyte_age_gender_range;
                    x.id_analyte_number_alert = obj.id_analyte_number_alert;
                    x.ids_analyte_text_alerts = obj.ids_analyte_text_alerts;
                }
            }

            if (x.compute !== "" && x.id_type_analyte === 7) {
                let resultado = "";
                if (x.is_interface) {
                    resultado = parseFloat(x.capture);
                }
                else {
                    resultado = eval(x.compute);
                }

                if (resultado !== "") {
                    if (!isNaN(resultado)) {
                        if (isFinite(resultado)) {
                            if (resultado === 0) {
                                x.capture = resultado;
                               
                            } else {
                              
                                try {

                                    x.capture = resultado.toFixed(x.decimals);
                                } catch (error) {
                                    
                                }
                                
                            }
                        }
                    }
                }
            }
            return x;
        })
        // ////console.log(analitos);

        // ////console.log(analytesComplex, "analitos complex");

        analytesComplex.forEach(element => {
            let variables_id = Object.keys(element.list_analytes);
            if (variables_id.find(x => x == obj.id_analyte) !== undefined) {
                let resultado = '';
                Object.entries(element.list_analytes).forEach(x => {
                    let valor = "";
                    if (obj.id_analyte == x[0]) {
                        valor = parseFloat(obj.valor);
                    } else {
                        let analyte_aux = analitos.find(q => q.id == x[0]);
                        
                        let aux = "";

                        try {
                            aux = analyte_aux.capture;
                        } catch (error) {
                            console.log(analyte_aux);
                            aux = "";                    
                        }

                        if (aux !== "") {
                            if (!isNaN(aux)) {
                                if (isFinite(aux)) {
                                    if (aux === 0) {
                                        valor = aux;
                                    } else {
                                        valor = parseFloat(aux);
                                    }
                                }
                            }
                        }
                    }
                    window[x[1]] = valor;
                });
                let resultado_final = "";
                let analito_final = analitos.find(a => a.id == element.id_analyte);
                funcionesFormulas.forEach(form => {
                    let form_js = form.toLowerCase();
                    for (let index = 0; index < 5; index++) {
                        element.formula = element.formula.replace(form, "Math." + form_js);
                    }
                })
                // ////console.log(element.formula)
                resultado = eval(element.formula);
              
                if (resultado !== "") {
                    if (!isNaN(resultado)) {
                        if (isFinite(resultado)) {
                            resultado = parseFloat(resultado);
                            if (resultado === 0) {
                                resultado_final = resultado;
                            } else {
                                resultado_final = resultado.toFixed(analito_final.decimals);
                            }
                        }
                    }
                }
                analitos.map(a => {
                    if (a.id === element.id_analyte) {
                        a.capture = resultado_final;
                        a.id_exam_method = obj.id_exam_method;
                        recalculateComplex(analitos);
                    }
                    return a;
                })
            }
        });
        // ////console.log(analitos, "**");
        // debugger;
        setAnalytes([...analitos]);
        // recalculateComplex(analitos);
        // setAnalytes([...analitos]);
    }

    const saveIndividualExamImagenology = async (obj) => {
        let formData = new FormData();

        formData.append("id_work_order", workOrderId);
        formData.append("id_exam", obj.id_exam);
        formData.append("capture", obj.valor);

        let requestOptions = {
            method: 'POST',
            body: formData
        };

        const response = await sendRequest(requestOptions, "Exam/SaveCaptureResultsImagenology", 'multipart/form-data');

        if (response.code === 200) {

            let updateSections = sections;
            let updateProfiles = profiles;

            let validacion_impresion = true;
            updateSections.map(section => {
                if (section.id === obj.id_section) {
                    section.exams.map(exam => {
                        if (exam.id_exam === obj.id_exam && exam.id_work_order_exam === obj.id_work_order_exam) {

                            let captureData = {
                                id_user: 0,
                                name_document: obj.valor
                            };

                            exam.imagenology.capture = captureData;

                            let usersCapture = exam.imagenology.usersCapture;

                            let datetime_actual = moment();

                            let user_capture = {
                                id_work_order_exam_imagenology: Date.now() + "-" + exam.id_exam,
                                username: "",
                                full_name: nameUser,
                                result_capture: obj.valor,
                                date_capture: datetime_actual.format("D/M/YYYY hh:mm a")
                            }

                            if (usersCapture === null || usersCapture === undefined) {
                                usersCapture = [user_capture];
                            }
                            else {
                                let validacion = usersCapture[0];
                                if (validacion.result_capture == user_capture.result_capture) {
                                    usersCapture = [user_capture, ...usersCapture];
                                }
                            }

                            let user_validate = {
                                id_work_order_exam_imagenology: Date.now() + "validate" + exam.id_exam,
                                full_name: nameUser,
                                date_capture: moment().format("D/M/YYYY hh:mm a"),
                                result_capture: "",
                                username: ""
                            };

                            exam.imagenology = {
                                ...exam.imagenology,
                                userValidate: user_validate,
                                usersCapture: usersCapture,
                                isCaptured: true
                            };
                        }

                        if (!exam.isRelease) {
                            validacion_impresion = false;
                        }

                        return exam;
                    });
                }
                return section;
            });

            updateProfiles.map(perfil => {
                if (perfil.id_profile === obj.sectionProfileId) {
                    let readyToValidateProfile = true;
                    let readyToReleaseProfile = true;
                    perfil.exams.map(exam => {
                        if (exam.id_exam === obj.id_exam && exam.id_work_order_exam === obj.id_work_order_exam) {
                            let captureData = {
                                id_user: 0,
                                name_document: obj.valor
                            };

                            exam.imagenology.capture = captureData;

                            let usersCapture = exam.imagenology.usersCapture;
                            let datetime_actual = moment();

                            let user_capture = {
                                "id_work_order_exam_imagenology": Date.now() + "-" + exam.id_exam,
                                username: "",
                                full_name: nameUser,
                                result_capture: obj.valor,
                                date_capture: datetime_actual.format("D/M/YYYY hh:mm a")
                            };

                            if (usersCapture === null || usersCapture === undefined) {
                                usersCapture = [user_capture];
                            }
                            else {
                                let validacion = usersCapture[0];
                                if (validacion.result_capture == user_capture.result_capture) {
                                    usersCapture = [user_capture, ...usersCapture];
                                }
                            }

                            let user_validate = {
                                id_work_order_exam_imagenology: Date.now() + "validate" + exam.id_exam,
                                full_name: nameUser,
                                date_capture: moment().format("D/M/YYYY hh:mm a"),
                                result_capture: "",
                                username: ""
                            };

                            exam.imagenology = {
                                ...exam.imagenology,
                                userValidate: user_validate,
                                usersCapture: usersCapture,
                                isCaptured: true
                            };
                        }

                        if (exam.imagenology.required) {
                            if (exam.imagenology.isCaptured === false) {
                                readyToValidateProfile = false;
                            }

                            if (exam.imagenology.userValidate === null) {
                                readyToReleaseProfile = false;
                            }
                        }
                        return exam;
                    });

                    perfil.readyToValidateProfile = readyToValidateProfile;
                    perfil.readyToReleaseProfile = readyToReleaseProfile;
                }

                return perfil;
            });

            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            createSweet("success", "success", "Resultado guardado con exito");
        }

    }

    const recalculateComplex = (analitos) => {
        analytesComplex.forEach(element => {
            let resultado = '';
            Object.entries(element.list_analytes).forEach(variable => {
                let valor = "";
                let analyte_aux = analitos.find(q => q.id == variable[0]);
                
                let aux = "";

                try {
                    aux = analyte_aux.capture;
                } catch (error) {
                    console.log(analyte_aux);
                    aux = "";                    
                }
                
                if (aux !== "") {
                    if (!isNaN(aux)) {
                        if (isFinite(aux)) {
                            if (aux === 0) {
                                valor = aux;
                            } else {
                                valor = parseFloat(aux);
                            }
                        }
                    }
                }
                window[variable[1]] = valor;
            });
            let resultado_final = "";
            let analito_final = analitos.find(a => a.id == element.id_analyte);
            resultado = eval(element.formula);
           
            if (resultado !== "") {
                if (!isNaN(resultado)) {
                    if (isFinite(resultado)) {
                        resultado = parseFloat(resultado);
                        if (resultado === 0) {
                            resultado_final = resultado;
                        } else {
                            resultado_final = resultado.toFixed(analito_final.decimals);
                        }
                    }
                }
            }
            analitos.map(a => {
                if (a.id === element.id_analyte) {
                    a.capture = resultado_final;
                    if (a.id_exam_method === "" || a.id_exam_method === 0) {
                        a.id_exam_method = a.id_exam_method_main;
                    }
                }
                return a;
            })
        });
        // debugger
        setAnalytes([...analitos]);

    }



    const saveAllAnalytes = async () => {


        setLoadingButton(true)
        let analitosSend = analytes;
        ////console.log(analytes);
        analitosSend = analitosSend.filter(x => (x.is_captured ? true : (x.capture !== "")));
        // analitosSend = analitosSend.filter(x => x.capture !== "");
        analitosSend = analitosSend.filter(x => x.id_exam_method !== "");
        let bacteriumsSend = microBacteriums;
        // debugger;
        bacteriumsSend = bacteriumsSend.map(x => {
            microAntibiograms.forEach(anti => {
                if (anti.id_mic_bacterium === x.id_bacterium && anti.id_work_order_exam === x.id_work_order_exam) {
                    // x.antibiogram = anti;
                   x.antibiogram = JSON.stringify(anti);
                }
            });
            return x;
        });

        let payload = {
            "id_work_order": workOrderId,
            "analytes_capture": analitosSend,
            "bacteriums_capture": bacteriumsSend,
        }

        console.log(payload);

        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        ////console.log(payload, "data");
        // ////console.log(analitosSend, "data");

        // return;

        const respuesta = await sendRequest(requestOptions, 'CaptureResult/SaveCaptureResults');

        if (respuesta.code === 200) {
            setLoadingButton(false)

            // ////console.log("Ëxito", respuesta.data);
            let updateSections = sections;
            let updateProfiles = profiles;
            analitosSend.forEach(analito => {
                if (analito.is_exam) {
                    //EXAMENES
                    updateSections.map(section => {
                        if (section.id === analito.id_section) {
                            section.exams.map(exam => {
                                if (exam.id_exam === analito.id_exam && exam.id_work_order_exam === analito.id_work_order_exam) {
                                    let reAnalytes = exam.analytes.map(analyte => {
                                        if (analyte.id_analyte === analito.id) {
                                            let precoded = null;
                                            let number = null;
                                            let text = "";

                                            if (analito.id_type_analyte === 1) {
                                                precoded = parseInt(analito.capture);
                                            } else if (analito.id_type_analyte === 6) {
                                                text = analito.capture;
                                            } else if (analito.id_type_analyte === 2) {
                                                number = parseInt(analito.capture);
                                            } else {
                                                number = parseFloat(analito.capture, analyte.decimals);
                                            }

                                            let captureData = {
                                                "id_user": 0,
                                                "id_precoded_text": precoded,
                                                "number": number,
                                                "text": text,
                                                "id_exam_method": analito.id_app_code_or_method_exam,
                                                "is_interface": false,
                                                "comments": analito.comment
                                            }
                                            analyte.capture = captureData;

                                            let usersCapture = analyte.usersCapture;

                                            let datetime_actual = moment();
                                            let user_capture = {
                                                "id_work_order_exam_analyte": Date.now() + "-" + analito.id,
                                                "username": "",
                                                "full_name": nameUser,
                                                "result_capture": analito.capture,
                                                "date_capture": datetime_actual.format("D/M/YYYY hh:mm a")
                                                // "date_capture": "15/10/2022 02:42 p. m."
                                            };
                                            if (usersCapture === null || usersCapture === undefined) {
                                                usersCapture = [user_capture];
                                            } else {
                                                let validacion = usersCapture[0];
                                                if (validacion.result_capture === user_capture.result_capture) {
                                                    usersCapture = [user_capture, ...usersCapture];
                                                }
                                            }
                                            analyte.usersCapture = usersCapture;

                                            analyte.isCaptured = true;
                                            analyte.is_captured = true;
                                            analyte.required=true;
                                        }
                                        return analyte;
                                    })
                                    exam.analytes = [...reAnalytes];
                                    exam.method_selected = analito.id_app_code_or_method_exam;
                                }
                                return exam;
                            })
                        }
                        return section;
                    })
                } else {
                    //PERFILES
                    updateProfiles.map(perfil => {
                        if (perfil.id_profile === analito.id_profile) {
                            let readyToValidateProfile = true;
                            let readyToReleaseProfile = true;
                            perfil.exams.map(exam => {

                                if (exam.id_exam === analito.id_exam && exam.id_work_order_exam === analito.id_work_order_exam) {
                                    let reAnalytes = exam.analytes.map(analyte => {
                                        if (analito.sub_id !== null && analito.sub_id !== undefined) {
                                            if ((analyte.id_analyte === analito.id) && (analyte.sub_id === analito.sub_id)) {
                                                let precoded = null;
                                                let number = null;
                                                let text = "";

                                                if (analito.id_type_analyte === 1) {
                                                    precoded = parseInt(analito.capture);
                                                } else if (analito.id_type_analyte === 6) {
                                                    text = analito.capture;
                                                } else if (analito.id_type_analyte === 2) {
                                                    number = parseInt(analito.capture);
                                                } else {
                                                    number = parseFloat(analito.capture, analyte.decimals);
                                                }

                                                let captureData = {
                                                    "id_user": 0,
                                                    "id_precoded_text": precoded,
                                                    "number": number,
                                                    "text": text,
                                                    "id_exam_method": analito.id_app_code_or_method_exam,
                                                    "is_interface": false,
                                                    "comments": analito.comment
                                                }
                                                analyte.capture = captureData;

                                                let usersCapture = analyte.usersCapture;

                                                let datetime_actual = moment();
                                                let user_capture = {
                                                    "id_work_order_exam_analyte": Date.now() + "-" + analito.id,
                                                    "username": "",
                                                    "full_name": nameUser,
                                                    "result_capture": analito.capture,
                                                    "date_capture": datetime_actual.format("D/M/YYYY hh:mm a")
                                                    // "date_capture": "15/10/2022 02:42 p. m."
                                                };
                                                if (usersCapture === null || usersCapture === undefined) {
                                                    usersCapture = [user_capture];
                                                } else {
                                                    let validacion = usersCapture[0];
                                                    if (validacion.result_capture === user_capture.result_capture) {
                                                        usersCapture = [user_capture, ...usersCapture];
                                                    }
                                                }
                                                analyte.usersCapture = usersCapture;

                                                analyte.isCaptured = true;
                                                analyte.is_captured = true;
                                                analyte.required = true;
                                            }
                                        }
                                        else if (analyte.id_analyte === analito.id) {
                                            let precoded = null;
                                            let number = null;
                                            let text = "";

                                            if (analito.id_type_analyte === 1) {
                                                precoded = parseInt(analito.capture);
                                            } else if (analito.id_type_analyte === 6) {
                                                text = analito.capture;
                                            } else if (analito.id_type_analyte === 2) {
                                                number = parseInt(analito.capture);
                                            } else {
                                                number = parseFloat(analito.capture, analyte.decimals);
                                            }

                                            let captureData = {
                                                "id_user": 0,
                                                "id_precoded_text": precoded,
                                                "number": number,
                                                "text": text,
                                                "id_exam_method": analito.id_app_code_or_method_exam,
                                                "is_interface": false,
                                                "comments": analito.comment
                                            }
                                            analyte.capture = captureData;

                                            let usersCapture = analyte.usersCapture;

                                            let datetime_actual = moment();
                                            let user_capture = {
                                                "id_work_order_exam_analyte": Date.now() + "-" + analito.id,
                                                "username": "",
                                                "full_name": nameUser,
                                                "result_capture": analito.capture,
                                                "date_capture": datetime_actual.format("D/M/YYYY hh:mm a")
                                                // "date_capture": "15/10/2022 02:42 p. m."
                                            };
                                            if (usersCapture === null || usersCapture === undefined) {
                                                usersCapture = [user_capture];
                                            } else {
                                                let validacion = usersCapture[0];
                                                if (validacion.result_capture === user_capture.result_capture) {
                                                    usersCapture = [user_capture, ...usersCapture];
                                                }
                                            }
                                            analyte.usersCapture = usersCapture;

                                            analyte.isCaptured = true;
                                        }
                                        return analyte;
                                    })
                                    exam.analytes = [...reAnalytes];

                                    exam.method_selected = analito.id_app_code_or_method_exam;
                                }


                                exam.analytes !== null && exam.analytes.forEach(element => {
                                    if (element.required) {
                                        if (element.isCaptured === false) {
                                            readyToValidateProfile = false;
                                        }
                                        if (element.userValidate === null) {
                                            readyToReleaseProfile = false;
                                        }
                                    }
                                });

                                return exam;
                            })
                            perfil.readyToValidateProfile = readyToValidateProfile;
                            perfil.readyToReleaseProfile = readyToReleaseProfile;
                        }
                        return perfil;
                    })
                }
            });
            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            previewWorkOrder();
            createSweet("success", "success", "La carga de analitos fue satisfactoria");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveAllAnalytes);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }


        // else {
        //     createSweet("error", "error", "Hubo un error!");
        //     validarSesion(history, respuesta.code, saveAllAnalytes);
        // }
    }

    const releaseExam = async (id_work_order_exam, id_exam) => {
        let payload = {
            "id_work_order_exam": id_work_order_exam,
            "id_exam": id_exam
        }

        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/ReleaseExam');

        if (respuesta.code === 200) {
            // ////console.log("Ëxito");
            let updateSections = sections;
            let updateProfiles = profiles;

            let validacion_impresion = true;

            updateSections.map(section => {
                let examenes = section.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam && exam.id_exam === id_exam) {
                        exam.isRelease = true;
                        let userData = {
                            "id_user": 0,
                            "id_external": "",
                            "is_admin": false,
                            "is_dfa": false,
                            "username": nameUser,
                            "name": "",
                            "paternal_surname": "",
                            "maternal_surname": "",
                            "email": null,
                            "id_job": null,
                            "job": null,
                            "card_code": null,
                        }
                        exam.user = userData;
                        exam.release_date = moment().format("D/M/YYYY hh:mm a");

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = {
                                "id_work_order_exam_analyte": moment() + analyte.id_analyte,
                                "full_name": nameUser,
                                "result_capture": "0",
                                "date_capture": moment().format("D/M/YYYY hh:mm a")
                            }
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    return exam;
                })
                section.exams = [...examenes];
                return section;
            });

            updateProfiles.map(perfil => {
                let examenes = perfil.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam && exam.id_exam === id_exam) {
                        exam.isRelease = true;
                        let userData = {
                            "id_user": 0,
                            "id_external": "",
                            "is_admin": false,
                            "is_dfa": false,
                            "username": nameUser,
                            "name": "",
                            "paternal_surname": "",
                            "maternal_surname": "",
                            "email": null,
                            "id_job": null,
                            "job": null,
                            "card_code": null,
                        }
                        exam.user = userData;
                        exam.release_date = moment().format("D/M/YYYY hh:mm a");

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = {
                                "id_work_order_exam_analyte": moment() + analyte.id_analyte,
                                "full_name": nameUser,
                                "result_capture": "0",
                                "date_capture": moment().format("D/M/YYYY hh:mm a")
                            }
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    return exam;
                })
                perfil.exams = [...examenes];
                return perfil;
            })
          
            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            setPrintAvailable(respuesta.data);
            // await getData();
            previewWorkOrder();
            createSweet("success", "success", "Se ha liberado el examen");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, releaseExam);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
      
    }

    const releaseAll = async () => {
        setLoadingRelease(true);
        let id_work_order = workOrderId;
        let nuevas_secciones = JSON.parse(userSections);
        let filtro_secciones = JSON.parse(userFilterSections);
        nuevas_secciones = nuevas_secciones.filter(x => {
            if (filtro_secciones.find(y => y.id_section === x)) {
                return x
            }
        })

        let payload = {
            "sections": nuevas_secciones,
            "nim": nim,
            "id_work_order": id_work_order,
        }
        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/ReleaseAll');

        if (respuesta.code === 200) {
            // ////console.log("Ëxito", respuesta.data);

            let liberados = respuesta.data.list_id_work_order_exams;

            let updateSections = sections;
            let updateProfiles = profiles;

            let validacion_impresion = true;
            updateSections.map(section => {
                // ////console.log(section);
                let examenes = section.exams.map(exam => {
                    if (liberados.find(x => x === exam.id_work_order_exam) !== undefined) {
                        exam.isRelease = true;
                        let userData = {
                            "id_user": 0,
                            "id_external": "",
                            "is_admin": false,
                            "is_dfa": false,
                            "username": nameUser,
                            "name": "",
                            "paternal_surname": "",
                            "maternal_surname": "",
                            "email": null,
                            "id_job": null,
                            "job": null,
                            "card_code": null,
                        }
                        exam.user = userData;
                        exam.release_date = moment().format("D/M/YYYY hh:mm a");

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = {
                                "id_work_order_exam_analyte": moment() + analyte.id_analyte,
                                "full_name": nameUser,
                                "result_capture": "0",
                                "date_capture": moment().format("D/M/YYYY hh:mm a")
                            }
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    return exam;
                })
                section.exams = [...examenes];
                return section;
            });

            updateProfiles.map(perfil => {
                let examenes = perfil.exams.map(exam => {
                    if (liberados.find(x => x === exam.id_work_order_exam) !== undefined) {
                        exam.isRelease = true;
                        let userData = {
                            "id_user": 0,
                            "id_external": "",
                            "is_admin": false,
                            "is_dfa": false,
                            "username": nameUser,
                            "name": "",
                            "paternal_surname": "",
                            "maternal_surname": "",
                            "email": null,
                            "id_job": null,
                            "job": null,
                            "card_code": null,
                        }
                        exam.user = userData;
                        exam.release_date = moment().format("D/M/YYYY hh:mm a");

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = {
                                "id_work_order_exam_analyte": moment() + analyte.id_analyte,
                                "full_name": nameUser,
                                "result_capture": "0",
                                "date_capture": moment().format("D/M/YYYY hh:mm a")
                            }
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    return exam;
                })
                perfil.exams = [...examenes];
                return perfil;
            })
            // ////console.log(updateSections);
            // ////console.log(updateProfiles);
            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            setPrintAvailable(respuesta.data.release);
            previewWorkOrder();
            // await getData();
            createSweet("success", "success", "Se ha liberado el examen");
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, releaseExam);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
        setLoadingRelease(false);
        toggleRelease();
    }

    const validateAnalyte = async (id_work_order_exam, id_exam, id_analyte, sub_id, analito) => {
        let payload = {
            "id_work_order_exam": id_work_order_exam,
            "id_exam": id_exam,
            "id_analyte": id_analyte,
            "sub_id": sub_id !== null ? sub_id : ""
        }
        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };
        const respuesta = await sendRequest(requestOptions, 'Exam/ValidateAnalyte');
        if (respuesta.code === 200) {
            if (analito.is_exam) {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === analito.id_section) {
                        section.exams.map(exam => {
                            if (exam.id_exam === analito.id_exam && exam.id_work_order_exam === analito.id_work_order_exam) {
                                let nuevos_analitos = exam.analytes.map(analyte => {
                                    if (analyte.id_analyte === analito.id) {
                                        let userValidate = {
                                            "id_work_order_exam_analyte": Date.now() + "validate" + id_analyte,
                                            "full_name": nameUser,
                                            "result_capture": "0",
                                            "date_capture": moment().format("D/M/YYYY hh:mm a")
                                        }
                                        analyte.userValidate = userValidate;
                                    }
                                    return analyte;
                                })
                                exam.analytes = [...nuevos_analitos];
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);

            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === analito.id_profile) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === analito.id_exam && exam.id_work_order_exam === analito.id_work_order_exam) {
                                let nuevos_analitos = exam.analytes.map(analyte => {
                                    if (analyte.id_analyte === analito.id) {
                                        let userValidate = {
                                            "id_work_order_exam_analyte": Date.now() + "validate" + id_analyte,
                                            "full_name": nameUser,
                                            "result_capture": "0",
                                            "date_capture": moment().format("D/M/YYYY hh:mm a")
                                        }
                                        analyte.userValidate = userValidate;
                                    }
                                    return analyte;
                                })
                                exam.analytes = [...nuevos_analitos];
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }

            createSweet("success", "success", "Se ha validado el resultado");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, validateAnalyte);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }


       
    }

    const validateExam = async (id_work_order_exam, id_exam, typeArray, sectionProfileId) => {
        let payload = {
            "id_work_order_exam": id_work_order_exam,
            "id_exam": id_exam,
        }
        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/ValidateExam');
        if (respuesta.code === 200) {
            if (typeArray === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === id_exam && exam.id_work_order_exam === id_work_order_exam) {
                                let nuevos_analitos = exam.analytes.map(analyte => {
                                    if (analyte.isCaptured) {
                                        let userValidate = {
                                            "id_work_order_exam_analyte": Date.now() + "validate" + analyte.id_analyte,
                                            "full_name": nameUser,
                                            "result_capture": "0",
                                            "date_capture": moment().format("D/M/YYYY hh:mm a")
                                        }
                                        analyte.userValidate = userValidate;
                                    }
                                    return analyte;
                                })
                                exam.analytes = [...nuevos_analitos];
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);

            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === id_exam && exam.id_work_order_exam === id_work_order_exam) {
                                let nuevos_analitos = exam.analytes.map(analyte => {
                                    if (analyte.isCaptured) {
                                        let userValidate = {
                                            "id_work_order_exam_analyte": Date.now() + "validate" + analyte.id_analyte,
                                            "full_name": nameUser,
                                            "result_capture": "0",
                                            "date_capture": moment().format("D/M/YYYY hh:mm a")
                                        }
                                        analyte.userValidate = userValidate;
                                    }
                                    return analyte;
                                })
                                exam.analytes = [...nuevos_analitos];
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }
            previewWorkOrder();

           
            createSweet("success", "success", "Se ha validado la prueba correctamente");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, validateAnalyte);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }

    const validateProfile = async (id_profile, id_work_order, id_work_order_profile) => {
        await saveAllAnalytes();
        let payload = {
            "id_work_order_exam": id_work_order_profile,
            // "id_work_order_exam": id_work_order,
            "id_exam": id_profile,
            "id_work_order":id_work_order
        }
        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/ValidateProfile');
        if (respuesta.code === 200) {

            let updateProfiles = profiles;
            updateProfiles.map(perfil => {
                if (perfil.id_work_order_profile === id_work_order_profile) {
                    let readyToValidateProfile = true;
                    let readyToReleaseProfile = true;
                    perfil.exams.map(exam => {
                        let nuevos_analitos = exam.analytes.map(analyte => {
                            if (analyte.isCaptured) {
                                let userValidate = {
                                    "id_work_order_exam_analyte": Date.now() + "validate" + analyte.id_analyte,
                                    "full_name": nameUser,
                                    "result_capture": "0",
                                    "date_capture": moment().format("D/M/YYYY hh:mm a")
                                }
                                analyte.userValidate = userValidate;
                            }
                            return analyte;
                        })
                        exam.analytes = [...nuevos_analitos];

                        exam.analytes !== null && exam.analytes.forEach(element => {
                            if (element.required) {
                                if (element.isCaptured === false) {
                                    readyToValidateProfile = false;
                                }
                                if (element.userValidate === null) {
                                    readyToReleaseProfile = false;
                                }
                            }
                        });
                        return exam;
                    })
                    perfil.readyToValidateProfile = readyToValidateProfile;
                    perfil.readyToReleaseProfile = readyToReleaseProfile;
                }
                return perfil;
            })
            setProfiles([...updateProfiles]);
            createSweet("success", "success", "Se ha validado el perfil correctamente");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, validateProfile);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }

    const calculateAge = (birthday) => {
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        var anios = Math.abs(ageDate.getUTCFullYear() - 1970);


        return anios;
    }

    const calculateAgeMonths = (birthday) => {
        var a = moment();
        var b = moment(birthday, 'YYYY-MM-DD');
        var age = moment.duration(a.diff(b));
        var months = age.months();
        var days = age.days();
        let anios = months + " mes(es)" + " y " + days + " día(s)";
        return anios;
    }

    const printWorkOrder = async (is_printed) => {

        let payload = {
            "nim": nim,
            "password": "",
            "type": "NP"
        }
        let data = JSON.stringify(payload);
        let token = localStorage.getItem("token");

        let alter_url = "?is_micro_sanitary=true";

        if (is_printed) {
            alter_url = "?is_printed=" + is_printed;
        }

        await fetch(ConfigServer.serverUrl + "/api/PrintResult/DownloadSheetResults" + alter_url, {
            method: 'POST',
            body: data,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se ha podido descargar el pdf");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "hoja_resultados_" + nim + ".pdf");
                document.body.appendChild(link);
                link.click();
                createSweet("success", "success", "Descarga completa");
            });

    }
   
    const previewWorkOrder = async () => {

        let payload = {
            "nim": nim,
            "password": "",
            "type": "NP"
        }
     
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(payload)
        };
        const respuesta = await sendRequest(requestOptions, 'PrintResult/Preview?is_micro_sanitary=true');

        if (respuesta.code === 200) {
        }
        setworkMedicalOrderPDF(respuesta.data.cadena);
    }

    const sendResultForEmail = async () => {

        let payload = {
            "nim": nim,
            "password": "",
            "type": "NP"
        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(payload)
        };

        const respuesta = await sendRequest(requestOptions, "PrintResult/SendResultForEmail");

        if (respuesta.code === 200) {
            createSweet("create", "success", "Exito!", "Los resultados se han enviado correctamente");
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, sendResultForEmail);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Resultados", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Resultados", respuesta.data.msg);
        }
    }

    useEffect(() => {
        getFuncionesFormula().then(resp => {
            getData(resp);
        });
        backNextState();
        previewWorkOrder()

    }, [])

    const handleEditCommentsPrintResults = (e) => {
        setCommentsPrintResult(e.target.value);
    }

    const saveCommentsWorkOrder = async () => {

        let formValues = {
            'id_work_order': workOrderId,
            'comment': commentsPrintResult
        };

        if (workOrderId != 0) {

            let requestOptions = {
                method: 'PUT',
                body: JSON.stringify(formValues)
            };

            const respuesta = await sendRequest(requestOptions, "WorkOrder/SaveGlobalCommentsWorkOrder");

            if (respuesta.code === 200) {
                createSweet("create", "success", "Exito!", "Comentarios actualizados");
            }
            else if (respuesta.code === 401) {
                validarSesion(history, respuesta.code, saveCommentsWorkOrder);
            }
            else if (respuesta.code === 500) {
                createSweet("error", "error", "Convenios", "Ocurrio un error en el servidor");
            }
            else {
                createSweet("error", "warning", "Convenios", respuesta.data.msg);
            }

        }
    }

    //!CONFIGURATION REMOVE VALIDATIONS
    const removeValidationFromExamOrProfile = async (id_work_order_exam) => {

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "ProcessExam/RemoveValidationFromExamOrProfile/" + id_work_order_exam);

        if (respuesta.code === 200) {
            let updateSections = sections;
            let updateProfiles = profiles;
          

            updateSections.map(section => {
                //console.log("wilson",section);
                let examenes = section.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam) {
                        exam.isRelease = false;
                        exam.user = null;
                        exam.release_date = null;
                        exam.id_estatus_exam = 3;

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = null;
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    return exam;
                })
                section.exams = [...examenes];
                return section;
            });

            updateProfiles.map(perfil => {
                //console.log("wilson2",perfil);
                let examenes = perfil.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam) {
                        exam.isRelease = false;
                        exam.user = null;
                        exam.release_date = null;
                        exam.id_estatus_exam = 3;
                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = null;
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }

                    return exam;
                })
                perfil.exams = [...examenes];
                return perfil;
            })
            

            setSections([...updateSections]);
            setProfiles([...updateProfiles]);


        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }

    }

    //!CONFIGURATION NAVIGATE VIEW NIMS
    const backNextState = () => {
        var nims = JSON.parse(localStorage.getItem("current_work_orders"));
        if(nims == null) nims = [];
        var idx = nims.indexOf(nim);
        setBackNim((idx <= 0)? null : nims[idx-1]);
        setNextNim((idx == (nims.length -1)) ? null : nims[idx+1])
    }

    const gotoNim = (goNim) => {
        if(goNim != null) {
            window.location.href = `/captura-resultados-sanitaria/${goNim}`
        }
        else console.log("Error");
    }
    //!END CONFIGURATION NAVIGATE VIEW NIMS
    //RESULTADO PREELIMINAR-FINAL
    const update_micro_type_result = async (id_work_order_exam, micro_type_result) => {
        
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Si tu backend espera JSON, esto es esencial.
            },
        };

        // Se envía la solicitud con la URL que tiene los parámetros en la query string
        const respuesta = await sendRequest(
            requestOptions,
            "Exam/UpdateMicroTypeResult/" + id_work_order_exam + "?micro_type_result=" + micro_type_result
        );

        if (respuesta.code === 200) {
            let updateSections = sections;
            let updateProfiles = profiles;

            // Se actualizan las secciones y perfiles con el nuevo valor de 'cedimi_micro_type_result'
            updateSections.map(section => {
                section.exams = section.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam) {
                        exam.cedimi_micro_type_result = micro_type_result;
                    }
                    return exam;
                });
                return section;
            });

            updateProfiles.map(perfil => {
                perfil.exams = perfil.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam) {
                        exam.cedimi_micro_type_result = micro_type_result;
                    }
                    return exam;
                });
                return perfil;
            });

            // Actualizar estado
            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            previewWorkOrder();
            // Mostrar mensaje de éxito
            createSweet("success", "success", "Captura de resultados", "Tipo de Informe Actualizado correctamente.");
            //window.location.reload();
        }
        else if (respuesta.code === 401) {
            // Aquí podrías redirigir a una página de login si es necesario
            // validarSesion(history, respuesta.code, validateBacterium);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", "Ocurrió un problema en el servidor.");
        }
        else {
            createSweet("error", "warning", "Captura de resultados.", respuesta.data.msg);
        }
    };



    return {
        analytes, profiles, sections, patient, saveIndividualAnalyte, saveAllAnalytes, releaseExam, releaseAll, validateAnalyte, validateExam, validateProfile, printAvailable, dobleValidacion, resultsPartial, printWorkOrder, sendResultForEmail,
        observations, commentsPrintResult, handleEditCommentsPrintResults, saveCommentsWorkOrder,
        microBacteriums, microAntibiograms,
        nimPatient, commentsFlebo, workMedicalOrder, workMedicalOrderPDF, type_file,
        handleCreate, toggle, method, modal, modalTitle1, typeModal, modalRelease, toggleRelease, loadingRelease, getViewPreview, previewWorkOrder,
        saveIndividualExamImagenology, handleGetDocumentImagenology, modalViewImagenology, modalTitleImg, toggleModalViewImagenology, documentImagenology, loadingPatient,
        //*REMOVE VALIDATIONS
        removeValidationFromExamOrProfile,
        //*CONFIGURATION NAVIGATE VIEW NIMS
        backNim,nextNim,gotoNim,loadingButton,update_micro_type_result

    }
}

