import React, { useState } from 'react';
import { Row, Col, FormGroup, Input, Card, Alert } from 'reactstrap';
import Select from 'react-select';
import { useSampleReportSanitary } from '../../../hooks/services/useSampleReportSanitary';

export const IndexReportsSanitary = () => {
    const {
        start_date,
        end_date,
        handleInputChange,
        handleSelectChange,
        DownloadWorkOrdersByCompany,
        listCompanyBusiness,
        id_company_business,
    } = useSampleReportSanitary();

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isCompanySelected, setIsCompanySelected] = useState(true);

    const handleDownloadClick = async () => {
        if (!id_company_business) {
            setIsCompanySelected(false);
            return;
        }

        setIsCompanySelected(true);
        setIsButtonDisabled(true);
        setIsDownloading(true);

        await DownloadWorkOrdersByCompany();

        setTimeout(() => {
            setIsButtonDisabled(false);
            setIsDownloading(false);
        });
    };

    return (
        <Col sm="12">
            <Card>
                <div style={{
                    backgroundColor: "#4694c0",
                    borderRadius: "8px 8px 0 0",
                    padding: "15px",
                    color: "#fff",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)"
                }}>
                    <Col sm="12">
                        <Row>
                            <Col sm="2" className='p-l-0 mt-4'>
                                <div style={{
                                    fontSize: "1.25em",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}>
                                    Reporte de Ordenes de Trabajo
                                </div>
                            </Col>
                            <Col sm="2" className='p-l-0'>
                                <label htmlFor="">Fecha Inicio</label>
                                <FormGroup>
                                    <Input
                                        style={{
                                            borderColor: "#fff",
                                            borderStyle: "solid",
                                            borderWidth: "1px",
                                            borderRadius: "15px",
                                            height: "35px",
                                            width: "200px",
                                            fontSize: "11px"
                                        }}
                                        type="date"
                                        name="start_date"
                                        value={start_date}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="2" className='p-l-0'>
                                <label htmlFor="">Fecha Final</label>
                                <FormGroup>
                                    <Input
                                        style={{
                                            borderColor: "#fff",
                                            borderStyle: "solid",
                                            borderWidth: "1px",
                                            borderRadius: "15px",
                                            height: "35px",
                                            width: "200px",
                                            fontSize: "11px"
                                        }}
                                        type="date"
                                        name="end_date"
                                        value={end_date}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="2" className='p-l-0'>
                                <label htmlFor="">Empresa <span>*</span></label>
                                <FormGroup>
                                    <Select
                                        isClearable={true}
                                        placeholder="Selecciona una empresa"
                                        name="id_company_business"
                                        value={listCompanyBusiness.find(option => option.value === id_company_business)}
                                        options={listCompanyBusiness}
                                        onChange={(e) => {
                                            handleSelectChange(e);
                                            setIsCompanySelected(true);
                                        }}
                                        styles={{
                                            option: (base) => ({
                                                ...base,
                                                color: '#000',
                                            }),
                                        }}
                                        noOptionsMessage={() => "Sin Opciones"}
                                        isSearchable={true}
                                    />
                                    {!isCompanySelected && (
                                        <Alert color="primary" style={{ marginTop: "1px" }}>
                                            Por favor selecciona una empresa.
                                        </Alert>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col sm="1" className="pt-3">
                                <button
                                    style={{
                                        backgroundColor: "#000",
                                        color: "#fff",
                                        borderRadius: "15px",
                                        padding: "10px",
                                        fontSize: "12px",
                                    }}
                                    onClick={handleDownloadClick}
                                    disabled={isButtonDisabled}
                                >
                                    {isDownloading ? "Cargando..." : "Descargar"}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Card>

            <Card className='shadow'>
                <div style={{ textAlign: "-webkit-center" }}>
                    <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
                    <label className='f-w-600 f-14 badge badge-light-primary'>Reporte Ordenes de Trabajo</label>
                </div>
            </Card>
        </Col>
    );
};
