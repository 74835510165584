import { sendRequest } from "../../hooks/requests/useRequest";

export async function getInformationQuoter (_mfrom,_mto,_code_quoter)
{
    const requestOptions = {
        method: "GET",
    };

    let _url = _code_quoter === "" ?  `Quoter/ReportQuoter?date_ini=${_mfrom}&date_end=${_mto}` :  `Quoter/ReportQuoter?code=${_code_quoter}`

    const response = await sendRequest(requestOptions, _url);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}