import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import { ComponentMicroorganismIsolated } from './ComponentMicroorganismIsolated';
import { getAllStainGrams,getAllBacteriumCount,getAllResistanceMechanism,getAllMorphology,getAllBacterium,getAllAntibiograms,getAllAntibioticBacter} from '../../../services/micro/micro'

export const InputsMicro = ({idExam,examId,position,is_profile,sectionProfileId,listMicroBacteriums, microBacteriums,setMicroBacteriums,handleTypeBacterium,microAntibiograms,handleChangeComments,removeBacterium1,removeBacteriumAntibitic,ValidateBacterium,typeArray,UnValidateBacterium,ReleaseBacterium,UnReleaseBacterium,_typeClient,cedimi}) => {


    //*OPTIONS SELECT
    const [morphologys, setMorphologys] = useState([]);
    const [bacterias, setBacterias] = useState([]);
    const [listOptionsBacteriums, setListOptionsBacteriums] = useState([]);

    // * CONFIGURATION CATALOGS
    const [listStainGrams, setListStainGrams] = useState([])
    const [listBacteriumCount, setListBacteriumCount] = useState([])
    const [listResistanceMechanism, setListResistanceMechanism] = useState([])

    // * CONFIGURATION ANTIBIOGRAM
    const [listAntibiogram, setListAntibiogram] = useState([])
    const [antibitic, setAntibitic] = useState([])

    useEffect(() => {
        getStainGrans();
        getBacteriumCount();
        getResistanceMechanism();
        getMorphologys()
        getBacteriums()
    }, [])

    
    // * CONFIGURATION CATALOGS
    const getStainGrans = async () =>
    {
        const stain = await getAllStainGrams();
    
        if (stain?.length > 0)
        {
            const list = stain?.map(_find => {
                return {
                    value: _find?.id_mic_stain_gram,
                    label: _find?.name,
                    id_mic_stain_gram: _find?.id_mic_stain_gram
                }
            });
            setListStainGrams(list);
        }
    }

    const getBacteriumCount = async () =>
        {
            const bacterium = await getAllBacteriumCount();
        
            if (bacterium?.length > 0)
            {
                const list = bacterium?.map(_find => {
                    return {
                        value: _find?.id_bacterium_count_text,
                        label: _find?.name,
                        id_bacterium_count_text: _find?.id_bacterium_count_text
                    }
                });
                setListBacteriumCount(list);
            }
    }

    const getResistanceMechanism = async () =>
    {
        const stain = await getAllResistanceMechanism();
    
        if (stain?.length > 0)
        {
            const list = stain?.map(_find => {
                return {
                    value: _find?.id_resistance_mechanism,
                    label: _find?.name,
                }
            });
            setListResistanceMechanism(list);
        }
    }

    const getMorphologys = async () =>
    {
        const morphology = await getAllMorphology();

        if (morphology !== null)
        { 
            setMorphologys(morphology);
        }
    }

    const getBacteriums = async () =>
    {
        const bacter = await getAllBacterium();
    
        if (bacter !== null)
        { 
            setBacterias(bacter);
        }
    }
    // * END CONFIGURATION CATALOGS

    useEffect(() => {

        if (bacterias.length > 0) {
            console.log(microBacteriums);
            console.log("***");
            
            

            if (microBacteriums.id_mic_bacterium !== "") {
                
                let groups = [];                
    
                let bacteriums = bacterias.filter(_find => {
            
                    if (_find?.label_mic_stain_gram === microBacteriums.id_mic_stain_gram.label) {
                        return _find;
                    }
                });

                morphologys.forEach(_mor => {
                    let _bacters = bacteriums.filter(x => x.id_mic_morphology === _mor.id_mic_morphology);
                
                    if (_bacters.length > 0) {  
                        _bacters.forEach(bacter => {
                            groups.push({
                                value: bacter?.id_mic_bacterium,
                                label: bacter?.bacterium, 
                            });
                        });
                    }
                });   
                setListOptionsBacteriums(groups);
            }
        }      
    }, [bacterias.length > 0])

  
    const handleGram = (e, _id, _valid_Input,_idExam,_id_mic_bacterium,_true) => {
        let groups = [];

        let bacteriums = bacterias.filter(_find => {
            
            if (_find?.label_mic_stain_gram === e.label) {
                return _find;
            }
        });
        morphologys.forEach(_mor => {
            
            let _bacters = bacteriums.filter(x => x.id_mic_morphology === _mor.id_mic_morphology);
        
            if (_bacters.length > 0) {  
                _bacters.forEach(bacter => {
                    groups.push({
                        value: bacter?.id_mic_bacterium,
                        label: bacter?.bacterium, 
                    });
                });
            }
        });      
        setListOptionsBacteriums(groups);
                         
        if (_true) { 
        }
        else handleChangeBacter(e, _id, _valid_Input, _idExam,_id_mic_bacterium)

    }

    const handleChangeBacter = (e, _id, _valid_Input,_idExam,_id_mic_bacterium,_section) => {
    
        let _newArray = listMicroBacteriums
        let _valid = _newArray.find(x => x.id_temp  === _id);
       
        if (_valid !== undefined) {   
            let _validate = false;

            if ((_id_mic_bacterium === null || _id_mic_bacterium === undefined || _id_mic_bacterium === "")) {
                
                if (_valid.id_temp === _id) {
                    _validate = true;
                }
            } else {
                
                if (_valid.id_work_order_exam === idExam) {
                    _validate = true;
                }
            }                        

            if (_validate) {

                switch (_valid_Input) {
                    case "id_mic_stain_gram":                        
                        // ? VALUE INPUT
                        _valid.id_mic_stain_gram = { "value": e.value, "label": e.label };
                        break;
                    case "id_mic_bacterium":   
                        // ? VALUE INPUT                     
                        _valid.id_mic_bacterium = { "value": e.value, "label": e.label };
                        handleGram(_valid.id_mic_stain_gram, _id, _valid_Input, _idExam, _id_mic_bacterium,true);
                    break;
                    case "quantity_number":
                        // ? VALUE INPUT
                        _valid.quantity_number = e?.target?.value
                        _valid.id_bacterium_count_text = 0
                    break;
                    case "id_bacterium_count_text":
                        // ? VALUE INPUT
                        _valid.id_bacterium_count_text = { "value": e.value, "label": e.label };
                        _valid.quantity_number = ""
                        break;
                        case "ids_resistance_mechanisms":
                        // ? VALUE INPUT
                            _valid.ids_resistance_mechanisms = e.map(option => ({
                                value: option.value,
                                label: option.label,
                            }));
                    break;
                    case "comment_resistance_mechanism":
                        // ? VALUE INPUT
                        _valid.comment_resistance_mechanism = e?.target?.value
                    break;
                    default:
                        break;
                }
            }    
            if (_newArray?.length > 0)
                setMicroBacteriums([..._newArray]);
        }
    }

    const colorBacter = {
        control: (provided) => ({
          ...provided,
          border: '2px solid #28a745', 
        }),
    };

    const warningBacter = {
        control: (provided) => ({
          ...provided,
          border: '2px solid #dc3545', 
        }),
    };

    useEffect(() => {
        if (microBacteriums.update) {
            if (microBacteriums?.id_work_order_exam === idExam) {                
                getAntibiograms(microBacteriums.id_mic_bacterium.value)
                getAntibioticBacter(microBacteriums.id_mic_antibiogram.value); 
            } 
        }
    }, [microBacteriums])
    

    // ? ANTIBIOGRAM ------------------------------------------------------------------
    const changeMicro = (e,_id,_idExam) => {      
       getAntibiograms(e.value)
    }

    // ? NEW ANTIBIOGRAM
    const handleChangeAntibiogram = (e, _id, _valid_Input, _idExam, _id_mic_bacterium, _section) => {
        console.log("🚀 ~ handleChangeAntibiogram ~ _section:", _section)
        console.log("🚀 ~ handleChangeAntibiogram ~ _id_mic_bacterium:", _id_mic_bacterium)
        console.log("🚀 ~ handleChangeAntibiogram ~ _idExam:", _idExam)
        console.log("🚀 ~ handleChangeAntibiogram ~ _valid_Input:", _valid_Input)
        console.log("🚀 ~ handleChangeAntibiogram ~ _id:", _id)
        console.log("🚀 ~ handleChangeAntibiogram ~ e:", e)
        let _newArray = listMicroBacteriums;
        let _valid = _newArray.find(x => x.id_temp === _id);
    
        if (_valid !== undefined) {
            let _validate = false;
    
            if (_id_mic_bacterium === null || _id_mic_bacterium === undefined || _id_mic_bacterium === "") {
                if (_valid.id_temp === _id) {
                    _validate = true;
                }
            } else {
                if (_valid.id_work_order_exam === _idExam) {
                    _validate = true;
                }
            }
    
            if (_validate) {
                _valid.id_mic_antibiogram = { "value": e.value, "label": e.label };
    
                _valid.antibiotics = e.antibiotics.map(antibiotic => ({
                    ...antibiotic,
                    cim: ""  
                }));
    
                getAntibioticBacter(e.antibiotics);
            }
    
            if (_newArray.length > 0) {
                setMicroBacteriums([..._newArray]);
            }
        }
    };
    
    const getAntibiograms = async (_id_mic_bacterium) => {
        
        const stain = await getAllAntibiograms(_id_mic_bacterium);
    
        if (stain?.length > 0) {
            const activeList = stain.filter(_find => _find.active);
            const list = activeList.map(_find => ({
                value: _find?.id_mic_antibiogram,
                label: _find?.antibiogram,
                is_cim: _find.is_cim,
                antibiotics: _find.antibiotics
            }));
            setListAntibiogram(list);
        }
    };

    const getAntibioticBacter = async (_array) =>
    {
        const antibiotic = await getAllAntibioticBacter();
    
        if (antibiotic?.length > 0)
        {
            setAntibitic(antibiotic);
        }
    }

    const handleChange = (e, _id, _idExam, _id_antibiotic_bacterium_result, _id_mic_antibiotic,_type) => {
    console.log("🚀 ~ handleChange ~ _idExam:", _idExam)
    console.log("🚀 ~ handleChange ~ _id:", _id)

        console.log("estrellas");
        
          
        let _newArray = [...listMicroBacteriums]; 
        console.log("🚀 ~ handleChange ~ _newArray:", _newArray)
        let _valid = _newArray.find(x => (x.id_temp === _id && x.id_work_order_exam === _idExam) );
        console.log("🚀 ~ handleChange ~ _valid:", _valid)

        
        if (_valid && _valid.id_work_order_exam === _idExam) {    
            console.log("entras????");
            

            if (_type === "cim") {
                const _exist = _valid.antibiotics.findIndex(antibiotic => antibiotic.id_mic_antibiotic === _id_mic_antibiotic);

                if (_exist === -1) {
                    _valid.antibiotics.push({
                        cim: e?.target?.value,
                    });
                } else  _valid.antibiotics[_exist].cim = e?.target?.value;      
            }
            else{
                if (e.target.checked) {

                    const _exist = _valid.antibiotics.findIndex(antibiotic => antibiotic.id_mic_antibiotic === _id_mic_antibiotic);
                    
                    if (_exist === -1) {
                        _valid.antibiotics.push({
                            id_mic_antibiotic: _id_mic_antibiotic,
                            id_antibiotic_bacterium_result: _id_antibiotic_bacterium_result
                        });
                    } else _valid.antibiotics[_exist].id_antibiotic_bacterium_result = _id_antibiotic_bacterium_result;
                    
                } else {

                    const _indexExist = _valid.antibiotics.findIndex(antibiotic => antibiotic.id_mic_antibiotic === _id_mic_antibiotic);

                    if (_indexExist !== -1) {
                        _valid.antibiotics[_indexExist].id_antibiotic_bacterium_result = null; 
                    }
                }
            }
            setMicroBacteriums([..._newArray]);
        }
    };

    // ? END ANTIBIOGRAM
    const handleChangeBacteriumTexts = (e, _id, _idExam, _text, _id_mic_bacterium, _type,_ids) => {
       
        let _newArray = [...listMicroBacteriums]; 

        let _valid = _newArray.find(x => x.id_temp === _id);
    
        if (_valid && _valid.id_work_order_exam === _idExam) {    

            if (_type === "text") {
                const _exist = _valid.bacterium_texts.findIndex(text => text.id === _ids); 
    
                if (_exist !== -1) {
                    _valid.bacterium_texts[_exist].text = e.target.value;
                } else {
                    _valid.bacterium_texts.push({
                        id: Date.now(),
                        id_work_order_exam_bacterium_text: null,
                        text: e.target.value
                    });
                }
            }
            setMicroBacteriums([..._newArray]);
        }
    };
    
 
    return (
        <>
            <Row  className='pt-1 mt-4 ' key={`bacter-micro-${idExam+microBacteriums.id_mic_bacterium+position}`}>
                <ComponentMicroorganismIsolated
                    idExam={idExam}
                    microBacteriums={microBacteriums}
                    handleChangeBacter={handleChangeBacter}
                    listStainGrams={listStainGrams}
                    listOptionsBacteriums={listOptionsBacteriums}
                    handleGram={handleGram}
                    listBacteriumCount={listBacteriumCount}
                    listResistanceMechanism={listResistanceMechanism}
                    colorBacter={colorBacter}
                    warningBacter={warningBacter}
                    handleTypeBacterium={handleTypeBacterium}
                    microAntibiograms={microAntibiograms}
                    changeMicro={changeMicro}
                    listAntibiogram={listAntibiogram}
                    handleChange={handleChange}
                    antibitic={antibitic}
                    handleChangeAntibiogram={handleChangeAntibiogram}
                    handleChangeComments={handleChangeComments}
                    handleChangeBacteriumTexts={handleChangeBacteriumTexts}    
                    removeBacterium1={removeBacterium1}
                    removeBacteriumAntibitic={removeBacteriumAntibitic}
                    ValidateBacterium={ValidateBacterium}
                    examId={examId}
                    is_profile={is_profile}
                    sectionProfileId={sectionProfileId}
                    typeArray={typeArray}
                    UnValidateBacterium={UnValidateBacterium}
                    ReleaseBacterium={ReleaseBacterium}
                    UnReleaseBacterium={UnReleaseBacterium}
                    _typeClient={_typeClient}
                    cedimi={cedimi}

                />
            </Row> 
        </>
    )
}
