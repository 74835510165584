import React, { useState } from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row, Col, InputGroup, InputGroupAddon, InputGroupText,  } from 'reactstrap';
import "react-datetime/css/react-datetime.css";


export const FormSection = (props) => {

    const {
        handleFormSection, modalFormSection, toggleFormSection, titleFormSection, methodFormSection, loading,saveSamplingPlan,    valueGroup,handleChangeGroup,
        


    } = props;




    return (
        <div>
              <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-12">
                        <Label className="col-form-label" >Nombre del Bloque <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            placeholder="Nombre del bloque"
                            type="text"
                            required={true}
                            autoComplete="nope"
                            className="form-control form-control-sm input-air-primary"
                            value={valueGroup}  
                            onChange={handleChangeGroup}  
                        />

                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                   
                   
        
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    // color="primary"
                    outline color="primary"
                    type="submit"
                    size="sm"
                    onClick={ () =>  saveSamplingPlan(valueGroup)}

                >
                    Guardar
                </Button>
                <Button
                    size="sm"
                    className="btn-air-light"
                    outline color="danger"
                    type="button"
                    onClick={toggleFormSection}
                >
                    Cancelar
                </Button>
            </ModalFooter>

        </div>
          
    )
}
