import { sendRequest } from "../../hooks/requests/useRequest";


export async function getAllGroup (_type)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `ProfileGroup/List`);

    if (response.code === 200)
        return {
            code: response?.code,
            data: response?.data

           
        }
    else 
        return response;
}

export async function createAllgroup({
    name
}) {
    const body = {
        name
        
    };


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "ProfileGroup/Create"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
            data: response?.data
            
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            code: response?.code ,

        };
}


export async function UpdateAllgroup(result,_id) {

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(result)
    };

    const response = await sendRequest(
        requestOptions, `ProfileGroup/AssingGroups/${_id}` 
    );

    if (response.code === 200)
        return {
            code: response?.code ,
            data: response?.data
            
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            code: response?.code ,

        };
}


export async function getListGroup (_id_profile)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `ProfileGroup/ListProfile/${_id_profile}`);

    console.log("🚀 ~ response:", response)
    if (response.code === 200)
        return {
            code: response?.code,
            data: response?.data
        }
    else 
        return response;
}


