import React, { useState } from 'react';
import { Container, Row, Col, Card, FormGroup, Label, Input, Button, CardBody } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { usePendingWork } from '../../../hooks/workOrder/usePendingWork';
import Select from 'react-select';
import './styleDataTable.css';

export const IndexPendingWork = () => {
    const { 
        // #region Filters
        branches, mDateRange1, mDateRange2, mSelectBranch,
        // #endregion 

        handleInputChange, handleSelectValues, loading, 
        getPedingWork, reportPendingExams, handleClean,
        sections,mSelectSections,
        
        //Indicators
        totalExam, totalExamsReleased, totalPendingExams, pendingExamsPercentage, 
        
        // search Exams
        handleSearchPedingWork
        

    } = usePendingWork();

    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    const rowExpansionTemplate = (data) => {
        const columnsPerRow = 5;
    
        const rows = [];
        for (let i = 0; i < data.pending_nims.length; i += columnsPerRow) {
            rows.push(data.pending_nims.slice(i, i + columnsPerRow));
        }
    
        return (
            <div className="p-3">
                <h6 style={{fontSize: '12px', fontWeight: 'bold'}} className='text-secondary'>NIMs Pendientes</h6>
                <table className="table table-bordered" style={{ fontSize: "12px" }}>
                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((nim, index) => (
                                    <td key={index} className="text-center" style={{fontSize: '12px'}}>
                                        <a href={`/captura-resultados/${nim}`} target="_blank" rel="noopener noreferrer">
                                            {nim}
                                        </a>
                                    </td>
                                ))}
                                {Array(columnsPerRow - row.length).fill("").map((_, index) => (
                                    <td key={`empty-${index}`} />
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
        
    };

    

    return (
        <Container fluid={true}>
            {/* Filters */}
            <Card>
                <CardBody className='p-1'>
                    <FormGroup>
            
                        <Row>

                            <Col sm="2" md="2" lg="2" className='text-center f-s-italic text-info p-2'>
                            <Label className='f-w-600 f-12 badge'>       </Label>

                                <h5 className='f-16 f-w-700'>Trabajo Pendiente</h5>
                            </Col>
                            <Col sm="2" className='p-1'>
                                <Label className='f-w-600 f-12 badge badge-light-primary'>Fecha de inicio</Label>
                                <Input type='date' value={mDateRange1} name='mDateRange1' onChange={handleInputChange} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD'/>
                            </Col>
                            <Col sm="2" className='p-1'>
                                <Label className='f-w-600 f-12 badge badge-light-primary'>Fecha de cierre</Label>
                                <Input type='date' value={mDateRange2} name='mDateRange2' onChange={handleInputChange} className='form-control form-control-sm input-air-primary b-r-9' placeholder='YYYY-MM-DD' />
                            </Col>
                            <Col sm="2" >
                                    <FormGroup>
                                            <Label type="text" classNa4me='f-4-600 f412 badge badge-light-primary'>Sucursal</Label>
                                        <Select
                                            name="mSelectBranch"
                                            isClearable={true}
                                            placeholder="Selecciona una sucursal"
                                            options={branches}
                                            onChange={(e) => handleSelectValues(e, 'mSelectBranch')}
                                            value={mSelectBranch}
                                            className="select-branch"

                                        />
                                    </FormGroup>
                                </Col>
                            <Col sm="4">
                                <Label className='f-w-600 f-12 badge badge-light-primary'>Acciones</Label> <br />
                                <Button onClick={(e) => getPedingWork(e, true)} outline color="primary" size='sm' disabled={loading}>
                                    Filtrar
                                </Button>
                                <Button onClick={handleClean} outline color="danger" size='sm' disabled={loading} className='ml-2'>
                                    Limpiar
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>

                </CardBody>
            </Card>
            <Card>
                <CardBody className='pl-2 pb-2 pt-2' >
                    <Row>
                        <Col sm="3" style={{ borderRight: "1px solid rgba(7, 34, 109, 0.42)" }} >
                            <Col sm="12" className='p-l-0 p-r-0' >
                                <Row>
                                    <Col sm="3">
                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/pendingWork/test-results.svg")} style={{ width: "60px", height: "60px" }} />
                                    </Col>
                                    <Col sm="9" >
                                        <label className='f-w-500 ' style={{ fontSize: "13" }} >Total de Exámenes</label>
                                        <div className='text-center widgets-ind'>
                                            {
                                                loading && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                            }
                                            {
                                                !loading && <h4 className='text-success'>{totalExam()} </h4>
                                            }
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>

                        <Col sm="3" style={{ borderRight: "1px solid rgba(7, 34, 109, 0.42)" }} >
                            <Col sm="12" className='p-l-0 p-r-0' >
                                <Row>
                                    <Col sm="3">
                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/pendingWork/total-exam.svg")} style={{ width: "60px", height: "60px" }} />
                                    </Col>
                                    <Col sm="9" >
                                        <label className='f-w-500 ' style={{ fontSize: "13" }} >Total de Exámenes Liberados</label>
                                        <div className='text-center widgets-ind'>
                                            {
                                                loading && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                            }
                                            {
                                                !loading && <h4 className='text-success'>{totalExamsReleased()} </h4>
                                            }                                        
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>

                        <Col sm="3" style={{ borderRight: "1px solid rgba(7, 34, 109, 0.42)" }} >
                            <Col sm="12" className='p-l-0 p-r-0' >
                                <Row>
                                    <Col sm="3">
                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/pendingWork/exam-time.svg")} style={{ width: "60px", height: "60px" }} />
                                    </Col>
                                    <Col sm="9" >
                                        <label className='f-w-500 ' style={{ fontSize: "13" }} >Total de Exámenes Pendientes</label>
                                        <div className='text-center widgets-ind'>
                                            {
                                                loading && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                            }
                                            {
                                                !loading && <h4 className='text-success'>{totalPendingExams()}</h4>
                                            }
                                        
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>

                        <Col sm="3"  >
                            <Col sm="12" className='p-l-0 p-r-0' >
                                <Row>
                                    <Col sm="3">
                                        <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/reportCxcPercentage.svg")} style={{ width: "60px", height: "60px" }} />
                                    </Col>
                                    <Col sm="9" >
                                        <label className='f-w-500 ' style={{ fontSize: "13" }} >Cumplimiento en Porcentaje</label>
                                        <div className='text-center widgets-ind'>
                                            {
                                                loading && <i className="fa fa-spin fa-spinner font-primary f-24"></i>
                                            }
                                            {
                                                !loading && <h4 className='text-success'>{pendingExamsPercentage()} %</h4>
                                            }
                                        
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            
            {/* search*/}

            <Row className='mb-2 justify-content-end'>
                <Col sm="4">
                    <Input type='search' placeholder='Busqueda...' className='form-control' onChange={handleSearchPedingWork} />
                </Col>
            </Row>


            {/* Table "work pending" */}
            
            <Row>
                <Col sm="4">
                    <Card>
                        <div className="ribbon ribbon-clip ribbon-primary">Secciones</div>
                        <CardBody className='mt-3'> 
                            <div className="d-flex flex-column align-items-center">
                                {sections.length > 0 ? (
                                    sections.reduce((rows, section, index) => {
                                        if (index % 2 === 0) {
                                            rows.push([section]);
                                        } else {
                                            rows[rows.length - 1].push(section);
                                        }
                                        return rows;
                                    }, []).map((row, rowIndex) => (
                                        <div key={rowIndex} className="d-flex w-100 justify-content-center mb-2">
                                            {row.map((section) => (
                                                <Button
                                                    key={section.value}
                                                    onClick={() => {
                                                        // console.log("Seleccionando sección:", section.value);
                                                        handleSelectValues(section, 'mSelectSections');
                                                    }}
                                                    color={mSelectSections?.value === section.value ? 'primary' : 'secondary'}
                                                    size="sm"
                                                    className="w-50 mx-1 text-center"
                                                    style={{ fontSize: '12px', padding: '5px 10px' }}
                                                    outline={mSelectSections?.value !== section.value}
                                                    >
                                                    {section.label}
                                                </Button>
                                            ))}
                                        </div>
                                
                                    
                                    ))
                                ) : (
                                    <p style={{ fontSize: '12px' }}>No hay secciones disponibles</p>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>


                <Col sm="8">
                    <div className='table-responsive'>
                        {loading ? (
                            <>
                                <Col sm="12" md="12" lg="12" className='text-center pt-5'>
                                    <img alt='modal-preview' src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "15%" }} />
                                </Col>
                                <Col sm="12" md="12" lg="12" className='text-center mt-1'>
                                    <p className='txt-secondary'>Cargando...</p>
                                </Col>
                            </>
                            
                        ) : reportPendingExams.length > 0 ? (
                            
                            <DataTable 
                                value={reportPendingExams} 
                                expandedRows={expandedRows} 
                                onRowToggle={(e) => setExpandedRows(e.data)} 
                                rowExpansionTemplate={rowExpansionTemplate}
                                paginator 
                                rows={10} 
                                rowsPerPageOptions={[10, 20, 50]} 
                                className='data-table'
                                globalFilter={globalFilter}
                                emptyMessage={
                                    <div className="text-center">
                                        <img 
                                            src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} 
                                            style={{ width: "45%" }} 
                                            alt="Sin resultados" 
                                        />
                                        <br />
                                        <p className='badge badge-light-primary mt-2' style={{fontSize: "15px"}}>
                                            No hay coincidencias con la búsqueda.
                                        </p>
                                    </div>
                                }
                                >
                                <Column 
                                    expander 
                                    style={{ width: '2rem', height: '2vh'  }} 
                                    headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold" }}

                                />
                                <Column 
                                    field="name" 
                                    header="Nombre" 
                                    sortable 
                                    headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize:"12px" }} 
                                    style={{fontSize:"12px"}}
                                

                                />
                                <Column 
                                    field="num_exams" 
                                    header="Total de Exámenes" 
                                    sortable 
                                    headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize:"12px" }} 
                                    style={{fontSize:"12px", textAlign: 'center'}}

                                />
                                <Column 
                                    field="num_exams_released" 
                                    header="Exámenes Liberados" 
                                    sortable 
                                    headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize: "12px" }} 
                                    style={{fontSize:"12px", textAlign: 'center'}}
                                />

                                <Column 
                                    field="pendingExams" 
                                    header="Exámenes Pendientes" 
                                    sortable 
                                    headerStyle={{ backgroundColor: "#1d5b83", color: "#fff", fontWeight: "bold", fontSize: "12px" }} 
                                    style={{fontSize:"12px", textAlign: 'center'}}
                                />
                            </DataTable>

                        ) : (
                            <div className="text-center">
                                <img 
                                    src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} 
                                    style={{ width: "60%" }} 
                                    alt="Sin resultados" 
                                />
                                <br />
                                <p className='badge badge-light-primary mt-2' style={{fontSize: "15px"}}>Sin Trabajos Pendientes.</p>
                            </div>
                        )}
                
                    </div>
                    <br /><br />
                </Col>
            </Row>
        </Container>
    );
};
