import React from 'react'
import { Button, Card, Col, Label, Row } from 'reactstrap';
import { ToolTips } from '../tooltips/ToolTips';

export const CardItemAgreement = (props) => {

    const {
        items, objId, methodsActions, methodsAbsolutes, handleDelete, handleUpdateStatus, navigateUpdate, handleListProfile, handleUpdateCategory,downdloadExcel
    } = props;


    let id_branch = localStorage.getItem('branchId');


    return (
        <>
            <Col sm="12" md="3" lg="3" xl="3" className={`${!items.visibleTable && 'd-none'}`}>
                <Card className='shadow-lg p-1 shadow-showcase ribbon-wrapper' style={{ height: '320px' }}>
                    <div className={ items.status ? "ribbon ribbon-clip ribbon-left ribbon-lis" : "ribbon ribbon-clip ribbon-left ribbon-lis2" }>
                        {
                            items.status ? "Aprobado" : "En revisión"
                        }
                    </div>
                    <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                        <Button id={`tooltip-in-activate-${objId}`} outline color={ items.status ? 'info' : 'success' } size='xs' onClick={() => handleUpdateStatus(items.id_agreement)}>
                            {
                                items.status ? <i className='fa fa-times'></i> : <i className='icofont icofont-ui-check'></i>
                            }
                            <ToolTips
                                placement="top"
                                dataTarget={`tooltip-in-activate-${objId}`}
                                dataText={ items.status ? "Inactivar convenio" : "Activar convenio" }
                            />
                        </Button>
                        {
                            !items.status && <Button id={`tooltip-edit-${objId}`} outline className='btn btn-air-info' color="info" size='xs' onClick={() => navigateUpdate(items.id_agreement)}>
                                <i className='icofont icofont-ui-edit f-14'></i>
                                <ToolTips
                                    placement='top'
                                    dataTarget={`tooltip-edit-${objId}`}
                                    dataText="Editar convenio"
                                />
                            </Button>
                        }
                        <Button id={`tooltip-delete-${objId}`} outline className='btn btn-air-info' color='danger' size='xs' onClick={() => handleDelete(items.id_agreement)}>
                            <i className="icofont icofont-ui-delete f-14"></i>
                            <ToolTips
                                placement="top"
                                dataTarget={`tooltip-delete-${objId}`}
                                dataText={ 'Eliminar convenio' }
                            />
                        </Button>
                        <Button id={`tooltip-listTest-${objId}`} outline className='btn btn-air-info' color='success' size='xs' onClick={() => downdloadExcel(items.id_agreement,id_branch,items.id_commercial_line)}>
                            <i className="fa fa-file-excel-o  f-14 "></i>
                            <ToolTips
                                placement="top"
                                dataTarget={`tooltip-listTest-${objId}`}
                                dataText={ 'Descargar Precios' }
                            />
                        </Button>
        
                    </div>
                    <Col sm="12" className='p-l-0 p-r-0'>
                        <Row>
                            <Col sm="1"></Col>
                            <Col sm="7" style={{textAlign: "right"}}>
                                <div className='profile-vector'>
                                    {
                                        (items.status) 
                                        ? <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Agreement/AgremmentAprovado.svg")} style={{ width: "58%" }} alt="" />
                                        : <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Agreement/AgremmentVencido.svg")} style={{ width: "58%" }} alt="" />                                        
                                    }
                                </div>
                            </Col>
                            <Col sm="1">
                                <br /><br />
                                <Label>
                                    {
                                        items.validityAgreement 
                                        ? <span className="badge badge-danger f-right"><i className="fa fa-exclamation-circle"></i> &nbsp;Vencido</span>
                                        : <span className="badge badge- f-right">&nbsp;{items.validity}</span>                                        
                                    }
                                </Label>
                            </Col>
                            <Col sm="12" className='p-l-0 p-r-0' style={{ textAlign: "-webkit-center" }}>
                                <label className='text-center f-12'>Contrato: {items.contract_number}</label> <br />
                                <label className='name_company text-secondary f-12'> { items.id_company !== null ?  items.name_company : items.name_doctor}</label>
                            </Col>
                            <Col sm="12" >
                                <Row>
                                    <Col md="6" className='text-center p-l-0 p-r-0'>
                                        <label className='text-secondary f-12' >Método financiero</label>
                                    </Col>
                                    {/* <Col md="6" className='text-right p-l-0 '>
                                        <label className='text-secondary f-12' >Método de Pago</label>    
                                    </Col> */}
                                    <Col md="6" className='text-center'>
                                        <label className='f-12'>{items.financing_method_name === null ? " ----- " : items.financing_method_name}</label>
                                    </Col>
                                    {/* <Col md="6" className='text-center'>
                                        <label className='f-12'>{items.name_pay_method}</label>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card>
            </Col>
        </>
    )
}
