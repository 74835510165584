import React, { Fragment, useState } from 'react'
import '../../../../assets/css/pantalla1.css'
import '../../../../assets/css/analiticaCards.css'
import { Container, Row, Col, Label, Button, Media, Table, Pagination, FormGroup, Input, Form, Card, CardHeader } from 'reactstrap';
import { useSend } from '../../../../hooks/send/useSend';
import { ModalSendSamplings } from './ModalSendSamplings';
import DeniReactTreeView from "deni-react-treeview"
import { ModalInfo } from './ModalInfo';
import { ToolTips } from '../../../../components/tooltips/ToolTips';
import { ModalSendRefused } from './ModalSendRefused';
import { ModalPendingSend } from './ModalPendingSend';
import 'animate.css';
import { ModalMicro } from './ModalMicro';





export const IndexSend = () => {

    const {
        //flebo 
        phlebotomist_name,
        //modal send
        typeSend, modalSend, openModal, closeModal, handleSendSample, nimAutoFocus, onKeyPressNim, getDataNim,
        handleSelectValuesNim, nim, setFocusNim, handleInputChangeNim, viewExams, handleCloseViewSend, loading,
        //info patient
        namepatien, age, curp, phone, gender, treeviewRef, itemsTree, listNims, flebo, idWorkOrder,
        openModalInfo, closeModalInfo, handleInfo, typeInfo, modalInfo, loadinginfo,
        //refused Sample
        typeModalRefused, modalRefused, closeModalCancell, handleCancell, reason, handleSelectValuesR, id_return_reason,
        datanim, refusedPatient, varSample, validateSpecimen, pruebas, itemsDontSend, handleDontSend, handleDontSendAll, handlePendingAll, valueDontSend,
        //pending Send Sample
        openModalPending, closeModalPending, handlePenndingSend, typePending, modalPending, list_analytes,
        //refused patient
        changeTree, saveSendSample, historic, loadinPending, dateInit, dateEnd, changeDate, handleHistoricSend,
        //Pagination
        nextPage, previousPage, totalPageCount, currentPage, goToPage, getNim, handleSearchNim, handleCleanFilter,
        is_micro, typeMicro, modalMicro, closeModalMicro, openModalMicro, micDetail, thermometers, id_thermometer_send,
        temperature_send, temperature_corrected_send, comply_temperature_send, identification_send, container_send, quantity_send,
        handleInputChangeMic, list_mic_sample, handleChangeMic, saveMicDetailSend, factorCorrection, codeMic, dataMic, handleView,

        //completeExams
        completeAllExams
    } = useSend();


    const onRenderItem = (_find) => {
        return (
            <div className="treeview-item-example">
                {


                    _find.statusTracking === true ?
                        <span className='text-success'>
                            <i className="icofont icofont-laboratory f-12"></i>{_find.text}&nbsp;
                        </span>
                        :
                        _find.state === 1 && _find.check_forwarded === false ?
                            <span className='text-success'>
                                <i className="icofont icofont-laboratory f-12"></i>{_find.text}&nbsp;
                                {
                                    _find.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                        _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                                            _find.valueDontSend === 1 ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                }&nbsp;
                                {
                                    _find.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                        _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                                            _find.valueDontSend === 1 ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                }&nbsp;
                                {
                                    _find.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> :
                                        _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                                            _find.valueDontSend === 1 ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                }&nbsp;
                            </span>
                            :
                            _find.state === 1 && _find.check_forwarded === true ?
                                <span className=''>
                                    <i className="icofont icofont-laboratory f-12"></i>{_find.text}&nbsp;
                                    {
                                        _find.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                            _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                                                _find.valueDontSend === 1 ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                    {
                                        _find.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                            _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                                                _find.valueDontSend === 1 ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                    {
                                        _find.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> :
                                            _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                                                _find.valueDontSend === 1 ? <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                                    }&nbsp;
                                </span>
                                :
                                _find.valueDontSend === 1 ?
                                    <span className='text-danger'>
                                        <i className="icofont icofont-laboratory txt-danger f-12"></i>{_find.text}&nbsp;
                                        {
                                            _find.valueDontSend === 1 ? <i className='icofont icofont-ui-block text-danger f-16' ></i> :
                                                _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                                                    <i className="icofont icofont-laboratory txt-danger f-12"></i>
                                        }&nbsp;
                                    </span>
                                    :
                                    <span style={{ color: '#FF8F00' }}>
                                        <i className="icofont icofont-laboratory txt-danger f-12"></i>{_find.text}&nbsp;
                                        {
                                            _find.valueDontSend === 1 ? <i className='icofont icofont-ui-block text-danger f-16' ></i> :
                                                _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                                                    <i className="icofont icofont-laboratory txt-danger f-12"></i>
                                        }&nbsp;
                                    </span>

                }
            </div>
        )
    }


    return (
        <Fragment>
            <Container fluid={true} className='bg-barra1 p-0'  >
                <Col xs="12" sm="12" md="12" lg="12" xl="12" className="">
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className=" p-l-0 p-r-0"> <br />
                            <div className='box-departamento'>
                                <Col sm="12" style={{ textAlign: "-webkit-center" }}>
                                    <Row>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0'>
                                            <Label className='labelToma1'></Label>
                                        </Col>
                                        <Col xs="9" sm="9" md="9" lg="9" xl="9">
                                            <label className='labelToma1'>
                                                {
                                                    varSample === false ? "Recibir Muestras" : "Envio de Muestras"
                                                }
                                            </label>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Row>
                        <Col xs="3" sm="3" md="3" lg="3" xl="4">
                            <Col sm="12" className='p-l-0 p-r-0'>
                                <Form onSubmit={(e) => getNim(e, true)}>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup className="col-md-12">
                                                <Label className="col-form-label">Ingresa el NIM<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                <Input className='form-control form-control-sm input-air-primary' name="nim" value={nim} onChange={handleInputChangeNim} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <Row>
                                                <Col xs="6" md="6" style={{ textAlign: "end" }} >
                                                    <br />
                                                    <br />
                                                    <Button type="submit" outline color="primary" size='xs' className={("text-center mt-1")} > Buscar</Button>
                                                </Col>
                                                <Col xs="6" md="6" className='p-1' >
                                                    <br />
                                                    <br />
                                                    <Button outline color='danger' size='xs' className={("text-center")} onClick={handleCleanFilter}>Limpiar</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>

                                <Row>
                                    <div className='table-responsive'>
                                        <Table size='sm' striped bordered hover>
                                            <thead>
                                                <tr className='text-center'>
                                                    <th>NIM</th>
                                                    <th>Estatus</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loading === true
                                                        ?
                                                        <tr>
                                                            <td colSpan={10} className="text-center">
                                                                <Col md="12" className='text-center'>
                                                                    <Row className='mt-2'>
                                                                        <Col sm="12" md="12" lg="12" className='text-center'>
                                                                            <img src={require("../../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '130px' }} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='mt-2'>
                                                                        <Col sm="3" md="3" lg="4" className='text-center'></Col>
                                                                        <Col sm="2" md="2" lg="2" className='text-left p-r-0'> <br />
                                                                            <p className='f-14 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p>
                                                                        </Col>
                                                                        <Col sm="1" md="1" lg="1" className=' p-l-0'>
                                                                            <br />
                                                                            <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
                                                                        </Col>
                                                                        <Col sm="1" md="1" lg="1" className=' p-l-0'>
                                                                            <br />
                                                                            <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-12"></i></p>
                                                                        </Col>
                                                                        <Col sm="1" md="1" lg="1" className='p-l-0'>
                                                                            <br />
                                                                            <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-12"></i></p>
                                                                        </Col>
                                                                        <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                                    </Row>
                                                                </Col>

                                                            </td>
                                                        </tr>
                                                        :
                                                        listNims.length >= 1 && listNims.map((_obj, _key) => {
                                                            //console.log(listNims, "lista de nims")
                                                            return (
                                                                <tr key={`trList-${_key}`} hidden={!_obj.visibleTable} >
                                                                    <td style={{ textAlign: "center" }} > <label className={_obj.is_refused === true ? "txt-danger" : ""}>{_obj.nim}</label></td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        <i
                                                                            className={_obj.enviado === "recibido" ? "icofont icofont-send-mail f-26 text-success" : "icofont icofont-send-mail f-26 text-danger"}
                                                                            id={`tooltip-muestra-${_key}`}
                                                                        >
                                                                        </i>
                                                                        {
                                                                            _obj.completeFlebotomia === false ? <i className='icofont icofont-laboratory text-danger animate__animated animate__fadeInDown animate__slow animate__infinite'></i> : ""
                                                                        }
                                                                        <ToolTips
                                                                            placement="top"
                                                                            dataTarget={`tooltip-muestra-${_key}`}
                                                                            dataText={_obj.enviado === "recibido" ? _obj.enviado : "Enviado"}
                                                                        />
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }} className={_obj.isMic === true ? "d-none" : ""}   >
                                                                        <Button outline color='primary' size='xs' onClick={() => handleInfo(_obj.nim)}>
                                                                            <i
                                                                                id={`tooltip-info-${_key}`}
                                                                                className='fa fa-eye f-12 pointer txt-secondary'
                                                                            >
                                                                            </i>
                                                                            <ToolTips
                                                                                placement="top"
                                                                                dataTarget={`tooltip-info-${_key}`}
                                                                                dataText="Detalles"
                                                                            />
                                                                        </Button>
                                                                        <Button outline color='danger' size='xs' onClick={() => handleCancell(_obj.nim)}>
                                                                            <i
                                                                                id={`tooltip-cancell-${_key}`}
                                                                                className='icofont icofont-close-circled f-12 text-danger pointer'
                                                                            >
                                                                            </i>
                                                                            <ToolTips
                                                                                placement="top"
                                                                                dataTarget={`tooltip-cancell-${_key}`}
                                                                                dataText="Rechazar"
                                                                            />
                                                                        </Button>
                                                                        &nbsp; &nbsp;
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Row>
                                {
                                    nextPage !== null && previousPage !== null &&
                                    <Row>
                                        <Col sm="12" md="12" lg="12" xl="12">
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Pagination aria-label='...' style={{ justifyContent: "center" }}>
                                                                <ul className='pagination pagination-primary'>
                                                                    {
                                                                        currentPage === 1
                                                                            ?
                                                                            <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                                            :
                                                                            <li style={{ cursor: 'pointer' }} className="page-item" onClick={previousPage}><span className='page-link'>Anterior</span></li>
                                                                    }
                                                                    {
                                                                        currentPage > 1 &&
                                                                        <>
                                                                            <li style={{ cursor: 'pointer' }} onClick={() => goToPage(1)} className="page-item"><span className='page-link'>1</span></li>
                                                                            {
                                                                                currentPage > 2 &&
                                                                                <>
                                                                                    <li className='page-item'><span className='page-link'>...</span></li>
                                                                                    <li style={{ cursor: 'pointer' }} onClick={() => goToPage(currentPage - 1)} className='page-item'><span className='page-link'>{currentPage - 1}</span></li>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                    <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{currentPage}</span></li>
                                                                    {
                                                                        currentPage < totalPageCount &&
                                                                        <>
                                                                            {
                                                                                currentPage < (totalPageCount - 1) &&
                                                                                <>
                                                                                    <li style={{ cursor: 'pointer' }} onClick={() => goToPage(currentPage + 1)} className="page-item"><span className='page-link'>{currentPage + 1}</span></li>
                                                                                    <li className='page-item'><span className='page-link'>...</span></li>
                                                                                </>
                                                                            }
                                                                            <li style={{ cursor: "pointer" }} onClick={() => goToPage(totalPageCount)} className="page-item"><span className='page-link'>{totalPageCount}</span></li>
                                                                        </>
                                                                    }
                                                                    {
                                                                        totalPageCount === currentPage ?
                                                                            <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                                                            :
                                                                            <li style={{ cursor: 'pointer' }} className='page-item primary' onClick={nextPage}><span className='page-link primary'>Siguiente</span></li>
                                                                    }
                                                                </ul>
                                                            </Pagination>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Col>
                        <Col xs="9" sm="9" md="9" lg="9" xl="8" style={{ textAlign: "center" }} className='p-r-0 p-l-0' >
                            <div style={{ position: 'absolute', left: "-800", top: "-1000", zIndex: 3 }} className={'ocultar-input'}	>
                                <input
                                    id='inputFocusNim'
                                    autoFocus={setFocusNim}
                                    value={nim}
                                    onChange={(e) => handleSelectValuesNim(e.target.value, "nim")}
                                    onKeyDown={onKeyPressNim}
                                    onBlur={nimAutoFocus}
                                />
                            </div>
                            <Col sm="12" className={!viewExams ? "" : "d-none"}>
                                <div style={{ zIndex: 1 }}>
                                    <img className="" src={require("../../../../assetsToma/imagenes/Barras.gif")} style={{ width: "38%" }} alt="" />
                                </div>
                                <div className='a-line'>
                                    <label className='labelIniciarMuestra'>Escanea el <b className=''>Código de barras </b> de las muestras</label> <br />
                                </div>
                                <label className='labelCode'>o</label> <br />
                                <button className='bottonIniciarMuestra' onClick={handleSendSample} > Ingresálo manualmente</button> <br /><br /><br />
                                <div className='tap-top' style={{ display: "block" }} ><i className="icon-angle-double-up f-24"></i>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewExams === true ? "cardSampleD" : "d-none"} >
                                <Col sm="12" className='p-r-0' style={{ textAlign: "end" }} >
                                    <br />
                                    <Button outline color='danger' size='sm' onClick={() => handleCloseViewSend()}> Regresar al registro</Button>

                                </Col>
                                <Col sm="12" className='text-center'>

                                    <div className="user-image">
                                        <div className="avatar">
                                            {
                                                gender === 2 ?
                                                    <Media body style={{ width: "15%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserMen.svg")} />
                                                    :
                                                    <Media body style={{ width: "15%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} />
                                            }
                                        </div>
                                        <div className="icon-wrapper" data-intro="Change Profile image here">
                                        </div>
                                    </div>
                                    <div className="info">
                                        <Row>
                                            <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                                                <Row >
                                                    <Col md="6">
                                                        <div className="user-designation">
                                                            <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Sexo"}</a></div>
                                                            <div className="desc  detailPatienModal f-14">
                                                                {
                                                                    gender === 2 ? "Masculino" : "Femenino"
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="user-designation">
                                                            <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Edad"}</a></div>
                                                            <div className="desc detailPatienModal f-14">
                                                                {age}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                                                <div className="user-designation">
                                                    <div className="title"><b target="_blank" >{"Nombre"}</b></div>
                                                    <div className="desc mt-2 detailPatienModal">{namepatien}</div>
                                                </div>
                                                <br />
                                                <div className="user-designation">
                                                    <div className="title"><b target="_blank" >{"NIM"}</b></div>
                                                    <div className="desc mt-2 detailPatienModal">{datanim}</div>
                                                </div>
                                            </Col>

                                            <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                                                <Row>
                                                    <Col md="3">
                                                        <div className="user-designation">
                                                            <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Curp"}</a></div>
                                                            <div className="desc detailPatienModal f-12">
                                                                {curp}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="">
                                                        <div className="user-designation">
                                                            <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Telefono"}</a></div>
                                                            <div className="desc detailPatienModal f-12">
                                                                {phone}
                                                            </div>
                                                        </div>
                                                    </Col>


                                                </Row>
                                            </Col>
                                        </Row>

                                        <hr />
                                        <div className="social-media step4" data-intro="This is your Social details">
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><a href="#javascript"></a></li>
                                                <li className="list-inline-item"><a href="#javascript"></a></li>
                                                <li className="list-inline-item"><a ><i class="fa fa-circle iconResults f-12"></i></a></li>
                                                <li className="list-inline-item"><a href="#javascript"></a></li>
                                                <li className="list-inline-item"><a href="#javascript"></a></li>
                                            </ul>
                                        </div>

                                        <div className="follow">
                                            <Row>
                                                {
                                                    (varSample === false || (completeAllExams === false && varSample === true)) &&
                                                    <Col sm="12">
                                                        <div className="follow-num counter txt-primary f-14">{"Estudios a realizar del paciente:"}</div>
                                                    </Col>

                                                }

                                            </Row>
                                        </div>
                                    </div>

                                    {
                                        completeAllExams === true && varSample === true ?
                                            <Col sm="12">

                                                <div className="position-relative">
                                                    <img className="mx-auto d-block" height="33%" width="33%" src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExaW1zb3lpN205eGRkM3EyejcyNXZvNXNzcWZ3dnJ2NG9zaXp5b3hzcSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9ZQ/UVqhzNsYWIelUBV7zN/giphy.gif" alt="Bien" />
                                                    <Label className="completeExams mx-auto d-block f-16 font-weight-bold text-center text-white bg-primary p-2 rounded-pill">Se han enviado todas las muestras, no es necesario volver a aceptar el NIM</Label>

                                                </div>

                                            </Col>
                                            :
                                            <Col sm="12">

                                                <Row>

                                                    <Col sm="3"></Col>

                                                    <Col sm="9">

                                                        <Row>
                                                            {is_micro ? (
                                                                <ul className="list-group" style={{ display: "-webkit-inline-box" }}>
                                                                    <li className="list-group-item list-group-item-success">Registrada</li>
                                                                    <li style={{ backgroundColor: "#FF8F00" }} className="list-group-item">Sin datos registrados</li>
                                                                </ul>
                                                            ) : (
                                                                <ul className="list-group" style={{ display: "-webkit-inline-box" }}>
                                                                    <li className="list-group-item list-group-item-success">Con muestra</li>
                                                                    <li className="list-group-item list-group-item-primary">Remitida</li>
                                                                    <li style={{ backgroundColor: "#FF8F00" }} className="list-group-item">Sin muestra</li>
                                                                    <li className="list-group-item list-group-item-danger">Rechazo</li>
                                                                </ul>
                                                            )}
                                                        </Row>




                                                        <DeniReactTreeView
                                                            ref={treeviewRef}
                                                            style={{ marginRight: '10px', marginBottom: '10px', height: "200px", textAlign: "center" }}
                                                            showCheckbox={false}
                                                            showIcon={false}
                                                            items={itemsTree}
                                                            selectRow={false}
                                                            onRenderItem={onRenderItem}
                                                            onCheckItem={(e) => changeTree(e)}
                                                            className="deni-react-treeview-container.green .deni-react-treeview-item-container.green .icon-and-text.selected"
                                                        />

                                                    </Col>
                                                </Row>
                                                <Col sm="12">
                                                    <Button outline color='primary' size='sm' onClick={() => saveSendSample()} >Aceptar</Button>
                                                </Col>
                                            </Col>
                                    }
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Container>
            <ModalSendSamplings
                {
                ...{
                    typeSend, modalSend, openModal, closeModal, getDataNim, handleSelectValuesNim, nim, handleInputChangeNim, getNim, loading
                }
                }
            />
            <ModalInfo
                {
                ...{
                    openModalInfo, closeModalInfo, handleInfo, typeInfo, modalInfo, codeMic, dataMic,
                    namepatien, age, curp, phone, gender, treeviewRef, itemsTree, listNims, flebo, onRenderItem, loadinginfo,
                    changeTree, saveSendSample, varSample, loading, thermometers, id_thermometer_send, factorCorrection,
                    typeMicro
                }
                }
            />
            <ModalSendRefused
                {
                ...{
                    typeModalRefused, modalRefused, closeModalCancell, reason, handleSelectValuesR, id_return_reason,
                    loadinginfo, flebo, namepatien, datanim, treeviewRef, itemsTree, onRenderItem, refusedPatient, idWorkOrder,
                    saveSendSample, loading, factorCorrection, validateSpecimen, pruebas, handleView, itemsDontSend, handleDontSend, handleDontSendAll, handlePendingAll,
                    valueDontSend, phlebotomist_name, list_analytes,
                }
                }
            />
            <ModalPendingSend
                {
                ...{
                    openModalPending, closeModalPending, typePending, modalPending, loadinginfo, historic, loadinPending, dateInit,
                    dateEnd, changeDate, handleHistoricSend, loading
                }
                }
            />
            <ModalMicro
                {
                ...{

                    typeMicro, modalMicro, closeModalMicro, openModalMicro, micDetail, thermometers, id_thermometer_send, codeMic, dataMic,
                    temperature_send, temperature_corrected_send, comply_temperature_send, identification_send, container_send, quantity_send,
                    handleInputChangeMic, list_mic_sample, handleChangeMic, saveMicDetailSend, factorCorrection
                }
                }
            />
        </Fragment >
    )
}
