import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormCedula } from '../../../components/users/FormCedula';
export const ModalCedula = (props) => {
    const {
        modalCedula, toggleCedula,modalTitleCard, typeCedula, handleInputChange, professional_license, userId , saveProfessionalLicense, handleInputChangeC, formValuesC
    } = props;
        console.log("🚀 ~ ModalCedula1 ~ toggleCedula1:", toggleCedula)
    

    console.log("ModalCedula isOpen: ", modalCedula);

    return (
        <Modal isOpen={modalCedula} toggle={toggleCedula} backdrop="static" keyboard={true} centered={false}>
            <ModalHeader toggle={toggleCedula} className="bg-primary">
                {modalTitleCard}
            </ModalHeader>
            {
                typeCedula === "typeCedula"
                    ?
                    <FormCedula button='Guardar' {...{ toggleCedula, modalCedula, professional_license, userId , saveProfessionalLicense, handleInputChangeC,typeCedula, formValuesC
                    }} />
                    : ""
            }

        </Modal>
    );
};

