import React, { useState, Fragment } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, FormText, Row, Table, CardHeader, Collapse, Label, Input, ButtonGroup } from 'reactstrap';
import Select from "react-select";
import { MultiSelect } from 'primereact/multiselect';
import '../../../hooks/componentMultiSelect/MultiSelectDemo.css';
import { SearchAsync } from '../../../components/elements/SearchAsync';


export const DataConfigurations = (props) => {

    const {
        indexs,
        id_machine_sanitarytemp,
        handleAddLote,
        handleSelectMachineSanitary,
        id_lote_sanitarytemp, handleSelectLote, searchLoteSanitary,
        list_determinations_save, AddNewLote, id_index_deter, id_exam, AddNewMachine,
        changeInputCarac,
        changeInputElement,
        handleMachineValueChange,
        handleLoteValueChange,
        formValues,
        listProcessConfig_save,
        handleDeleteMachine,
        handleDeleteLote,
        idwsconfig,
        changeInputElementCheck,
        CancelSheet

        //860 useaddagremet

    } = props;
    
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            zIndex: 999
        }),
    }
    console.log(indexs,"indexs");
    return (
        <div>
            <Row key={indexs.id_process_config}>
                <Col sm="9" md="9" lg="9" xl="9">
                    <Label className="f-18"><b>{indexs.name}</b></Label>
                </Col>
                {indexs.estatus_pcrws !== null && (
                    <>
                    <Col sm="3" md="3" lg="3" xl="3" className='p-2'>
                            <Button color="danger" class="btn-air-danger btn-sm btn-outline-danger" size="sm"
                                style={{ marginLeft: '10px', flex: '0 0 auto' }}
                                onClick={() => CancelSheet(indexs.id_worksheet_wss, indexs.id_process_config_wss)}>
                                CANCELAR
                            </Button>
                        </Col>
                        </>
                )}
                {indexs.is_lm === 1 && indexs.estatus_pcr !== 2 && (
                    <>
                        <Col sm="3" md="3" lg="3" xl="3">
                            <Label className="f-16">{"LOTES DE MEDIOS"}<span style={{ color: "#0079C7" }}>*</span></Label>

                            <SearchAsync
                                cacheOptions={false}
                                value={id_lote_sanitarytemp}
                                name="id_lote_sanitarytemp" // Aquí estaba "id_machine_sanitarytemp"
                                method={handleSelectLote}
                                loincState={searchLoteSanitary}
                                url="CatLotesSanitary/SearchCatLoteSanitary"
                                maxLenght={3}
                                placeholder="Búsqueda de lotes de medios"
                            />
                        </Col>
                        <Col sm="3" md="3" lg="3" xl="3" style={{ marginTop: "2.5rem" }}>

                            <Button outline color='primary' size='sm' onClick={() => AddNewLote(id_exam, indexs.id_process_config)}>
                                Agregar Lote
                            </Button>
                        </Col>
                    </>
                )}
                {indexs.is_ec === 1 && indexs.estatus_pcr !== 2 && (
                    <>
                        <Col sm="3" md="3" lg="3" xl="3">
                            <Label className="f-16">{"EQUIPOS Y CONSUMIBLES (Clave y lote)"} <span style={{ color: "#0079C7" }}>*</span></Label>
                            <SearchAsync

                                cacheOptions={false}
                                value={id_machine_sanitarytemp}
                                name="id_machine_sanitarytemp"
                                method={handleSelectMachineSanitary}
                                loincState={searchLoteSanitary}
                                url="CatMachineSanitary/SearchMachineSanitary"
                                maxLenght={3}
                                placeholder="Búsqueda de equipos y consumibles"
                            />
                        </Col>
                        <Col sm="3" md="3" lg="3" xl="3" style={{ marginTop: "2.5rem" }}>
                            <Button outline color='primary' size='sm' onClick={() => AddNewMachine(id_exam, indexs.id_process_config)}>
                                Agregar Equipo
                            </Button>
                        </Col>
                    </>
                )}
                {indexs.is_lm === 1 && (
                    <>
                        <Col sm="6" md="6" lg="6" xl="6" style={{ marginTop: "2.5rem" }}>
                            {indexs.ListLotesCapture && indexs.ListLotesCapture.map((lote, loteIndex) => (
                                <div key={loteIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <Label className="f-16" style={{ flex: '1' }}><b>*{lote.n_lote} - {lote.descripcion}</b></Label>
                                    <Input
                                        disabled={indexs.estatus_pcr === 2 || indexs.estatus_pcr === 4}
                                        type="text"
                                        value={lote.valor || ''} // Utiliza el valor de la propiedad 'valor' si está definido
                                        onChange={(e) => handleLoteValueChange(e, loteIndex, indexs.id_process_config)}
                                        style={{ flex: '2' }}
                                    />
                                    <Button color="danger" class="btn-air-danger btn btn-outline-danger btn-xs" size="sm"
                                        style={{ marginLeft: '10px', flex: '0 0 auto' }}
                                        onClick={() => handleDeleteLote(loteIndex, indexs.id_process_config)}>
                                        <i class="fa fa-trash-o"></i>
                                    </Button>
                                </div>
                            ))}
                        </Col>
                    </>
                )}
                {indexs.is_ec === 1 && (
                    <>
                        <Col sm="6" md="6" lg="6" xl="6" style={{ marginTop: "2.5rem" }}>
                            {indexs.ListMachineCapture && indexs.ListMachineCapture.map((machine, machineIndex) => (
                                <div key={machineIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <Label className="f-16" style={{ flex: '1' }}><b>*{machine.descripcion} - {machine.clave}</b></Label>
                                    <Input
                                        disabled={indexs.estatus_pcr === 2 || indexs.estatus_pcr === 4}
                                        type="text"
                                        value={machine.valor || ''} // Utiliza el valor de la propiedad 'valor' si está definido
                                        onChange={(e) => handleMachineValueChange(e, machineIndex, indexs.id_process_config)} // Llama a una función para manejar el cambio de valor de la máquina
                                        style={{ flex: '2' }}
                                    />
                                    <Button color="danger" class="btn-air-danger btn btn-outline-danger btn-xs" size="sm"
                                        style={{ marginLeft: '10px', flex: '0 0 auto' }}
                                        onClick={() => handleDeleteMachine(machineIndex, indexs.id_process_config)}>
                                        <i class="fa fa-trash-o"></i>
                                    </Button>
                                </div>
                            ))}
                        </Col>
                    </>
                )}
                <Col sm="12" md="12" lg="12" xl="12">
                    <hr />
                </Col>
                {indexs.elementsconfig && indexs.elementsconfig.map((elemento, indexElemento) => (
                    <Col key={indexElemento} sm="4" md="4" lg="4" xl="4" className="mt-4">
                        <Label className="f-14 mt-2">{elemento.comment}<br />{elemento.name}:</Label><br />
                        {elemento.id_type_element === 3 ? (
                            <div>
                                <Input
                                    disabled={indexs.estatus_pcr === 2 || indexs.estatus_pcr === 4}
                                    type="datetime-local"
                                    value={elemento.valor || ''}
                                    onChange={(e) => changeInputElement(
                                        e,
                                        elemento.name,
                                        elemento.comment,
                                        elemento.orden,
                                        indexs.id_process_config,
                                        elemento.id_elements_config,
                                        elemento.id_type_element
                                    )}
                                />

                            </div>

                        ) : elemento.id_type_element === 2 ? (

                            <div>
                                <Input
                                    disabled={indexs.estatus_pcr === 2 || indexs.estatus_pcr === 4}
                                    type="checkbox"
                                    className='ml-2'
                                    checked={elemento.valor} // Asegúrate de que valor sea booleano
                                    onChange={(e) => changeInputElementCheck(
                                        e,
                                        elemento.name,
                                        elemento.comment,
                                        elemento.orden,
                                        indexs.id_process_config,
                                        elemento.id_elements_config,
                                        elemento.id_type_element
                                    )}
                                />
                            </div>
                        ) : elemento.id_type_element === 5 ? (

                            <div>
                                <Input
                                    disabled={indexs.estatus_pcr === 2 || indexs.estatus_pcr === 4}
                                    type="time"
                                    value={elemento.valor || ''}
                                    onChange={(e) => changeInputElement(
                                        e,
                                        elemento.name,
                                        elemento.comment,
                                        elemento.orden,
                                        indexs.id_process_config,
                                        elemento.id_elements_config,
                                        elemento.id_type_element
                                    )}
                                />
                            </div>
                        ) : elemento.id_type_element === 6 ? (

                            <div>
                                <Input
                                    disabled={indexs.estatus_pcr === 2 || indexs.estatus_pcr === 4}
                                    type="date" // Cambiado de checkbox a date
                                    value={elemento.valor || ''}
                                    onChange={(e) => changeInputElement(
                                        e,
                                        elemento.name,
                                        elemento.comment,
                                        elemento.orden,
                                        indexs.id_process_config,
                                        elemento.id_elements_config,
                                        elemento.id_type_element
                                    )}
                                />
                            </div>
                        ) : (
                            <div>
                                <Input
                                    disabled={indexs.estatus_pcr === 2 || indexs.estatus_pcr === 4}
                                    type="text"
                                    value={elemento.valor || ''}
                                    onChange={(e) => changeInputElement(e, elemento.name, elemento.comment, elemento.orden, indexs.id_process_config, elemento.id_elements_config, elemento.id_type_element)}
                                />
                            </div>

                        )}
                    </Col>
                ))}

                <Col sm="12" md="12" lg="12" xl="12" className='mt-5'>
                    <Label>{indexs.comment_dilutions_colonies}</Label>
                    <Table responsive bordered>
                        <thead>

                            <tr>
                                <th></th>
                                {indexs.tubes && indexs.tubes.map((tube, indexTube) => (
                                    <th key={indexTube}><strong>{tube.name}</strong></th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {indexs.caracteristicas && indexs.caracteristicas.map((caracteristica, indexCaracteristica) => (
                                <tr key={indexCaracteristica}>
                                    <td>{caracteristica.nombre}</td>
                                    {indexs.tubes && indexs.tubes.map((tube, indexTube) => {
                                        // Encontrar la característica correspondiente
                                        const existingProcess = formValues.listProcessConfig_save.find(process => process.id_process_config === indexs.id_process_config);
                                        const caracteristicaCapturada = existingProcess
                                            ? existingProcess.dilutionscoloniesconfig.find(capturada =>
                                                capturada.id_dilutions_colonies === caracteristica.id_dilutions_colonies && capturada.id_tubes === tube.id_tubes
                                            )
                                            : null;
                                        //{console.log(caracteristicaCapturada, "caracteristicaCapturada")}
                                        return (
                                            <td key={`${indexCaracteristica}-${indexTube}`}>
                                                <Input
                                                    disabled={indexs.estatus_pcr === 2 || indexs.estatus_pcr === 4}
                                                    type="text"
                                                    value={caracteristicaCapturada ? caracteristicaCapturada.valor : ''} // Si no hay valor, el input estará vacío
                                                    onChange={(e) => changeInputCarac(e, caracteristica.nombre, indexs.id_process_config, caracteristica.id_dilutions_colonies, tube.id_tubes)}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </Col>




            </Row>
        </div>
    )


}
