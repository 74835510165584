import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Col, Row, Button, Input, Table, ModalHeader, Form, FormGroup, Label } from 'reactstrap';
import { Tabs, Tab } from '@mui/material';

export const ModalMicro = (props) => {
    const {
        typeMicro = "Ismicro", modalMicro, closeModalMicro, micDetail, handleCreateThermometer, thermometers, id_thermometer_send,
        temperature_send, temperature_corrected_send, comply_temperature_send, identification_send, container_send, quantity_send,
        handleInputChangeMic, list_mic_sample, handleChangeMic, saveMicDetailSend, expiration_send, mark_and_lot_send,
        factorCorrection, codeMic, dataMic
    } = props;


    const [activeTab, setActiveTab] = useState(0);
    const [disabledSamples, setDisabledSamples] = useState({});
    const [noSamples, setNoSamples] = useState(false);

    useEffect(() => {
        if (modalMicro) {
            setDisabledSamples(prevDisabledSamples => {
                const updatedDisabledSamples = { ...prevDisabledSamples };

                list_mic_sample.forEach(sample => {
                    if (sample.statusTrackingMic) {
                        updatedDisabledSamples[sample.id_mic_detail_sample] = true;
                    }
                });

                // Evaluar si todas las muestras están deshabilitadas después de actualizar el estado
                const allSamplesDisabled = list_mic_sample.length > 0 &&
                    list_mic_sample.every(sample => updatedDisabledSamples[sample.id_mic_detail_sample]);

                setNoSamples(allSamplesDisabled);

                return updatedDisabledSamples;
            });

            /*const firstActiveIndex = list_mic_sample.findIndex(sample => !updatedDisabledSamples[sample.id_mic_detail_sample]);
            setActiveTab(firstActiveIndex !== -1 ? firstActiveIndex : 0);
            setNoSamples(firstActiveIndex === -1);*/
        }
    }, [modalMicro, list_mic_sample]);



    /*const handleSaveMicDetailSend = async (idMicDetailSample, index) => {
        await saveMicDetailSend(idMicDetailSample);

        setDisabledSamples(prev => {
            const updated = { ...prev, [idMicDetailSample]: true };

            const nextTab = list_mic_sample.findIndex((sample, i) => i > index && !updated[sample.id_mic_detail_sample]);
            if (nextTab !== -1) {
                setActiveTab(nextTab);
            }

            return updated;
        });
    };*/

    /*const handleSaveMicDetailSend = async (idMicDetailSample) => {
        await saveMicDetailSend(idMicDetailSample);

        // Deshabilitar la muestra después de guardarla
        setDisabledSamples(prev => {
            return { ...prev, [idMicDetailSample]: true };
        });

        // Cambiar el estado para mostrar el mensaje "Muestra registrada"
        //setNoSamples(true);
    };*/

    const handleSaveMicDetailSend = async (idMicDetailSample) => {
        await saveMicDetailSend(idMicDetailSample);

        setDisabledSamples(prev => {
            const updated = { ...prev, [idMicDetailSample]: true };

            const allSamplesDisabled = list_mic_sample.every(sample => updated[sample.id_mic_detail_sample]);

            setNoSamples(allSamplesDisabled);

            return updated;
        });
    };





    const handleTabChange = (event, newValue) => {
        const sampleId = list_mic_sample[newValue]?.id_mic_detail_sample;
        if (sampleId && !disabledSamples[sampleId]) {
            setActiveTab(newValue);
        }
    };

    return (
        <Modal className='modal-position' isOpen={modalMicro} toggle={closeModalMicro} backdrop='static' keyboard={false} centered={true} scrollable={true}
            style={{
                maxWidth: '80%',
                width: '900px',
                //height: '500px'
            }}>
            {typeMicro === "Ismicro" && (
                <Col sm="12">
                    <ModalHeader toggle={closeModalMicro}>RECEPCIÓN DE MUESTRAS</ModalHeader>
                    <ModalBody className="modal-body" style={{ /*width: '850px',*/ height: '600px' }} >
                        <Row style={{/* height: '440px', overflowY: "scroll"*/ }}>
                            {noSamples ? (
                                <div className="table-responsive">
                                    <div style={{ textAlign: 'center' }}>
                                        <img
                                            className="img-fluid"
                                            src="/static/media/recepcionista.1a12ff5d.svg"
                                            alt=""
                                            style={{ width: '80%' }}
                                        />
                                        <br />
                                        <p className="f-w-600 f-14 badge badge-light-primary">
                                            No hay más muestras.
                                        </p>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            ) : (
                                <>

                                    {/* Verificar las muestras que se tienen por listas*/
                                    /*console.log(list_mic_sample)*/}
                                    <Tabs
                                        value={activeTab}
                                        onChange={handleTabChange}
                                        indicatorColor="danger"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        {list_mic_sample.map((_mic, index) => {
                                            const isDisabled = disabledSamples[_mic.id_mic_detail_sample];

                                            return (
                                                <Tab
                                                    key={index}
                                                    label={
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                            <div style={{ textAlign: 'left', fontSize: '12px' }}>{_mic.nameMic}</div>
                                                            <div style={{ textAlign: 'left', fontSize: '12px' }}>{_mic.code}</div>
                                                            <div style={{ textAlign: 'left', fontSize: '12px' }}>{_mic.nameSample}</div>
                                                            {isDisabled && (
                                                                <span style={{ color: 'red', fontSize: '12px', fontWeight: 'bold', marginTop: '4px' }}>
                                                                    Muestra guardada
                                                                </span>
                                                            )}
                                                        </div>
                                                    }
                                                    disabled={isDisabled}
                                                    style={{
                                                        backgroundColor: activeTab === index ? '#f1f9fe' : 'transparent', // Color cuando está activo
                                                        transition: '0.3s',
                                                        //borderRadius: '3px',
                                                        borderTopLeftRadius: '8px',
                                                        borderTopRightRadius: '8px'
                                                    }}
                                                />
                                            );
                                        })}
                                    </Tabs>




                                    {list_mic_sample.map((_mic, index) => {
                                        const isDisabled = disabledSamples[_mic.id_mic_detail_sample];
                                        const matchingDataMic = dataMic.find(_micro =>
                                            _micro.lista_pruebas?.some(_prueba =>
                                                _prueba.listExam?.some(_exam => _exam.id_mic_detail_sample === _mic.id_mic_detail_sample)
                                            )
                                        );

                                        return (
                                            <div key={index} hidden={activeTab !== index || isDisabled}
                                                style={{
                                                    //backgroundColor: '#c0e2f7',
                                                    width: '850px',
                                                    //transition: '0.3s', // Suavizar transición
                                                    backgroundColor: '#f1f9fe',
                                                    borderRadius: '5px',
                                                }}>
                                                <Col sm="12" className='p-2'>
                                                    <br></br>
                                                    <div>
                                                        {matchingDataMic?.lista_pruebas.map((_prueba, _pruebaKey) => (
                                                            <div key={_pruebaKey}>
                                                                {_prueba.listExam.map((_exam, _examKey) => (
                                                                    _exam.id_mic_detail_sample === _mic.id_mic_detail_sample && (
                                                                        <div key={_examKey}>
                                                                            {_exam.name && _exam.abbreviation && (
                                                                                <label className='f-10 ca1'>
                                                                                    • {_exam.name}
                                                                                </label>
                                                                            )}
                                                                            {/*{_exam.name_specimen_origin && (
                                                                                <label className='m-1 ca1'> - {_exam.name_specimen_origin}</label>
                                                                            )}*/}
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>
                                                <Form style={{ padding: '15px' }}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <label>Temperatura de recepción <span style={{ color: 'red' }}>*</span></label>
                                                                <Input
                                                                    type="text"
                                                                    name="temperature_send"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    tabIndex={1}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Marca y Lote <span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    type="text"
                                                                    name="mark_and_lot_send"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    tabIndex={5}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <label>Termómetros</label>
                                                                <Input
                                                                    type="select"
                                                                    name={`id_thermometer_send${_mic.id_mic_detail_sample}`}
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    tabIndex={2}
                                                                >
                                                                    <option value="">Selecciona una opción</option>
                                                                    {thermometers.map((c, key) => (
                                                                        <option key={key} value={c.id_thermometer}>
                                                                            {c.label}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Caducidad</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="expiration_send"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    tabIndex={6}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <label>Temperatura corregida</label>
                                                                <Input
                                                                    type="text"
                                                                    name="temperature_corrected_send"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    tabIndex={3}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Contenedor</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="container_send"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    tabIndex={7}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <label>Cumple temperatura</label>
                                                                <Input
                                                                    type="text"
                                                                    name="comply_temperature_send"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    tabIndex={4}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label>Cantidad</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="quantity_send"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    tabIndex={8}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={10}>
                                                            <FormGroup>
                                                                <Label>Observaciones</Label>
                                                                <textarea
                                                                    className="form-control form-control-sm input-air-primary"
                                                                    name="comments"
                                                                    rows="3"
                                                                    onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    tabIndex={9}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={2} className="d-flex justify-content-center align-items-center">
                                                            <Button
                                                                size="sm"
                                                                outline
                                                                color="primary"
                                                                type="button"
                                                                onClick={() => handleSaveMicDetailSend(_mic.id_mic_detail_sample, index)}
                                                                disabled={
                                                                    !_mic.temperature_send ||
                                                                    _mic.temperature_send.trim() === "" ||
                                                                    !_mic.mark_and_lot_send ||
                                                                    _mic.mark_and_lot_send.trim() === ""
                                                                }
                                                                tabIndex={10}
                                                            >
                                                                Aceptar
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>




                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </Row >
                    </ModalBody >
                </Col >
            )
            }
        </Modal >
    );
    // return (
    //     <Modal className='modal-position' isOpen={modalMicro} toggle={closeModalMicro} backdrop='static' keyboard={false} centered={true} scrollable={true}>
    //         {
    //             typeMicro === "Ismicro" ?
    //                 <>
    //                     <Col sm="12" style={{ textAlign: "center" }}>
    //                         <ModalBody className="modal-body">
    //                             <Row className='border-3 b-primary' style={{ height: '440px', overflowY: "scroll" }} >
    //                                 {
    //                                     list_mic_sample !== undefined && list_mic_sample.map((_mic, _key) => {
    //                                         return (
    //                                             <Col sm="12" key={`curveTr-${_key}`}>
    //                                                 <Col sm="12" className='bg-primary' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }}>
    //                                                     <label className='f-14 ca1'><h5>{_mic.nameMic}</h5></label> <br/>
    //                                                     <label className='m-1 ca1'><b>{_mic.code}</b> / {_mic.id_mic_type_sample}</label>
    //                                                 </Col>
    // <Table className='table table-striped table-sm'>
    //     <tr>
    //         <th>Temperatura de recepción</th>
    //         <th>
    //             <Input type='text' name="temperature_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th >Termómetros</th>
    //         <th>
    //             <Input
    //                 className="form-control form-control-sm input-air-primary"
    //                 type="select"
    //                 name="id_thermometer_send"
    //                 onChange={handleInputChangeMic}
    //             >
    //                 <option value={0}>Selecciona una opción</option>
    //                 {
    //                     thermometers.length > 0 && thermometers.map((c, key) => {
    //                         return <option key={key} value={c.id_thermometer} selected={(id_thermometer_send === c.id_thermometer_send) ? 'selected' : ''}>{c.label}</option>
    //                     })
    //                 }
    //             </Input>
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Temperatura corregida</th>
    //         <th>
    //             <Input type='text' name="temperature_corrected_send" autoComplete='off'
    //                 value={factorCorrection} disabled
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Cumple temperatura:</th>
    //         <th>
    //             <Input type='text' name="comply_temperature_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Identificación:</th>
    //         <th>
    //             <Input type='text' name="identification_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Marca y Lote:</th>
    //         <th>
    //             <Input type='text' name="mark_and_lot_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Caducidad: </th>
    //         <th>
    //             <Input type='text' name="expiration_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Contenedor</th>
    //         <th>
    //             <Input type='text' name="container_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Cantidad</th>
    //         <th>
    //             <Input type='text' name="quantity_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Observaciones</th>
    //         <th>
    //             <textarea
    //                 className='form-control form-control-sm input-air-primary'
    //                 name="comments"
    //                 rows="3"
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //             >
    //             </textarea>
    //         </th>
    //     </tr>
    // </Table>
    //                                                 {
    //                                                     dataMic !== undefined && dataMic.map((_micro, _key) => {
    //                                                         return (
    //                                                             <div key={`micro_${_key}`}>
    //                                                                 <Col sm="12" className='bg-custom text-left' key={`exam_${_key}`}>
    //                                                                     <label className='f-14 ca1'><h5><center>{_mic.nameMic}</center></h5></label> <br />
    //                                                                     {
    //                                                                         _micro.lista_pruebas !== undefined && _micro.lista_pruebas.map((_prueba, _pruebaKey) => {
    //                                                                             return (
    //                                                                                 <div key={`prueba_${_pruebaKey}`}>
    //                                                                                     {
    //                                                                                         _prueba.listExam !== undefined && _prueba.listExam.map((_exam, _examKey) => {
    //                                                                                             return (
    //                                                                                                 _exam && (
    //                                                                                                     <div key={`exam_${_examKey}`}>
    //                                                                                                         {
    //                                                                                                             _exam.name && _exam.abbreviation && (
    //                                                                                                                 <label className='f-14 ca1'>DETERMINACIÓN: {_exam.name} - {_exam.abbreviation}</label>
    //                                                                                                             )
    //                                                                                                         }
    //                                                                                                         {
    //                                                                                                             _exam.name_specimen_origin && (
    //                                                                                                                 <label className='m-1 ca1'>   -{_exam.name_specimen_origin}</label>
    //                                                                                                             )
    //                                                                                                         }
    //                                                                                                         <br />
    //                                                                                                     </div>
    //                                                                                                 )
    //                                                                                             )
    //                                                                                         })
    //                                                                                     }
    //                                                                                 </div>
    //                                                                             )
    //                                                                         })
    //                                                                     }
    //                                                                 </Col>
    //                                                             </div>
    //                                                         )
    //                                                     })
    //                                                 }
    //                                             </Col>
    //                                         )
    //                                     })
    //                                 }
    //                             </Row>
    //                         </ModalBody>
    //                         <ModalFooter>
    //                             <Button size='sm' outline color='primary' type="button" onClick={saveMicDetailSend} >
    //                                 {"Aceptar"}
    //                             </Button>
    //                             {/* <Button size='sm' className='btn-air-light' outline color='danger' type='button' >Cancelar</Button> */}
    //                         </ModalFooter>
    //                     </Col>
    //                 </>
    //                 :
    //                 ""
    //         }

    //     </Modal >

    // )
}