import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { Modal, ModalHeader,Media,ModalBody,ModalFooter,Col,Button,Row ,Form,Input,InputGroupAddon,InputGroup,InputGroupText} from 'reactstrap';




export const ModalPriceProfile = (props) => {

    const {titlePrice,typeprice="viewPrices" ,handleprices,togglePrice,modalPrice,calcularPorcentajeDescuentoProfile,
    resul,handleInputChange,porcentaje,arrayExams,llavecard,totalCommercialLines,key,findRate} = props;


  return (

    

    <Modal isOpen={modalPrice} toggle={togglePrice}  backdrop='static' keyboard={false} centered={true} className="modal-xs" >
    <ModalHeader toggle={togglePrice} className="bg-primary">
    {titlePrice}
    </ModalHeader>
        {
             typeprice ==="viewPrices" ?
             <ModalBody>
                <Col sm="12" key={llavecard}>
                    <Row>
                        <Col sm="3">
                        </Col>
                        <Col sm="6">
                        <label htmlFor="">Nuevo Precio:</label> <br /> 
                                <InputGroup className="">
                                <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>

                                <Input className="form-control" name="porcentaje" value={porcentaje} onChange={handleInputChange}  /> 
                                </InputGroup>
                        </Col>
                    </Row>
                </Col>
             </ModalBody>
             :""
       }       
    <ModalFooter>
        <Button size="sm" className='btn-air-light' outline color='primary' onClick={() => calcularPorcentajeDescuentoProfile()}>
            Calcular
        </Button>
    </ModalFooter> 
    
    
</Modal>
    
  )
}
