import React, { useState } from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import "react-datetime/css/react-datetime.css";
import Select from 'react-select';


export const FormGlobalComment = ({
    toggleCrudComment, 
    handleInputChange, saveCreate, text
    
}) => {


    return (
        <Form className="" role="form" onSubmit={saveCreate}>
            <ModalBody>
                <FormGroup>
                    <Label className="col-form-label">
                        Texto <span className='f-16' style={{ color: "#0079C7" }}>*</span>
                    </Label>
                    <Input
                        name='text'
                        type="text"
                        value={text}
                        required={true}
                        onChange={handleInputChange}
                        className="form-control form-control-sm input-air-primary"
                    />
                    <FormFeedback>Debes llenar este campo</FormFeedback>
                    
                </FormGroup>
            </ModalBody>
            <ModalFooter>
            <Button
                    size="sm"
                    className="btn-air-light"
                    outline
                    color="primary"
                    type="submit"
                >
                    Guardar
                </Button>
                <Button
                    size="sm"
                    className="btn-air-light"
                    outline
                    color="danger"
                    type="button"
                    onClick={toggleCrudComment}
                >
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    );
};

