import React, { useEffect, useState } from 'react'
import { Col, Input, Row, Button, Label } from 'reactstrap'
import { ModalTableGlobalComment } from '../../../components/ResultsCapture/ModalTableGlobalComment'
// import { useSweetAlert } from '../../../sweetAlerts/useSweetAlert';

import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert'
export const ComponentCommentGlobal = ({
    idExam, microCommentss, typeArray, sectionProfileId, examId, handleChangeIsolated, valid, handleChangeResult,
    toggleTableGlobalComment, modalTableGlobalComment, method, handleCreateGlobalComment, modalCrudComment, toggleCrudComment,
    saveCreate, handleInputChange, text, type, comments,handleDeleteCommentText,loading,search, setSearch


}) => {
    console.log("🚀 ~ ComponentCommentGlobal ~ valid:", valid)

    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient, sweetConfirmationCancel, sweetValidDate,sweetDeleteBacterium1] = useSweetAlert()

    const handleInsert = (rowData) => {
        console.log("🚀 ~ handleInsert ~ rowData:", rowData)
        const palabraSeleccionada = rowData.text;
    
        if (type === 1) {
            console.log("🚀 ~ handleInsert ~ type:", type)
            if (microCommentss?.global_result) {
                microCommentss.global_result += " " + palabraSeleccionada;
            } else {
                microCommentss.global_result = palabraSeleccionada;
            }
        } else if (type === 2) {
            if (microCommentss?.global_comment) {
                microCommentss.global_comment += " " + palabraSeleccionada;
            } else {
                microCommentss.global_comment = palabraSeleccionada;
            }
        }
    
        createSweet("create", "success", "Texto Agregado");
        handleChangeResult({ ...microCommentss });
    };
    
    return (
        <>
            <Col sm="12" className='p-l-0 p-r-0'>
                {microCommentss?.id_work_order_exam === idExam && (
                    <div key={`id_comment-${microCommentss?.id}`}>
                        <Row>
                            <h5>Comentario Global</h5> <br />

                        </Row>

                        <Row>
                            <Col sm="1">
                                <label className='txt-info'>Resultado:</label>
                            </Col>
                            <Col sm="9">
                                <Input
                                    type='text'
                                    className='form-control form-control-sm input-air-info border-info input-mic'
                                    value={microCommentss?.global_result || ''}
                                    onChange={(e) => handleChangeResult(e, microCommentss?.id_work_order_exam, typeArray, examId, sectionProfileId)}
                                    disabled={(valid === 4 || valid === 6) ? true : false}
                                />
                            </Col>
                            <Col sm="2">
                                <Button 
                                    outline 
                                    color='primary' 
                                    onClick={() => handleCreateGlobalComment(1)}
                                >
                                    <i className="fa fa-search-plus p-1"></i>
                                </Button>
                                
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm="1">
                                <label className='txt-info' >Observación:</label>
                            </Col>
                            <Col sm="9">
                                <Input
                                    type='text'
                                    className='form-control form-control-sm input-air-info border-info input-mic'
                                    value={microCommentss?.global_comment}
                                    onChange={(e) => handleChangeIsolated(e, microCommentss?.id_work_order_exam, typeArray, examId, sectionProfileId)}
                                    disabled={(valid === 4 || valid === 6) ? true : false}
                                />
                            </Col>

                            <Col sm="2"  >
                            <Button 
                                outline 
                                color='primary' 
                                onClick={() => handleCreateGlobalComment(2)}
                            >
                                <i className="fa fa-search-plus p-1"></i>
                            </Button>
                        </Col>
                        </Row>
                        <br />

                    </div>
                )}
            </Col>
            <ModalTableGlobalComment
                {
                ...{
                    toggleTableGlobalComment, modalTableGlobalComment, method, handleCreateGlobalComment, modalCrudComment, toggleCrudComment, saveCreate, handleInputChange, text, type,
                    comments, handleInsert, handleDeleteCommentText, loading, search, setSearch
                }
                }
            />
        </>

    )
}