import React, { useState } from 'react';
import { Col, Form, ModalBody, Row, Label, Input, FormText, Image, FormFeedback, FormGroup, ModalFooter, Button, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { getAge } from '../../hooks/workOrder/useWorkOrder';
import MaskedInput from 'react-text-mask'
import Select from 'react-select';

export const UpdatePatient = (props) => {
    const urlHelen = localStorage.getItem('url_helen');

    const {
        button, toggle, loading,
        handleInputChange, handleSelectValues, handleChangeSocialGender, handleDate, validHopitalInterfaced,
        id_gender, phone, email, paternal_surname, maternal_surname, name, birthday, age, curp, membership, cardnumber,matricula_buap,
        id_social_gender, new_social_gender, validPorcentejeSpecial,
        validationsPatient, handleWheel, listSocialGender, methodAction,
        viewNewSocialGender, setViewNewSocialGender,
        //INE
        scan_ine_check, setScan_ine_check, handleOnChangeINEU, DeleteINE, showClassINE, img64File,
        id_state, id_municipality, id_blood_type, id_tax_regime, cp, codeP, nss,
        listBloodType, address, colony, expedient_number,
        listStates, listMunicipalities, handleChangeState, getAge, handleDateB,
    } = props;



    const {
        id_gender_valid,
        paternal_surname_valid,
        maternal_surname_valid,
        name_valid,
        birthday_valid,
        age_valid,
        phone_patient_valid,
        email_patient_valid,
        curp_valid,
        municipality_valid,
        state_valid
    } = validationsPatient;

    return (
        <div>
            <Form className="form theme-form" role="form" onSubmit={methodAction}>
                <ModalBody className='p-b-0'>
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <span className='link text-sm text-primary badge badge-light-primary f-12'>Información general</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Nombres <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input type='text' name="name" value={name} autoComplete='off' onChange={handleInputChange} invalid={name_valid} className='form-control form-control-sm input-air-primary' />
                                <FormFeedback>Nombre invalido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Apellido paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input type="text" name='paternal_surname' value={paternal_surname} autoComplete='off' onChange={handleInputChange} invalid={paternal_surname_valid} className='form-control form-control-sm input-air-primary' />
                                <FormFeedback>Completa este campo</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3" className='p-1' >
                            <FormGroup>
                                <Label className='col-form-label'>Apellido materno </Label>
                                <Input type="text" name='maternal_surname' value={maternal_surname} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                            </FormGroup>
                        </Col>
                        
                        {urlHelen === 'https://cdb.helen-sw.com' && (
                            <Col sm="3" md="3" lg="3" className='p-1' >
                                <FormGroup>
                                    <Label className='col-form-label'>Matrícula </Label>
                                    <Input type="text" name='matricula_buap' value={matricula_buap} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                </FormGroup>
                            </Col>
                        )}
                    </Row>
                    <Row>

                        <Col sm="3" md="3" lg="3">
                            <Label className='col-form-label'>
                                Fecha de nacimiento <span className='f-16' style={{ color: "#0079C7" }}>*</span> <span style={{ color: "#0079C7" }}><b>(Dia/Mes/Año)</b></span>
                            </Label>
                            <Input
                                type="date"
                                name="birthday"
                                value={birthday}
                                onChange={(e) => handleDateB(e.target.value)}
                            />
                        </Col>
                        <Col sm="3" md="3" lg="3" className='p-1'>
                            <FormGroup>
                                <Label className='col-form-label'>Edad</Label>
                                <Input
                                    type="number"
                                    min={0}
                                    max={110}
                                    name='age'
                                    value={age}
                                    onChange={(e) => getAge(e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Género <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input type='select' name="id_gender" onChange={handleInputChange} className='form-control form-control-sm input-air-primary' value={id_gender} invalid={id_gender_valid} >
                                    <option value={0}>Selecciona una opción</option>
                                    <option value={1}>Femenino</option>
                                    <option value={2}>Masculino</option>
                                </Input>
                                <FormFeedback>Selecciona una opción</FormFeedback>
                            </FormGroup>
                        </Col>

                        {/* <Col sm="3" md="3" lg="3">
                            <Label className='col-form-label'>Fecha de nacimiento <span className='f-16' style={{ color: "#0079C7" }}>*</span> <span style={{ color: "#0079C7" }}><b>(Dia/Mes/Año)</b></span></Label>
                            <MaskedInput
                                mask={[
                                    /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/
                                ]}
                                className='form-control form-control-sm input-air-primary'
                                placeholder='Dia/Mes/Año'
                                name='birthday'
                                value={birthday}
                                guide={false}
                                onChange={(e) => handleDate(e, "birthday")}
                            />
                            <FormText color='danger' hidden={!birthday_valid}>Ingresa una fecha valida</FormText>
                        </Col>
                        <Col sm="3" md="3" lg="3" className='p-1' >
                            <FormGroup>
                                <Label className='col-form-label'>Edad </Label>
                                <Input
                                    type="number"
                                    min={0}
                                    name='age'
                                    value={age}
                                    autoComplete='off'
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.preventDefault()}
                                    className='form-control form-control-sm input-air-primary no-arrows'
                                />
                            </FormGroup>
                        </Col> */}
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Teléfono</Label>
                                <Input type='tel' name='phone' value={phone} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' invalid={phone_patient_valid} />
                                <FormFeedback>Completa este campo</FormFeedback>
                            </FormGroup>
                        </Col>
                        {/* <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>NSS <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                <Input type='nss' name='nss' value={nss} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                <FormFeedback>Completa este campo</FormFeedback>
                            </FormGroup>
                        </Col> */}
                        {
                            validHopitalInterfaced === "True" &&
                            <Col sm="3" md="3" lg="3">
                                <FormGroup>
                                    <Label className='col-form-label'>Núm. Expediente <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                    <Input type='expedient_number' name='expedient_number' value={expedient_number} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                    <FormFeedback>Completa este campo</FormFeedback>
                                </FormGroup>
                            </Col>
                        }
                        <Col sm="4" md="4" lg="4" className="d-none">
                            <FormGroup>
                                <Label className='col-form-label'>Escanear INE</Label>
                                <br />
                                <Label className='switch'>
                                    <Input type='checkbox' name='scan_ine_check' checked={scan_ine_check} onChange={() => setScan_ine_check()} />
                                    <span className='slider round'></span>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="4" lg="4" xl="4">
                            {scan_ine_check == true && showClassINE.NameFile == "" && img64File != null && img64File != "" ?
                                <img width="40%" heigh="40%" src={'data:image/*;base64,' + img64File}></img>
                                : ""
                            }
                        </Col>
                        {scan_ine_check == true ?

                            <Col sm="4" md="4" lg="4" xl="4" className="d-none">
                                <FormGroup>
                                    <Label htmlFor="file_INE" className="primary btn-primary btn-sm" outline color='primary' size="xs">
                                        Cargar INE <span className="icofont icofont-upload-alt"></span>
                                        <Input type="file" accept="image/*" id="file_INE" name="file_INE" onChange={handleOnChangeINEU} style={{ display: 'none' }} />
                                    </Label> &nbsp;
                                    <Label className='col-form-label'>{showClassINE.NameFile}</Label>
                                    <Button onClick={DeleteINE} className={showClassINE.ClassNameINE} outline color="danger" size="xs" type="button">
                                        <i className="fa fa-trash"></i>
                                    </Button>&nbsp; &nbsp;
                                </FormGroup>
                            </Col> : ""}
                    </Row>
                    {/* <br />
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <span className='link text-sm text-primary badge badge-light-primary f-12'>Tipo de Servicio</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Núm. Expediente <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                <Input type='expedient_number' name='expedient_number' value={expedient_number} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                <FormFeedback>Completa este campo</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Tipo Servicio <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                <Select
                                    name="id_work_order_type_service"
                                    value={id_work_order_type_service}
                                    isClearable={true}
                                    placeholder="Selecciona una opción"
                                    options={listTypeService}
                                    className="input-air-primary input-sm"
                                    onChange={(e) => handleChangeTypeService(e)}
                                />
                                
                            </FormGroup>
                        </Col>
                    </Row> */}
                    {
                        validPorcentejeSpecial === "https://grupo-acosta.helen-sw.com" && <>
                            <Row className='mt-1'>
                                <Col sm="3" md="3" lg="3">
                                    <FormGroup>
                                        <Label className='col-form-label'>
                                            NSS <span className='f-16' style={{ color: "#0079C7" }}></span>
                                        </Label>
                                        <Input
                                            type='text'
                                            name='nss'
                                            value={nss}
                                            autoComplete='off'
                                            maxLength={30}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                            // invalid={nss.length !== 11 && nss.length > 0}
                                            className='form-control form-control-sm input-air-primary'
                                        />
                                        {/* <FormFeedback>El NSS debe tener exactamente 11 dígitos</FormFeedback> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row className='mt-1'>
                        <Col sm="12" md="12" lg="12">
                            <br />
                            <Row>
                                <Col sm="9">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Información adicional</span>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>CURP <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input type='text' name='curp' value={curp} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' invalid={curp_valid} />
                                <FormFeedback>Completa este campo</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Correo</Label>
                                <Input type='text' name='email' value={email} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' invalid={email_patient_valid} />
                                <FormFeedback>Ingresa un correo valido</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Código postal </Label>
                                <Input type='text' name='cp' value={cp} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                <FormFeedback>Completa este campo</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mt-1'>
                        <Col sm="12" md="12" lg="12">
                            <span className='link text-sm text-primary badge badge-light-primary f-12'>Datos de contacto</span>
                        </Col>
                    </Row>
                    <Col sm="12" md="12" lg="12">
                        <Row>

                            <Col sm="2" md="2" lg="2">
                                <FormGroup>
                                    <Label className='col-form-label'>Estado </Label>
                                    <Select
                                        name="id_state"
                                        value={id_state}
                                        isClearable={true}
                                        placeholder="Selecciona una opción"
                                        options={listStates}
                                        className="input-air-primary input-sm"
                                        onChange={(e) => handleChangeState(e)}
                                    />
                                    <FormText color='danger' hidden={!state_valid}>Selecciona un estado</FormText>
                                </FormGroup>
                            </Col>
                            <Col sm="2" md="2" lg="2">
                                <FormGroup>
                                    <Label className='col-form-label'>Municipio</Label>
                                    <Select
                                        name="id_municipality"
                                        value={id_municipality}
                                        isClearable={true}
                                        placeholder="Selecciona una opción"
                                        options={listMunicipalities}
                                        className="input-air-primary input-sm"
                                        onChange={(e) => handleSelectValues(e, "id_municipality")}
                                    />
                                    <FormText color='danger' hidden={!municipality_valid}>Selecciona un municipio</FormText>
                                </FormGroup>
                            </Col>

                            <Col sm="2" md="2" lg="2">
                                <FormGroup>
                                    <Label className='col-form-label'>Dirección</Label>
                                    <Input type='text' name='address' value={address} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                </FormGroup>
                            </Col>
                            <Col sm="2" md="2" lg="2">
                                <FormGroup>
                                    <Label className='col-form-label'>Colonia</Label>
                                    <Input type='text' name='colony' value={colony} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                </FormGroup>
                            </Col>
                            <Col sm="2" md="2" lg="2">
                                <FormGroup>
                                    <Label className='col-form-label'>Membresia</Label>
                                    {/* <Input type='text' autoComplete='off' className='form-control form-control-sm input-air-primary' name='membership' value={membership} onChange={handleInputChange} /> */}
                                    <Input
                                        type='text'
                                        autoComplete='off'
                                        className='form-control form-control-sm input-air-primary'
                                        name='membership'
                                        value={membership === null || membership === "null" ? '' : membership}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>
                    </Col>
                    <Row>

                        <Col sm="4" md="4" lg="4" className="d-none">
                            <FormGroup>
                                <Label className='col-form-label'>Escanear INE</Label>
                                <br />
                                <Label className='switch'>
                                    <Input type='checkbox' name='scan_ine_check' checked={scan_ine_check} onChange={() => setScan_ine_check()} />
                                    <span className='slider round'></span>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="4" md="4" lg="4" xl="4">
                            {scan_ine_check == true && showClassINE.NameFile == "" && img64File != null && img64File != "" ?
                                <img width="40%" heigh="40%" src={'data:image/*;base64,' + img64File}></img>
                                : ""
                            }
                        </Col>
                        {scan_ine_check == true ?

                            <Col sm="4" md="4" lg="4" xl="4" className="d-none">
                                <FormGroup>
                                    <Label htmlFor="file_INE" className="primary btn-primary btn-sm" outline color='primary' size="xs">
                                        Cargar INE <span className="icofont icofont-upload-alt"></span>
                                        <Input type="file" accept="image/*" id="file_INE" name="file_INE" onChange={handleOnChangeINEU} style={{ display: 'none' }} />
                                    </Label> &nbsp;
                                    <Label className='col-form-label'>{showClassINE.NameFile}</Label>
                                    <Button onClick={DeleteINE} className={showClassINE.ClassNameINE} outline color="danger" size="xs" type="button">
                                        <i className="fa fa-trash"></i>
                                    </Button>&nbsp; &nbsp;
                                </FormGroup>
                            </Col> : ""}
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        size="sm"
                        outline color="primary"
                        //color="primary"
                        type="submit"
                        className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                    >
                        {button}
                    </Button>
                    <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </div>

    )

}
