import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useHistory } from "react-router-dom";
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { getAllCommercialLines } from '../../services/commercialLine/commercialLine';
import { getAllClients, getCompanyBusinessNameByClientId } from '../../services/clients/clients';
import { GetInvoicesByClientOrPatientId, getPayForms, sendStampCreditNote } from '../../services/invoice/invoice';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table } from 'reactstrap';
import { PlaceHolderOption } from '../../constant';
import { SearchAsync } from '../../components/elements/SearchAsync';
import ReceptorInvoice from '../../utils/enum/ReceptorInvoice';
import TypePersonInvoiceEnum from '../../utils/enum/type_person.enum';
import Select from 'react-select';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { getPatientById } from '../../services/patient/patient';
import { validarSesion } from '../../hooks/tokens/useToken';
import FormatNumbers, { FormatAmount, OperationAmounts, OperationsAmountCreditNote } from '../../utils/formatNumbers';
import { ModalInvoiceRelateds } from '../../components/Invoice/ModalInvoiceRelateds';
import { getListUseOfCfdi, getListUseOfCfdiGeneral } from '../../services/invoice/useCfdi';
import { ModalLoading } from '../../components/Invoice/ModalLoading';

export const IndexCreditNotesService = () => {

	const history = useHistory();
	const [createSweet] = useSweetAlert();
	const branchId = localStorage.getItem("branchId");
	const listCardClients = [
		{
			name: "Paciente particular",
			value: ReceptorInvoice.PATIENT,
			icon: '/faviconh.png'
		},
		{
			name: "Empresas",
			value: ReceptorInvoice.COMPANY,
			icon: '/faviconh.png'
		},
		{
			name: "Público en general",
			value: ReceptorInvoice.GENERAL_PUBLIC,
			icon: '/faviconh.png'
		}
	];
	const listRelations = [
		{
			value: "",
			label: "Selecciona una opción",
		},
		{
			value: "01",
			label: "01 - Nota de crédito de los documentos relacionados",
		},
		{
			value: "02",
			label: "02 - Nota de débito de los documentos relacionados",
		},
		{
			value: "03",
			label: "03 - Devolución de mercancía sobre facturas o traslados previos",
		},
		{
			value: "04",
			label: "04 - Sustitución de CFDI previos",
		},
		{
			value: "05",
			label: "05 - Traslados de mercancías facturados previamente",
		},
		{
			value: "06",
			label: "06 - Factura generada por los traslados previos",
		},
		{
			value: "07",
			label: "07 - CFDI por aplicación de anticipo",
		},
	];
	const listPayMethods = [
		{
			label: 'PUE',
			value: 'PUE'
		},
		{
			label: 'PPD',
			value: 'PPD'
		}
	];

	const [searchPatientState, setSearchPatientState] = useState([]);
	const [listCommercialLines, setListCommercialLines] = useState([]);
	const [listClients, setListClients] = useState([]);
	const [listPayForms, setListPayForms] = useState([]);
	const [listUseOfCfdis, setListUseOfCfdis] = useState([]);

	const [informationCLient, setInformationCLient] = useState(null);
	const [listClientCompanyBusinessName, setListClientCompanyBusinessName] = useState([]);

	const [selectCompany, setSelectCompany] = useState(null);
	const [selectCommercialNameCompany, setSelectCommercialNameCompany] = useState(null);

	const [hideUpdateButton, setHideUpdateButton] = useState(true);

	const [modalListInvoices, setModalListInvoices] = useState(false);
	const [hideGridConcepts, setHideGridConcepts] = useState(false);

	const [activeStamp, setActiveStamp] = useState(false);

	const [showLoading, setShowLoading] = useState(false);

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [totalPages, setTotalPages] = useState(0);
	const [listRelatedsInvoices, setListRelatedsInvoices] = useState([]);

	const [selectedInvoices, setSelectedInvoices] = useState([]);

	const GetAllClients = async () => {
		const listClients = await getAllClients();

		if (listClients?.length > 0) {
			const list = listClients?.map(client => {
				return {
					value: client.id_company,
					label: `${client?.code} ${client?.name}`
				}
			});

			setListClients(list);
		}
		else setListClients([]);
	};

	const GetCommercialLines = async () => {
		const commercialLines = await getAllCommercialLines();

		if (commercialLines?.length > 0) {
			const list = commercialLines?.map((commercial) => {
				return {
					value: commercial?.id_commercial_line,
					label: commercial?.long_name,
					rfc: commercial?.rfc,
					cp: commercial?.cp,
					id_tax_regime: commercial?.id_tax_regime,
					code_tax_regime: commercial?.code_tax_regime,
					name_tax_regime: commercial?.name_tax_regime,
					type_person: commercial?.type_person
						? TypePersonInvoiceEnum.MORAL
						: TypePersonInvoiceEnum.FISICA,
				};
			});

			setListCommercialLines(list);
		} else setListCommercialLines([]);
	};

	const GetPayForms = async () => {
		const payForms = await getPayForms();

		if (payForms?.length > 0) {
			const list = payForms?.map((form) => {
				return {
					label: form.abbreviation + " - " + form.name,
					value: form.id_pay_method,
					code: form.abbreviation,
				};
			});

			setListPayForms(list);
		} else setListPayForms([]);
	};

	const GetUseCfdis = async (tax_regime_id, type_person) => {
		const listuseCfdi = await getListUseOfCfdi(
			tax_regime_id, type_person
		);

		if (listuseCfdi?.length > 0) {
			const list = listuseCfdi?.map(use => {
				return {
					value: use?.id_use_cfdi,
					label: `${use?.code} ${use?.name}`,
					code: use?.code
				};
			});

			setListUseOfCfdis(list);
		}
		else setListUseOfCfdis([]);
	}

	const [formCreditNote, setFormCreditNote] = useState({
		filterTypeReceptor: '',
		type_relation: '',
		pay_method: '',
		pay_form: '',
		client_id: null,
		client_business_id: null,
		commercial_line_id: null,
		patient_id: null,
		listConcepts: [],
		listInvoices: [],
		UseCfdiId: null,
		Comments: ""
	});

	const [formConcepts, setFormConcepts] = useState({
		concept: "",
		product_code: "",
		quantity: 1,
		total_unitary: 0,
		total: 0,
		object_tax: 0.16,
		object_retentions: 0,
		unit: "",
		noIdentification: "",
		index_edit: null
	});

	const [totalFooter, setTotalFooter] = useState({
		subtotal: 0,
		total_iva: 0,
		total: 0
	});

	useEffect(() => {
		GetAllClients();
		GetCommercialLines();
		GetPayForms();
	}, []);

	useEffect(() => {

		if (formCreditNote.pay_method !== '') {
			async function fetchPayForm() {
				const payForms = await getPayForms();

				if (payForms?.length === 0) return;

				const selectablePayWays = payForms?.filter((way) => (formCreditNote.pay_method?.value === "PUE" ? way.abbreviation !== "99" : way.abbreviation === "99"))?.map((way) => (
					{
						label: way.abbreviation + " - " + way.name,
						value: way.id_pay_method,
						code: way.abbreviation,
					}
				));

				setListPayForms(selectablePayWays);
			}

			fetchPayForm();
		}
	}, [formCreditNote])

	useEffect(() => {
		if (formCreditNote.type_relation !== "" && formCreditNote.type_relation?.value !== undefined) {
			async function fetchInvoices() {
				const listInvoices = await GetInvoicesByClientOrPatientId(true, page, limit,
					formCreditNote.filterTypeReceptor === ReceptorInvoice.COMPANY ? formCreditNote.client_id : null,
					formCreditNote.filterTypeReceptor === ReceptorInvoice.PATIENT ? formCreditNote.patient_id : null);

				if (listInvoices.code === 200) {
					const { results, total_pages } = listInvoices.data;
					setListRelatedsInvoices(results);
					setTotalPages(total_pages);

				}
				else
					createSweet("warning", "warning", "Facturas", "No se encontraron facturas relacionadas");
				// if (payForms?.length === 0) return;

				// const selectablePayWays = payForms?.filter((way) => ( formCreditNote.pay_method?.value === "PUE" ? way.abbreviation !== "99" : way.abbreviation === "99" ))?.map((way) => (
				// 	{
				// 		label: way.abbreviation + " - " + way.name,
				// 		value: way.id_pay_method,
				// 		code: way.abbreviation,
				// 	}
				// ));

				// setListPayForms(selectablePayWays);
			}

			setListRelatedsInvoices([]);
			setPage(1);
			fetchInvoices();
		}
	}, [formCreditNote, page, limit]);

	useEffect(() => {
	  if (selectedInvoices?.length > 0)
	  {
		const items = [];
		const related_documents = [];

		selectedInvoices.forEach((invoice) => {
			console.log(invoice);
			related_documents.push({
				invoice_id: invoice?.invoiceId
			});			

			items.push({
				concept: `DEVOLUCIONES, DESCUENTOS O BONIFICACIONES ${invoice?.serie ?? ''} ${invoice?.folio ?? ''}`,
				product_code: "84111506",
          		unit: "E48",
				quantity: 1,
				unit_price: invoice.subtotal,
				subtotal: invoice.subtotal,
				total: invoice.total,
				total_unitary: invoice.total,
				object_tax: invoice?.decimalTax ?? 0.16,
				object_retentions: invoice?.totalRetentions > 0 ? 0.0125 : 0,
				total_iva: invoice.totalTaxes,
				total_retentions: invoice.totalRetentions,
				index_edit: null
			});					
		});

		setFormConcepts({
			concept: "",
			product_code: "",
			quantity: 1,
			total_unitary: 0,
			total: 0,
			object_tax: 0.16,
			object_retentions: 0,
			unit: "",
			noIdentification: "",
			index_edit: null
		});	

		setFormCreditNote({
			...formCreditNote,
			listInvoices: related_documents,
			listConcepts: items
		});

	  }
	}, [selectedInvoices]);
	
	useMemo(() => {
		if (formCreditNote.listConcepts?.length > 0) {
			let subtotal = 0;
			let total_iva = 0;
			let total_retentions = 0;
			let total = 0;
			
			formCreditNote.listConcepts.forEach((concept) => {			
				subtotal += FormatAmount(concept.subtotal, 2);
				total_retentions += FormatAmount(concept.total_retentions, 2);
				total_iva += FormatAmount(concept.total_iva, 2);
			});

			subtotal = FormatAmount(subtotal, 2);
			total_iva = FormatAmount(total_iva, 2);
			total_retentions = FormatAmount(total_retentions, 2);
			total = (subtotal + total_iva) - total_retentions;
			total = FormatAmount(total, 2);

			setTotalFooter({
				subtotal,
				total_iva,
				total_retentions,
				total
			});
		}
		else
			setTotalFooter({
				subtotal: 0,
				total_iva: 0,
				total_retentions: 0,
				total: 0
			});
	}, [formCreditNote.listConcepts]);

	const handleChangeTyepeReceptor = (typeReceptor) => {
		setFormCreditNote({
			...formCreditNote,
			filterTypeReceptor: typeReceptor
		});
	}

	const handleChangeClient = async (e) => {
		setSelectCompany(e);
		setListClientCompanyBusinessName([]);
		setSelectCommercialNameCompany(null);

		if (e !== null && e?.value !== undefined) {
			const getList = await getCompanyBusinessNameByClientId(e.value);

			if (getList?.length > 0) {
				const list = getList?.map(bussinessName => {
					return {
						"value": bussinessName.id_company_business_name,
						"label": bussinessName.business_name,
						"id_company": bussinessName.id_company,
						"tax_regime_id": bussinessName.id_tax_regime ?? null,
						"use_cfdi_id": null,
						"rfc": bussinessName.rfc ?? "",
						"address": bussinessName.address,
						"colony": bussinessName.colony,
						"cp": bussinessName.cp ?? "",
						"phone": bussinessName.phone,
						"email": bussinessName.email,
						"code_tax_regime_business_name": bussinessName.code_tax_regime,
						"name_tax_regime": bussinessName.name_tax_regime,
						"type_person": bussinessName.type_person === 2 ? TypePersonInvoiceEnum.MORAL : TypePersonInvoiceEnum.FISICA,
						"name_receptor": e?.label ?? '',
					}
				});

				setListClientCompanyBusinessName(list);
			}
			else setListClientCompanyBusinessName([]);

			setFormCreditNote({
				...formCreditNote,
				client_id: e?.value,
				client_business_id: null,
				patient_id: null
			});
		}
		else {
			setFormCreditNote({
				...formCreditNote,
				client_id: null,
				client_business_id: null,
				patient_id: null
			});
		}
	}

	const handleChangeClientBusinessId = async (e) => {
		setSelectCommercialNameCompany(e);
		if (e !== null) {
			setFormCreditNote({
				...formCreditNote,
				client_business_id: e.value,
			});

			setInformationCLient({
				name_commercial_line: e?.label ?? "",
				rfc: e?.rfc ?? "",
			})

			await GetUseCfdis(e.tax_regime_id, e.type_person === TypePersonInvoiceEnum.MORAL ? false : true);

		}
		else {
			setFormCreditNote({
				...formCreditNote,
				client_business_id: null,
			});

			setInformationCLient(null)
		}
	}

	const handleChangePatient = async (e) => {
		const patient_id = e.target.value;

		if (patient_id !== 0 && patient_id !== null && patient_id !== "" && patient_id !== "0") {
			const getPatient = await getPatientById(patient_id);

			if (getPatient?.id_patient !== undefined) {
				setFormCreditNote({
					...formCreditNote,
					patient_id: patient_id,
				});

				setInformationCLient({
					name_commercial_line: getPatient?.business_name ?? "",
					rfc: getPatient?.rfc ?? "",
				});

				setSearchPatientState({
					value: patient_id,
					label: getPatient?.business_name ?? "",
				})

				await GetUseCfdis(getPatient.id_tax_regime, getPatient.type_person === 1 ? true : false);
			}
			else if (getPatient.code === 401)
				validarSesion(history, getPatient.code, getPatientById);
			else if (getPatient.code === 500)
				createSweet("warning", "warning", "Obtener información del paciente", "Ocurrio un problema al obtener la información del paciente");
			else
				createSweet("warning", "warning", "Paciente", getPatient.data.msg);
		}
		else {
			setFormCreditNote({
				...formCreditNote,
				patient_id: null
			});

			setInformationCLient(null);
		}
	}

	const handleChangeReceotor = () => {
		setFormCreditNote({
			filterTypeReceptor: '',
			type_relation: '',
			pay_method: '',
			pay_form: '',
			client_id: null,
			client_business_id: null,
			patient_id: null,
			listConcepts: []
		});

		setSelectCompany(null);
		setSelectCommercialNameCompany(null);
		setListClientCompanyBusinessName([]);
		setInformationCLient(null);
	}

	const handleAddItems = () => {
		const {
			concept,
			product_code,
			quantity,
			total,
			object_tax,
			object_retentions,
			unit,
			noIdentification,
			index_edit,
		} = formConcepts;

		if (quantity === "" || total === "" || unit === "" || concept === "" || product_code === "" || noIdentification === "") {
			createSweet("warning", "warning", "Conceptos", "Los datos de los conceptos deben estar completos");

			return;
		}

		const operations = OperationsAmountCreditNote(
			quantity,
			total,
			object_retentions > 0 ? true : false,
			object_tax > 0 ? true : false,
			object_tax,
			6
		)

		let totalUnit = operations.total / quantity;
		totalUnit = FormatAmount(totalUnit, 6);

		const conceptData = {
			concept,
			product_code,
			unit,
			quantity,
			unit_price: operations.unitary_price,
			subtotal: operations.subtotal,
			total: operations.total,
			total_unitary: totalUnit,
			object_tax,
			total_iva: operations.total_iva,
			total_retentions: operations.total_retentions,
			is_global: true,
			index_edit: index_edit
		};

		setFormCreditNote({
			...formCreditNote,
			listConcepts: [
				...formCreditNote.listConcepts,
				conceptData
			]
		});

		setFormConcepts({
			concept: "",
			product_code: "",
			quantity: 1,
			total: 0,
			object_tax: "16%",
			unit: "",
			index_edit: null,
			total_unitary: 0,
			noIdentification: ""
		});
	}

	const handleEdition = (index) => {
		const concept = formCreditNote.listConcepts[index];

		setFormConcepts({
			concept: concept.concept,
			product_code: concept.product_code,
			quantity: concept.quantity,
			total_unitary: concept.total_unitary,
			total: concept.total,
			object_tax: concept.object_tax,
			object_retentions: concept.object_retentions,
			unit: concept.unit,
			noIdentification: "",
			index_edit: index
		});

		setHideGridConcepts(false);
		setHideUpdateButton(false);
	}

	const handleUpdateItem = () => {
		const {
			concept,
			product_code,
			quantity,
			total,
			object_tax,
			object_retentions,
			unit,
			noIdentification,
			index_edit,
		} = formConcepts;

		if (quantity === "" || total === "" || unit === "" || concept === "" || product_code === "" || noIdentification === "") {
			createSweet("warning", "warning", "Conceptos", "Los datos de los conceptos deben estar completos");

			return;
		}

		let concepts = formCreditNote.listConcepts; 

		const operations = OperationsAmountCreditNote(
			quantity,
			total,
			object_retentions > 0 ? true : false,
			object_tax > 0 ? true : false,
			object_tax,
			6
		)

		concepts[index_edit] = {
			concept,
			product_code,
			unit,
			quantity,
			unit_price: operations.unitary_price,
			subtotal: operations.subtotal,
			total: operations.total,
			object_tax,
			object_retentions,
			total_retentions: operations.total_retentions,
			total_iva: operations.total_iva,
			is_global: true,
			index_edit: null,
		};

		setFormCreditNote({
			...formCreditNote,
			listConcepts: [...concepts]
		});

		setFormConcepts({
			concept: "",
			product_code: "",
			quantity: 1,
			total_unitary: 0,
			total: 0,
			object_tax: 0.16,
			object_retentions: 0,
			unit: "",
			noIdentification: "",
			index_edit: null
		});	

		setHideGridConcepts(true);
	}

	const handleConfirmInvoice = async () => 
	{
		setShowLoading(true);

		if (formCreditNote.type_relation?.value !== undefined && formCreditNote.listInvoices.length === 0 )
		{
			createSweet("warning", "warning", "Tipo de relación", "Necesitas seleccionar una factura para relacionar la nota de crédito");
			setShowLoading(false);
			return;
		}

		if (formCreditNote.pay_method?.value === undefined)
		{
			createSweet("warning", "warning", "Método de pago", "Necesitas seleccionar un método de pago");
			setShowLoading(false);
			return;
		}

		if (formCreditNote.pay_form?.value === undefined)
		{
			createSweet("warning", "warning", "Forma de pago", "Necesitas seleccionar una forma de pago");
			setShowLoading(false);
			return;
		}

		if (formCreditNote.filterTypeReceptor === ReceptorInvoice.COMPANY)
		{
			if (formCreditNote.client_id === "" || formCreditNote.client_id === null)
			{
				createSweet("warning", "warning", "Cliente", "Necesitas seleccionar un cliente");
				setShowLoading(false);
				return;
			}

			if (formCreditNote.client_business_id === "" || formCreditNote.client_business_id === null)
			{
				createSweet("warning", "warning", "Cliente", "Necesitas seleccionar una razón social para el cliente");
				setShowLoading(false);
				return;
			}
		}
		else if (formCreditNote.filterTypeReceptor === ReceptorInvoice.PATIENT)
		{
			if (formCreditNote.patient_id === "" || formCreditNote.patient_id === null)
			{
				createSweet("warning", "warning", "Paciente", "Necesitas seleccionar un pciente");
				setShowLoading(false);
				return;
			}
		}

		if (formCreditNote.listConcepts?.length === 0)
		{
			createSweet("warning", "warning", "Conceptos", "Debes agregar al menos un concepto");
			setShowLoading(false);
			return;
		}

		if (formCreditNote?.commercial_line_id?.value === undefined || formCreditNote?.commercial_line_id?.value === null)
		{
			createSweet("warning", "warning", "Razón social emisora", "Debes seleccionar una razón social emisora");
			setShowLoading(false);
			return;
		}

		const body = {
			CommercialLineId: formCreditNote.commercial_line_id?.value,
			BranchId: branchId,
			CodeOfUseCfdi: formCreditNote.UseCfdiId?.code,
			UseCfdiId: formCreditNote.UseCfdiId?.value,
			TypeClient: formCreditNote.filterTypeReceptor,
			ClientId: formCreditNote.client_id,
			ClientBusinessId: formCreditNote.client_business_id,
			PatientId: formCreditNote.patient_id,
			PayFormId: formCreditNote.pay_form?.value,
			CodePayForm: formCreditNote.pay_form?.code,
			CodePayMethod: formCreditNote.pay_method?.value,
			CodeOfRelation: formCreditNote.type_relation?.value !== undefined ? formCreditNote.type_relation?.value : "",
			listInvoices: formCreditNote.listInvoices,
			listConcepts: formCreditNote.listConcepts,
			Comments: formCreditNote?.Comments
		};

		const sendStamp = await sendStampCreditNote(body);

		if (sendStamp.code === 200) {
			createSweet("create", "info", "Exito!", "Se genero la factura con exito!");

			setTimeout(() => {
				window.location.href = '../../../facturacion/Emitidos';
			}, 2000);
		}
		else if (sendStamp.code === 401)
			createSweet(
				"error",
				"error",
				"Factura",
				"Su sesión ha expirado, por favor vuelva a iniciar sesión"
			);
		else if (sendStamp.code === 500)
			createSweet("error", "error", "Factura", "Se perdio la conexión, con el servidor");
		else if (sendStamp.code === 400)
			createSweet("error", "error", "Factura", "No se llevo acabo la operación");
		else
			createSweet("warning", "warning", "Factura", sendStamp.msg);

		setShowLoading(false);
	}

	const rowMarkUp = formCreditNote.listConcepts?.map((concept, index) => {
		return (
			<tr key={index} className='text-center'>
				<td>{concept.product_code}</td>
				<td>{concept.unit}</td>
				<td>{concept.concept}</td>
				<td>{concept.quantity}</td>
				<td>{FormatNumbers(concept?.unit_price ?? 0, 2)}</td>
				<td>{FormatNumbers(concept?.subtotal ?? 0, 2)}</td>
				<td>
					<ButtonGroup>
						<Button color='dark' size='sm' onClick={() => handleEdition(index)}><span className='icofont icofont-ui-edit'></span></Button>
						{/* <Button color='danger' size='sm'><span className='icon-trash'></span></Button> */}
					</ButtonGroup>
				</td>
			</tr>
		)
	});	

	return (
		<Fragment>
			<Container fluid={true} className='p-l-0 p-r-0'>
				<Row>
					{
						formCreditNote.filterTypeReceptor === '' && (
							listCardClients.map((client, index) => {
								return <Col key={`card-client-${index}`} xs="3" md="3" xl="3" className='cursor-pointer pointer' onClick={() => handleChangeTyepeReceptor(client.value)}>
									<Card className='shadow'>
										<Row>
											<Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-2'>
												<img className="img-fluid mt-2 ml-4" src={(`${client.icon}`)} alt={client.name} style={{ width: '50%', height: '75%' }} />
											</Col>
											<Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-l-0 p-r-0'>
												<h6 className='labelIndicadorCash mt-4'>{client.name}</h6>
											</Col>
										</Row>
									</Card>
								</Col>
							})
						)
					}
				</Row>
				{
					formCreditNote.filterTypeReceptor !== '' && (<>
						<Row>
							<Col sm="2" md="2" lg="2" className='default-according style-1 faq-accordion job-accordion position-relative'>
								<Row className="position-sticky" style={{ top: 140 }}>
									<Col xl="12">
										<Card>
											<CardHeader className='bg-secondary'>
												<h5 className="mb-0 f-12">
													Acciones
												</h5>
											</CardHeader>
											<CardBody style={{ padding: "8px" }}>
												<Row>
													<Col sm="12" md="12" >
														<Button color='light' className='mr-5 mb-3' title='' onClick={() => handleChangeReceotor()}>
															<span className='fa fa-filter'></span> Seleccionar otro cliente
														</Button>
														{/* <Button className={`m-b-10`} color='info' outline ><i className="icofont icofont-optic"></i> &nbsp;&nbsp; Previsualizar</Button> */}
														<Button className='' color='success' outline onClick={() => setActiveStamp(true)}>  <i className="icofont icofont-list"></i> &nbsp;&nbsp; Timbrar </Button>
													</Col>
												</Row>
												{
													activeStamp && (
														<Row className='mt-2'>
															<Col sm="12">
																<h2 className='f-12 text-info'>Selecciona un emisor</h2>
																<Select
																	className="input-air-primary input-xs w-100"
																	classNamePrefix="select "
																	name="client_id"
																	value={formCreditNote.commercial_line_id}
																	options={listCommercialLines}
																	placeholder={PlaceHolderOption}
																	isClearable={true}
																	onChange={(e) => {
																		setFormCreditNote({
																			...formCreditNote,
																			commercial_line_id: e
																		})
																	}}
																/>
															</Col>
															<Col sm="12">
																<Button className='mt-2' color='info' outline onClick={() => handleConfirmInvoice()} disabled={ showLoading }>  <i className="icofont icofont-list"></i> &nbsp;&nbsp; Confirmar </Button>
															</Col>
														</Row>
													)
												}
											</CardBody>
										</Card>
									</Col>
								</Row>
							</Col>
							<Col sm="10" md="10" lg="10">
								<Row className='container-fluid d-flex '>
									<Col sm="12" md="12" lg="6" xl="6" className='h-100'>
										<Card>
											<CardHeader className='p-3'>
												<div className='d-flex align-items-center w-100 '>
													<b className='mr-5'>Cliente</b>
												</div>
											</CardHeader>
											<CardBody className='p-3'>
												<Row className='mb-4'>
													<Col sm="12" md="12" lg="12">
														<InputGroup className='w-100'>
															{
																formCreditNote.filterTypeReceptor === ReceptorInvoice.COMPANY && (
																	<Select
																		className="input-air-primary input-xs w-100"
																		classNamePrefix="select "
																		name="client_id"
																		value={selectCompany}
																		options={listClients}
																		placeholder={PlaceHolderOption}
																		isClearable={true}
																		onChange={(e) => handleChangeClient(e)}
																	/>
																)
															}
															{
																formCreditNote.filterTypeReceptor === ReceptorInvoice.PATIENT && (
																	<div className='col-sm-12 col-md-12 col-lg-12 w-100'>
																		<SearchAsync
																			cacheOptions={false}
																			name="patient_id"
																			value={formCreditNote.patient_id}
																			method={handleChangePatient}
																			loincState={searchPatientState}
																			url="Patient/SearchPatient"
																			maxLenght={3}
																			placeholder='Búsqueda por nombre o CURP'
																		/>
																	</div>
																)
															}
														</InputGroup>
													</Col>
												</Row>
												<Row>
													<Col sm="12" md="12" lg="7">
														<h2 className='f-12 text-info'>Razón social</h2>
														{
															formCreditNote.client_id && !formCreditNote.client_business_id ? (
																<Select
																	className="input-air-primary input-xs"
																	classNamePrefix="select"
																	name="client_business_id"
																	value={selectCommercialNameCompany}
																	options={listClientCompanyBusinessName}
																	placeholder={PlaceHolderOption}
																	isClearable={true}
																	onChange={(e) => handleChangeClientBusinessId(e)}
																/>
															)
																: <h2 className='f-12'>{informationCLient?.name_commercial_line}</h2>
														}

													</Col>
													<Col sm="12" md="12" lg="3">
														<h2 className='f-12 text-info'>RFC</h2>
														<h2 className='f-12'>{informationCLient?.rfc}</h2>
													</Col>
													<Col sm="12" md="12" lg="2">
														{
															formCreditNote.client_business_id && <Button id='btn-tooltip-filter-p' outline color='secondary' onClick={() => handleChangeClientBusinessId(null)} className='p-3'
																style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 60 }}
															>
																<i className="icofont icofont-filter"></i>
																<ToolTips
																	placement="top"
																	dataTarget='btn-tooltip-filter-p'
																	dataText='Seleccionar otra razón social'
																/>
															</Button>
														}
													</Col>
												</Row>
												<Row className='mt-2'>
													<Col sm="12" md="12" lg="12">
                                                    	<div className='form-group'>
                                                    	    <Label>Uso del CFDI <span className='text-info'>*</span></Label>
                                                    	    <Select
                                                    	        className="input-air-primary input-xs"
                                                    	        classNamePrefix="select"
                                                    	        name="use_cfdi_id"
                                                    	        value={formCreditNote.UseCfdiId}
                                                    	        options={listUseOfCfdis}
                                                    	        placeholder={PlaceHolderOption}
                                                    	        isClearable={false}
                                                    	        onChange={(e) => {
																	setFormCreditNote({
																		...formCreditNote,
																		UseCfdiId: e
																	})
																}}
                                                    	    />
                                                    	</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
									<Col sm="12" md="12" lg="6" xl="6" className='h-100'>
										<Card>
											<CardHeader className='p-3'>
												<div className='d-flex align-items-center w-100 '>
													<b className='mr-5'>Tipo de relación</b>
												</div>
											</CardHeader>
											<CardBody className='p-3'>
												<Row>
													<Col sm="12" md="12" lg="12">
														<FormGroup>
															<Label>Tipo de relación</Label>
															<Select className="input-air-primary input-xs"
																options={listRelations}
																placeholder={PlaceHolderOption}
																isClearable={true}
																value={formCreditNote.type_relation}
																onChange={(e) => {
																	if (e?.value !== "") {
																		setModalListInvoices(true);
																		setHideGridConcepts(true);
																	}
																	else {
																		setHideGridConcepts(false);
																		setModalListInvoices(false);
																	}
																	setFormCreditNote({
																		...formCreditNote,
																		type_relation: e
																	})
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col sm="12" md="12" lg="5">
														<FormGroup>
															<Label>Método de pago</Label>
															<Select className="input-air-primary input-xs"
																options={listPayMethods}
																placeholder={PlaceHolderOption}
																isClearable={true}
																value={formCreditNote.pay_method}
																onChange={(e) => {
																	setFormCreditNote({
																		...formCreditNote,
																		pay_method: e
																	})
																}}
															/>
														</FormGroup>
													</Col>
													<Col sm="12" md="12" lg="7">
														<FormGroup>
															<Label>Forma de pago</Label>
															<Select className="input-air-primary input-xs"
																options={listPayForms}
																placeholder={PlaceHolderOption}
																isClearable={true}
																value={formCreditNote.pay_form}
																onChange={(e) => {
																	setFormCreditNote({
																		...formCreditNote,
																		pay_form: e
																	})
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
								{!hideGridConcepts && <Row className='container-fluid d-flex full-height'>
									<Col sm="12" md="12" lg="12">
										<Card>
											<CardHeader className='p-3'>
												<div className='d-flex align-items-center w-100 '>
													<b className='mr-5'>Conceptos</b>
													<div className='w-100'>
														{
															hideUpdateButton && <Button color='dark' size='sm' className='float-right' onClick={() => handleAddItems()}>
																<span className='fa fa-plus mr-2'></span>
																Agregar
															</Button>
														}
														{
															!hideUpdateButton && <Button color='dark' size='sm' className='float-right' onClick={() => handleUpdateItem()}>
																<span className='fa fa-plus mr-2'></span>
																Actualizar
															</Button>
														}
													</div>
												</div>
											</CardHeader>
											<CardBody className='p-3'>
												<Row>
													<Col sm="12" md="12" lg="6">
														<FormGroup>
															<Label>Descripción</Label>
															<Input type='text' placeholder='Descripción' className="input-air-primary input-xs rounded-sm"
																value={formConcepts.concept} onChange={(e) => {
																	setFormConcepts({
																		...formConcepts,
																		concept: e.target.value
																	})
																}}
															/>
														</FormGroup>
													</Col>
													<Col sm="12" md="12" lg="3">
														<FormGroup>
															<Label>Clave de unidad</Label>
															<Input type='text' placeholder='E48' className="input-air-primary input-xs rounded-sm"
																value={formConcepts.unit} onChange={(e) => {
																	setFormConcepts({
																		...formConcepts,
																		unit: e.target.value
																	})
																}}
															/>
														</FormGroup>
													</Col>
													<Col sm="12" md="12" lg="3">
														<FormGroup>
															<Label>No. identificacón</Label>
															<Input type='text' placeholder='No. de identificación' className="input-air-primary input-xs rounded-sm"
																value={formConcepts.noIdentification} onChange={(e) => {
																	setFormConcepts({
																		...formConcepts,
																		noIdentification: e.target.value
																	})
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col sm="12" md="12" lg="4">
														<FormGroup>
															<Label>Clave Producto/Servicio</Label>
															<Input type='text' placeholder='Clave de producto o servicio' className="input-air-primary input-xs rounded-sm"
																value={formConcepts.product_code} onChange={(e) => {
																	setFormConcepts({
																		...formConcepts,
																		product_code: e.target.value
																	})
																}}
															/>
														</FormGroup>
													</Col>
													<Col sm="12" md="12" lg="3">
														<FormGroup>
															<Label>Cantidad</Label>
															<Input type='text' placeholder='Cantidad' className="input-air-primary input-xs rounded-sm"
																value={formConcepts.quantity} onChange={(e) => {
																	if (isNaN(+e.target.value)) {
																		createSweet("warning", "warning", "", "Solo se pueden añadir numeros");
																		return;
																	}

																	setFormConcepts({
																		...formConcepts,
																		quantity: e.target.value,
																		total: FormatAmount(e.target.value * formConcepts.total_unitary, 6)
																	});
																}}
															/>
														</FormGroup>
													</Col>
													<Col sm="12" md="12" lg="3">
														<FormGroup>
															<Label>Total</Label>
															<Input type='number' placeholder='Total' className="input-air-primary input-xs rounded-sm"
																value={formConcepts.total} onChange={(e) => {
																	setFormConcepts({
																		...formConcepts,
																		total: e.target.value
																	});
																}}
															/>
														</FormGroup>
													</Col>
													<Col sm="12" md="12" lg="2" className='d-flex'>
														<FormGroup className='mr-1'>
															<Label className='col-form-label'>IVA al { formConcepts.object_tax }</Label>
															<br />
															<Label className='switch'> <br /> <br />
																<Input type='radio' name='tax' checked={formConcepts.object_tax > 0 ? true : false}
																	onChange={(e) => {
																		setFormConcepts({
																			...formConcepts,
																			object_tax: formConcepts.object_tax
																		})
																	}}
																/>
																<span className='slider round'></span>
															</Label>
														</FormGroup>
														{/* <FormGroup className='ml-2'>
															<Label className='col-form-label'>IVA al 0%</Label>
															<br />
															<Label className='switch'> <br /> <br />
																<Input type='radio' name='tax' checked={formConcepts.object_tax === 0 ? true : false}
																	onChange={(e) => {
																		setFormConcepts({
																			...formConcepts,
																			object_tax: 0
																		})
																	}}
																/>
																<span className='slider round'></span>
															</Label>
														</FormGroup> */}
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
								}
								<Row className='container-fluid d-flex full-height'>
									<Col sm="12" md="12" lg="12">
										<Card>
											<CardBody className='p-3'>
												{
													formCreditNote.listConcepts.length === 0 && (
														<div className='text-center'>
															<div style={{ textAlign: "-webkit-center" }} className='d-block'>
																<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "32%" }} alt="" />
															</div>
															<p className='f-w-600 f-14 badge badge-light-primary'>No hay elementos por mostrar</p>
														</div>
													)
												}
												{
													formCreditNote.listConcepts.length > 0 && (<div className='table-responsive'>
														<Table>
															<thead>
																<tr className='bg-primary'>
																	<th className='text-white'>Clave Prod/Serv</th>
																	<th className='text-white'>Unidad</th>
																	<th className='text-white'>Descripción</th>
																	<th className='text-white'>Cantidad</th>
																	<th className='text-white'>Precio Unitario</th>
																	<th className='text-white'>Importe</th>
																	<th className='text-white'></th>
																</tr>
															</thead>
															{
																rowMarkUp
															}
														</Table>
													</div>)
												}
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Row className='container-fluid d-flex full-height'>
									<Col sm="6" md="6" lg="6">
										<Card>
											<CardHeader className='p-3'>
												<div className='d-flex align-items-center w-100 '>
													<b className='mr-5'>Comentario adjuntos a la nota de crédito</b>
												</div>
											</CardHeader>
											<CardBody className='p-3'>
												<Row>
													<Col sm="12" md="12" lg="12">
														<FormGroup>
															<Input type='textarea' rows="5" placeholder='Comentarios' className="input-air-primary input-xs rounded-sm" value={formCreditNote.Comments} onChange={(e) => {
																setFormCreditNote({
																	...formCreditNote,
																	Comments: e.target.value
																})
															}} />
														</FormGroup>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
									<Col sm="6" md="6" lg="6">
										<Card>
											<CardHeader className='p-3'>
												<div className='d-flex align-items-center w-100 '>
													<b className='mr-5'>Total</b>
												</div>
											</CardHeader>
											<CardBody className='p-3'>
												<Row>
													<Col sm="12" md="12" lg="12">
														<table className="table table-stripped table-sm">
															<tbody>
																<tr>
																	<td className="text-start">
																		<b>Subtotal</b>
																	</td>
																	<td className="text-end fs-6">$ {FormatNumbers(totalFooter.subtotal, 2)}</td>
																</tr>
																<tr>
																	<td> IVA &nbsp;&nbsp;&nbsp; 16.00% </td>
																	<td className="text-end fs-6">$ {FormatNumbers(totalFooter.total_iva, 2)}</td>
																</tr>
																<tr>
																	<td> Retenciones &nbsp;&nbsp;&nbsp; 1.25% </td>
																	<td className="text-end fs-6">$ {FormatNumbers(totalFooter.total_retentions, 2)}</td>
																</tr>
																<tr>
																	<td className="text-start">
																		<b>Total</b>
																	</td>
																	<td className="text-end fs-6">$ {FormatNumbers(totalFooter.total, 2)}</td>
																</tr>
															</tbody>
														</table>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
					</>
					)}
			</Container>
			{
				modalListInvoices && (
					<ModalInvoiceRelateds
						modalTitle="Nota de crédito"
						modalListEgress={modalListInvoices}
						toggleModalListEgress={() => setModalListInvoices(false)}
						listNims={listRelatedsInvoices}
						handleSelectInvoice={setSelectedInvoices}
						setPage={setPage}
						page={page}
						setLimit={setLimit}
						limit={limit}
						totalPages={totalPages}
					/>
				)
			}

			{
				showLoading && <ModalLoading
					showLoding={showLoading}
					handleLoading={() => {
						setShowLoading(!showLoading);
					}}
				/>
			}
		</Fragment>
	)
}
