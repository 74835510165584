import React, { Fragment, useState } from 'react'
import { SearchAsync } from '../../components/elements/SearchAsync';
import { Button, Card, CardBody, Col, Container, FormGroup, Row, Table, CardHeader, Collapse, Label, Input, ButtonGroup, Badge, Alert, FormFeedback } from 'reactstrap';
import { useQuatation } from '../../hooks/quoter/useQuatation'
import { TableStriped } from '../../components/tables/TableStriped';

import './QuoteStyle.css';
import { useHistory } from 'react-router-dom';
import 'animate.css';
import { TableQuoter } from './TableQuoter';
import { ModalViewExamsQuoter } from './ModalViewExamsQuoter';
import { ModalRepeatProfile } from '../../components/workOrders/ModalRepeatProfile';
import DeniReactTreeView from "deni-react-treeview"
import Select from 'react-select';


export const IndexQuoter = () => {


	const {
		//MODALS
	   modalTitle, modalRepeatProfile, modalViewExamsP,
		toggleModalViewExams, toggleRepeatProfile,

		//QUOTER VALUES
		observations, listQuoterTest, name, paternal_surname, maternal_surname, email,
		handleInputChange,
		totalW, subTotalW, totalTaxesW,
		examProfileState, viewExamsProfile, times,
		viewButtons, validationsRepeat,

		//QUOTER METHODS
		handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
		deleteExamProfile, handleCheckUrgent, handleCheckSpecimen,
		handleViewExamsProfile, handleCancellQuoter, saveCreate,
		printPdf, sentPdf, downdloadpdf, formValues, phone,
		validationsSG,
		handleChangePercentage,
		lab,
		
        // ? CONFIGURATION QUOTER
        itemsTree,treeviewRef,

		// ? CONFIGURATION TYPE CLIENT 
		handleChangeTypeClient,chekTypeClient,id_agreement,listAgreement,handleSelectValues,loadingQuoter,number_format,

		 // ? CONFIGURATION SEARCH QUOTER
		 dataQuoter, totalPageCount, currentPage, nextPage, previousPage, goToPage,
		 handleFilterValues,date_ini,date_end,searchQuotes,downdloadQuoter, handleMouseEnter,handleMouseLeave,isHovered,
		 code_quoter

	} = useQuatation();


	let branchs = localStorage.getItem('branchName');
	let user = localStorage.getItem('nameUser');

	const divStyle = {
		backgroundColor: isHovered ? "#1e90ff" : "#46afe5",
		borderColor: "#46afe5",
		color: "#fff",
		textAlign: "center",
		cursor: "pointer",
		padding: "8px 16px",  
	  };

	  const divStyleClean = {
		backgroundColor: isHovered ? "#dc3545b3" : "#dc35455e",
		borderColor: "#dc35455e",
		color: "#fff",
		textAlign: "center",
		cursor: "pointer",
		padding: "8px 16px",  
	  };




	

	
    const onRenderItem = (item) => {
        const icon = item.isLeaf ? <i className='icofont icofont-prescription' ></i> : <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/typeService.svg")} style={{ width: "21px", height: "21px" }} />; 
        const color = item.state === 1 ? "#000000" : item.state === 2 ?  "#3c413ca6"  : item.state === 3 ? "#1f9ad9"  : item.state === 4 ? "#1f9ad9a3" :"#343a40"
    
        return (
            <div style={{ color, display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>{icon}</span>
                {item.text}
            </div>
        );
    };

	const {
		nameV, paternal_surnameV, maternal_surnameV
	} = validationsSG;


	return (
		<Fragment>
		<Container fluid={true} className='p-l-0 p-r-0'>
		<Row className='mt-2  '>
			<Col sm="12">
				<Row className='pt-3'>
					<Col sm="9">
					
					{
						!loadingQuoter && <Card>
						<CardHeader style={{ paddingTop: "10px", paddingBottom: "10px" }} className='text-rigth'>
							<Row>
								<Col sm="12" md="12" lg="4" >
									<Button   type="button" color='warning'  className='btn-block round btn-air-secondary text-center' onClick={() => handleChangeTypeClient(0)} outline={chekTypeClient === 0 ? false : true} >
									<div className='w-100'>
								    <Col sm="12" style={{textAlign:"center"}}>
										<img alt="flask"  src={require("../../assets/images/price/codeQuoter.svg")} style={{ width: "28px", height: "28px" }} />
									</Col>
									</div>
										{<span className='f-w-600'>Buscar cotización</span>}
										</Button>
								</Col>
								<Col sm="12" md="12" lg="4">
								<Button disabled={viewButtons ? "" : true} color="info" type="button"  className='btn-block round btn-air-secondary text-center' onClick={() => handleChangeTypeClient(1)} outline={chekTypeClient === 1 ? false : true}>
									<Col sm="12" style={{textAlign:"center"}}>
									<img alt="flask"  src={require("../../assets/images/price/particular-company.svg")} style={{ width: "28px", height: "28px" }} />
									</Col>
									<span className='f-w-600'>Paciente particular</span>
								</Button>
								</Col>
								<Col sm="12" md="12" lg="4">
								<Button disabled={viewButtons ? "" : true} color="primary" type="button"  className='btn-block text-center' onClick={() => handleChangeTypeClient(3)} outline={chekTypeClient === 3 ? false : true}>
									<Col sm="12" style={{textAlign:"center"}}>
									<img alt="flask"  src={require("../../assets/images/price/company-micro.svg")} style={{ width: "28px", height: "28px" }} />
									</Col>
									<span className='f-w-600'>Empresas</span>
								</Button>
								</Col>
								
							</Row>
						</CardHeader>
						<CardBody className={chekTypeClient === 0 ? "d-none" : ""}  style={{ paddingTop: "15px", paddingLeft: "15px", paddingRight: "15px" }}>
							<Row>
								<Col  sm="12" md="12" lg="6" className='pt-4' >
								<h6  className='f-w-600'>Cliente</h6>
								<Row>
									{
										chekTypeClient === 3 && <Col sm="12">
										<div className='form-group'>
											<label className='txt-secondary' >Selecciona el convenio</label>
										<Select
										    isDisabled={viewButtons ? "" : true}
											classNamePrefix="select"
											name="id_agreement"
											value={id_agreement}
											placeholder="Selecciona una empresa"
											options={listAgreement}
											onChange={(e) => handleSelectValues(e, "id_agreement")}
																						
										/>
										</div>
									</Col>
									}
									{
										<Col sm="12">
											<Row>
												<Col sm="6">
												<Label className="col-form-label">Nombres <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
														    disabled={ viewButtons ? "" : true}
															type="text"
															name="name"
															value={name}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															className={`form-control form-control-sm input-air-primary b-r-9 ${(nameV ? 'border-danger' : '')}`}
															invalid={nameV}
														/>
														<FormFeedback>Debes llenar este campo</FormFeedback>
												</Col>
												<Col sm="6">
												<Label className="col-form-label">Apellido Paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
															type="text"
															disabled={ viewButtons ? "" : true}
															name="paternal_surname"
															value={paternal_surname}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															className={`form-control form-control-sm input-air-primary b-r-9 ${(paternal_surnameV ? 'border-danger' : '')}`}
															invalid={paternal_surnameV}
														/>
														<FormFeedback>Debes llenar este campo</FormFeedback>
												</Col>
												<Col sm="6">
												<Label className="col-form-label">Apellido Materno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
															type="text"
															disabled={ viewButtons ? "" : true}
															name="maternal_surname"
															value={maternal_surname}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															invalid={maternal_surnameV}
															className={`form-control form-control-sm input-air-primary b-r-9 ${(maternal_surnameV ? 'border-danger' : '')}`}
														/>
														<FormFeedback>Debes llenar este campo</FormFeedback>
												</Col>
												<Col sm="6">
												<Label className="col-form-label"> Telefono </Label>
														<Input
															type="text"
															disabled={ viewButtons ? "" : true}
															name="phone"
															value={phone}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															className="form-control form-control-sm input-air-primary b-r-9"
														/>
												
												</Col>
												<Col sm="12" className='pt-2'>
												<Label className="col-form-label">Correo electronico </Label>
														<Input
															type="text"
															disabled={ viewButtons ? "" : true}
															name="email"
															value={email}
															required={true}
															autoComplete="nope"
															onChange={handleInputChange}
															className="form-control form-control-sm input-air-primary b-r-9"
														/>
												
												</Col>
											</Row>
										</Col>
									}
								</Row>
								
								</Col>
								<Col  sm="12" md="12" lg="6" className='pt-4' >
								<h6 className='f-w-600' >Datos Generales</h6>
								<Col sm="12" className='disabled disableLabel pt-4 col-sm-12 pb-3' >
								<i className="fa fa-circle-o text-info"> </i> <b>Responsable:</b> <label className='text-secondary'>&nbsp;&nbsp;{user}</label>
									<br />
								<i className="fa fa-circle-o text-info"> </i>  <b>Sucursal:</b><label className='text-secondary'>&nbsp;&nbsp;{branchs}</label>
									<br />
								<i className="fa fa-circle-o text-info"> </i>  <b>Paciente:</b><label className='text-secondary'>&nbsp;{formValues.name}&nbsp;{formValues.paternal_surname}&nbsp;{formValues.maternal_surname}</label>
								</Col>
											
								<Col sm="12" className='pt-4'>
								<label  >Observaciones </label>
									<textarea
									disabled={ viewButtons ? "" : true}
									name="observations"
									className="form-control input-air-primary form-control-sm "
									value={observations}
									onChange={handleInputChange}
									style={{fontSize:"11px"}}
									/>
									</Col>
								</Col>
							</Row>
						</CardBody>
						{
							chekTypeClient === 0 && <CardBody>
							<div className="table-responsive">
								{
									dataQuoter.length === 0 ?
										<div style={{ textAlign: "-webkit-center" }}>
											<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")}  style={{ width: "40%" }} alt="" />   <br />                                         
											<br /> <p className="f-18">{"No se a registrado ningún resultado."}</p>
											</div>
											:
											<TableStriped
												notMaped={[ "visibleTable"]}
												methodsActions={false}
												cabeceras={["Código", "Cliente", "Estatus", "Fecha de uso"]}
											    items={dataQuoter}
												nextPage={nextPage}
												previousPage={previousPage}
												totalPageCount={totalPageCount}
												currentPage={currentPage}
												goToPage={goToPage}
												dataBadge={true}
												dataBadgeType={[
													{
														type: "idAction",
														method: downdloadQuoter,
														icon: "fa fa-file-pdf-o f-12",
														name: "navigateUpdate",
														color: 'dark',
														tooltip: "Descargar",
													},
												
													
												]}

											/>
								}
							</div>
							</CardBody>
						}
					</Card>
					}
					{
						loadingQuoter && <div className='pt-4' >
							<>
							    <Row>
									<Col sm="12" md="12" lg="12" className='text-center'>
										<img alt='modal-preview' src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "10%" }} />
									</Col>
									<Col sm="12" md="12" lg="12" className='text-center mt-1'>
										<p>Cargando ...</p>
									</Col>
								</Row>
							</>
						</div>
					}
					</Col>
					<Col xs="12"  md="3" lg="3" xl="3">
					{
						chekTypeClient !==0 && <Card className="d-none d-sm-block" style={  chekTypeClient === 3 ? {height:"480px"} :  {height:"400px"} }>
						<Col sm="12" >
						<h6 className='txt-secondary pt-2'>Resúmen del pago</h6>
						<div className='hr-detail-patient '></div> <br />
						<div  className="table-responsive pt-1" >
							<Table style={{ fontSize: "11px" }}>
								<thead>
									<tr style={{ backgroundColor: "#46AFE5" }}>
									<td colSpan={2} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
									Detalle de movimientos
									</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ padding: "5px" }}>
											<label className='f-12'>Subtotal</label>
										</td>
										<td className="text-center" style={{ padding: "5px" }}>
											<label>$ {(subTotalW).toFixed(2)}</label>
										</td>
									</tr>
									<tr>
									<td style={{ padding: "5px" }}>
										<label style={{fontSize:"13px"}} className={'f-w-600 txt-primary' }  >I.V.A</label>
									</td>
									<td className="text-center" style={{ padding: "5px" }}>
										<label>${(totalTaxesW.toFixed(2))}</label>
									</td>
									</tr>
									<tr>
										<td style={{ padding: "5px" }}>
											<label style={{fontSize:"13px"}} className='f-w-600'>Total</label>
										</td>
										<td className="text-center f-w-600 bg-dark" style={{ padding: "5px" }}>
											<label style={{fontSize:"13px"}} className='f-w-600'>$ {(totalW).toFixed(2)}</label>
										</td>
									</tr>
								</tbody>
							</Table>
						 </div>
						 <Col sm="12" className='p-l-0 p-r-0 pt-5' >
							{
								listQuoterTest.length > 0 &&
									<ButtonGroup className={viewButtons ? "btn-group-pill btn-group-sm" : "d-none"} size='sm' style={{ width: '100%' }}>
									<Button
										outline
										color='primary-2x'
										onClick={saveCreate}
									>
								<label className="labelSaveCreate">{"Cotizar"}</label>
								</Button>
								<Button
									className='btn-air-danger'
									outline
									color='danger'
									onClick={() => handleCancellQuoter()}
								>
								<label className='labelSaveCreate'>Cancelar</label>
								</Button>
								</ButtonGroup>
							}
						 </Col>
						 <Col sm="12" className={viewButtons ? "d-none" : " pt-3 p-l-0 p-r-0"}  > <br />
						 <h6 className='txt-secondary pt-2'>Acciones</h6>
						<div className='hr-detail-patient '></div> <br />
						<Col sm="12">
						<Row>
							<Col sm="4" className={viewButtons ? "d-none" : "p-2"}> <br />
								<button color="secondary" class="btn btn-outline-secondary   btn-xs" onClick={() => sentPdf()}>{"Enviar"} &nbsp; <i className="icofont icofont-envelope colorbutton"></i></button>
							</Col>
							<Col sm="4" className={viewButtons ? "d-none" : "p-2"}> <br />
								<button color="info" class="btn btn-outline-info  btn-xs" onClick={() => downdloadpdf()}>{"Descargar"}&nbsp;<i className="icofont icofont-download-alt"> </i></button>
							</Col>
							<Col sm="1" className={viewButtons ? "d-none" : "p-2"}> <br />
								<button color="info" class="btn btn-outline-primary b btn-xs" onClick={() => printPdf()}>{"Imprimir"}&nbsp;<i className="icofont icofont-print"></i></button>
							</Col>
						</Row>
						</Col>
						
						</Col>

						
						</Col>
					


					</Card>
					}
					{
						chekTypeClient === 0 && <Card>
							<CardHeader className='p-3  bg-warning'>
								<i className='fa fa-filter fIconFilter f-20 '></i>
								<label className='f-14'>Filtros de Busqueda</label>
							</CardHeader>
							<CardBody className='p-3'>
								<Row className='pb-4'>
									<Col sm="12" md="12" lg="12" className='text-center'>
									</Col>  
									<Col sm="12" md="12" lg="12">
										<label className='f-w-600 f-12'>Fecha de incio</label>
										<Input type='date' style={{fontSize:"14px"}} value={date_ini} className='border-0 mr-2' name='start_date'  onChange={handleFilterValues}  ></Input>
									</Col>    
									<Col sm="12" md="12" lg="12" className='pt-2'>
										<label className='f-w-600 f-12'>Fecha Final</label>
										<Input type='date'  style={{fontSize:"14px"}} value={date_end}  className='border-0 mr-2' name='end_date' onChange={handleFilterValues}  > </Input>
									</Col>  
									<Col sm="12" md="12" lg="12" className='pt-2 pb-4'>
										<label className='f-w-600 f-12'>Codigo</label>
										<Input type='text'  style={{fontSize:"14px"}} value={code_quoter}  className=' mr-2' name='code_quoter' onChange={handleFilterValues}  > </Input>
									</Col>  
									 
									<Col sm="12" md="12" lg="12" className='pt-1'>
										<div  onClick={ () => searchQuotes() } className='pointer' style={divStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
										Buscar
										</div> 
									</Col>
									
								</Row>
						
							</CardBody>
						</Card>
					}
					
					</Col>
				
				</Row>
			</Col>
		</Row>
		{
			chekTypeClient !== 0 && <Row>
			<Col sm="12" className='pt-2 pb-4'>
			<Card>
				<CardBody className='pl-4 pb-4 pt-4 pr-4' >
				<h5 className='f-w-600' >Estudios solicitados</h5>
				
				<Col sm="12" className={viewButtons ? ' pt-3 p-l-0 p-r-0 ' : "d-none" }    >			
					<FormGroup>
						<SearchAsync
							className="col-md-12"
							method={handleSelectExamChange}
							loincState={examProfileState}
							url="Profiles/SearchProfileExam"
							maxLenght={3}
							selectSize="col-md-12"
							isWorkOrder={true}
						/>
					</FormGroup>				
				</Col>
				<Col sm="12" className='p-l-0 p-r-0' >
				<Row>
					<Col sm="4" className='p-l-0 p-r-0' >
					<Col sm="12" md="12" lg="12" xl="12" className='pr-0 pt-2'  >
					<h5 className='f-w-600' >Indicaciones de los estudios</h5>
					<div>
					{itemsTree && itemsTree.length > 0 ? (
						<DeniReactTreeView
						    key={JSON.stringify(itemsTree)}
							ref={treeviewRef}
							style={{ marginRight: '10px', marginBottom: '10px', width: "97%", textAlign: "center" }}
						    showCheckbox={false}
							showIcon={false}
							items={itemsTree}
							onRenderItem={onRenderItem}
						/>
						) : (
							<p className='txt-secondary' >Sin indicaciones previas</p>
						)}
					</div>																	
				</Col>
					</Col>
					<Col sm="8" className='p-l-0 p-r-0 pt-5' >
					<div className='table-responsive'>
						{
							listQuoterTest.length > 0 && <TableQuoter
								key={"table"}
								items={listQuoterTest}
								{...{ handleCheckUrgent, deleteExamProfile, changeSelectSamplings, changeValueTimeSampling, times, 
									handleCheckSpecimen, deleteExamProfile, viewButtons, handleViewExamsProfile,handleChangePercentage,lab ,number_format
								}}
							/>
						}
					</div>
					</Col>
				</Row>
				</Col>
				</CardBody>
			</Card>
			</Col>
		</Row>
		}
		{
			chekTypeClient !==0 && <Card  className="d-lg-none" style={  chekTypeClient === 3 ? {height:"480px"} :  {height:"400px"} }>
						<Col sm="12" >
						<h6 className='txt-secondary pt-2'>Resúmen del pago</h6>
						<div className='hr-detail-patient '></div> <br />
						<div  className="table-responsive pt-1" >
							<Table style={{ fontSize: "11px" }}>
								<thead>
									<tr style={{ backgroundColor: "#46AFE5" }}>
									<td colSpan={2} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
									Detalle de movimientos
									</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ padding: "5px" }}>
											<label className='f-12'>Subtotal</label>
										</td>
										<td className="text-center" style={{ padding: "5px" }}>
											<label>$ {(subTotalW).toFixed(2)}</label>
										</td>
									</tr>
									<tr>
									<td style={{ padding: "5px" }}>
										<label style={{fontSize:"13px"}} className={'f-w-600 txt-primary' }  >I.V.A</label>
									</td>
									<td className="text-center" style={{ padding: "5px" }}>
										<label>${(totalTaxesW.toFixed(2))}</label>
									</td>
									</tr>
									<tr>
										<td style={{ padding: "5px" }}>
											<label style={{fontSize:"13px"}} className='f-w-600'>Total</label>
										</td>
										<td className="text-center f-w-600 bg-dark" style={{ padding: "5px" }}>
											<label style={{fontSize:"13px"}} className='f-w-600'>$ {(totalW).toFixed(2)}</label>
										</td>
									</tr>
								</tbody>
							</Table>
						 </div>
						 <Col sm="12" className='p-l-0 p-r-0 pt-5' >
							{
								listQuoterTest.length > 0 &&
									<ButtonGroup className={viewButtons ? "btn-group-pill btn-group-sm" : "d-none"} size='sm' style={{ width: '100%' }}>
									<Button
										outline
										color='primary-2x'
										onClick={saveCreate}
									>
								<label className="labelSaveCreate">{"Cotizar"}</label>
								</Button>
								<Button
									className='btn-air-danger'
									outline
									color='danger'
									onClick={() => handleCancellQuoter()}
								>
								<label className='labelSaveCreate'>Cancelar</label>
								</Button>
								</ButtonGroup>
							}
						 </Col>
						 <Col sm="12" className={viewButtons ? "d-none" : " pt-3 p-l-0 p-r-0"}  > <br />
						 <h6 className='txt-secondary pt-2'>Acciones</h6>
						<div className='hr-detail-patient '></div> <br />
						<Col sm="12">
						<Row>
							<Col sm="4" className={viewButtons ? "d-none" : "p-2"}> <br />
								<button color="secondary" class="btn btn-outline-secondary   btn-xs" onClick={() => sentPdf()}>{"Enviar"} &nbsp; <i className="icofont icofont-envelope colorbutton"></i></button>
							</Col>
							<Col sm="4" className={viewButtons ? "d-none" : "p-2"}> <br />
								<button color="info" class="btn btn-outline-info  btn-xs" onClick={() => downdloadpdf()}>{"Descargar"}&nbsp;<i className="icofont icofont-download-alt"> </i></button>
							</Col>
							<Col sm="1" className={viewButtons ? "d-none" : "p-2"}> <br />
								<button color="info" class="btn btn-outline-primary b btn-xs" onClick={() => printPdf()}>{"Imprimir"}&nbsp;<i className="icofont icofont-print"></i></button>
							</Col>
						</Row>
						</Col>
						
						</Col>

						
						</Col>
					


			</Card>
		}

		
		
		

		</Container>

			<ModalViewExamsQuoter
				{
				...{ modalViewExamsP, toggleModalViewExams, viewExamsProfile, handleViewExamsProfile }
				}
			/>

			<ModalRepeatProfile
				{
				...{
					modalRepeatProfile, validationsRepeat, modalTitle, toggleRepeatProfile,
				}
				}
			/>

		</Fragment >
	)
}